import signupImage from "../../assets/signup/signup.png";
import eye from "../../assets/signup/eye.svg";
import { useCallback, useEffect, useMemo, useState } from "react";
import "./createpassword.scss";
import checkmark from "../../assets/signup/checkmark.svg";
import closemark from "../../assets/signup/closemark.svg";
import { resetPasswordPost } from "../../utils/api/resetPassword";
import { resetPasswordValidator } from "../../utils/validators/registerValidator";
import Visibilityoff from "../../assets/signup/visibilityoff.svg";
import { resetPasswordInitial } from "../../utils/constants/selected";
import { ResetPasswordPayload } from "../../utils/types/main";
import { useLocation, useNavigate } from "react-router-dom";
import { getForgotResetPassword } from "../../utils/api/getForgotResetPassword";
import { toast, ToastContainer } from "react-toastify";
import Loader from "../loader/Loader";

const CreatePassword = () => {
  const token = useLocation();
  const navigate = useNavigate();
  const [isTokenVerified, setisTokenVerified] = useState<{
    loading: boolean;
    verified: boolean;
  }>({
    loading: false,
    verified: false,
  });
  const [passwordShown, setPasswordShown] = useState<boolean>(false);
  const [passwordShow, setPasswordShow] = useState<boolean>(false);
  const [errorData, SetErrorData] = useState<any>("");
  const [text, setText] = useState("");
  const [isValidationActive, setisActiveValidation] = useState<boolean>(false);
  const [resetPasswordData, setResetPasswordData] =
    useState<ResetPasswordPayload>(resetPasswordInitial);
  const [passwordMatch, setPasswordMatch] = useState<{
    display: boolean;
    minCheck: boolean;
    minMessage: string;
    maxCheck: boolean;
    maxMessage: string;
    lowerCheck: boolean;
    lowerMessage: string;
    upperCheck: boolean;
    upperMessage: string;
    numberCheck: boolean;
    numberMessage: string;
    specialCheck: boolean;
    specialDisplay: string;
  }>({
    display: false,
    minCheck: false,
    minMessage: "Minimum 6 characters",
    maxCheck: false,
    maxMessage: "Maximum 15 characters",
    lowerCheck: false,
    lowerMessage: "At least 1 lower character",
    upperCheck: false,
    upperMessage: "At least 1 upper character",
    numberCheck: false,
    numberMessage: "At least 1 number",
    specialCheck: false,
    specialDisplay: "At least 1 special character",
  });
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };
  const togglePassVisiblity = () => {
    setPasswordShow(passwordShow ? false : true);
  };
  const resetSubmit = async () => {
    setisActiveValidation(true);
    const { error, isError } = resetPasswordValidator(resetPasswordData);

    if (isError) {
      SetErrorData(error);
    } else {
      SetErrorData(error);
      const response = await resetPasswordPost(resetPasswordData, currToken);

      if (response?.status?.code === 200) {
        setResetPasswordData({ confirmNewPassword: "", newPassword: "" });
        setText(response?.status?.msg);
        toast.success("Password reset successfully ", {
          position: "top-right",
          theme: "colored",
          autoClose: 3000,
        });
        setTimeout(() => {
          navigate("/login");
        }, 2000);
      } else {
        toast.error("Something went wrong please try again", {
          position: "top-right",
          theme: "colored",
          autoClose: 3000,
        });
      }
    }
  };
  const passwordCheck = (password: string) => {
    const currentCheck: {
      minCheck: boolean;
      maxCheck: boolean;
      lowerCheck: boolean;
      upperCheck: boolean;
      numberCheck: boolean;
      specialCheck: boolean;
    } = {
      minCheck: false,
      maxCheck: false,
      lowerCheck: false,
      upperCheck: false,
      numberCheck: false,
      specialCheck: false,
    };
    if (/[a-z]/.test(password)) {
      currentCheck.lowerCheck = true;
    } else {
      currentCheck.lowerCheck = false;
    }
    if (/[A-Z]/.test(password)) {
      currentCheck.upperCheck = true;
    } else {
      currentCheck.upperCheck = false;
    }
    if (/[0-9]/.test(password)) {
      currentCheck.numberCheck = true;
    } else {
      currentCheck.numberCheck = false;
    }
    if (/[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(password)) {
      currentCheck.specialCheck = true;
    } else {
      currentCheck.specialCheck = false;
    }
    if (password.length > 5) {
      currentCheck.minCheck = true;
    } else {
      currentCheck.minCheck = false;
    }
    if (password.length < 16 && password !== "") {
      currentCheck.maxCheck = true;
    } else {
      currentCheck.maxCheck = false;
    }
    setPasswordMatch({
      ...passwordMatch,
      lowerCheck: currentCheck.lowerCheck,
      upperCheck: currentCheck.upperCheck,
      numberCheck: currentCheck.numberCheck,
      specialCheck: currentCheck.specialCheck,
      minCheck: currentCheck.minCheck,
      maxCheck: currentCheck.maxCheck,
    });
  };
  const validationOnly = (resetPasswordData: ResetPasswordPayload) => {
    const { error } = resetPasswordValidator(resetPasswordData);
    SetErrorData(error);
  };
  const currToken = useMemo(() => {
    return token.search.split("=")[1];
  }, [token]);
  const verifyEmail = useCallback(
    async (currToken: string) => {
      setisTokenVerified({ ...isTokenVerified, loading: true });
      const response = await getForgotResetPassword(currToken);
      if (response?.status?.code === 200) {
        setisTokenVerified({ loading: false, verified: true });
      } else {
        setisTokenVerified({ verified: false, loading: false });
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currToken]
  );
  useEffect(() => {
    if (!currToken) return;
    verifyEmail(currToken);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currToken]);
  return (
    <>
      {isTokenVerified.verified && !isTokenVerified.loading && (
        <div className="createpassword_Con">
          <div className="createpassword_Left">
            <h2>Reset Password</h2>
            <h5>Please reset a password of your choice</h5>

            <div className="createpassword-login-input">
              <label htmlFor="">Password</label>
              <input
                type={passwordShown ? "text" : "password"}
                placeholder="Enter password"
                value={resetPasswordData.newPassword}
                onChange={(e) => {
                  if (isValidationActive) {
                    validationOnly({
                      ...resetPasswordData,
                      newPassword: e.target.value,
                    });
                  }

                  setResetPasswordData({
                    ...resetPasswordData,
                    newPassword: e.target.value,
                  });
                  passwordCheck(e.target.value);
                }}
                onFocus={() =>
                  setPasswordMatch({ ...passwordMatch, display: true })
                }
                onBlur={() =>
                  setPasswordMatch({ ...passwordMatch, display: false })
                }
                style={{
                  border: `${
                    errorData?.password
                      ? "1px solid #FF0000"
                      : "1px solid #aaaaaa"
                  }`,
                }}
              />
              <div>
                <img
                  src={passwordShown ? eye : Visibilityoff}
                  onClick={togglePasswordVisiblity}
                  className={passwordShown ? "Visibility-off" : ""}
                  style={{ cursor: "pointer" }}
                  alt="hidden"
                />
              </div>
              {errorData && (
                <span className="signup__Password__Match__Con__Red">
                  {errorData?.password}
                </span>
              )}
              {(passwordMatch?.display || isValidationActive) &&
                !errorData?.password && (
                  <section className="signup__Password__Match__Con">
                    <p>
                      {passwordMatch.minCheck ? (
                        <img
                          src={checkmark}
                          alt=""
                          className="signup__Password__Match__Con__img"
                        />
                      ) : (
                        <img
                          src={closemark}
                          alt=""
                          className="signup__Password__Match__Con__img"
                        />
                      )}{" "}
                      <span
                        className={`${
                          passwordMatch.minCheck
                            ? "signup__Password__Match__Con__Green"
                            : "signup__Password__Match__Con__Red"
                        }`}
                      >
                        {passwordMatch.minMessage}
                      </span>{" "}
                    </p>
                    <p>
                      {passwordMatch.maxCheck ? (
                        <img
                          src={checkmark}
                          alt=""
                          className="signup__Password__Match__Con__img"
                        />
                      ) : (
                        <img
                          src={closemark}
                          alt=""
                          className="signup__Password__Match__Con__img"
                        />
                      )}{" "}
                      <span
                        className={`${
                          passwordMatch.maxCheck
                            ? "signup__Password__Match__Con__Green"
                            : "signup__Password__Match__Con__Red"
                        }`}
                      >
                        {passwordMatch.maxMessage}
                      </span>{" "}
                    </p>
                    <p>
                      {passwordMatch.lowerCheck ? (
                        <img
                          src={checkmark}
                          alt=""
                          className="signup__Password__Match__Con__img"
                        />
                      ) : (
                        <img
                          src={closemark}
                          alt=""
                          className="signup__Password__Match__Con__img"
                        />
                      )}{" "}
                      <span
                        className={`${
                          passwordMatch.lowerCheck
                            ? "signup__Password__Match__Con__Green"
                            : "signup__Password__Match__Con__Red"
                        }`}
                      >
                        {passwordMatch.lowerMessage}
                      </span>{" "}
                    </p>
                    <p>
                      {" "}
                      {passwordMatch.upperCheck ? (
                        <img
                          src={checkmark}
                          alt=""
                          className="signup__Password__Match__Con__img"
                        />
                      ) : (
                        <img
                          src={closemark}
                          alt=""
                          className="signup__Password__Match__Con__img"
                        />
                      )}{" "}
                      <span
                        className={`${
                          passwordMatch.upperCheck
                            ? "signup__Password__Match__Con__Green"
                            : "signup__Password__Match__Con__Red"
                        }`}
                      >
                        {passwordMatch.upperMessage}
                      </span>{" "}
                    </p>
                    <p>
                      {passwordMatch.numberCheck ? (
                        <img
                          src={checkmark}
                          alt=""
                          className="signup__Password__Match__Con__img"
                        />
                      ) : (
                        <img
                          src={closemark}
                          alt=""
                          className="signup__Password__Match__Con__img"
                        />
                      )}{" "}
                      <span
                        className={`${
                          passwordMatch.numberCheck
                            ? "signup__Password__Match__Con__Green"
                            : "signup__Password__Match__Con__Red"
                        }`}
                      >
                        {passwordMatch.numberMessage}
                      </span>
                    </p>
                    <p>
                      {passwordMatch.specialCheck ? (
                        <img
                          src={checkmark}
                          alt=""
                          className="signup__Password__Match__Con__img"
                        />
                      ) : (
                        <img
                          src={closemark}
                          alt=""
                          className="signup__Password__Match__Con__img"
                        />
                      )}{" "}
                      <span
                        className={`${
                          passwordMatch.specialCheck
                            ? "signup__Password__Match__Con__Green"
                            : "signup__Password__Match__Con__Red"
                        }`}
                      >
                        {passwordMatch.specialDisplay}
                      </span>
                    </p>
                  </section>
                )}
            </div>
            <div className="createpassword-login-input">
              <label htmlFor="">Confirm Password</label>
              <input
                type={passwordShow ? "text" : "password"}
                style={{
                  border: `${
                    errorData?.confirmPassword
                      ? "1px solid #FF0000"
                      : "1px solid #aaaaaa"
                  }`,
                }}
                placeholder="Enter password"
                value={resetPasswordData.confirmNewPassword}
                onChange={(e) => {
                  setResetPasswordData({
                    ...resetPasswordData,
                    confirmNewPassword: e.target.value,
                  });
                  if (!isValidationActive) return;
                  validationOnly({
                    ...resetPasswordData,
                    confirmNewPassword: e.target.value,
                  });
                }}
              />
              <div>
                <img
                  src={passwordShow ? eye : Visibilityoff}
                  onClick={togglePassVisiblity}
                  className={passwordShow ? "Visibility-off" : ""}
                  style={{ cursor: "pointer" }}
                  alt="hidden"
                />
              </div>
              {errorData && (
                <span className="signup__Password__Match__Con__Red">
                  {errorData?.confirmPassword}
                </span>
              )}
            </div>
            <div className="remember_password">
              <span className="response-text">{text}</span>
            </div>
            <div className="submit_btn_area">
              <button
                className="createpassword-btn"
                onClick={() => resetSubmit()}
              >
                Submit
              </button>
            </div>
          </div>

          <div className="createpassword_Right">
            <img src={signupImage} alt="Signup" />
          </div>
          <ToastContainer />
        </div>
      )}
      {isTokenVerified.loading && (
        <div className="createPassword__pagenot__found">
          <Loader size="lg" />
        </div>
      )}
      {!isTokenVerified.verified && !isTokenVerified.loading && (
        <div className="createPassword__pagenot__found">
          <h4>404</h4>
          <p>Page not found</p>
          <a href="https://dev.outreachkart.de/">Go to Home </a>
        </div>
      )}
    </>
  );
};

export default CreatePassword;
