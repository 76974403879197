import { useEffect } from "react";
import "./aboutMid.scss";
import { Link } from "react-router-dom";

const AboutMid = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="aboutMid_Con">
      <h4>About Us</h4>
      <p>
      Welcome to Outreachkart, where we believe in the power of collaboration and the potential of shared knowledge.
       Our platform is dedicated to fostering meaningful connections between advertisers and publishers, 
       creating a space where  <Link to="/blogs" className="footer-text">guest posting</Link> becomes a seamless and enriching experience.</p>
       <h3>Our Mission:</h3>
       <p> At Outreachkart, our mission is to transform the way content is shared and distributed across the digital landscape. 
       We aim to bridge the gap between talented writers seeking a platform to showcase their expertise and publishers eager to discover fresh,
        high-quality content. By doing so, we contribute to the growth and diversity of online communities.</p>
      <h3>Why Outreachkart?</h3>
      <h3>Connecting Voices:</h3>
      <p>Outreachkart is more than just a guest posting platform; it's a vibrant community where voices from various domains come together.
      We believe in the power of diverse perspectives and aim to amplify these voices, creating a rich tapestry of insights and knowledge.</p>

      <h3> Streamlined Collaboration:</h3>
      <p>Our user-friendly interface makes the guest posting process smooth and efficient.
         From finding the right opportunities to submitting your content, 
         Outreachkart provides a seamless experience that allows writers and publishers to focus on what they do best – creating and sharing valuable content.</p>


         <h3>Quality Assurance:</h3>
         <p>We understand the importance of quality content. 
          That's why we have implemented rigorous screening processes to ensure that every piece of content on Outreachkart meets high standards. 
          Whether you're a writer or a publisher, you can trust that the content you encounter is well-researched, insightful, and engaging.</p>

          <h3>Building Relationships:</h3>
          <p>Outreachkart goes beyond a transactional platform; it's a place to build lasting relationships. 
            We encourage open communication and collaboration between writers and publishers, fostering connections that extend beyond a single guest post. 
            Together, we build a network of professionals and enthusiasts who support and inspire each other.</p>
            <h3>Join Us on the Journey:</h3>
          <p>Whether you're a seasoned writer looking to expand your reach or a publisher seeking fresh ideas, Outreachkart welcomes you.
             Join us on this exciting journey of collaboration, creativity, and community building. Together, 
             let's reshape the digital landscape and make a lasting impact through the power of shared knowledge.</p>

<p>Thank you for being a part of Outreachkart ! <Link to="/signup" className="footer-text">Sign Up Now</Link></p>

<h3>Summary:</h3>
<p>OutreachKart is a platform for both publishers and advertisers. Our mission is to make guest posting easy. 
Our user-friendly platform simplifies the process, leaving you free to focus on crafting high-quality content.
 We ensure quality through proper screening that enhances the website’s visibility.</p>

      
    </div>
  );
};

export default AboutMid;
