import React, { useEffect, useState, useRef } from "react";
import "./AddAdminWebsite.scss";

import withAuth from "../../hoc/withAuth";
import "react-toastify/dist/ReactToastify.css";
import SideHeaderAdmin from "../../components/SideHeader/SideHeaderAdmin";
import DashboardHeaderAdmin from "../../components/DashboardHeader/DashboardHeaderAdmin";
//import "./addWebsiteFormDetails.scss";
import info from "../../assets/add_Websites/info.svg";
import {
  AddAdminWebsiteDetailsError,
  AddAdminWebsiteDetailsPayload,
} from "../../utils/types/main";
import { ToastContainer, toast } from "react-toastify";
import { useAppSelector } from "../../store/reduxHooks";
import "react-toastify/dist/ReactToastify.css";
import { addAdminWebsiteValidator } from "../../utils/validators/addWebsitevalidators";
import { getWebsiteCategories } from "../../utils/api/getWebsiteCategories";
import { addAdminWebsiteDetailsPost } from "../../utils/api/postAddWebsiteDetails";
import close from "../../assets/dropdown/close.svg";
import { websiteCategoryFilter } from "../../utils/filters/websitecategoryFilter";
//import { setAddFormDetailsEdit, setFormDisplay } from "../../reducers/addWebsite/addWebsitedataReducer";
import JoditEditor from 'jodit-react';
import { FormControl, FormControlLabel, MenuItem, Select, Switch, SwitchProps, styled } from "@mui/material";
import { guaranteePostValidityArray, backlinkTypeArray, maxBacklinkArray } from "../../utils/constants/selected";

const AddAdminWebsite = () => {
  //const dispatch = useAppDispatch();
  const editor = useRef(null);
  const [displayError, setDisplayError] = useState<AddAdminWebsiteDetailsError>({
    isError: false,
    domainAuthority: false,
    traffic: false,
    websiteName: false,
    websiteUrl: false,
    guestPostPrice: false,
    linkInsertionPrice: false,
    casinoPostPrice: false,
    sampleUrl: false,
    tat: false,
    postGuideline: false,
    contactNumber: false,
    skypeId: false,
    websiteCategoryIdList: false,
    priceError: false,
    sensetivePriceError: false,
    referingDomain: false,
    siteAdmin: false,
    communicationEmail: false,
    emailId: false,
    minPostWordCount: false,
    guaranteePostValidity: false,
    backLinkType: false,
    maxBackLinks: false,
    cryptoPostPrice: false,
    cbdPostPrice: false,
    adultPostPrice: false,
    isSenstiveAccepted: false,
    spamScore: false,
  });

  const addWebsiteState = useAppSelector(
    (state) => state?.addWebsiteState?.addedWebsiteData
  );
  const addWebsiteDetailsEdit = useAppSelector(
    (state) => state.addWebsiteState.addFormDetailsEdit
  );
  const [addFormDetails, setaddFormDetails] =
    useState<AddAdminWebsiteDetailsPayload>(
      {
        ...addWebsiteDetailsEdit,
        websiteUrl:
          addWebsiteState?.checkEligibility?.websiteEligibility.websiteUrl ||
          addWebsiteDetailsEdit.websiteUrl,
        emailId: "publishers@outreachkart.com",
        siteAdmin: "",
        communicationEmail: "",
      }
    );
  const [websiteCategories, setWebsiteCategories] = useState<any>([]);
  const [filteredCategories, setFilteredCategories] = useState<any>([]);
  const [isDropdownDisplay, setIsdropDownDisplay] = useState<boolean>(false);
  const [isMouseOnDropDown, setIsMouseOnDropDown] = useState<boolean>(false);
  const [postCategoryError, setPostCategoryError] = useState<{
    isError: boolean;
    message: string;
  }>({
    isError: false,
    message: "",
  });

  const handleAddFormDetails = async (e: any) => {
    e.preventDefault();
    const innerDisplay = addAdminWebsiteValidator(
      addFormDetails,
      displayError,
      setDisplayError
    );
    if (!innerDisplay.isError) {
      const response = await addAdminWebsiteDetailsPost(addFormDetails);
      //  console.log(response)
      if (response?.status?.code === 200) {
        toast.success("Website details added successfully", {
          position: "top-right",
          theme: "colored",
          autoClose: 5000,
        });
        setSelectedWebCategories({
          id: addWebsiteDetailsEdit?.websiteCategoryIdList || [],
          name: [],
        });

        // setFilteredCategories([]);
        setaddFormDetails({
          domainAuthority: 0,
          traffic: 0,
          websiteName: "",
          websiteUrl: "",
          guestPostPrice: 0,
          referingDomain: 0,
          linkInsertionPrice: 0,
          casinoPostPrice: 0,
          sampleUrl: "",
          tat: 0,
          postGuideline: "",
          contactNumber: "",
          skypeId: "",
          websiteCategoryIdList: [],
          emailId: "publishers@outreachkart.com",
          siteAdmin: "",
          communicationEmail: "",
          minPostWordCount: 700,
          guaranteePostValidity: 'YEARLY',
          backLinkType: 'DOFOLLOW',
          maxBackLinks: 3,
          cryptoPostPrice: 0,
          cbdPostPrice: 0,
          adultPostPrice: 0,
          isSenstiveAccepted: false,
          spamScore: 0,
        });
      } else {
        toast.error("Something went wrong please try again", {
          position: "top-right",
          theme: "colored",
          autoClose: 5000,
        });
      }
    }
    // const innerDisplay = addWebsiteValidator(
    //   addFormDetails,
    //   displayError,
    //   setDisplayError
    // );
    // console.log(innerDisplay);
    // if (!innerDisplay.isError) {
    //   const response = await addWebsiteDetailsPost(addFormDetails);
    //   //  console.log(response)
    //   if (response?.status?.code === 200) {
    //     toast.success("Website details added successfully", {
    //       position: "top-right",
    //       theme: "colored",
    //       autoClose: 5000,
    //     });
    //   //  setIsAddWebsite(true);
    //   //  setShowTable(true);
    //   } else {
    //     toast.error("Something went wrong please try again", {
    //       position: "top-right",
    //       theme: "colored",
    //       autoClose: 5000,
    //     });
    //   }
    // }
  };
  const [selectedWebsiteCategories, setSelectedWebCategories] = useState<any>({
    id: addWebsiteDetailsEdit?.websiteCategoryIdList || [],
    name: [],
  });
  const [searchCategory, setSearchCategory] = useState<any>("");
  const getCategories = async (setWebsiteCategories: React.Dispatch<any>) => {
    const response = await getWebsiteCategories();

    if (response?.status?.code === 200) {
      setWebsiteCategories(response?.data?.websiteCategories?.categories);
      setFilteredCategories(response?.data?.websiteCategories?.categories);
      setSelectedWebCategories(() => ({
        id: addWebsiteDetailsEdit?.websiteCategoryIdList || [],
        name: response?.data?.websiteCategories?.categories
          ?.filter((category: any) => {
            let match = false;
            if (!addWebsiteDetailsEdit?.websiteCategoryIdList) return false;
            for (
              let i = 0;
              i < addWebsiteDetailsEdit.websiteCategoryIdList.length;
              i++
            ) {
              if (
                category.id === addWebsiteDetailsEdit.websiteCategoryIdList[i]
              )
                match = true;
            }
            return match;
          })
          ?.map((category: any) => category?.friendlyName),
      }));
    }
  };

  const setPostGuideline = (val: any) => {
    addFormDetails.postGuideline = val;
  }

  const parseFloatVal = (val: any) => {
    if (val === '') {
      return 0;
    }
    if (val > 9007199254740991) {
      var temp = val.toString().substring(0, val.toString().length - 1);
      var tempVal = parseFloat(temp);
      if (tempVal > 9007199254740991) {
        return 9007199254740991;
      } else {
        return tempVal;
      }
    } else {
      if (isNaN(parseFloat(val))) {
        return 0;
      } else {
        return parseFloat(val);
      }
    }
  }

  const IOSSwitch = styled((props: SwitchProps) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(16px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
          opacity: 1,
          border: 0,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#33cf4d',
        border: '6px solid #fff',
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color:
          theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 22,
      height: 22,
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500,
      }),
    },
  }));

  useEffect(() => {
    getCategories(setWebsiteCategories);
    setDisplayError(displayError);
    // return ()=>{
    //   dispatch(
    //     // setAddFormDetailsEdit({
    //     //   domainAuthority: "",
    //     //   traffic:"",
    //     //   websiteName: "",
    //     //   websiteUrl:  "",
    //     //   guestPostPrice:  0,
    //     //   referingDomain: "",
    //     //   linkInsertionPrice: 0,
    //     //   casinoPostPrice:  0,
    //     //   sampleUrl: "",
    //     //   tat: "",
    //     //   postGuideline:  "",
    //     //   contactNumber:  "",
    //     //   skypeId: "",
    //     //   websiteCategoryIdList:  [],
    //     // })
    //    // setAddFormDetailsEdit(addWebsiteDetailsEdit)
    //   );
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (

    <div className={`dashboard_Con   `}>
      <SideHeaderAdmin />

      <div className={`dashboard_Con__Right`}>
        <DashboardHeaderAdmin />
        <div className="publisherWebsiteMid__Con">
          <div className="pubLisherAddWebsite__Con">
            <h4>Add Website </h4>

            <div className="AddWebsiteFormDetails__Con">
              <div className="AddWebsiteFormDetails__Form">
                <section className="AddWebsiteFormDetails__Form__Domain" >

                  <div className="AddWebsiteFormDetails__Form__Domain__Authority">
                    <label htmlFor="">
                      Select Publisher <span>*</span>{" "}
                    </label>
                    <input
                      type="text"
                      name=""
                      placeholder="Enter Publisher email id"
                      id=""
                      value={addFormDetails.emailId}
                      onChange={(e: any) =>
                        setaddFormDetails({
                          ...addFormDetails,
                          emailId: e.target.value,
                        })
                      }
                    />
                    {displayError.emailId && (
                      <p>
                        {" "}
                        <img src={info} alt="" /> <span>{displayError.emailId}</span>{" "}
                      </p>
                    )}
                  </div>
                  <div className="AddWebsiteFormDetails__Form__Domain__Authority">
                    <label htmlFor="">
                      Website URL<span>*</span>{" "}
                    </label>
                    <input
                      type="text"
                      name=""
                      placeholder="Please enter website URL"
                      id=""
                      max={100}
                      value={addFormDetails.websiteUrl}
                      onChange={(e: any) => {
                        console.log(
                          addWebsiteState?.checkEligibility?.websiteEligibility
                            ?.websiteUrl
                        );
                        setaddFormDetails({
                          ...addFormDetails,
                          websiteUrl: e.target.value,
                        });
                      }}
                    />
                    {displayError.websiteUrl && (
                      <p>
                        {" "}
                        <img src={info} alt="" />{" "}
                        <span>{displayError.websiteUrl}</span>{" "}
                      </p>
                    )}
                  </div>
                </section>
                <section className="AddWebsiteFormDetails__Form__Domain">

                  <div className="AddWebsiteFormDetails__Form__Domain__Authority">
                    <label htmlFor="">
                      Site Admin <span>*</span>{" "}
                    </label>
                    <input
                      type="text"
                      name=""
                      placeholder="Enter email address"
                      id=""
                      value={addFormDetails.siteAdmin}
                      onChange={(e: any) =>
                        setaddFormDetails({
                          ...addFormDetails,
                          siteAdmin: e.target.value,
                        })
                      }
                    />
                    {displayError.siteAdmin && (
                      <p>
                        {" "}
                        <img src={info} alt="" /> <span>{displayError.siteAdmin}</span>{" "}
                      </p>
                    )}
                  </div>
                  <div className="AddWebsiteFormDetails__Form__Domain__Authority">
                    <label htmlFor="">
                      Communication Email<span>*</span>{" "}
                    </label>
                    <input
                      type="text"
                      name=""
                      placeholder="Enter email address"
                      id=""
                      max={100}
                      value={addFormDetails.communicationEmail}
                      onChange={(e: any) => {
                        setaddFormDetails({
                          ...addFormDetails,
                          communicationEmail: e.target.value,
                        });
                      }}
                    />
                    {displayError.communicationEmail && (
                      <p>
                        {" "}
                        <img src={info} alt="" />{" "}
                        <span>{displayError.communicationEmail}</span>{" "}
                      </p>
                    )}
                  </div>
                </section>
                <section className="AddWebsiteFormDetails__Form__Name">

                  <div className="AddWebsiteFormDetails__Form__Name__Website">
                    <label htmlFor="">
                      Website Name <span>*</span>{" "}
                    </label>
                    <input
                      type="text"
                      name=""
                      placeholder="Enter website name"
                      id=""
                      value={addFormDetails.websiteName}
                      onChange={(e: any) =>
                        setaddFormDetails({
                          ...addFormDetails,
                          websiteName: e.target.value,
                        })
                      }
                    />
                    {displayError.websiteName && (
                      <p>
                        {" "}
                        <img src={info} alt="" /> <span>{displayError.websiteName}</span>{" "}
                      </p>
                    )}
                  </div>
                  <div className="AddWebsiteFormDetails__Form__Name__Categories">
                    <label htmlFor="">
                      Post Categories <span> * </span>{" "}
                      <p> &nbsp; (You can select upto 5 categories max) </p>{" "}
                    </label>
                    <article
                      style={{
                        paddingTop: `${selectedWebsiteCategories?.name?.length > 3 ? "10px" : "0px"
                          }`,
                      }}
                    >
                      {selectedWebsiteCategories?.name.map(
                        (selectedWebsiteCategory: any, index: number) => (
                          <h5 key={index}>
                            {selectedWebsiteCategory}{" "}
                            <img
                              src={close}
                              className="AddWebsiteFormDetails__Form__Name__Categories__Close"
                              alt=""
                              onClick={() => {
                                setSelectedWebCategories({
                                  ...selectedWebsiteCategories,
                                  name: selectedWebsiteCategories?.name.filter(
                                    (currentCategory: any) =>
                                      selectedWebsiteCategory !== currentCategory
                                  ),
                                });
                                if (
                                  selectedWebsiteCategories?.name.filter(
                                    (currentCategory: any) =>
                                      selectedWebsiteCategory !== currentCategory
                                  ).length < 5
                                ) {
                                  setPostCategoryError({
                                    isError: false,
                                    message: "",
                                  });
                                }
                                setaddFormDetails({
                                  ...addFormDetails,
                                  websiteCategoryIdList:
                                    addFormDetails?.websiteCategoryIdList?.filter(
                                      (websiteCategoryId: any, index2: number) =>
                                        index2 !== index
                                    ),
                                });
                              }}
                            />{" "}
                          </h5>
                        )
                      )}

                      <input
                        type="text"
                        name=""
                        id=""
                        placeholder="Enter categories"
                        value={searchCategory}
                        onChange={(e: any) => {
                          setSearchCategory(e.target.value);
                          websiteCategoryFilter(
                            setFilteredCategories,
                            websiteCategories,
                            e.target.value
                          );
                        }}
                        onFocus={() => setIsdropDownDisplay(true)}
                        onBlur={() => {
                          if (!isMouseOnDropDown) {
                            setIsdropDownDisplay(false);
                          }
                        }}
                        style={{
                          // border: `${
                          //   selectedWebsiteCategories?.name?.length > 3
                          //     ? "1px solid #aaaaaa"
                          //     : "none"
                          // }`,

                          margin: `${selectedWebsiteCategories?.name?.length > 3
                            ? "9px 0px"
                            : "0px 0px"
                            }`,
                          padding: `${selectedWebsiteCategories?.name?.length > 3
                            ? "7px 13px"
                            : "15px 13px"
                            }`,
                        }}
                      />
                    </article>
                    <div
                      className="AddWebsiteFormDetails__Form__Name__Categories__DropDownContent"
                      style={{
                        display: `${isDropdownDisplay && filteredCategories.length > 0 ? "block" : "none"}`,
                        top: `${selectedWebsiteCategories?.name?.length > 3 ? "117px" : "76px"
                          }`,
                        left: `${selectedWebsiteCategories?.name?.length > 4 ? "200px" : "10px"
                          }`,
                        height: `${filteredCategories.length < 10
                          ? `${filteredCategories.length * 41}px`
                          : "410px"
                          }`,
                      }}
                    >
                      {filteredCategories?.length > 0 &&
                        filteredCategories?.map((websiteCategory: any) => (
                          <div
                            key={websiteCategory.id}
                            onMouseEnter={() => setIsMouseOnDropDown(true)}
                            onMouseLeave={() => setIsMouseOnDropDown(false)}
                            onClick={() => {
                              if (selectedWebsiteCategories?.name?.length < 5) {
                                setPostCategoryError({
                                  isError: false,
                                  message: "",
                                });
                                if (
                                  selectedWebsiteCategories?.name?.includes(
                                    websiteCategory.friendlyName
                                  )
                                ) {
                                  setPostCategoryError({
                                    isError: true,
                                    message: "Category already selected",
                                  });
                                } else {
                                  setPostCategoryError({
                                    isError: false,
                                    message: "",
                                  });
                                  setSelectedWebCategories({
                                    name: [
                                      ...selectedWebsiteCategories.name,
                                      websiteCategory.friendlyName,
                                    ],
                                    id: [
                                      ...selectedWebsiteCategories.id,
                                      websiteCategory.id,
                                    ],
                                  });
                                  setSearchCategory('');
                                  websiteCategoryFilter(
                                    setFilteredCategories,
                                    websiteCategories,
                                    ''
                                  );
                                  setaddFormDetails({
                                    ...addFormDetails,
                                    websiteCategoryIdList: [
                                      ...addFormDetails.websiteCategoryIdList,
                                      websiteCategory.id,
                                    ],
                                  });

                                }
                              } else {
                                setPostCategoryError({
                                  isError: true,
                                  message: "Only 5 categories can be selected",
                                });
                              }
                              setIsdropDownDisplay(false);

                            }}
                          >
                            {websiteCategory?.friendlyName}{" "}
                          </div>
                        ))}
                    </div>
                    {/* <select
              name={websiteCategories?.[0]?.friendlyName}
              id=""
              onChange={(e: any) => {
                console.log("x");
                setaddFormDetails({
                  ...addFormDetails,
                  websiteCategoryIdList: [e.target.value],
                });
              }}
            >
              {websiteCategories?.length > 0 &&
                websiteCategories?.map((websiteCategory: any) => (
                  <option value={websiteCategory.id} key={websiteCategory.id}>
                    {websiteCategory?.friendlyName}{" "}
                  </option>
                ))}
            </select> */}

                    {postCategoryError.isError && (
                      <p>
                        {" "}
                        <img src={info} alt="" />{" "}
                        <span>{postCategoryError.message}</span>{" "}
                      </p>
                    )}

                    {displayError.websiteCategoryIdList && (
                      <p>
                        {" "}
                        <img src={info} alt="" />{" "}
                        <span>Post categories is required</span>{" "}
                      </p>
                    )}
                  </div>
                </section>

                <section className="AddWebsiteFormDetails__Form__Domain" style={{ display: "none" }} >
                  <div className="AddWebsiteFormDetails__Form__Domain__Authority">
                    <label htmlFor="">
                      Domain Authority (DA)<span>*</span>{" "}
                    </label>
                    <input
                      type="number"
                      name=""
                      placeholder="Enter value not more than 100"
                      id=""
                      max={100}
                      value={addFormDetails.domainAuthority}
                      onChange={(e: any) => {
                        console.log(
                          addWebsiteState?.checkEligibility?.websiteEligibility
                            ?.websiteUrl
                        );
                        setaddFormDetails({
                          ...addFormDetails,
                          domainAuthority: parseFloat(e.target.value),
                        });
                      }}
                    />
                    {displayError.domainAuthority && (
                      <p>
                        {" "}
                        <img src={info} alt="" />{" "}
                        <span>{displayError.domainAuthority}</span>{" "}
                      </p>
                    )}
                  </div>
                  <div className="AddWebsiteFormDetails__Form__Domain__Authority">
                    <label htmlFor="">
                      Traffic <span>*</span>{" "}
                    </label>
                    <input
                      type="number"
                      name=""
                      placeholder="Enter Value"
                      id=""
                      value={addFormDetails.traffic}
                      onChange={(e: any) =>
                        setaddFormDetails({
                          ...addFormDetails,
                          traffic: parseFloat(e.target.value),
                        })
                      }
                    />
                    {displayError.traffic && (
                      <p>
                        {" "}
                        <img src={info} alt="" /> <span>{displayError.traffic}</span>{" "}
                      </p>
                    )}
                  </div>
                  <div className="AddWebsiteFormDetails__Form__Domain__Authority">
                    <label htmlFor="">
                      Domain Rating (DR) <span>*</span>{" "}
                    </label>
                    <input
                      type="number"
                      name=""
                      placeholder="Enter Value"
                      id=""
                      value={addFormDetails.referingDomain}
                      onChange={(e: any) =>
                        setaddFormDetails({
                          ...addFormDetails,
                          referingDomain: parseFloat(e.target.value),
                        })
                      }
                    />
                    {displayError.referingDomain && (
                      <p>
                        {" "}
                        <img src={info} alt="" />{" "}
                        <span>{displayError.referingDomain}</span>{" "}
                      </p>
                    )}
                  </div>
                </section>
                <section className="AddWebsiteFormDetails__Form__Domain" >
                  <div className="AddWebsiteFormDetails__Form__Domain__Authority">
                    <label htmlFor="">
                      Minimum Post Word Count <span>*</span>{" "}
                    </label>
                    <input
                      type="number"
                      name=""
                      placeholder="Enter value between 300 to 4000"
                      id=""
                      value={addFormDetails.minPostWordCount}
                      onChange={(e: any) => {
                        setaddFormDetails({
                          ...addFormDetails,
                          minPostWordCount: parseFloat(e.target.value),
                        });
                      }}
                    />
                    {displayError.minPostWordCount && (
                      <p>
                        {" "}
                        <img src={info} alt="" />{" "}
                        <span>{displayError.minPostWordCount}</span>{" "}
                      </p>
                    )}
                  </div>
                  <div className="AddWebsiteFormDetails__Form__Domain__Authority">
                    <label htmlFor="">
                      Guarantee Post Validity <span>*</span>{" "}
                    </label>
                    <FormControl sx={{ m: 1, minWidth: 180 }} size="small">
                      <Select
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        value={addFormDetails.guaranteePostValidity}
                        onChange={(e: any) =>
                          setaddFormDetails({
                            ...addFormDetails,
                            guaranteePostValidity: (e.target.value),
                          })
                        }>
                        {guaranteePostValidityArray?.map((guaranteePostValidityObj: any, index) => (
                          <MenuItem value={guaranteePostValidityObj.id} key={index}>{guaranteePostValidityObj.friendlyName}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    {displayError.guaranteePostValidity && (
                      <p>
                        {" "}
                        <img src={info} alt="" /> <span>{displayError.guaranteePostValidity}</span>{" "}
                      </p>
                    )}
                  </div>
                  <div className="AddWebsiteFormDetails__Form__Domain__Authority">
                    <label htmlFor="">
                      Backlink Type <span>*</span>{" "}
                    </label>
                    <FormControl sx={{ m: 1, minWidth: 180 }} size="small">
                      <Select
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        value={addFormDetails.backLinkType}
                        onChange={(e: any) =>
                          setaddFormDetails({
                            ...addFormDetails,
                            backLinkType: (e.target.value),
                          })
                        }>
                        {backlinkTypeArray?.map((backlinkTypeObj: any, index) => (
                          <MenuItem value={backlinkTypeObj.id} key={index}>{backlinkTypeObj.friendlyName}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    {displayError.backLinkType && (
                      <p>
                        {" "}
                        <img src={info} alt="" />{" "}
                        <span>{displayError.backLinkType}</span>{" "}
                      </p>
                    )}
                  </div>
                  <div className="AddWebsiteFormDetails__Form__Domain__Authority">
                    <label htmlFor="">
                      Maximum Backlinks <span>*</span>{" "}
                    </label>
                    <FormControl sx={{ m: 1, minWidth: 180 }} size="small">
                      <Select
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        value={addFormDetails.maxBackLinks}
                        onChange={(e: any) =>
                          setaddFormDetails({
                            ...addFormDetails,
                            maxBackLinks: parseFloat(e.target.value),
                          })
                        }>
                        {maxBacklinkArray?.map((maxBacklinkObj: any, index) => (
                          <MenuItem value={maxBacklinkObj.id} key={index}>{maxBacklinkObj.friendlyName}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    {displayError.maxBackLinks && (
                      <p>
                        {" "}
                        <img src={info} alt="" />{" "}
                        <span>{displayError.maxBackLinks}</span>{" "}
                      </p>
                    )}
                  </div>
                </section>
                <section className="AddWebsiteFormDetails__Form__Domain" >
                  <div className="AddWebsiteFormDetails__Form__Domain__Authority">
                    <label htmlFor="">
                      Domain Authority <span>*</span>{" "}
                    </label>
                    <input
                      type="number"
                      name=""
                      placeholder="Enter domain authority"
                      id=""
                      value={addFormDetails.domainAuthority}
                      onChange={(e: any) => {
                        setaddFormDetails({
                          ...addFormDetails,
                          domainAuthority: parseFloat(e.target.value),
                        });
                      }}
                    />
                    {displayError.domainAuthority && (
                      <p>
                        {" "}
                        <img src={info} alt="" />{" "}
                        <span>{displayError.domainAuthority}</span>{" "}
                      </p>
                    )}
                  </div>
                  <div className="AddWebsiteFormDetails__Form__Domain__Authority">
                    <label htmlFor="">
                      Domain Rating <span>*</span>{" "}
                    </label>
                    <input
                      type="number"
                      name=""
                      placeholder="Enter domain rating"
                      id=""
                      value={addFormDetails.referingDomain}
                      onChange={(e: any) => {
                        setaddFormDetails({
                          ...addFormDetails,
                          referingDomain: parseFloat(e.target.value),
                        });
                      }}
                    />
                    {displayError.referingDomain && (
                      <p>
                        {" "}
                        <img src={info} alt="" /> <span>{displayError.referingDomain}</span>{" "}
                      </p>
                    )}
                  </div>
                  <div className="AddWebsiteFormDetails__Form__Domain__Authority">
                    <label htmlFor="">
                      Traffic  <span>*</span>{" "}
                    </label>
                    <input
                      type="number"
                      name=""
                      placeholder="Enter traffic"
                      id=""
                      value={addFormDetails.traffic}
                      onChange={(e: any) => {
                        setaddFormDetails({
                          ...addFormDetails,
                          traffic: parseFloat(e.target.value),
                        });
                      }}
                    />
                    {displayError.traffic && (
                      <p>
                        {" "}
                        <img src={info} alt="" />{" "}
                        <span>{displayError.traffic}</span>{" "}
                      </p>
                    )}
                  </div>
                  <div className="AddWebsiteFormDetails__Form__Domain__Authority">
                    <label htmlFor="">
                      Spam Score <span>*</span>{" "}
                    </label>
                    <input
                      type="number"
                      name=""
                      placeholder="Enter spam score"
                      id=""
                      value={addFormDetails.spamScore}
                      onChange={(e: any) => {
                        setaddFormDetails({
                          ...addFormDetails,
                          spamScore: parseFloat(e.target.value),
                        });
                      }}
                    />
                    {displayError.spamScore && (
                      <p>
                        {" "}
                        <img src={info} alt="" />{" "}
                        <span>{displayError.spamScore}</span>{" "}
                      </p>
                    )}
                  </div>
                </section>
                <section className="AddWebsiteFormDetails__Form__Price__Title">
                  <h4>Set Price Per Post Type  <span className="red">*</span></h4>
                  <p>Set lower price to get more visibility</p>
                </section>
                {displayError.priceError && (
                  <p className="AddWebsiteFormDetails__Form__Price__Red">
                    *At least one price is required
                  </p>
                )}
                <section className="AddWebsiteFormDetails__Form__Price">
                  <div className="AddWebsiteFormDetails__Form__Price__Post">
                    <label htmlFor="">
                      General post{" "}
                    </label>
                    <input
                      type="text"
                      name=""
                      placeholder="Enter Value"
                      id=""
                      value={"$ " + addFormDetails.guestPostPrice}
                      onChange={(e: any) =>
                        setaddFormDetails({
                          ...addFormDetails,
                          guestPostPrice: parseFloat(e?.target?.value?.split(' ')[1]) || 0,
                        })
                      }
                    />
                    {displayError.guestPostPrice && (
                      <p>
                        {" "}
                        <img src={info} alt="" />{" "}
                        <span> {displayError.guestPostPrice}</span>{" "}
                      </p>
                    )}
                  </div>{" "}
                  <div className="AddWebsiteFormDetails__Form__Price__Post">
                    <label htmlFor="">
                      Link Insertion <span></span>{" "}
                    </label>
                    <input
                      type="text"
                      name=""
                      placeholder="Enter Value"
                      id=""
                      value={"$ " + addFormDetails.linkInsertionPrice}
                      onChange={(e: any) =>
                        setaddFormDetails({
                          ...addFormDetails,
                          linkInsertionPrice: parseFloat(e?.target?.value?.split(' ')[1]) || 0,
                        })
                      }
                    />
                    {displayError.linkInsertionPrice && (
                      <p>
                        {" "}
                        <img src={info} alt="" />{" "}
                        <span>{displayError.linkInsertionPrice}</span>{" "}
                      </p>
                    )}
                  </div>{" "}
                  <div className="AddWebsiteFormDetails__Form__Price__Post">
                    <label htmlFor="">
                      Crypto/Finance <span></span>{" "}
                    </label>

                    <input
                      type="text"
                      name=""
                      placeholder="Enter Value"
                      id=""
                      value={"$ " + addFormDetails.cryptoPostPrice}
                      onChange={(e: any) => {

                        setaddFormDetails({
                          ...addFormDetails,
                          cryptoPostPrice: parseFloatVal(e?.target?.value?.split(' ')[1]) || 0,
                        })
                      }

                      }
                    />
                    {displayError.cryptoPostPrice && (
                      <p>
                        {" "}
                        <img src={info} alt="" />{" "}
                        <span>{displayError.cryptoPostPrice}</span>{" "}
                      </p>
                    )}
                  </div>
                </section>
                <section className="AddWebsiteFormDetails__ForbiddedSensitiveCategories">
                  <h4>Do you accept orders for Forbidden/Sensitive Categories?   <p color="red">*</p>  &nbsp;
                    <FormControlLabel
                      control={<IOSSwitch sx={{ m: 1 }} checked={addFormDetails.isSenstiveAccepted}
                        onChange={(e: any) => {
                          setaddFormDetails({
                            ...addFormDetails,
                            isSenstiveAccepted: !addFormDetails.isSenstiveAccepted,
                          })
                        }
                        } />}
                      label=""
                    />
                    {(displayError.sensetivePriceError && addFormDetails.isSenstiveAccepted) &&
                      <p color="red">  *At least one price is required</p>
                    }
                  </h4>
                </section>
                {addFormDetails.isSenstiveAccepted &&
                  <section className="AddWebsiteFormDetails__Form__Price">

                    <div className="AddWebsiteFormDetails__Form__Price__Post">
                      <label htmlFor="">
                        Casino Post <span></span>{" "}
                      </label>

                      <input
                        type="text"
                        name=""
                        placeholder="Enter Value"
                        id=""
                        value={"$ " + addFormDetails.casinoPostPrice}
                        onChange={(e: any) => {

                          setaddFormDetails({
                            ...addFormDetails,
                            casinoPostPrice: parseFloatVal(e?.target?.value?.split(' ')[1]) || 0,
                          })
                        }

                        }
                      />
                      {displayError.casinoPostPrice && (
                        <p>
                          {" "}
                          <img src={info} alt="" />{" "}
                          <span>{displayError.casinoPostPrice}</span>{" "}
                        </p>
                      )}
                    </div>
                    <div className="AddWebsiteFormDetails__Form__Price__Post">
                      <label htmlFor="">
                        CBD/Vape {" "}
                      </label>
                      <input
                        type="text"
                        name=""
                        placeholder="Enter Value"
                        id=""
                        value={"$ " + addFormDetails.cbdPostPrice}
                        onChange={(e: any) =>
                          setaddFormDetails({
                            ...addFormDetails,
                            cbdPostPrice: parseFloatVal(e?.target?.value?.split(' ')[1]) || 0,
                          })
                        }
                      />
                      {displayError.cbdPostPrice && (
                        <p>
                          {" "}
                          <img src={info} alt="" />{" "}
                          <span> {displayError.cbdPostPrice}</span>{" "}
                        </p>
                      )}
                    </div>{" "}
                    <div className="AddWebsiteFormDetails__Form__Price__Post">
                      <label htmlFor="">
                        Adult <span></span>{" "}
                      </label>
                      <input
                        type="text"
                        name=""
                        placeholder="Enter Value"
                        id=""
                        value={"$ " + addFormDetails.adultPostPrice}
                        onChange={(e: any) =>
                          setaddFormDetails({
                            ...addFormDetails,
                            adultPostPrice: parseFloatVal(e?.target?.value?.split(' ')[1]) || 0,
                          })
                        }
                      />
                      {displayError.adultPostPrice && (
                        <p>
                          {" "}
                          <img src={info} alt="" />{" "}
                          <span>{displayError.adultPostPrice}</span>{" "}
                        </p>
                      )}
                    </div>{" "}
                  </section>
                }

                <section className="AddWebsiteFormDetails__Form__SampleURL">
                  <div className="AddWebsiteFormDetails__Form__SampleURL_Input">
                    <label htmlFor="">
                      Sample URL <span>*</span>{" "}
                    </label>
                    <input
                      type="text"
                      name=""
                      value={addFormDetails.sampleUrl}
                      placeholder="Enter website name"
                      id=""
                      onChange={(e: any) =>
                        setaddFormDetails({
                          ...addFormDetails,
                          sampleUrl: e.target.value,
                        })
                      }
                    />
                    {displayError.sampleUrl && (
                      <p>
                        {" "}
                        <img src={info} alt="" /> <span>{displayError.sampleUrl}</span>{" "}
                      </p>
                    )}
                  </div>
                  <div className="AddWebsiteFormDetails__Form__SampleURL_TAT">
                    <label htmlFor="">
                      Turn Around Time in Days (TAT) <span>*</span>{" "}
                    </label>
                    <input
                      type="number"
                      name=""
                      value={addFormDetails.tat}
                      placeholder="Enter Turn around time in days"
                      id=""
                      onChange={(e: any) =>
                        setaddFormDetails({
                          ...addFormDetails,
                          tat: parseFloat(e.target.value),
                        })
                      }
                    />
                    {displayError.tat && (
                      <p>
                        {" "}
                        <img src={info} alt="" /> <span>{displayError.tat}</span>{" "}
                      </p>
                    )}
                  </div>
                </section>
                <section className="AddWebsiteFormDetails__Form__GuideLines">
                  <div className="AddWebsiteFormDetails__Form__GuideLines__Post">
                    <label htmlFor="">
                      Post Guidelines <span>*</span>{" "}
                    </label>
                    < JoditEditor
                      ref={editor}
                      value={addFormDetails.postGuideline}

                      // tabIndex of textarea
                      onBlur={newContent => setPostGuideline(newContent)} // preferred to use only this option to update the content for performance reasons
                      onChange={newContent => { }}
                    />
                    {displayError.postGuideline && (
                      <p>
                        {" "}
                        <img src={info} alt="" />{" "}
                        <span className="errorColorAddWebsite">{displayError.postGuideline}</span>{" "}
                      </p>
                    )}
                    <p className="bold">Guidelines Suggestions:</p>
                    <p>1.) What type of article do you accept (Professional/Casual way of writing) on your website.</p>
                    <p>2.) Explain how backlinks should be added, such as including 1 client link, 1-2 high authority links, and 1-2 internal links.</p>
                    <p>3.) Explain if there are any types of links that are not accepted, and mention anything that adds value to a general post on your website.</p>
                    {/* // <textarea
            //   rows={6}
            //   name=""
            //   placeholder="Enter guidelines seperated by comma"
            //   id=""
            //   value={addFormDetails.postGuideline}
            //   onChange={(e: any) =>
            //     setaddFormDetails({
            //       ...addFormDetails,
            //       postGuideline: e.target.value,
            //     })
            //   }
            // /> */}

                  </div>
                </section>
                <section className="AddWebsiteFormDetails__Form__Finish">
                  <button onClick={(e: any) => handleAddFormDetails(e)}>Add Website</button>
                </section>
              </div>
              <ToastContainer />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default withAuth(AddAdminWebsite);
