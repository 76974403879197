import axios from "axios";
import {
  ACTIVATE_WEBSITES,
  ADDWEBSITE_DETAILS,
  CHECK_WEBSITE_ELIGIBILITY,
  CHECK_WEBSITE_VERIFIED,
  IN_ACTIVATE_WEBSITES,
} from "../constants/url";

export const checkWebsiteEligibility = async (params: string) => {
  try {
    const accessToken = sessionStorage.getItem('access-token');
    const response = await axios.get(
      process.env.REACT_APP_BASE + CHECK_WEBSITE_ELIGIBILITY,
      {
        params: { websiteUrl: params },
        headers: { 'Authorization': 'Bearer '+accessToken}
      }
    );
    return response.data;
  } catch (err:any) {
    console.log(err);
    return err?.response?.data;
  }
};

export const checkVErifiedWebsite = async (
  params: string,
  verificationType: string
) => {
  try {
    const accessToken = sessionStorage.getItem('access-token');
    const response = await axios.get(
      process.env.REACT_APP_BASE + CHECK_WEBSITE_VERIFIED,
      {
        params: { websiteUrl: params, verificationType: verificationType },
        headers: { 'Authorization': 'Bearer '+accessToken}
      }
    );
    return response.data;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const customerWebsiteList = async (
  pageRowLimit: number,
  pageOffset: number,
  searchParam: string,
) => {
  try {
    const accessToken = sessionStorage.getItem('access-token');
    const response = await axios.get(
      process.env.REACT_APP_BASE + ADDWEBSITE_DETAILS,
      {
        params: { pageRowLimit, pageOffset, searchParam},
        headers: { 'Authorization': 'Bearer '+accessToken}
      }
    );
    return response.data;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const deleteCustomerWebsite = async (id: string) => {
  try {
    const accessToken = sessionStorage.getItem('access-token');
    const response = await axios.delete(
      `${process.env.REACT_APP_BASE + ADDWEBSITE_DETAILS}/${id}`,{ headers: { 'Authorization': 'Bearer '+accessToken}}
    );
    return response.data;
  } catch (err:any) {
    console.log(err?.response?.data);
    return err?.response?.data;
  }
};
export const activateCustomerWebsite = async (id: string) => {
  try {
    const accessToken = sessionStorage.getItem('access-token');
    const response = await axios.put(
      `${process.env.REACT_APP_BASE + ACTIVATE_WEBSITES}/${id}`, {},
      { headers: { 'Authorization': 'Bearer '+accessToken}}
    );
    return response.data;
  } catch (err:any) {
    console.log(err?.response?.data);
    return err?.response?.data;
  }
};

export const inActivateCustomerWebsite = async (id: string) => {
  try {
    const accessToken = sessionStorage.getItem('access-token');
    const response = await axios.put(
      process.env.REACT_APP_BASE + IN_ACTIVATE_WEBSITES +'/' +id, {},
      { headers: { 'Authorization': 'Bearer '+accessToken}}
    );
    return response.data;
  } catch (err:any) {
    console.log(err?.response?.data);
    return err?.response?.data;
  }
};
