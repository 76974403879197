import './forgotPasswordPage.scss';
//import Footer from '../../components/footer/Footer'
import Header from '../../components/Header'
import ForgotPassword from '../../components/forgotPassword/ForgotPassword';

const ForgotPasswordPage = () => {
  return (
    <div className='forgotpassword-bg' >
    <Header/>
    <ForgotPassword/>
    {/* <div className='footer-bg' >
    <Footer/>
    </div> */}
    </div>
  )
}

export default ForgotPasswordPage