import Footer from "../../components/footer/Footer";
import Header from "../../components/Header";
import AboutMid from "../../components/aboutMid/AboutMid";
import "./about.scss";

const ContactUs = () => {
  return (
    <div className="about-bg">
      <Header />
      <AboutMid />
      <div className="footer-bg">
        <Footer />
      </div>
    </div>
  );
};

export default ContactUs;
