import React from "react";
import loaderLarge from "../../assets/loader/loader_large.svg";
import loaderMedium from "../../assets/loader/loader_medium.svg";
import loaderSmall from "../../assets/loader/loader_small.svg";
import "./loader.scss";
type Props = {
  size: "sm" | "md" | "lg";
  text?: string;
};

const Loader: React.FC<Props> = ({ size, text }) => {
  return (
    <div className="loader__container__svg">
      {size === "lg" && <img src={loaderLarge} alt="" />}
      {size === "md" && <img src={loaderMedium} alt="" />}
      {size === "sm" && <img src={loaderSmall} alt="" />}
      {text && <p>{text}</p>}
    </div>
  );
};

export default Loader;
