import React, { useEffect, useState } from "react";
import "./marketPlaceMid.scss";
import 'react-tooltip/dist/react-tooltip.css'
// import manThinking from "../../assets/add_Websites/manThinking.svg";
// import { useAppSelector } from "../../store/reduxHooks";
// import AddWebsiteForm from "../addWebsiteForm/AddWebsiteForm";
// import PublisherSummaryTable from "../publisherSummaryTable/PublisherSummaryTable";
import { getMarketPlaceList, addToCartWishList } from "../../utils/api/marketPlace";
// import Loader from "../loader/Loader";
import { MarketPlaceItem, MarketPlaceSortObj } from "../../utils/types/main";
import { sortMarketPlaceArray, postTypeArray } from "../../utils/constants/selected";
//import daIcon from '../../assets/marketPlace/DA_Icon.svg'
//import rdIcon from '../../assets/marketPlace/RD_Icon.svg'
//import trafficIcon from '../../assets/marketPlace/Traffic_Icon.svg'
import checkIcon from '../../assets/marketPlace/check_Icon.svg'
import questionIcon from '../../assets/marketPlace/Question_Icon.svg'
import tatIcon from '../../assets/marketPlace/TAT_Icon.svg'
import { getWebsiteCategories } from "../../utils/api/getWebsiteCategories";
import { Tooltip } from 'react-tooltip'
import Popup from '../popup/popup';
import search from "../../assets/add_Websites/search.svg";
import InfiniteScroll from 'react-infinite-scroll-component';
import { ToastContainer, toast } from "react-toastify";
import { formatTraffic } from "../../utils/filters/nameFilters";
import AppLoader from "../../components/defaultLoader/DefaultLoader";
import parse from 'html-react-parser';
import Slider from '@mui/material/Slider';
import Checkbox from "@mui/material/Checkbox";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGem , faStarHalfStroke, faCommentSlash } from '@fortawesome/free-solid-svg-icons';

const MarketPlaceMid = () => {
  // const [isPageLoading, setIsPageLoading] = useState<boolean>(true);
  let navigate = useNavigate();
  const defaultSortType = {
    sortBy: 'referingDomain',
    orderBy: 'DESC',
  };
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [hasMoreData, setHasMoreData] = useState<boolean>(false);
  const [filterControlDiv, setFilterControlDiv] = useState<boolean>(false);
  const [listView] = useState<boolean>(false);
  const [marketPlaceList, setMarketPlaceList] = useState<any>([]);
  const [websiteCategories, setWebsiteCategories] = useState<any>({});
  const [websiteCategoriesList, setWebsiteCategoriesList] = useState<any>([]);
  const [searchInput, setSearchInput] = useState<string>("");
  const [sortType, setSortType] = useState<any>(defaultSortType);
  const [selectedPostType, setSelectedPostType] = useState<string>("");
  const [selectedCategoryType, setSelectedCategoryType] = useState<string>("");
  const [isDAChecked, setIsDAChecked] = useState<boolean>(false);
  const [priceInputFilterMin, setPriceInputFilterMin] = useState<string>('');
  const [priceInputFilterMax, setPriceInputFilterMax] = useState<string>('');
  const [trafficInputFilterMin, setTrafficInputFilterMin] = useState<string>('');
  const [trafficInputFilterMax, setTrafficInputFilterMax] = useState<string>('');
  const [spamScoreInputFilterMin, setSpamScoreInputFilterMin] = useState<string>('');
  const [spamScoreInputFilterMax, setSpamScoreInputFilterMax] = useState<string>('');
  const [isFilterApplied, setIsFilterApplied] = useState<boolean>(false);
  const [daValue, setDaValue] = useState<number[]>([0, 100]);
  let [filterMarketPlace, setFilterMarketPlace] = useState<any>();
  const roleType = sessionStorage.getItem("roleType");
  // const [isLevel1FilterApplied, setIsLevel1FilterApplied] = useState<boolean>(false);
  // const [isLevel2FilterApplied, setIsLevel2FilterApplied] = useState<boolean>(false);
  // const [isLevel3FilterApplied, setIsLevel3FilterApplied] = useState<boolean>(false);
  // const [isLevel4FilterApplied, setIsLevel4FilterApplied] = useState<boolean>(false);
  // const [isLevel5FilterApplied, setIsLevel5FilterApplied] = useState<boolean>(false);
  // const [isLevel6FilterApplied, setIsLevel6FilterApplied] = useState<boolean>(false);
  const [dataVersion, setDataVersion] = useState(0); // This helps to trigger re-renders

  const getmarketPlaceList = async (search: string, sortObj: any, pageNo: number, filterMarketPlace: any) => {
    setIsLoading(true);
    let page = pageNumber;
    if (pageNo !== undefined) {
      page = pageNo;
    }
    const response = await getMarketPlaceList(page, 20, search, sortObj, filterMarketPlace);
    if (response?.status?.code === 200) {
      setPageNumber(page + 1);
      const hasNext = response?.data?.marketplace?.pageInfo?.hasNext
      setHasMoreData(hasNext);
      //  marketPlaceList = response?.data?.marketplace?.websites;
      setMarketPlaceList(response?.data?.marketplace?.websites);
      console.log(response?.data?.marketplace?.websites);
      setIsLoading(false);
      console.log("--------------------------getmarketPlaceList------------------------------", hasNext, filterMarketPlace.levelFilter);
      if (hasNext === false && filterMarketPlace.levelFilter !== undefined && filterMarketPlace.levelFilter === 'level1') {
        console.log("-------------------------filterMarketPlace.levelFilter----------------------------", filterMarketPlace.levelFilter);
        level2Filter();
        setPageNumber(0);
        setHasMoreData(true);
        // Force a data change
        setDataVersion(prevVersion => prevVersion + 1);

       // fetchMoreData();
        // getmarketPlaceList("", sortType, 0, level2Filter());
      }
    } else {
      toast.error("Something went wrong", {
        position: "top-right",
        theme: "colored",
        autoClose: 5000,
      });
    }
  };

  const fetchMoreData = async () => {
    //   setIsPageLoading(true);
   // setIsLoading(true);
    console.log(" hasMoreData --> ", filterMarketPlace, marketPlaceList);
    const response = await getMarketPlaceList(pageNumber, 20, searchInput, sortType, filterMarketPlace);
    if (response?.status?.code === 200) {
      const hasNext = response?.data?.marketplace?.pageInfo?.hasNext;
      
      
      const list = marketPlaceList;
      console.log(" filterMarketPlace.levelFilter --->>>   ", filterMarketPlace.levelFilter);
      console.log(" hasNext --->>>   ", hasNext);
      console.log(" marketPlaceList--->>>   ", marketPlaceList);
      console.log(" response list --->>>   ", response?.data?.marketplace?.websites);
      const mergedResult = [...list, ...response?.data?.marketplace?.websites];
      console.log("  mergedResult ---->    ", mergedResult);

      //  marketPlaceList = mergedResult
      setMarketPlaceList(mergedResult);
      if (hasNext === false && filterMarketPlace.levelFilter !== undefined && filterMarketPlace.levelFilter === 'level1') {
        setHasMoreData(true);
        setDataVersion(prevVersion => prevVersion + 1);
        setPageNumber(0);
        level2Filter();
      //  await fetchMoreData();
        // getmarketPlaceList("", sortType, 0, level2Filter());
      } else if (hasNext === false && filterMarketPlace.levelFilter !== undefined && filterMarketPlace.levelFilter === 'level2') {
        setHasMoreData(true);
        setDataVersion(prevVersion => prevVersion + 1);
        setPageNumber(0);
        level3Filter();
       // await fetchMoreData();
      }
      else if (hasNext === false && filterMarketPlace.levelFilter !== undefined && filterMarketPlace.levelFilter === 'level3') {
        setHasMoreData(true);
        setDataVersion(prevVersion => prevVersion + 1);
        setPageNumber(0);
        level4Filter();
      //  await fetchMoreData();
      }
      else if (hasNext === false && filterMarketPlace.levelFilter !== undefined && filterMarketPlace.levelFilter === 'level4') {
        setHasMoreData(true);
        setDataVersion(prevVersion => prevVersion + 1);
        setPageNumber(0);
        level5Filter();
     //   await fetchMoreData();
      }
      else if (hasNext === false && filterMarketPlace.levelFilter !== undefined && filterMarketPlace.levelFilter === 'level5') {
        setHasMoreData(true);
        setDataVersion(prevVersion => prevVersion + 1);
        setPageNumber(0);
        level6Filter();
      //  await fetchMoreData();
      }
      else if (hasNext === false && filterMarketPlace.levelFilter !== undefined && filterMarketPlace.levelFilter === 'level6') {
        setHasMoreData(true);
        setDataVersion(prevVersion => prevVersion + 1);
        setPageNumber(0);
        level7Filter();
     //   await fetchMoreData();
      }
      else if (hasNext === false && filterMarketPlace.levelFilter !== undefined && filterMarketPlace.levelFilter === 'level7') {
        setHasMoreData(true);
        setDataVersion(prevVersion => prevVersion + 1);
        setPageNumber(0);
        level8Filter();
      //  await fetchMoreData();
      }else{
        console.log("----------------------ELSE----------------------------")
        setHasMoreData(hasNext);
        setPageNumber(pageNumber+1);
      }
      setIsLoading(false);
    } else {
      toast.error("Something went wrong", {
        position: "top-right",
        theme: "colored",
        autoClose: 5000,
      });
      setIsLoading(false);
    }
  };

  const getCategories = async (setWebsiteCategories: React.Dispatch<any>) => {
    const response = await getWebsiteCategories();
    if (response?.status?.code === 200) {
      const map = new Map();
      response?.data?.websiteCategories?.categories.forEach((obj: { id: any; friendlyName: any; }) => {
        map.set(obj.id, obj.friendlyName);
      });
      setWebsiteCategoriesList(response?.data?.websiteCategories?.categories);
      setWebsiteCategories(map);
    } else {
      toast.error("Something went wrong", {
        position: "top-right",
        theme: "colored",
        autoClose: 5000,
      });
    }
  };

  const getCategoryName = (id: String) => {
    return websiteCategories?.get(id)
  };

  const [isOpen, setIsOpen] = useState(false);
  const [guidelineContent, setGuidelineContent] = useState("");
  const togglePopup = (content: string) => {
    setGuidelineContent(content);
    setIsOpen(!isOpen);
  }

  const filterControlAction = () => {
    setFilterControlDiv(!filterControlDiv);
  }

  // const listViewChangeAction = () => {
  //   setListView(!listView);
  // }

  const applyFilterAction = () => {
    let isFilterApplied = false;
    filterMarketPlace = {};
    if (selectedPostType) {
      // if(parseInt(priceInputFilterMax) < parseInt(priceInputFilterMin) || isNaN(parseInt(priceInputFilterMax)) || priceInputFilterMax === ''){
      //   toast.error("Invalid price values", {
      //     position: "top-right",
      //     theme: "colored",
      //     autoClose: 5000,
      //   });
      //   return;
      // }
      let priceInputMin = priceInputFilterMin;
      let priceInputMax = priceInputFilterMax;
      if (isNaN(parseInt(priceInputMin)) && isNaN(parseInt(priceInputMax))) {
        priceInputMin = 1 + '';
        priceInputMax = Number.MAX_SAFE_INTEGER + '';
      } else if (isNaN(parseInt(priceInputMin)) && !isNaN(parseInt(priceInputMax))) {
        priceInputMin = 1 + '';
      } else if (!isNaN(parseInt(priceInputMin)) && isNaN(parseInt(priceInputMax))) {
        priceInputMax = Number.MAX_SAFE_INTEGER + '';
      }
      if (selectedPostType === 'General Post') {
        isFilterApplied = true;
        filterMarketPlace.guestPostPriceFilter = {};

        filterMarketPlace.guestPostPriceFilter.min = parseInt(priceInputMin);
        filterMarketPlace.guestPostPriceFilter.max = parseInt(priceInputMax);
      } else if (selectedPostType === 'Link Insertion') {
        isFilterApplied = true;
        filterMarketPlace.linkInsertionPriceFilter = {};
        filterMarketPlace.linkInsertionPriceFilter.min = parseInt(priceInputMin);
        filterMarketPlace.linkInsertionPriceFilter.max = parseInt(priceInputMax);
      } else if (selectedPostType === 'Adult Post') {
        isFilterApplied = true;
        filterMarketPlace.adultPostPriceFilter = {};
        filterMarketPlace.adultPostPriceFilter.min = parseInt(priceInputMin);
        filterMarketPlace.adultPostPriceFilter.max = parseInt(priceInputMax);
      } else if (selectedPostType === 'Crypto/Finance Post') {
        isFilterApplied = true;
        filterMarketPlace.cryptoPostPriceFilter = {};
        filterMarketPlace.cryptoPostPriceFilter.min = parseInt(priceInputMin);
        filterMarketPlace.cryptoPostPriceFilter.max = parseInt(priceInputMax);
      } else if (selectedPostType === 'CBD/Vape Post') {
        isFilterApplied = true;
        filterMarketPlace.cbdPostPriceFilter = {};
        filterMarketPlace.cbdPostPriceFilter.min = parseInt(priceInputMin);
        filterMarketPlace.cbdPostPriceFilter.max = parseInt(priceInputMax);
      } else {
        isFilterApplied = true;
        filterMarketPlace.casinoPostPriceFilter = {};
        filterMarketPlace.casinoPostPriceFilter.min = parseInt(priceInputMin);
        filterMarketPlace.casinoPostPriceFilter.max = parseInt(priceInputMax);
      }
    }

    if (isDAChecked) {
      isFilterApplied = true;
      filterMarketPlace.domainAuthFilter = {};
      filterMarketPlace.domainAuthFilter.min = daValue[0];
      filterMarketPlace.domainAuthFilter.max = daValue[1];
      console.log("Domain Filter Engaged 111111111111 ");
    }

    if (selectedCategoryType) {
      isFilterApplied = true;
      filterMarketPlace.categoryFilter = [selectedCategoryType];
      console.log("Category Filter Engaged 111111111111 ");
    }
    if (trafficInputFilterMin !== '' || trafficInputFilterMax !== '') {
      // if(parseInt(trafficInputFilterMax) < parseInt(trafficInputFilterMin)){
      //   toast.error("Invalid traffic values", {
      //     position: "top-right",
      //     theme: "colored",
      //     autoClose: 5000,
      //   });
      //   return;
      // }
      let trafficInputMin = trafficInputFilterMin;
      let trafficInputMax = trafficInputFilterMax;
      if (isNaN(parseInt(trafficInputMin)) && isNaN(parseInt(trafficInputMax))) {
        trafficInputMin = 0 + '';
        trafficInputMax = Number.MAX_SAFE_INTEGER + '';
      } else if (isNaN(parseInt(trafficInputMin)) && !isNaN(parseInt(trafficInputMax))) {
        trafficInputMin = 0 + '';
      } else if (!isNaN(parseInt(trafficInputMin)) && isNaN(parseInt(trafficInputMax))) {
        trafficInputMax = Number.MAX_SAFE_INTEGER + '';
      }
      isFilterApplied = true;
      filterMarketPlace.trafficFilter = {};
      filterMarketPlace.trafficFilter.min = parseInt(trafficInputMin);
      filterMarketPlace.trafficFilter.max = parseInt(trafficInputMax);
    }


    if (spamScoreInputFilterMin !== '' || spamScoreInputFilterMax !== '') {
      // if(parseInt(trafficInputFilterMax) < parseInt(trafficInputFilterMin)){
      //   toast.error("Invalid traffic values", {
      //     position: "top-right",
      //     theme: "colored",
      //     autoClose: 5000,
      //   });
      //   return;
      // }
      let spamScoreInputMin = spamScoreInputFilterMin;
      let spamScoreInputMax = spamScoreInputFilterMax;
      if (isNaN(parseInt(spamScoreInputMin)) && isNaN(parseInt(spamScoreInputMax))) {
        spamScoreInputMin = 0 + '';
        spamScoreInputMax = maxIntValue + '';
      } else if (isNaN(parseInt(spamScoreInputMin)) && !isNaN(parseInt(spamScoreInputMax))) {
        spamScoreInputMin = 0 + '';
      } else if (!isNaN(parseInt(spamScoreInputMin)) && isNaN(parseInt(spamScoreInputMax))) {
        spamScoreInputMax = maxIntValue + '';
      }
      isFilterApplied = true;
      filterMarketPlace.spamScoreFilter = {};
      filterMarketPlace.spamScoreFilter.min = parseInt(spamScoreInputMin);
      filterMarketPlace.spamScoreFilter.max = parseInt(spamScoreInputMax);
      console.log('filterMarketPlace   1111  ----->>>>      ', filterMarketPlace);
    }

    if (!isFilterApplied) {
      toast.error("At least one filter is required", {
        position: "top-right",
        theme: "colored",
        autoClose: 5000,
      });
    } else {
      console.log('filterMarketPlace  ----->>>>      ', filterMarketPlace);
      setIsFilterApplied(true);
      setFilterMarketPlace(filterMarketPlace);
      getmarketPlaceList("", sortType, 0, filterMarketPlace);
      setFilterControlDiv(!filterControlDiv);
    }
  }
  const parseValueToInt = (event: any) => {
    return isNaN(parseInt(event.target.value)) ? '' : parseInt(event.target.value) + '';

  }
  const clearFilterAction = () => {
    setFilterMarketPlace({});
    setIsDAChecked(false);
    setSelectedPostType('');
    setPriceInputFilterMin('')
    setPriceInputFilterMax('');
    setTrafficInputFilterMin('');
    setTrafficInputFilterMax('');
    setSpamScoreInputFilterMin('');
    setSpamScoreInputFilterMax('');
    setSelectedCategoryType('');
    setDaValue([0, 100]);
    setIsFilterApplied(false);
    getmarketPlaceList("", sortType, 0, level1Filter());

  }

  const backgroundFilterAction = (event: any) => {
    event.preventDefault();
    if (event.target === event.currentTarget) {
      if (isFilterApplied) {
        // setFilterMarketPlace({});
        // setIsDAChecked(false);
        // setSelectedPostType('');
        // setPriceInputFilterMin('')
        // setPriceInputFilterMax('');
        // setTrafficInputFilterMin('');
        // setTrafficInputFilterMax('');
        // setSpamScoreInputFilterMin('');
        // setSpamScoreInputFilterMax('');
        // setSelectedCategoryType('');
        // setDaValue([0, 100]);
      }
      setFilterControlDiv(!filterControlDiv);
    }
  }


  const addToCart = async (marketPlaceObj: any, type: string) => {
    setIsLoading(true);
    const postType = marketPlaceObj.postType === undefined ? defaultMarketPlace(marketPlaceObj) : marketPlaceObj.postType;
    const response = await addToCartWishList(marketPlaceObj.id, type, postType);
    let successMsg;
    if (response?.status?.code === 200) {
      const updatedList = marketPlaceList.map((marketPlaceItem: MarketPlaceItem) => {
        if (marketPlaceItem.id === marketPlaceObj.id) {
          if (type === 'CART') {
            successMsg = "Site added to cart";
            const input = document.getElementById('cartCount') as HTMLInputElement | null;
            if (input !== null) {
              input.innerHTML = Number(input.innerHTML) + 1 + "";
              input.classList.remove("hide");
              input.classList.add("show");
            }
            if (postType === 'GUEST_POST') {
              marketPlaceObj.cart['guestPost'] = true;
            } else if (postType === 'LINK_INSERTION') {
              marketPlaceObj.cart['linkInsertion'] = true;
            } else if (postType === 'CASINO_POST') {
              marketPlaceObj.cart['casinoPost'] = true;
            } else if (postType === 'CRYPTO_POST') {
              marketPlaceObj.cart['cryptoPostPrice'] = true;
            } else if (postType === 'ADULT_POST') {
              marketPlaceObj.cart['adultPostPrice'] = true;
            } else if (postType === 'CBD_POST') {
              marketPlaceObj.cart['cbdPostPrice'] = true;
            }
          } else {
            successMsg = "Site is added to wishlist";
            const input = document.getElementById('wishCount') as HTMLInputElement | null;
            if (input !== null) {
              input.innerHTML = Number(input.innerHTML) + 1 + "";
              input.classList.remove("hide");
              input.classList.add("show");
            }
            if (postType === 'GUEST_POST') {
              marketPlaceObj.wish['guestPost'] = true;
            } else if (postType === 'LINK_INSERTION') {
              marketPlaceObj.wish['linkInsertion'] = true;
            } else if (postType === 'CASINO_POST') {
              marketPlaceObj.wish['casinoPost'] = true;
            } else if (postType === 'CRYPTO_POST') {
              marketPlaceObj.wish['cryptoPostPrice'] = true;
            } else if (postType === 'ADULT_POST') {
              marketPlaceObj.wish['adultPostPrice'] = true;
            } else if (postType === 'CBD_POST') {
              marketPlaceObj.wish['cbdPostPrice'] = true;
            }
          }
          return marketPlaceObj;
        } else {
          return marketPlaceItem;
        }
      });
      setMarketPlaceList(updatedList);
      toast.success(successMsg, {
        position: "top-right",
        theme: "colored",
        autoClose: 5000,
      });
      setIsLoading(false);
    } else {
      toast.error("Something went wrong", {
        position: "top-right",
        theme: "colored",
        autoClose: 5000,
      });
      setIsLoading(false);
    }
  };

  const setPostType = (marketPlaceObj: any, postType: string) => {
    marketPlaceObj.postType = postType;
    const updatedList = marketPlaceList.map((marketPlaceItem: MarketPlaceItem) => {
      if (marketPlaceItem.id === marketPlaceObj.id) {
        return marketPlaceObj;
      } else {
        return marketPlaceItem;
      }
    });
    setMarketPlaceList(updatedList);
  };

  const isAddedToCart = (marketPlaceObj: any) => {
    let isAddedToCart = false;
    if (marketPlaceObj.postType === undefined) {
      if (defaultMarketPlace(marketPlaceObj) === 'GUEST_POST') {
        isAddedToCart = marketPlaceObj?.cart?.guestPost;
      } else if (defaultMarketPlace(marketPlaceObj) === 'LINK_INSERTION') {
        isAddedToCart = marketPlaceObj?.cart?.linkInsertion;
      } else if (defaultMarketPlace(marketPlaceObj) === 'CASINO_POST') {
        isAddedToCart = marketPlaceObj?.cart?.casinoPost;
      } else if (defaultMarketPlace(marketPlaceObj) === 'CRYPTO_POST') {
        isAddedToCart = marketPlaceObj?.cart?.cryptoPostPrice;
      } else if (defaultMarketPlace(marketPlaceObj) === 'ADULT_POST') {
        isAddedToCart = marketPlaceObj?.cart?.adultPostPrice;
      } else if (defaultMarketPlace(marketPlaceObj) === 'CBD_POST') {
        isAddedToCart = marketPlaceObj?.cart?.cbdPostPrice;
      }
    } else {
      if (marketPlaceObj.postType === 'GUEST_POST') {
        isAddedToCart = marketPlaceObj?.cart?.guestPost;
      } else if (marketPlaceObj.postType === 'LINK_INSERTION') {
        isAddedToCart = marketPlaceObj?.cart?.linkInsertion;
      } else if (marketPlaceObj.postType === 'CASINO_POST') {
        isAddedToCart = marketPlaceObj?.cart?.casinoPost;
      } else if (marketPlaceObj.postType === 'CRYPTO_POST') {
        isAddedToCart = marketPlaceObj?.cart?.cryptoPostPrice;
      } else if (marketPlaceObj.postType === 'ADULT_POST') {
        isAddedToCart = marketPlaceObj?.cart?.adultPostPrice;
      } else if (marketPlaceObj.postType === 'CBD_POST') {
        isAddedToCart = marketPlaceObj?.cart?.cbdPostPrice;
      }
    }
    return isAddedToCart;
  };

  const isAddedToWish = (marketPlaceObj: any) => {
    let isAddedToWish = false;
    if (marketPlaceObj.postType === undefined) {
      if (defaultMarketPlace(marketPlaceObj) === 'GUEST_POST') {
        isAddedToWish = marketPlaceObj?.wish?.guestPost;
      } else if (defaultMarketPlace(marketPlaceObj) === 'LINK_INSERTION') {
        isAddedToWish = marketPlaceObj?.wish?.linkInsertion;
      } else if (defaultMarketPlace(marketPlaceObj) === 'CASINO_POST') {
        isAddedToWish = marketPlaceObj?.wish?.casinoPost;
      } else if (defaultMarketPlace(marketPlaceObj) === 'CRYPTO_POST') {
        isAddedToWish = marketPlaceObj?.wish?.cryptoPostPrice;
      } else if (defaultMarketPlace(marketPlaceObj) === 'ADULT_POST') {
        isAddedToWish = marketPlaceObj?.wish?.adultPostPrice;
      } else if (defaultMarketPlace(marketPlaceObj) === 'CBD_POST') {
        isAddedToWish = marketPlaceObj?.wish?.cbdPostPrice;
      }
    } else {
      if (marketPlaceObj.postType === 'GUEST_POST') {
        isAddedToWish = marketPlaceObj?.wish?.guestPost;
      } else if (marketPlaceObj.postType === 'LINK_INSERTION') {
        isAddedToWish = marketPlaceObj?.wish?.linkInsertion;
      } else if (marketPlaceObj.postType === 'CASINO_POST') {
        isAddedToWish = marketPlaceObj?.wish?.casinoPost;
      } else if (marketPlaceObj.postType === 'CRYPTO_POST') {
        isAddedToWish = marketPlaceObj?.wish?.cryptoPostPrice;
      } else if (marketPlaceObj.postType === 'ADULT_POST') {
        isAddedToWish = marketPlaceObj?.wish?.adultPostPrice;
      } else if (marketPlaceObj.postType === 'CBD_POST') {
        isAddedToWish = marketPlaceObj?.wish?.cbdPostPrice;
      }
    }
    return isAddedToWish;
  };

  const searchMarketPlace = () => {
    //  setSearchInput(event.target.value);
    setPageNumber(0);
    filterMarketPlace = {};
    setFilterMarketPlace(filterMarketPlace);
    getmarketPlaceList(searchInput, sortType, 0, filterMarketPlace);
  };


  const clearSearchMarketPlace = () => {
    setSearchInput('');
    setPageNumber(0);
    getmarketPlaceList('', sortType, 0,  level1Filter());
  };

  const defaultMarketPlace = (marketPlaceObj: any) => {
    if (marketPlaceObj.guestPostPrice !== 0) {
      return 'GUEST_POST';
    }
    if (marketPlaceObj.linkInsertionPrice !== 0) {
      return 'LINK_INSERTION';
    }
    if (marketPlaceObj.casinoPostPrice !== 0) {
      return 'CRYPTO_POST';
    }
    if (marketPlaceObj.cryptoPostPrice !== 0) {
      return 'CASINO_POST';
    }
    if (marketPlaceObj.cbdPostPrice !== 0) {
      return 'CBD_POST';
    }
    if (marketPlaceObj.adultPostPrice !== 0) {
      return 'ADULT_POST';
    }
  };

  const getCategoryArrayMap = (marketPlaceItemCatObj: any) => {
    const returnList = [];
    let charCount = 0;
    for (var i = 0; i < marketPlaceItemCatObj.websiteCategoryIdList.length; i++) {
      charCount = charCount + getCategoryName(marketPlaceItemCatObj.websiteCategoryIdList[i]).length;
      if (charCount <= 52) {
        returnList[i] = marketPlaceItemCatObj.websiteCategoryIdList[i];
      } else {
        break;
      }
    }
    return returnList;
  };

  const getSlicedCategoryArrayMap = (marketPlaceItem: any) => {
    const returnListt = [];
    let charCount = 0;
    let itemCount = 0;
    for (var i = 0; i < marketPlaceItem.websiteCategoryIdList.length; i++) {
      charCount = charCount + getCategoryName(marketPlaceItem.websiteCategoryIdList[i]).length;
      if (charCount > 53) {
        returnListt[itemCount] = marketPlaceItem.websiteCategoryIdList[i];
        itemCount++;
      }
    }
    return returnListt;
  };

  const getSlicedCategoryStr = (marketPlaceItem: any) => {
    let charCount = 0;
    let returnStr = "";
    for (var i = 0; i < marketPlaceItem.websiteCategoryIdList.length; i++) {
      charCount = charCount + getCategoryName(marketPlaceItem.websiteCategoryIdList[i]).length;
      if (charCount > 53) {
        returnStr += getCategoryName(marketPlaceItem.websiteCategoryIdList[i]);
        returnStr += (marketPlaceItem.websiteCategoryIdList.length - 1 !== i) ? " , " : "";
      }
    }
    return returnStr;
  };



  const sortMarketPlace = (event: any) => {
    let selectedVal;
    setIsLoading(true);
    setPageNumber(0);
    let isDefaultFilterApplied = false;
    if (event.target.value !== '-1') {
      selectedVal = sortMarketPlaceArray[event.target.value];
      isDefaultFilterApplied = false;
    } else {
      selectedVal = defaultSortType;
      isDefaultFilterApplied = true;
    }
    const sortObj = {
      sortBy: selectedVal.sortBy,
      orderBy: selectedVal.orderBy,
    }
    setSortType(sortObj);
    filterMarketPlace = {};
    setFilterMarketPlace(filterMarketPlace);
    if(isDefaultFilterApplied){
      getmarketPlaceList(searchInput, sortObj, 0, level1Filter());
    }else{
      getmarketPlaceList(searchInput, sortObj, 0, filterMarketPlace);
    }
    
  };


  const handleDASliderChange = (event: any, value: any) => {
    setDaValue(value);
  };

  const setSelectedPostTypeDropDown = (event: any) => {
    if (event.target.value !== '-1') {
      setSelectedPostType(event.target.value);
    } else {
      setSelectedPostType('');
    }
  };

  const setSelectedCategoryTypeDropDown = (event: any) => {
    if (event.target.value !== '-1') {
      setSelectedCategoryType(event.target.value);
    } else {
      setSelectedCategoryType('');
    }
  };

  const marks = [
    {
      value: 0,
      label: '0',
    },
    {
      value: 100,
      label: '100',
    },
  ];

  function valuetext(value: number) {
    return `${value}`;
  }

  function valueLabelFormat(value: number) {
    return value;
  }

  const navigateToWishlist = () => {
    navigate("/dashboard/wishlist");
  };

  const navigateToCart = () => {
    navigate("/dashboard/cart");
  };

  const getBacklinkType = (backlinkType: any) => {
    if (backlinkType === 'DOFOLLOW') {
      return 'Dofollow';
    } else if (backlinkType === 'NOFOLLOW') {
      return 'Nofollow';
    }
  };


  const getPostValidityType = (postValidityType: string) => {
    if (postValidityType === 'YEARLY') {
      return 'Yearly';
    } else if (postValidityType === 'HALF_YEARLY') {
      return 'Half Yearly';
    } else if (postValidityType === 'LIFETIME') {
      return 'Lifetime';
    }
  };

  function safeInteger(value: number): number {
    const MIN_INT = -2147483648;
    const MAX_INT = 2147483647;

    if (value < MIN_INT || value > MAX_INT) {
      throw new Error(`Value ${value} is out of range. Must be between ${MIN_INT} and ${MAX_INT}.`);
    }

    return value;
  }

  const maxIntValue = safeInteger(2147483647);

  const levelFilter = (rdMin: number, rdMax: number,
    trafficMin: number, trafficMax: number,
    spamScoreMin: number, spamScoreMax: number, level: string) => {
    console.log("----------------------- Inside Level Filter  -----------------------",level);
    filterMarketPlace = {};
    filterMarketPlace.referingDomainFilter = {};
    filterMarketPlace.referingDomainFilter.min = rdMin;
    filterMarketPlace.referingDomainFilter.max = rdMax;
    filterMarketPlace.trafficFilter = {};
    filterMarketPlace.trafficFilter.min = trafficMin;
    filterMarketPlace.trafficFilter.max = trafficMax;
    filterMarketPlace.spamScoreFilter = {};
    filterMarketPlace.spamScoreFilter.min = spamScoreMin;
    filterMarketPlace.spamScoreFilter.max = spamScoreMax;
    filterMarketPlace.levelFilter = level;
    setFilterMarketPlace(filterMarketPlace);
    //setLevelFilter(level);
    return filterMarketPlace;
  };

  const level1Filter = () => {
    return levelFilter(31, 70, 100001, Number.MAX_SAFE_INTEGER, 0, 10, 'level1')
  }

  const level2Filter = () => {
    return levelFilter(11, 70, 10001, 100000, 0, 10, 'level2')
  }

  const level3Filter = () => {
    return levelFilter(71, maxIntValue, 100001, Number.MAX_SAFE_INTEGER, 0, 10, 'level3')
  }

  const level4Filter = () => {
    return levelFilter(0, 30, 1000001, Number.MAX_SAFE_INTEGER, 0, 10, 'level4')
  }

  const level5Filter = () => {
    return levelFilter(1, 100, 0, 10000, 0, 10, 'level5')
  }

  const level6Filter = () => {
    return levelFilter(71, 100, 10001, 100000, 0, 10, 'level6')
  }

  const level7Filter = () => {
    return levelFilter(0, 10, 100001, 1000000, 0, 10, 'level7')
  }

  const level8Filter = () => {
    return levelFilter(0, 100, 0, Number.MAX_SAFE_INTEGER, 11, 30, 'level8')
  }

  // const setLevelFilter = (level: string) => {
  // setIsLevel1FilterApplied(false);
  // setIsLevel2FilterApplied(false);
  // setIsLevel3FilterApplied(false);
  // setIsLevel4FilterApplied(false);
  // setIsLevel5FilterApplied(false);
  // setIsLevel6FilterApplied(false);
  // if (level === 'level1') {
  //   console.log("-------------------inside level111111111------------------")
  //   setIsLevel1FilterApplied(true);
  // } else if (level === 'level2') {
  //   setIsLevel2FilterApplied(true);
  // } else if (level === 'level3') {
  //   setIsLevel3FilterApplied(true);
  // } else if (level === 'level4') {
  //   setIsLevel4FilterApplied(true);
  // } else if (level === 'level5') {
  //   setIsLevel5FilterApplied(true);
  // } else if (level === 'level6') {
  //   setIsLevel6FilterApplied(true);
  // }
  // };

  useEffect(() => {
    getmarketPlaceList("", sortType, 0, level1Filter());
    getCategories(setWebsiteCategories);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="publisherWebsiteMid__Con">
      {isLoading && <AppLoader size="lg" ></AppLoader>}
      <div className="searchSortFilterDiv">
        <div className="searchDiv">
          <input
            className="searchMarketPlace"
            type="text"
            value={searchInput}
            placeholder='Search'
            onChange={(e: any) => setSearchInput(e.target.value)}
          />
          <img className="search-img" src={search} alt="" onClick={() => { searchMarketPlace(); }} />
          {searchInput !== '' && <span className="material-icons lightGrey close-img" onClick={() => { clearSearchMarketPlace(); }}>close</span>}
        </div>
        <div className="sortDiv">

          <select className="sortMarketPlace" onChange={(e) => sortMarketPlace(e)}>
            <option key={-1} value={-1}>{'Sort by'}</option>
            {sortMarketPlaceArray?.map((sortMarketPlaceItem: MarketPlaceSortObj, index) => (
              <option key={sortMarketPlaceItem.friendlyName} value={index}>{sortMarketPlaceItem.friendlyName}</option>
            ))}
          </select>
        </div>
        <div className="filter_btn"  > <span onClick={() => { filterControlAction(); }}> Filter </span>
          {isFilterApplied && <span className="clearFilterText" onClick={() => { clearFilterAction(); }}> Clear Filter </span>}</div>
        {/* {!listView && <button className="grid_view_btn  " onClick={() => { listViewChangeAction(); }} ><span className="material-icons">density_medium</span> </button>}
        {listView && <button className="grid_view_btn " onClick={() => { listViewChangeAction(); }}><span className="material-icons">view_cozy</span> </button>} */}
      </div>
      {filterControlDiv &&
        <div className="filterMainDiv" onClick={(e) => { backgroundFilterAction(e); }}>
          <div className="filterControlDiv">
            <div className="filter_control_div"> Domain Authority (DA)
              <Checkbox
                checked={isDAChecked}
                onClick={() => setIsDAChecked((prev) => !prev)}
                inputProps={{ 'aria-label': 'controlled' }}
              />
              <Slider
                getAriaLabel={() => 'DA range'}
                defaultValue={0}
                valueLabelFormat={valueLabelFormat}
                getAriaValueText={valuetext}
                value={daValue}
                onChange={(event, value) => handleDASliderChange(event, value)}
                step={1}
                valueLabelDisplay="auto"
                marks={marks}
                disabled={!isDAChecked}
              />

            </div>
            <div className="filter_control_div"> Post Type
              <div className="filterPostTypeSelectDiv">
                <select className="filterPostTypeSelect" value={selectedPostType} onChange={(e) => setSelectedPostTypeDropDown(e)} >
                  <option key={-1} value={-1}>{'Select'}</option>
                  {postTypeArray?.map((postType: any) => (
                    <option key={postType.friendlyName} value={postType.friendlyName}>{postType.friendlyName}</option>
                  ))}

                </select>
              </div>


              <div className="paddingTop5"> Price
                <div className="paddingTop5">
                  <input
                    type="number"
                    name=""
                    placeholder="Min."
                    id=""
                    value={priceInputFilterMin}
                    className="priceMinInput"
                    onChange={(e) => setPriceInputFilterMin(parseValueToInt(e))}
                    disabled={selectedPostType === ''}
                  />
                  <input
                    type="number"
                    name=""
                    placeholder="Max."
                    id=""
                    value={priceInputFilterMax}
                    onChange={(e) => setPriceInputFilterMax(parseValueToInt(e))}
                    className="priceMinInput left10"
                    disabled={selectedPostType === ''}
                  />
                </div>
              </div>
            </div>
            <div className="filter_control_div"> Categories
              <div className="filterPostTypeSelectDiv">
                <select className="filterPostTypeSelect" value={selectedCategoryType} onChange={(e) => setSelectedCategoryTypeDropDown(e)} >
                  <option key={-1} value={-1}>{'Select'}</option>
                  {websiteCategoriesList?.map((category: any) => (
                    <option key={category.id} value={category.id}>{category.friendlyName}</option>
                  ))}

                </select>
              </div>
            </div>
            <div className="filter_control_div"> Traffic
              <div className="paddingTop5">
                <input
                  type="number"
                  name=""
                  placeholder="Min."
                  id=""
                  value={trafficInputFilterMin}
                  onChange={(e) => setTrafficInputFilterMin(parseValueToInt(e))}
                  className="priceMinInput"
                />
                <input
                  type="number"
                  name=""
                  placeholder="Max."
                  id=""
                  value={trafficInputFilterMax}
                  onChange={(e) => setTrafficInputFilterMax(parseValueToInt(e))}
                  className="priceMinInput left10"
                />
              </div>
            </div>
            <div className="filter_control_div"> Spam Score
              <div className="paddingTop5">
                <input
                  type="number"
                  name=""
                  placeholder="Min."
                  id=""
                  value={spamScoreInputFilterMin}
                  onChange={(e) => setSpamScoreInputFilterMin(parseValueToInt(e))}
                  className="priceMinInput"
                />
                <input
                  type="number"
                  name=""
                  placeholder="Max."
                  id=""
                  value={spamScoreInputFilterMax}
                  onChange={(e) => setSpamScoreInputFilterMax(parseValueToInt(e))}
                  className="priceMinInput left10"
                />
              </div>
            </div>
            <div className="filter_control_div">
              <button className="addToCart_btn width100 " onClick={() => { applyFilterAction(); }} > Apply </button>
            </div>
          </div>
        </div>}
      {/* <div className="marketPlaceContainer">  */}

      {listView &&
        <div className="MarketPlaceTable__Con">
          <section className="MarketPlace__Con__Table__Header">
            <article>
              <h5>Website URL</h5>
              <p>DA</p>
              <p>Traffic</p>
              <p>DR</p>
              <div>
                <p>General Post</p>
                <h4>
                  {" "}
                  <span>Price ($)</span> <span>Link Insertion</span>
                </h4>
                <p>Casino Post</p>
              </div>
              <h6>Action</h6>
            </article>
            <InfiniteScroll
              dataLength={marketPlaceList.length + dataVersion}
              next={fetchMoreData}
              hasMore={hasMoreData}
              loader={''}
              height={'calc(65vh - 10px)'}
              scrollThreshold={0.8}
              pullDownToRefresh={true}
              className="infineScroll">
              {marketPlaceList?.map((marketPlaceItem: any, index: number) => (
                <div
                  className={`MarketPlaceTable__Con__Table__Cell ${index % 2 === 0
                    ? "PublisherSummaryTable__Con__Table__Cell__grey__bg"
                    : ""
                    } ${index === marketPlaceList.length - 1
                      ? "PublisherSummaryTable__Con__Table__Cell__last__border"
                      : ""
                    } `}
                  key={index}
                >

                  <a
                    href={marketPlaceItem?.websiteUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <p style={{ textAlign: 'left' }} className="WebsiteUrlPublisherSummaryTable">{marketPlaceItem?.websiteUrl}</p>
                  </a>
                  <p>{marketPlaceItem?.domainAuthority}</p>
                  <p>{formatTraffic(marketPlaceItem?.traffic)}</p>
                  <p>{marketPlaceItem?.referingDomain}</p>
                  <div>
                    <p>{marketPlaceItem?.guestPostPrice}</p>
                    <h4> {marketPlaceItem?.linkInsertionPrice}</h4>
                    <p>{marketPlaceItem?.casinoPostPrice}</p>
                  </div>
                  <h6>
                    {isAddedToCart(marketPlaceItem) ?
                      <button className="addToCart_btn min-width-120" disabled={roleType === 'Admin'}
                        onClick={() => { navigateToCart(); }}  > Go to Cart  </button> :
                      <button className="addToCart_btn min-width-120" disabled={roleType === 'Admin'}
                        onClick={() => { addToCart(marketPlaceItem, 'CART'); }} > Add To Cart  </button>}
                    {isAddedToWish(marketPlaceItem) ?
                      <button className="wishList_btn addToCart__btn min-width-120" disabled={roleType === 'Admin'}
                        onClick={() => { navigateToWishlist(); }} > Go to Wishlist</button> :
                      <button className="wishList_btn addToCart__btn min-width-120" disabled={roleType === 'Admin'}
                        onClick={() => { addToCart(marketPlaceItem, 'WISH'); }}> Wishlist</button>}
                  </h6>
                </div>
              ))}
            </InfiniteScroll>

          </section>
        </div>
      }
      {!listView &&
      
        <InfiniteScroll
          dataLength={marketPlaceList.length + dataVersion}
          next={fetchMoreData}
          hasMore={hasMoreData}
          loader={''}
          height={'calc(80vh - 10px)'}
          className="infineScroll"
        >
          {marketPlaceList?.map((marketPlaceItem: MarketPlaceItem) => (
            <div className="marketPlace_content" key={marketPlaceItem.id} >
            <div className="url_tat" >
              <div className="url" >{marketPlaceItem.websiteUrl}

              </div>
              <div className="tat" style={{ textAlign: 'end' }} >
                <img src={tatIcon} alt="" />
                TAT - {marketPlaceItem.tat} days </div>
            </div>
              <div className="domain_traffic_referring" >
                <div className="dtr_div_main">
                  <div className="width20">
                    <span> </span>
                    <FontAwesomeIcon icon={faGem} size="lg" style={{color: "#7cbd5a",}} />
                    
                  </div>
                  <div className="" > <div> DA <b>{marketPlaceItem.domainAuthority} </b></div></div>
                </div>
                <div className="dtr_div_main ">
                 
                <svg aria-hidden="true" className="marketPlaceIcon" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M396.8 352h22.4c6.4 0 12.8-6.4 12.8-12.8V108.8c0-6.4-6.4-12.8-12.8-12.8h-22.4c-6.4 0-12.8 6.4-12.8 12.8v230.4c0 6.4 6.4 12.8 12.8 12.8zm-192 0h22.4c6.4 0 12.8-6.4 12.8-12.8V140.8c0-6.4-6.4-12.8-12.8-12.8h-22.4c-6.4 0-12.8 6.4-12.8 12.8v198.4c0 6.4 6.4 12.8 12.8 12.8zm96 0h22.4c6.4 0 12.8-6.4 12.8-12.8V204.8c0-6.4-6.4-12.8-12.8-12.8h-22.4c-6.4 0-12.8 6.4-12.8 12.8v134.4c0 6.4 6.4 12.8 12.8 12.8zM496 400H48V80c0-8.84-7.16-16-16-16H16C7.16 64 0 71.16 0 80v336c0 17.67 14.33 32 32 32h464c8.84 0 16-7.16 16-16v-16c0-8.84-7.16-16-16-16zm-387.2-48h22.4c6.4 0 12.8-6.4 12.8-12.8v-70.4c0-6.4-6.4-12.8-12.8-12.8h-22.4c-6.4 0-12.8 6.4-12.8 12.8v70.4c0 6.4 6.4 12.8 12.8 12.8z"></path></svg>
                
                  <div  > <div> Traffic   <b>{formatTraffic(marketPlaceItem.traffic)} </b></div>  </div>
                </div>
                <div className="dtr_div_main">
                  <div className="width20">
                  <FontAwesomeIcon icon={faStarHalfStroke}  size="lg" style={{color: "#7cbd5a",}} />
                  </div>
                  <div className="" > <div> DR  <b>{marketPlaceItem.referingDomain}</b> </div>  </div>
                </div>
                <div className="dtr_div_main_26">
                  <div className="width20">
                  <FontAwesomeIcon icon={faCommentSlash} size="lg" style={{color: "#7cbd5a",}} />
                  </div>
                  <div className="" > <div> Spam Score  <b>{marketPlaceItem.spamScore}</b> </div>  </div>
                </div>
              </div>

              <div className="guest_link_casino">
                {(marketPlaceItem.guestPostPrice !== undefined && marketPlaceItem.guestPostPrice !== 0) &&
                  <div className="dtr_div_30">
                    <div className="width20">  <input type="radio" value="Male" name={marketPlaceItem.id}
                      defaultChecked={'GUEST_POST' === defaultMarketPlace(marketPlaceItem)}
                      onChange={() => { setPostType(marketPlaceItem, "GUEST_POST") }} disabled={roleType === 'Admin'} />  </div>
                    <div className="width80" > <div> General Post </div> <div> <b>${marketPlaceItem.guestPostPrice}</b> </div>  </div>
                  </div>
                }
                {!marketPlaceItem.guestPostPrice &&
                  <div className="dtr_div_30">
                    <div className="width20">  <input type="radio" value="Male" name={marketPlaceItem.id}
                      disabled />  </div>
                    <div className="width80" > <div> General Post </div> <div> <b>NA</b> </div>  </div>
                  </div>
                }
                {(marketPlaceItem.linkInsertionPrice !== undefined && marketPlaceItem.linkInsertionPrice !== 0) &&
                  <div className="dtr_div_30">
                    <div className="width20">  <input type="radio" value="Male" name={marketPlaceItem.id}
                      defaultChecked={'LINK_INSERTION' === defaultMarketPlace(marketPlaceItem)}
                      onChange={() => { setPostType(marketPlaceItem, "LINK_INSERTION") }} disabled={roleType === 'Admin'} />  </div>
                    <div className="width80" > <div>Link Insertion </div> <div>  <b>${marketPlaceItem.linkInsertionPrice}</b> </div> </div>
                  </div>
                }
                {!marketPlaceItem.linkInsertionPrice &&
                  <div className="dtr_div_30">
                    <div className="width20">  <input type="radio" value="Male" name={marketPlaceItem.id}
                      defaultChecked={'LINK_INSERTION' === defaultMarketPlace(marketPlaceItem)}
                      onChange={() => { setPostType(marketPlaceItem, "LINK_INSERTION") }} disabled />  </div>
                    <div className="width80" > <div>Link Insertion </div> <div>  <b>NA</b> </div> </div>
                  </div>
                }
                {(marketPlaceItem.cryptoPostPrice !== undefined && marketPlaceItem.cryptoPostPrice !== 0) && <>
                  <div className="dtr_div_40">
                    <div className="width20">  <input type="radio" value="Male" name={marketPlaceItem.id}
                      defaultChecked={'CRYPTO_POST' === defaultMarketPlace(marketPlaceItem)}
                      onChange={() => { setPostType(marketPlaceItem, "CRYPTO_POST") }} disabled={roleType === 'Admin'} />  </div>
                    <div className="width80" > <div> Crypto/Finance Post </div> <div> <b>${marketPlaceItem.cryptoPostPrice}</b> </div> </div>
                  </div>
                </>
                }
                {!marketPlaceItem.cryptoPostPrice &&
                  <div className="dtr_div_40">
                    <div className="width20">  <input type="radio" value="Male" name={marketPlaceItem.id}
                      disabled />  </div>
                    <div className="width80" > <div> Crypto/Finance Post </div> <div> <b>NA</b> </div> </div>
                  </div>
                }
              </div>
              {/* {marketPlaceItem.isSenstiveAccepted && <> */}
              <div className="container_fbdn">
                <div className="label_fbdn">Forbidden/Sensitive Post</div>
              </div>

              <div className="guest_link_casino">
                {(marketPlaceItem.casinoPostPrice !== undefined && marketPlaceItem.casinoPostPrice !== 0) &&
                  <div className="dtr_div_30">
                    <div className="width20">  <input type="radio" value="Male" name={marketPlaceItem.id}
                      defaultChecked={'CASINO_POST' === defaultMarketPlace(marketPlaceItem)}
                      onChange={() => { setPostType(marketPlaceItem, "CASINO_POST") }} disabled={roleType === 'Admin'} />  </div>
                    <div className="width80" > <div> Casino Post </div> <div> <b>${marketPlaceItem.casinoPostPrice}</b> </div>  </div>
                  </div>
                }
                {!marketPlaceItem.casinoPostPrice &&
                  <div className="dtr_div_30">
                    <div className="width20">  <input type="radio" value="Male" name={marketPlaceItem.id} disabled />  </div>
                    <div className="width80" > <div> Casino Post </div> <div> <b>NA</b> </div>  </div>
                  </div>
                }
                {(marketPlaceItem.cbdPostPrice !== undefined && marketPlaceItem.cbdPostPrice !== 0) &&
                  <div className="dtr_div_30">
                    <div className="width20">  <input type="radio" value="Male" name={marketPlaceItem.id}
                      defaultChecked={'CBD_POST' === defaultMarketPlace(marketPlaceItem)}
                      onChange={() => { setPostType(marketPlaceItem, "CBD_POST") }} disabled={roleType === 'Admin'} />  </div>
                    <div className="width80" > <div>CBD/Vape Post </div> <div>   <b>${marketPlaceItem.cbdPostPrice}</b> </div> </div>
                  </div>
                }
                {!marketPlaceItem.cbdPostPrice &&
                  <div className="dtr_div_30">
                    <div className="width20">  <input type="radio" value="Male" name={marketPlaceItem.id} disabled />  </div>
                    <div className="width80" > <div>CBD/Vape Post </div> <div>  <b>NA</b> </div> </div>
                  </div>
                }
                {(marketPlaceItem.adultPostPrice !== undefined && marketPlaceItem.adultPostPrice !== 0) &&
                  <div className="dtr_div_40">
                    <div className="width20">  <input type="radio" value="Male" name={marketPlaceItem.id}
                      defaultChecked={'ADULT_POST' === defaultMarketPlace(marketPlaceItem)}
                      onChange={() => { setPostType(marketPlaceItem, "ADULT_POST") }} disabled={roleType === 'Admin'} />  </div>
                    <div className="width80" > <div> Adult Post </div> <div> <b>${marketPlaceItem.adultPostPrice}</b> </div> </div>
                  </div>
                }
                {!marketPlaceItem.adultPostPrice &&
                  <div className="dtr_div_40">
                    <div className="width20">  <input type="radio" value="Male" name={marketPlaceItem.id} disabled />  </div>
                    <div className="width80" > <div> Adult Post </div> <div> <b>NA</b> </div> </div>
                  </div>
                }
              </div>
              {/* </>}
              {!marketPlaceItem.isSenstiveAccepted && <>
                <div className="dummy_forbidden_post"></div>
              </>} */}
              <Tooltip data-class="tool" id="show-Category" place="top" />
              <div className="guest_link_casino displayInlineBlock width100 categoryParentDiv"> <p className="floatLeft marginCategoryText">Categories</p>
                {getCategoryArrayMap(marketPlaceItem)?.map((id: any) => (
                  <div className="category_div floatLeft" key={id} > <b> {getCategoryName(id)} </b></div>

                ))}
                {getSlicedCategoryArrayMap(marketPlaceItem).length > 0 && <div className="category_div floatLeft" data-class="tool1"
                  data-tooltip-id="show-Category" data-tooltip-content={getSlicedCategoryStr(marketPlaceItem)}><b>
                    +{getSlicedCategoryArrayMap(marketPlaceItem).length}</b>
                </div>}
              </div>
              <div className="domain_traffic_referring " >
                <div className="dtr_div_30">
                  {/* <div className="width20"> 
                     <img src={daIcon} alt="" /> 
                     </div> */}
                  <div className="" > <div> Post Validity  <b>{getPostValidityType(marketPlaceItem.guaranteePostValidity)} </b></div></div>
                </div>
                <div className="dtr_div_30">
                  {/* <img src={trafficIcon} alt="" /> */}
                  <div  > <div> Min. Word Count  <b>{formatTraffic(marketPlaceItem.minPostWordCount)} </b></div>  </div>
                </div>
                <div className="dtr_div_40">
                  {/* <div className="width20">  
                    <img src={rdIcon} alt="" /> 
                  </div> */}
                  <div className="" > <div> Max. Backlinks  <b>{marketPlaceItem.maxBackLinks}  {getBacklinkType(marketPlaceItem.backLinkType)}</b>  </div>  </div>
                </div>
              </div>
              <div className="btnDiv btnParentDiv">
                <div className="sample_guideline_icon">
                  <Tooltip id="show-guidelines" place="top" />
                  <Tooltip data-class="tool" id="show-sample" place="top" />
                  {marketPlaceItem.postGuideline !== undefined && marketPlaceItem.postGuideline !== '' && <>
                    <b>Guidelines </b>

                    <img className="iconStyle" src={questionIcon} alt=""
                      data-tooltip-id="show-guidelines" data-tooltip-content="Show Guidelines" onClick={() => { togglePopup(marketPlaceItem.postGuideline); }} />
                  </>}
                  {marketPlaceItem.sampleUrl !== undefined && marketPlaceItem.sampleUrl !== '' &&
                    <>
                      <b>Sample URL </b><a href={marketPlaceItem.sampleUrl} target="_blank" rel="noreferrer" >
                        <img src={checkIcon} className="iconStyle" alt="" data-class="tool1" data-tooltip-id="show-sample" data-tooltip-content="Show Sample" /></a>
                    </>}</div>
                <div className="width60">
                  {isAddedToCart(marketPlaceItem) ? <a className="textDecorationNone" href="/dashboard/cart"> <button className="addToCart_btn" disabled={roleType === 'Admin'}   > Go to Cart  </button> </a> :
                    <button className="addToCart_btn" onClick={() => { addToCart(marketPlaceItem, 'CART'); }} disabled={roleType === 'Admin'}   > Add To Cart  </button>}
                  {isAddedToWish(marketPlaceItem) ? <a className="textDecorationNone" href="/dashboard/wishlist">
                    <button className="wishList_btn addToCart__btn" disabled={roleType === 'Admin'}    > Go to Wishlist</button> </a> :
                    <button className="wishList_btn addToCart__btn" disabled={roleType === 'Admin'} onClick={() => { addToCart(marketPlaceItem, 'WISH'); }}> Add to Wishlist</button>}
                </div>
              </div>
            </div>


          ))}

        </InfiniteScroll>
      }
      <ToastContainer />
      {/* </div>  */}
      {isOpen && <Popup
        content={<>
          <div><div className="popup-header">Guidelines</div>
            <div className="popup-content">{parse(guidelineContent)}</div>
            <div className="btnDiv">
              <button className="wishList_btn addToCart_btn" onClick={() => { togglePopup(""); }} > Close  </button>
            </div>
          </div>
        </>}
        handleClose={togglePopup}
      />}

      <p className="publisherWebsiteMid__Footer">
        © 2024 Powered By Outreach Kart | All Rights Reserved
      </p>
    </div>
  );
};

export default MarketPlaceMid;
