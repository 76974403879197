import React from "react";
import "./addWebsiteForm.scss";
import settings from "../../assets/add_Websites/settings.svg";
import globe from "../../assets/add_Websites/globe.svg";
import globeGreen from "../../assets/add_Websites/globeGreen.svg";
import AddWebsiteFormBegin from "./AddWebsiteFormBegin";
import AddWebsiteFormMeta from "./AddWebsiteFormMeta";
import AddWebsiteFormHtml from "./AddWebsiteFormHtml";
import AddwebsiteFormDns from "./AddwebsiteFormDns";
import AddwebsiteFormDetails from "./AddWebsiteFormDetails";
import { useAppSelector } from "../../store/reduxHooks";

type Props = {
  setIsAddWebsite: React.Dispatch<React.SetStateAction<boolean>>;
  setShowTable: React.Dispatch<React.SetStateAction<boolean>>;
};

const AddWebsiteForm: React.FC<Props> = ({ setIsAddWebsite, setShowTable }) => {

  const { formDisplay } = useAppSelector((state) => ({
    formDisplay: state?.addWebsiteState?.formDisplay,
  }));
  return (
    <div className="pubLisherAddWebsite__Con">
      { formDisplay.isAdd && 
      <h4> Add Website Form</h4> }
      { !formDisplay.isAdd && 
      <h4> Edit Website Form</h4> }
      <div className="pubLisherAddWebsite__form">
        <div className="pubLisherAddWebsite__form__Status">
          <div className="pubLisherAddWebsite__form__Status__icons">
            <div className="greenForm__Line"></div>
            <img src={settings} alt="" />
            <div
              className={`${
                formDisplay.details
                  ? "greenForm__Line__mid"
                  : "greyForm__Line__mid"
              }  `}
            >
              {" "}
            </div>
            {formDisplay.details ? (
              <img src={globeGreen} alt="grey" />
            ) : (
              <img src={globe} alt="globeGreen" />
            )}
            <div
              className={`${
                formDisplay.details ? "greenForm__Line" : "greyForm__Line"
              } `}
            >
              {" "}
            </div>
          </div>
          <div className="pubLisherAddWebsite__form__Status__labels">
            <p>Check Eligibility</p>
            <p
              className={`${
                formDisplay.details
                  ? "pubLisherAddWebsite__form__Status__labels__details__Active"
                  : "pubLisherAddWebsite__form__Status__labels__details__Inactive"
              } `}
            >
              Website Details
            </p>
          </div>
        </div>
        <form action="" className="pubLisherAddWebsite__form__mid">
          {formDisplay.begin && <AddWebsiteFormBegin  />}
          {formDisplay.meta && (
            <AddWebsiteFormMeta
              setIsAddWebsite={setIsAddWebsite}
              
            />
          )}
          {formDisplay.html && (
            <AddWebsiteFormHtml
              setIsAddWebsite={setIsAddWebsite}
              
            />
          )}
          {formDisplay.dns && (
            <AddwebsiteFormDns
              
              setIsAddWebsite={setIsAddWebsite}
            />
          )}
          {formDisplay.details && (
            <AddwebsiteFormDetails
        
              setIsAddWebsite={setIsAddWebsite}
              setShowTable={setShowTable}
            />
          )}
        </form>
      </div>
    </div>
  );
};

export default AddWebsiteForm;
