import axios from "axios";
import {  BLOG_POST,ADMIN_SAVE_BLOG,GET_BLOG,GET_BLOG_CATEGORIES,ADD_BLOG_CATEGORY,CATEGORIES_LIST,BLOG_BY_PERMLINK} from "../constants/url";

export const postBlogPost = async (
  pageOffset: number,
  pageRowLimit: number
) => {
  try {
    let filters = [{name:"isEnabled",value:true}];
    const accessToken = sessionStorage.getItem('access-token');
    const response = await axios.post(process.env.REACT_APP_BASE + BLOG_POST,{pageOffset,pageRowLimit,filters},{ headers: { 'Authorization': 'Bearer '+accessToken}});
  
    return response.data.data
  } catch (err) {
    return err;
  }
};

export const adminBlogPostSummary = async (
  pageOffset: number,
  pageRowLimit: number,
  isEnabled: boolean|undefined,
  categoryIds : string|undefined,
  searchInput : string|undefined,
  authorId  : string|undefined,
) => {
  let filters;
  let searchParams ;
  if( authorId === undefined){
    if(isEnabled !== undefined && (categoryIds !== undefined && categoryIds.length > 0) ){
      filters = [{name:"isEnabled",value:isEnabled},{name:"categoryIds",value:categoryIds}];
    }else if((categoryIds !== undefined && categoryIds.length > 0)){
      filters = [{name:"categoryIds",value:categoryIds}];
    }else if(isEnabled !== undefined){
      filters = [{name:"isEnabled",value:isEnabled}];
    }
    if(searchInput){
      searchParams = [{name:"title",value:searchInput}]
    }
  }else{
    filters = [{name:"isEnabled",value:true},{name:"authorId",value:authorId}];
  }

  try {
    const accessToken = sessionStorage.getItem('access-token');
    const response = await axios.post(process.env.REACT_APP_BASE + BLOG_POST,{pageOffset,pageRowLimit,filters,searchParams},{ headers: { 'Authorization': 'Bearer '+accessToken}});
    return response.data
  } catch (err) {
    return err;
  }
};


export const saveBlogApi = async (
  blogObj: {} ,
  file : any,
) => {
  try {
    var formData = new FormData();
    formData.append("file", file);
    formData.append("payload", JSON.stringify(blogObj));
    const url = ADMIN_SAVE_BLOG ;
    const accessToken = sessionStorage.getItem('access-token');
    const response = await axios.post(
      process.env.REACT_APP_BASE + url ,
      formData,
      { headers: { 'Authorization': 'Bearer '+accessToken}}
    );
    return response?.data;
  } catch (err: any) {
    console.log(err);
    return err?.response?.data;
  }
};

export const fetchBlogApi = async (
  blogId: string,
) => {
  try {
    const accessToken = sessionStorage.getItem('access-token');
    const response = await axios.get(
      process.env.REACT_APP_BASE + ADMIN_SAVE_BLOG+"/"+blogId,
      {
        headers: { 'Authorization': 'Bearer '+accessToken}
      }
    );
    return response.data;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const fetchHomeBlogApi = async (
  blogId: string,
) => {
  try {
    const response = await axios.get(
      process.env.REACT_APP_BASE + GET_BLOG+"/"+blogId,
    );
    return response.data;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const fetchBlogCategories = async () => {
  try {
    const accessToken = sessionStorage.getItem('access-token');
    const response = await axios.get(
      process.env.REACT_APP_BASE + GET_BLOG_CATEGORIES,
      {
        headers: { 'Authorization': 'Bearer '+accessToken}
      }
    );
    return response.data;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const saveCategoryApi = async (
  title: {} 
) => {
  try {
    const accessToken = sessionStorage.getItem('access-token');
    const response = await axios.post(
      process.env.REACT_APP_BASE + ADD_BLOG_CATEGORY ,
      {title},
      { headers: { 'Authorization': 'Bearer '+accessToken}}
    );
    return response?.data;
  } catch (err: any) {
    console.log(err);
    return err?.response?.data;
  }
};

export const fetchCategoriesList = async () => {
  try {
    const response = await axios.get(
      process.env.REACT_APP_BASE + CATEGORIES_LIST
    );
    return response.data;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const fetchBlogFromPermlink = async (
  permLink: string,
) => {
  try {
    const response = await axios.get(
      process.env.REACT_APP_BASE + BLOG_BY_PERMLINK+permLink,
    );
    return response.data;
  } catch (err) {
    console.log(err);
    return err;
  }
};