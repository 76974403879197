import React, { useEffect, useState } from "react";
import "./AdminBillingComp.scss";
import { getDateFormatted,getBillingStatus,getInitalCap } from "../../utils/filters/nameFilters";
import { fetchAdminBillingList } from "../../utils/api/billing";
import SideHeaderAdmin from "../../components/SideHeader/SideHeaderAdmin";
import DashboardHeaderAdmin from "../../components/DashboardHeader/DashboardHeaderAdmin";
import AppLoader from "../../components/defaultLoader/DefaultLoader";
//import { ToastContainer, toast } from "react-toastify";
import InfiniteScroll from 'react-infinite-scroll-component';
import withAuth from "../../hoc/withAuth";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { selectInitiatedByArray } from "../../utils/constants/selected";
import search from "../../assets/add_Websites/search.svg";

const AdminBillingComp = () => {
  const tableCells = [1, 2, 3, 4, 5, 6];
  const [billingList, setBillingList] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [hasMoreData, setHasMoreData] = useState<boolean>(true);
  const [initiatedByFilter, setInitiatedByFilter] = useState<any>('');
  const [searchInput, setSearchInput] = useState<string>("");

  const fetchAdminBillinglist = async (intiatedByFiler:string|undefined) => {
    const response = await fetchAdminBillingList(0,10,intiatedByFiler);
    if (response?.status?.code === 200) {
      setBillingList(response?.data?.payment?.invoices);
      setPageNumber(1);
      setHasMoreData(response?.data?.payment?.pageInfo?.hasNext);
      setIsLoading(false);
    }
  };

  const fetchMoreData = async () => {
    const response = await fetchAdminBillingList(pageNumber, 10,initiatedByFilter);
    if (response?.status?.code === 200) {
        setHasMoreData(response?.data?.payment?.pageInfo?.hasNext);
        const list = billingList;
        const mergedResult = [...list, ...response?.data?.payment?.invoices];
        setPageNumber(pageNumber + 1);
        setBillingList(mergedResult);
    }
};

const handleInitiatedByFilterChange = (event: { target: { value: string } }) => {
  setInitiatedByFilter(event.target.value);
  fetchAdminBillinglist(event.target.value);
};

const searchOrderData = () => {
  setPageNumber(1);
  fetchAdminBillinglist(undefined);
};


const clearSearchOrderData = () => {
  setSearchInput('');
 // getFilteredOrdersListData("");
};

const setSearchOrderData = (e: any) => {
  //   var inputType = e.nativeEvent.inputType;
  //   if (inputType !== 'deleteContentForward' && inputType !== 'deleteContentBackward') {
  setSearchInput(e.target.value);
  //   }
};
  useEffect(() => {
    fetchAdminBillinglist(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (

    <div className={`dashboard_Con   `}>
    <SideHeaderAdmin />

    <div className={`dashboard_Con__Right`}>
        <DashboardHeaderAdmin />
        <div className="publisherWebsiteMid__Con">
            {isLoading && <AppLoader size="lg" ></AppLoader>}
            <div className="pubLisherAddWebsite__Con">
                <h4>Order Billing</h4>
                <div>
                    <div className="sortDiv floatLeft">


<FormControl sx={{ m: 1, minWidth: 180 }} size="small">
    <InputLabel id="demo-select-small-label">Select Status</InputLabel>
    <Select
        labelId="demo-simple-select-helper-label"
        id="demo-simple-select-helper"
        value={initiatedByFilter}
        label="Select Initiated By"
        onChange={handleInitiatedByFilterChange}>
        <MenuItem value="">
            <em>Select Status</em>
        </MenuItem>
        {selectInitiatedByArray?.map((selectInitiatedByObj: any, index) => (
            <MenuItem value={selectInitiatedByObj.id} key={index}>{selectInitiatedByObj.friendlyName}</MenuItem>
        ))}
    </Select>
</FormControl>
</div>
<div className="searchAdvPubDiv floatRight">
                            <FormControl sx={{ m: 1, minWidth: 250,background:'white' }} size="small">
                                 <input
                                    className="searchMarketPlace"
                                    type="text"
                                    value={searchInput}
                                    placeholder='Search Advertiser/Publisher'
                                    onChange={(e: any) => setSearchOrderData(e)}
                                /> 
                                {/* <TextField id="outlined-basic" label="Search Advertiser/Publisher" variant="outlined"
                                 value={searchInput} onChange={(e: any) => setSearchCustomerData(e)} className="searchMarketPlace"/> */}
                                
                                {searchInput !== '' && <img className="search-img right25px" src={search} alt="" onClick={() => { searchOrderData(); }} />}
                                {searchInput !== '' && <span className="material-icons lightGrey close-img right0" onClick={() => { clearSearchOrderData(); }}>close</span>}
                                </FormControl>
                            </div>
                            </div>
              

            <section className="PublisherSummaryTable__Con__Table__Header">
              <article>
                <h5>Website URL</h5>
                <p>Order Date</p>
                <p>Invoice Number</p>
                <h5 style={{ textAlign: 'center' }}>Description</h5>
                <p>Invoice Date</p>
                <p>Amount</p>
                <p>Payment Date</p>
                <p>Status</p>
                <h6>Invoice</h6>
                <p>Initiated By</p>
              </article>
              <InfiniteScroll
                                        dataLength={billingList.length}
                                        next={fetchMoreData}
                                        hasMore={hasMoreData}
                                        loader={''}
                                        height={'calc(58vh - 10px)'}
                                    >
              {billingList.length !== 0 &&
                billingList?.map((cell: any, index: number) => (
                  <div
                    className={`PublisherSummaryTable__Con__Table__Cell ${index % 2 === 0
                        ? "PublisherSummaryTable__Con__Table__Cell__grey__bg"
                        : ""
                      } ${index === tableCells.length - 1
                        ? "PublisherSummaryTable__Con__Table__Cell__last__border"
                        : ""
                      } `}
                    key={index}
                  >
                    <a
                      href={cell?.websiteUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <p style={{ textAlign: 'left' }} className="WebsiteUrlPublisherSummaryTable">{cell?.websiteUrl}</p>
                    </a>
                    <p>{getDateFormatted(cell?.order?.createdDate)}</p>
                    <p>{cell?.invoiceNumber}</p>
                    <h5>{(cell?.description)}</h5>
                    <p>{getDateFormatted(cell?.invoiceDate)}</p>
                    <p>{(cell?.price)}</p>
                    <p>{getDateFormatted(cell?.order?.updatedDate)}</p>
                    <p>{getBillingStatus(cell?.status)}</p>
                    <h6>
                    <span>
                      <a href={cell?.invoiceUrl} target="_blank" rel="noopener noreferrer"
                       className="material-icons lightGrey textDecorationNone fontsize18" title="View/Download Invoice">download</a>
                     </span>
                    </h6>
                    <p>{getInitalCap(cell?.initiatedBy)}</p>
                  </div>
                ))}
                </InfiniteScroll>
            </section>
          </div>
        </div>
      </div>
    </div>
    
  );
};

export default withAuth(AdminBillingComp);
