import React, { useState } from "react";
// import { activateCustomerWebsite } from "../../utils/api/addWebsite";
import { toast } from "react-toastify";
import { useAppDispatch, useAppSelector } from "../../store/reduxHooks";
import { setActiveSuccess } from "../../reducers/addWebsite/addWebsitedataReducer";
import { postAdminApproveWebsite, getAdminWebsiteCount } from "../../utils/api/adminWebsite";
import { setAdminWebsiteDetails, setTaskboardDisplay } from "../../reducers/admin/adminReducer";
import { ApproveWebsiteDetailsError } from "../../utils/types/main";
import { approveWebsiteValidator } from "../../utils/validators/addWebsitevalidators";
type Props = {
  setApproveData: React.Dispatch<
    React.SetStateAction<{
      display: boolean;
      websiteObj: any;
      categories: any;
    }>
  >;
  approveData: {
    display: boolean;
    websiteObj: any;
    categories: any;
  };
};

const AdminApprovePopup: React.FC<Props> = ({
  setApproveData,
  approveData,
}) => {
  const dispatch = useAppDispatch();
  const { taskboardDisplay, adminWebsiteDetails } = useAppSelector((state) => ({
    taskboardDisplay: state.adminState.taskboardDisplay,
    adminWebsiteDetails: state?.adminState?.adminWebsiteDetails,
  }));
  const [websiteObj] = useState<any>(approveData?.websiteObj);
  const [approvalObj, setApprovalObj] = useState(
    {
      websiteId: websiteObj?.id,
      domainAuthority: websiteObj?.domainAuthority || '',
      traffic: websiteObj?.traffic || '',
      referingDomain: websiteObj?.referingDomain || '',
      spamScore: websiteObj?.spamScore || (websiteObj?.spamScore  !== 0 ? '' : 0),
    }
  );

  const [displayError, setDisplayError] = useState<ApproveWebsiteDetailsError>({
    isError: false,
    domainAuthority: false,
    traffic: false,
    referingDomain: false,
    spamScore: false,
  })

  const parseFloatVal = (val: any) => {
    if (val === '') {
      return '';
    }
    if (val > 9007199254740991) {
      var temp = val.toString().substring(0, val.toString().length - 1);
      var tempVal = parseFloat(temp);
      if (tempVal > 9007199254740991) {
        return 9007199254740991;
      } else {
        return tempVal;
      }
    } else {
      if (isNaN(parseFloat(val))) {
        return '';
      } else {
        return parseFloat(val);
      }
    }
  }

  const handleYes = async () => {
    const innerDisplay = approveWebsiteValidator(
      approvalObj,
      displayError,
      setDisplayError
    );
    if (!innerDisplay.isError) {
      console.log("approveData", approveData?.websiteObj?.id);

      setApproveData({ ...approveData, display: false });
      dispatch(
        setAdminWebsiteDetails({ ...adminWebsiteDetails, isLoading: true })
      );

      // setApprovalObj({
      //   ...approvalObj,
      //   websiteId: approveData?.websiteObj?.id,
      // })
      approvalObj.websiteId = approveData?.websiteObj?.id;
      console.log("approveData", approvalObj);
      const response = await postAdminApproveWebsite(approvalObj);
      if (response?.status?.code === 200) {
        toast.success("Website approved successfully.", {
          position: "top-right",
          theme: "colored",
          autoClose: 5000,
        });

        if (response?.status?.code === 200) {
          const arr = adminWebsiteDetails?.data;
          const newList = arr.filter((item: any) => item.id !== approveData?.websiteObj?.id);
          dispatch(
            setAdminWebsiteDetails({ ...adminWebsiteDetails, data: newList })
          );
          const responseCount = await getAdminWebsiteCount();
          if (responseCount?.status?.code === 200) {
            const total = responseCount?.data?.websiteApprovalData?.totalNewApproval;
            const newTotal = responseCount?.data?.websiteApprovalData?.totalPendingApproval;
            const edit = responseCount?.data?.websiteApprovalData?.totalEditApproval;
            const selected = taskboardDisplay?.selected;
            dispatch(
              setTaskboardDisplay({
                ...taskboardDisplay,
                isActive: true,
                selected: selected,
                total: total,
                edit: edit,
                new: newTotal,
              })
            );
          }
        } else {
          dispatch(
            setAdminWebsiteDetails({
              ...adminWebsiteDetails,
              isLoading: false,
              data: [],
              pageInfo: {
                hasNext: false,
                totalCount: 0,
              },
            })
          );
        }

      } else {
        toast.error(
          response?.status?.errors?.[0]?.websiteNotFound ||
          "Something went wrong",
          {
            position: "top-right",
            theme: "colored",
            autoClose: 5000,
          }
        );
        dispatch(setActiveSuccess(true))
      }
    }
  };
  const handleNo = () => {
    setApproveData({ ...approveData, display: false });
  };

  return (
    <div className="publisherDeletePopup__Con__out">
      <div className="admin_approve_popup_out">
        <div className="width100 website_approval_header" >Website Approval</div>
        <div className="approval_info_container width100">
          <div className="width100 flexrow approvalRow">
            <div className="width50 flexrow">
              <div className="width40 flexrow">Website Url : </div>
              <div className="width60 flexrow"> {approveData?.websiteObj?.websiteUrl}</div>
            </div>
            <div className="width50 flexrow">
              <div className="width40 flexrow">Email ID :</div>
              <div className="width60 flexrow">{approveData?.websiteObj?.emailId}</div>
            </div>
          </div>
          <div className="width100 flexrow approvalRow">
            <div className="width50 flexrow">
              <div className="width60 "><p>Domain Authority : </p>
                <p className="errorText">
                  {displayError.domainAuthority && (
                    <p>
                      {" "}
                      <span>{displayError.domainAuthority}</span>{" "}
                    </p>
                  )}</p>
              </div>
              <div className="width40 flexrow inputRowPadding">
                <input
                  id="outlined-number"
                  type="number"
                  className="approvalInpBtn"
                  placeholder="Enter Domain Authority"
                  value={approvalObj.domainAuthority}
                  onChange={(e: any) =>
                    setApprovalObj({
                      ...approvalObj,
                      domainAuthority: parseFloatVal(e.target.value),
                    })
                  }
                />
              </div>
            </div>
            <div className="width50 flexrow">
              <div className="width60 "><p>Traffic :</p>
                {displayError.traffic && (
                  <p className="errorText">
                    {" "}
                    <span>{displayError.traffic}</span>{" "}
                  </p>
                )}</div>
              <div className="width40 flexrow inputRowPadding">
                <input
                  id="outlined-number"
                  type="number"
                  className="approvalInpBtn"
                  placeholder="Enter Traffic"
                  value={approvalObj.traffic}
                  onChange={(e: any) =>
                    setApprovalObj({
                      ...approvalObj,
                      traffic: parseFloatVal(e.target.value),
                    })
                  }
                />
              </div>
            </div>
          </div>
          <div className="width100 flexrow approvalRow">
            <div className="width50 flexrow">
              <div className="width60 "><p>Domain Rating :</p>
                {displayError.referingDomain && (
                  <p className="errorText">
                    {" "}
                    <span>{displayError.referingDomain}</span>{" "}
                  </p>
                )}</div>
              <div className="width40 flexrow inputRowPadding">
                <input
                  id="outlined-number"
                  type="number"
                  className="approvalInpBtn"
                  placeholder="Enter Domain Rating"
                  value={approvalObj.referingDomain}
                  onChange={(e: any) =>
                    setApprovalObj({
                      ...approvalObj,
                      referingDomain: parseFloatVal(e.target.value),
                    })
                  }
                />
              </div>
            </div>
            <div className="width50 flexrow">
              <div className="width60 "><p>Spam Score :</p>
                {displayError.spamScore && (
                  <p className="errorText">
                    {" "}
                    <span>{displayError.spamScore}</span>{" "}
                  </p>
                )}</div>
              <div className="width40 flexrow inputRowPadding">
                <input
                  id="outlined-number"
                  className="approvalInpBtn"
                  type="number"
                  value={approvalObj.spamScore}
                  placeholder="Enter Spam Score"
                  onChange={(e: any) =>
                    setApprovalObj({
                      ...approvalObj,
                      spamScore: parseFloatVal(e.target.value),
                    })
                  }
                />
              </div>
            </div>
          </div>
        </div>
        <div className="width100 text-align-center admin_buttons_padding">
          <button
            className="admin_cancel"
            onClick={() => handleNo()} > Cancel
          </button>
          <button
            className="admin_save_and_approve"
            onClick={() => handleYes()}
          > Save and Approve
          </button>
        </div>
      </div>

    </div >
  );
};

export default AdminApprovePopup;
