import signupImage from "../../assets/signup/forgetpassword.png";
import "./forgotPassword.scss";
import { useEffect, useState } from "react";
import { forgotPasswordValidator } from "../../utils/validators/registerValidator";
import { forgotPasswordPost } from "../../utils/api/forgotPassword";
import { forgotPasswordInitial } from "../../utils/constants/selected";
import { ForgotPasswordPayload } from "../../utils/types/main";
import { ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import ReCaptchaComponent , { reGenerateToken } from '../../pages/ReCaptchaComponent/ReCaptchaComponent';

const ForgotPassword = () => {
  const [forgotPasswordData, setForgotPasswordData] =
    useState<ForgotPasswordPayload>(forgotPasswordInitial);
  const [errorData, SetErrorData] = useState<any>("");
  const [text, setText] = useState<{isError:boolean,message:string}>({
    isError:false,
    message:""
  });

  const handleVerify = (token: string | null) => {
    //console.log(" tedt ",token);
  //  setRecaptchaToken(token);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const forgotSubmit = async () => {
    const { error, isError } = forgotPasswordValidator(forgotPasswordData);

    if (isError) {
      SetErrorData(error);
    } else {
      SetErrorData(error);
      reGenerateToken().then((captchaToken) => {
        //console.log("captchaToken   --> ", captchaToken);
        return forgotPasswordPost(forgotPasswordData,captchaToken);
      }).then((response) => {

      //const response = await forgotPasswordPost(forgotPasswordData);

      if (response?.status?.code === 200) {
        setForgotPasswordData({ emailId: "" });
        setText( {isError:false,message:response?.status?.msg} );
      } else {
        setText({isError:true, message:"Please enter valid email address"});
        // toast.error("Please enter valid email address", {
        //   position: "top-right",
        //   theme: "colored",
        //   autoClose: 3000,
        // });
      }
    })
    }
  };

  return (
    <>
      <div className="forgotPassword_Con">
      <ReCaptchaComponent onVerify={handleVerify} />
        <div className="forgotPassword_Left">
          <h2>Forgot Password</h2>
          <h6>
            Type the email you used to sign up and we'll send you a password
            reset email.
          </h6>
          <ToastContainer />
          <div className="forgotPassword-input">
            <label htmlFor="">Email</label>
            <input
              type="email"
              placeholder="example@outlook.com"
              value={forgotPasswordData.emailId.trim()}
              onChange={(e) =>
                setForgotPasswordData({ emailId: e.target.value })
              }
              style={{
                border: `${
                  errorData?.emailId ? "1px solid #FF0000" : "1px solid #aaaaaa"
                }`,
              }}
            />
            {errorData && (
              <span className="forgot-error-text">{errorData?.emailId}</span>
            )}
            <span className={`response-text  ${text.isError?'forgot-error-text__red':'forgot-error-text__green'} `}>{text.message}</span>
          </div>
          <div className="forgot_password"></div>
          <div className="forgot-submit_btn_area">
            <button className="forgot-btn" onClick={() => forgotSubmit()}>
              Submit
            </button>
          </div>
          <h4>
            Goto{" "}
            <Link to="/login" style={{ textDecoration: "none" }}>
              {" "}
              <span> Log in</span>{" "}
            </Link>
          </h4>
        </div>

        <div className="forgotPassword_Right">
          <img src={signupImage} alt="Signup" />
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
