import React, { useEffect, useState } from "react";
import "./cart.scss";
import { getWishCartList, placeOrder, removeFromWishList } from "../../utils/api/marketPlace";
import { getWebsiteCategories } from "../../utils/api/getWebsiteCategories";
import SideHeader from "../../components/SideHeader/SideHeader";
import DashboardHeader from "../../components/DashboardHeader/DashboardHeader";
import { Tooltip } from 'react-tooltip'
import checkIcon from '../../assets/marketPlace/check_Icon.svg'
import questionIcon from '../../assets/marketPlace/Question_Icon.svg'
import Popup from '../../components/popup/popup';
//import {CartDetailsPayload} from "../../utils/types/main";
import info from "../../assets/add_Websites/info.svg";
import { ToastContainer, toast } from "react-toastify";
import { formatTraffic } from "../../utils/filters/nameFilters";
import AppLoader from "../../components/defaultLoader/DefaultLoader";
import parse from 'html-react-parser';
import withAuth from "../../hoc/withAuth";
import { useNavigate } from "react-router-dom";

const CartComp = () => {
  const [cartList, setCartList] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const roleType = sessionStorage.getItem("roleType");
  const [websiteCategories, setWebsiteCategories] = useState<any>({});
  let navigate = useNavigate();

  const fetchWishlist = async () => {
    const response = await getWishCartList("CART");
    console.log(" Cart  ", response)
    if (response?.status?.code === 200) {
      // setCartList(response?.data?.bag?.bagItems);
      const updatedList = response?.data?.bag?.bagItems.map((e: any) => {
        return {
          ...e,
          existingPostUrl: "",
          anchorText: "",
          targetUrl: "",
          attachmentFile: "",
        }
      });
      setCartList(updatedList);
      setIsLoading(false);
    }
  };

  const getCategories = async (setWebsiteCategories: React.Dispatch<any>) => {
    const response = await getWebsiteCategories();
    if (response?.status?.code === 200) {
      const map = new Map();
      response?.data?.websiteCategories?.categories.forEach((obj: { id: any; friendlyName: any; }) => {
        map.set(obj.id, obj.friendlyName);
      });
      setWebsiteCategories(map);
    }
  };

  const getCategoryName = (id: String, index: number) => {
    return websiteCategories?.get(id);
  };

  const handleUpdate = (cartObj: any, e: any, field: string) => {
    console.log(e.target.value);
    if (field === 'existingPostUrl') {
      cartObj.existingPostUrl = e.target.value;
    } else if (field === 'anchorText') {
      cartObj.anchorText = e.target.value;
    } else {
      cartObj.targetUrl = e.target.value;
    }
    const updatedList = cartList.map((cart: any) => {
      if (cart.id === cartObj.id) {
        return cartObj;
      } else {
        return cart;
      }
    });
    setCartList(updatedList);
  };

  const placeOrderClick = async (marketPlaceObj: any) => {
    // const postType = marketPlaceObj.postType === undefined ? "GUEST_POST" :marketPlaceObj.postType;
    if (marketPlaceObj.status === 'INACTIVE') {
      toast.error("This site is no longer available. Please explore the marketplace for other options.", {
        position: "top-right",
        theme: "colored",
        autoClose: 5000,
      });
      return;
    }
    if (marketPlaceObj.priceType !== 'LINK_INSERTION') {
      console.log("  marketPlaceObj.attachmentFile  ", marketPlaceObj.attachmentFile)
      if (!marketPlaceObj.attachmentFile) {
        toast.error("Please upload the attachment.", {
          position: "top-right",
          theme: "colored",
          autoClose: 5000,
        });
        return;
      }
    }
    if (marketPlaceObj.priceType === 'LINK_INSERTION') {
      let isError = false;
      marketPlaceObj.existingPostUrlError = undefined;
      marketPlaceObj.targetUrlError = undefined;
      marketPlaceObj.anchorTextError = undefined;
      if (!marketPlaceObj.existingPostUrl || marketPlaceObj.existingPostUrl === '') {
        isError = true;
        marketPlaceObj.existingPostUrlError = "Please provide existing Post URL";
      }
      if (!marketPlaceObj.anchorText || marketPlaceObj.anchorText === '') {
        isError = true;
        marketPlaceObj.anchorTextError = "Please provide anchor text";
      }
      if (!marketPlaceObj.targetUrl || marketPlaceObj.targetUrl === '') {
        isError = true;
        marketPlaceObj.targetUrlError = "Please provide target URL";
      }
      const newCartList = cartList.map((item: any) => {
        if (item.id === marketPlaceObj.id) {
          return marketPlaceObj;
        }
        return item;
      });
      setCartList(newCartList);
      if (isError) {
        return;
      }
    }
    setIsLoading(true);
    console.log('marketPlaceObj.attachmentFile  --->>  ', marketPlaceObj.attachmentFile);
    const response = await placeOrder(
      marketPlaceObj.websiteId,
      marketPlaceObj.priceType,
      marketPlaceObj.existingPostUrl,
      marketPlaceObj.anchorText,
      marketPlaceObj.targetUrl,
      marketPlaceObj.instructions,
      marketPlaceObj.price,
      marketPlaceObj.attachmentFile,
    );
    if (response?.status?.code === 200) {
      setTimeout(() => { setIsLoading(false) }, 5000);
      const isCustAdminLogin = JSON.parse(sessionStorage.getItem('isCustAdminLogin') + "");
      //  window.location.href = "https://www.sandbox.paypal.com/cgi-bin/webscr?cmd=_express-checkout&token=EC-0XV06922HP836264J";
      if (isCustAdminLogin) {
        toast.success("Order placed successfully", {
          position: "top-right",
          theme: "colored",
          autoClose: 5000,
        });
        navigate("/dashboard/orderDashboard");
      } else {
        window.location.href = response?.data?.paymentUrl;
      }


    } else {
      const error = response?.status?.errors[0]['websiteNotFound'] ?? response?.status?.errors[0]['invalidPrice'];
      toast.error(error, {
        position: "top-right",
        theme: "colored",
        autoClose: 5000,
      });
      setIsLoading(false);
      return;
    }

  };

  const getPriceType = (marketPlaceObj: any) => {
    if (marketPlaceObj.priceType === undefined || marketPlaceObj.priceType === 'GUEST_POST') {
      return 'General Post';
    } else if (marketPlaceObj.priceType === 'LINK_INSERTION') {
      return 'Link Insertion';
    } else if (marketPlaceObj.priceType === 'CASINO_POST') {
      return 'Casino Post';
    } else if (marketPlaceObj.priceType === 'CRYPTO_POST') {
      return 'Crypto/Finance Post';
    } else if (marketPlaceObj.priceType === 'CBD_POST') {
      return 'CBD/Vape Post';
    } else if (marketPlaceObj.priceType === 'ADULT_POST') {
      return 'Adult Post';
    }
  };

  const getPostValidityType = (postValidityType: string) => {
    if (postValidityType === 'YEARLY') {
      return 'Yearly';
    } else if (postValidityType === 'HALF_YEARLY') {
      return 'Half Yearly';
    } else if (postValidityType === 'LIFETIME') {
      return 'Lifetime';
    }
  };

  const getBacklinkType = (backlinkType: any) => {
    if (backlinkType === 'DOFOLLOW') {
      return 'Dofollow';
    } else if (backlinkType === 'NOFOLLOW') {
      return 'Nofollow';
    }
  };

  const [isOpen, setIsOpen] = useState(false);
  const [guidelineContent, setGuidelineContent] = useState("");
  const togglePopup = (content: string) => {
    setGuidelineContent(content);
    setIsOpen(!isOpen);
  }


  const handleDelete = async (marketPlaceObj: any) => {
    setIsLoading(true);
    const response = await removeFromWishList(marketPlaceObj.id, "CART");
    if (response?.status?.code === 200) {
      toast.success("Item removed from cart successfully", {
        position: "top-right",
        theme: "colored",
        autoClose: 5000,
      });
      const newList = cartList.filter((item: any) => item.id !== marketPlaceObj.id);
      setCartList(newList);
      const input = document.getElementById('cartCount') as HTMLInputElement | null;
      if (input !== null) {
        input.innerHTML = Number(input.innerHTML) - 1 + "";
        if (Number(input.innerHTML) <= 0) {
          input.classList.add("hide");
          input.classList.remove("show");
        }
      }
      setIsLoading(false);
    } else {
      toast.error("Somethiing went wrong", {
        position: "top-right",
        theme: "colored",
        autoClose: 5000,
      });
      setIsLoading(false);
    }
  };

  const handleFileChange = (cartObj: any, e: any) => {
    if (e.target.files) {
      const fileSize = e.target.files[0].size;
      const fileMb = fileSize / 1024 ** 2;
      if (fileMb >= 2) {
        toast.error("Please select a file less than 2MB.", {
          position: "top-right",
          theme: "colored",
          autoClose: 5000,
        });
        return;
      }
      const allowedExtensions = [".doc", ".docx", ".txt", ".html"]; // Define allowed file extensions
      const fileName = e.target.files[0].name;
      const fileExtension = fileName.slice(((fileName.lastIndexOf(".") - 1) >>> 0) + 2);
      if (!allowedExtensions.includes("." + fileExtension.toLowerCase())) {
        toast.error("Please select a file with extension .doc, .docx, .txt, or .html.", {
          position: "top-right",
          theme: "colored",
          autoClose: 5000,
        });
        return;
      }
      cartObj.attachmentFile = e.target.files[0];
      const newCartList = cartList.map((item: any) => {
        if (item.id === cartObj.id) {
          return cartObj;
        }
        return item;
      });
      setCartList(newCartList);
    }
  };

  function calculateCols() {
    // Get the screen width
    const screenWidth = window.innerWidth;
    const userAgent = window.navigator.userAgent;

    if (userAgent.indexOf('Mac') !== -1) {
      return Math.floor(screenWidth / 11);
    } else {
      return Math.floor(screenWidth / 10.2);
    }
    // Calculate the number of columns based on screen width
    // You can adjust this calculation according to your needs
    // For example, you might want to set a minimum and maximum number of columns
    // or adjust the ratio between screen width and number of columns
    // const cols = Math.floor(screenWidth / 11); // Adjust the divisor as needed
    // return cols;
  }

  useEffect(() => {
    fetchWishlist();
    getCategories(setWebsiteCategories);
  }, []);

  return (
    <div className="dashboard_Con">
      {isLoading && <AppLoader size="lg" ></AppLoader>}
      <SideHeader roleType={roleType} />
      <div className="dashboard_Con__Right">
        <DashboardHeader />
        <div className="publisherWebsiteMid__Con">
          <div className="PublisherSummaryTableCart__Con">

            <section className="PublisherSummaryTable__Con__Inner">
              <header className="PublisherSummaryTable__Con__Header">
                <p>Cart</p>
              </header>
            </section>
            {(cartList.length === 0 && isLoading === false) &&
              <div className="textAlignCenter emptyCartMargin">
                <div className="material-icons lightGrey size-300">shopping_cart</div>
                <div className="size-40 lightGrey">Your cart is empty!</div>
              </div>
            }
            <section className="PublisherSummaryTableCart__Con__Table__Header">


              {cartList.length !== 0 &&
                cartList?.map((cell: any, index: number) => (
                  <div className={"cartMainDiv" + (cell.priceType === "LINK_INSERTION" ? "linkInsertion" : "")} key={index} >
                    <div className="url_cart">
                      <div className="urlDiv">
                        {cell.websiteUrl}
                        <Tooltip id="show-guidelines" place="top" />
                        <Tooltip data-class="tool" id="show-sample" place="top" />
                        {cell.postGuideline !== undefined && cell.postGuideline !== '' && <img className="iconStyle" src={questionIcon} alt=""
                          data-tooltip-id="show-guidelines" data-tooltip-content="Show Guidelines" onClick={() => { togglePopup(cell.postGuideline); }} />}
                        {cell.sampleUrl !== undefined && cell.sampleUrl !== '' && <a href={cell.sampleUrl} target="_blank" rel="noreferrer" >
                          <img className="iconStyle" src={checkIcon} style={{ marginBottom: '-4px' }} alt="" data-class="tool1" data-tooltip-id="show-sample" data-tooltip-content="Show Sample" /></a>}
                      </div>


                      <div className="tatCart" style={{ textAlign: 'end' }} >
                        <b>TAT - {cell.tat} days </b>
                      </div>

                    </div>
                    <div className="domain_traffic_referring_cart " >
                      <div className="dtrDivCart">
                        <div className="width20">  </div>
                        <div className="width80" > <div>  <b> Domain Authority </b>{cell.domainAuthority} </div></div>
                      </div>
                      <div className="dtrDivCart justifyCenter">

                        < div><div>  <b> Traffic </b>{formatTraffic(cell.traffic)} </div> </div>
                      </div>
                      <div className="dtrDivCart">
                        <div className="width20">  </div>
                        <div className="width80" > <div> <b> Domain Rating </b> {cell.referingDomain} </div>  </div>
                      </div>
                      <div className="dtrDivCart">
                        <div className="width20">  </div>
                        <div className="width80" > <div> <b> Spam Score </b> {cell.spamScore} </div>  </div>
                      </div>
                    </div>
                    <div className="domain_traffic_referring_cart top_border_cart" >
                      <div className="dtrDivCart">
                        <div className="width20">  </div>
                        <div className="width80" > <div> <b>Post Validity - </b> {getPostValidityType(cell.guaranteePostValidity)}</div></div>
                      </div>
                      <div className="dtrDivCart justifyCenter">

                        < div><div><b>  Min. Word Count -</b> {formatTraffic(cell.minPostWordCount)}</div> </div>
                      </div>
                      <div className="dtrDivCart">
                        <div className="width20">  </div>
                        <div className="width80" > <div>  <b> Max Backlinks - </b> {cell.maxBackLinks}
                          &nbsp;&nbsp;&nbsp;{getBacklinkType(cell.backLinkType)} </div>  </div>
                      </div>
                    </div>
                    <div className="categories_cart" >
                      <div className="guest_link_casino">
                        <div className="" > <b>Categories</b> </div>
                        {cell.websiteCategoryIdList?.map((id: any, index: any) => (
                          <div className="category_div_cart" key={index} > {getCategoryName(id, index)}  </div>

                        ))}
                      </div>
                    </div>

                    {
                      cell.priceType !== "LINK_INSERTION" &&
                      <div className="contentAttachmentDiv displayFlex">
                        <div className="contentTypeDiv">
                          <p className="contentMargin"><label htmlFor="">
                            Content Type <span className="red">*</span>{" "}
                          </label></p>
                          <select name="contentType" id="contentType" className="contentTypeSelect">
                            <option value="provideContent">Provide Content</option>
                          </select>
                        </div>
                        <div className="attachmentDiv">
                          <p className="contentMargin"><label htmlFor="">
                            Attachment  (Supported - .doc, .docx, .txt and html file){" "}
                          </label></p>
                          <input type="file" id="myfile" name="myfile" value={""}
                            onChange={(e) => { handleFileChange(cell, e) }}
                            placeholder="Drop your file here" /> <span>
                            {/* <a href={cell?.attachmentFile} download={cell?.attachmentFile?.name}>  {cell?.attachmentFile?.name} </a> */}
                            {cell?.attachmentFile?.name} </span>
                        </div>
                      </div>
                    }
                    {
                      cell.priceType === "LINK_INSERTION" &&
                      <div className=" ">
                        <div className="">
                          <p className="contentMargin"><label htmlFor="">
                            Existing Post Url <span className="red">*</span>{" "}
                            {cell.existingPostUrlError && (
                              <span>
                                {" "}
                                <img src={info} alt="" /> <span>{cell.existingPostUrlError}</span>{" "}
                              </span>
                            )}
                          </label></p>

                          <input type="text" className="existingPostUrl" placeholder="https://sample.com" value={cell.existingPostUrl} onChange={(e: any) =>
                            handleUpdate(cell, e, 'existingPostUrl')}></input>
                        </div>
                        <div className="displayFlex">
                          <div className="contentTypeDiv">
                            <p className="contentMargin"><label htmlFor="">
                              Anchor Text <span className="red">*</span>{" "}
                              {cell.anchorTextError && (
                                <span>
                                  {" "}
                                  <img src={info} alt="" /> <span>{cell.anchorTextError}</span>{" "}
                                </span>
                              )}
                            </label></p>

                            <input type="text" className="existingPostUrl" placeholder="Enter Text" value={cell.anchorText} onChange={(e: any) =>
                              handleUpdate(cell, e, 'anchorText')}></input>

                          </div>
                          <div className="contentTypeDiv">
                            <p className="contentMargin"><label htmlFor="">
                              Target Link <span className="red">*</span>{" "}
                              {cell.targetUrlError && (
                                <span>
                                  {" "}
                                  <img src={info} alt="" /> <span>{cell.targetUrlError}</span>{" "}
                                </span>
                              )}
                            </label></p>

                            <input type="text" className="existingPostUrl" placeholder="https://sample.com" value={cell.targetUrl} onChange={(e: any) =>
                              handleUpdate(cell, e, 'targetUrl')}></input>
                          </div>
                        </div>
                      </div>
                    }



                    < div >

                      <p className="contentMargin"><label htmlFor="">
                        Additional Instructions {" "}
                      </label></p>
                      <textarea id="w3review" name="w3review" className="instructionField" rows={3} cols={calculateCols()} value={cell.instructions} onChange={(e) => {
                        cell.instructions = e.target.value;
                      }}>
                      </textarea>
                    </div>
                    <div className="priceButtonDiv">

                      <div className="priceType">
                        <b>  {getPriceType(cell)} ${cell.price}</b>
                        {cell.status === 'INACTIVE' && <b className="inactiveText floatRight">This site is no longer available.</b>}
                        {cell.status === 'INACTIVE_PRICE' && <b className="inactiveText floatRight">This post type is no longer available for this website.</b>}
                      </div>
                      <div className="cartBtnDiv">
                        <div className="floatRight">
                          <button className={cell.price === 0 ? 'addToCart_btn_disabled' : 'addToCart_btn'} disabled={cell.price === 0} onClick={() => { placeOrderClick(cell); }}> Check Out </button>
                          <button className="addToCart_btn removeBtn" onClick={() => { handleDelete(cell); }}> Remove </button>
                        </div>
                      </div>
                    </div>
                  </div>

                ))}
            </section>
          </div>
        </div >
        <ToastContainer />
      </div >

      {isOpen && <Popup
        content={<>
          <div><div className="popup-header">Guidelines</div>
            <div className="popup-content">{parse(guidelineContent)}</div>
            <div className="btnDiv">
              <button className="wishList_btn addToCart_btn" onClick={() => { togglePopup(""); }} > Close  </button>
            </div>
          </div>
        </>}
        handleClose={togglePopup}
      />}
    </div >


  );
};

export default withAuth(CartComp);
