import React, { useEffect, useState } from "react";
import "./wishlist.scss";
import { numberDisplay, getPriceType, formatTraffic } from "../../utils/filters/nameFilters";
import { getWishCartList, addToCartWishList, removeFromWishList } from "../../utils/api/marketPlace";
import SideHeader from "../../components/SideHeader/SideHeader";
import DashboardHeader from "../../components/DashboardHeader/DashboardHeader";
import { useNavigate } from "react-router-dom";
import AppLoader from "../../components/defaultLoader/DefaultLoader";
import { ToastContainer, toast } from "react-toastify";
import withAuth from "../../hoc/withAuth";

const WishlistComp = () => {
  const tableCells = [1, 2, 3, 4, 5, 6];
  const [wishList, setWishList] = useState<any>([]);
  const roleType = sessionStorage.getItem("roleType");
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const fetchWishlist = async () => {
    const response = await getWishCartList("WISH");
    if (response?.status?.code === 200) {
      setWishList(response?.data?.bag?.bagItems);
      setIsLoading(false);
    }
  };

  const handleDelete = async (marketPlaceObj: any) => {
    setIsLoading(true);
    const response = await removeFromWishList(marketPlaceObj.id, "WISH");
    if (response?.status?.code === 200) {
      const newList = wishList.filter((item: any) => item.id !== marketPlaceObj.id);
      setWishList(newList);
      const input = document.getElementById('wishCount') as HTMLInputElement | null;
      if (input !== null) {
        input.innerHTML = Number(input.innerHTML) - 1 + "";
        if (Number(input.innerHTML) <= 0) {
          input.classList.add("hide");
          input.classList.remove("show");
        }
      }
      toast.success("Item removed from wishlist successfully", {
        position: "top-right",
        theme: "colored",
        autoClose: 5000,
      });
      setIsLoading(false);

    }
  };

  const addToCart = async (wishListObj: any) => {
    if (wishListObj?.status === 'INACTIVE') {
      toast.error("This site is no longer available.", {
        position: "top-right",
        theme: "colored",
        autoClose: 5000,
      });
      return;
    }
    if (wishListObj?.status === 'INACTIVE_PRICE') {
      toast.error("This post type is no longer available for this website.", {
        position: "top-right",
        theme: "colored",
        autoClose: 5000,
      });
      return;
    }
    setIsLoading(true);
    const postType = wishListObj.priceType === undefined ? "GUEST_POST" : wishListObj.priceType;
    const response = await addToCartWishList(wishListObj.websiteId, "CART", postType);
    if (response?.status?.code === 200) {
      const input = document.getElementById('cartCount') as HTMLInputElement | null;
      if (input !== null) {
        input.innerHTML = Number(input.innerHTML) + 1 + "";
        input.classList.add("show");
        input.classList.remove("hide");
      }
      const updatedList = wishList.map((wishListItem: any) => {
        if (wishListItem.id === wishListObj.id) {
          wishListObj.isAddedInCart = true;
          return wishListObj;
        } else {
          return wishListItem;
        }
      });
      setWishList(updatedList);
      toast.success("Site added to cart", {
        position: "top-right",
        theme: "colored",
        autoClose: 5000,
      });
      setIsLoading(false);
    }
  };

  const goToCart = async () => {
    navigate("/dashboard/cart");
  };


  useEffect(() => {
    fetchWishlist();
  }, []);

  return (
    <div className="dashboard_Con">
      <ToastContainer />
      {isLoading && <AppLoader size="lg" ></AppLoader>}
      <SideHeader roleType={roleType} />
      <div className="dashboard_Con__Right">
        <DashboardHeader />
        <div className="publisherWebsiteMid__Con">
          <div className="PublisherSummaryTable__Con">

            <section className="PublisherSummaryTable__Con__Inner">
              <header className="PublisherSummaryTable__Con__Header">
                <p>Wishlist</p>
              </header>
            </section>

            <section className="PublisherSummaryTable__Con__Table__Header">
              <article>
                <h5>Website URL</h5>
                <p>DA</p>
                <p>Traffic</p>
                <p>DR</p>
                <p>TAT (Days)</p>
                <p>Type</p>
                <p>Price ($)</p>
                <h6>Action</h6>
              </article>
              {wishList.length !== 0 &&
                wishList?.map((cell: any, index: number) => (
                  <div
                    className={`PublisherSummaryTable__Con__Table__Cell ${index % 2 === 0
                      ? "PublisherSummaryTable__Con__Table__Cell__grey__bg"
                      : ""
                      } ${index === tableCells.length - 1
                        ? "PublisherSummaryTable__Con__Table__Cell__last__border"
                        : ""
                      } ${(cell?.status === 'INACTIVE' || cell?.status === 'INACTIVE_PRICE')
                        ? "inactiveWebsiteBackground"
                        : ""
                      }`}
                    key={index}
                  >
                    <a
                      href={cell?.websiteUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <p style={{ textAlign: 'left' }} className="WebsiteUrlPublisherSummaryTable">{cell?.websiteUrl}</p>
                    </a>
                    <p>{numberDisplay(cell?.domainAuthority)}</p>
                    <p>{formatTraffic(cell?.traffic)}</p>
                    <p>{numberDisplay(cell?.referingDomain)}</p>
                    <p>{numberDisplay(cell?.tat)}</p>
                    <p>{getPriceType(cell?.priceType)}</p>
                    <p>{cell?.price}</p>
                    <h6>
                      {!cell.isAddedInCart && <span onClick={() => addToCart(cell)}>
                        Add To Cart
                      </span>}
                      {cell.isAddedInCart && <span onClick={() => goToCart()}>
                        Go To Cart
                      </span>}
                      <span onClick={() => handleDelete(cell)}>Remove</span>
                    </h6>

                  </div>
                ))}
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withAuth(WishlistComp);
