import React from "react";
import Footer from "../../components/footer/Footer";
import Header from "../../components/Header";
import "./blogSummary.scss";
import BlogSummary from "../../components/blogSummary/BlogSummary";
import { useParams } from 'react-router-dom'

export default function BlogOverview  (props) {
  const { title } = useParams();
  return (
    <div className="about-bg">
      <Header />
      <BlogSummary data = {title}/>
      <div className="footer-bg">
        <Footer />
      </div>
    </div>
  );
}
