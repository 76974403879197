import React, { useState } from "react";
// import { activateCustomerWebsite } from "../../utils/api/addWebsite";
import { toast } from "react-toastify";
import { useAppDispatch, useAppSelector, } from "../../store/reduxHooks";
// import { setActiveSuccess } from "../../reducers/addWebsite/addWebsitedataReducer";
import close from "../../assets/admin/close.svg";
import {
  postAdminRejectWebsite, getAdminWebsiteCount
} from "../../utils/api/adminWebsite";
import { setAdminWebsiteDetails, setTaskboardDisplay } from "../../reducers/admin/adminReducer";
type Props = {
  setRejectData: React.Dispatch<
    React.SetStateAction<{
      display: boolean;
      id: string;
      rejectedReason: string;
    }>
  >;
  rejectData: {
    display: boolean;
    id: string;
    rejectedReason: string;
  };
};

const AdminRejectPopup: React.FC<Props> = ({ setRejectData, rejectData }) => {
  const dispatch = useAppDispatch();
  const [isRejectionError, setIsRejectionError] = useState<boolean>(false);
  const { taskboardDisplay, adminWebsiteDetails } = useAppSelector((state) => ({
    taskboardDisplay: state.adminState.taskboardDisplay,
    adminWebsiteDetails: state?.adminState?.adminWebsiteDetails,
  }));
  const handleYes = async () => {
    setIsRejectionError(false);
    console.log('rejectData.rejectedReason', rejectData.rejectedReason)
    if (rejectData.rejectedReason === undefined || rejectData.rejectedReason === '') {
      setIsRejectionError(true);
      return;
    }

    if (rejectData.rejectedReason && rejectData.rejectedReason.length > 500) {
      toast.error("Rejection reasson length can not be greater than 500",
        {
          position: "top-right",
          theme: "colored",
          autoClose: 5000,
        }
      );
      return;
    }
    setRejectData({ ...rejectData, display: false });
    const response = await postAdminRejectWebsite(
      rejectData?.id,
      rejectData?.rejectedReason
    );
    if (response?.status?.code === 200) {
      toast.success("Website rejected successfully", {
        position: "top-right",
        theme: "colored",
        autoClose: 5000,
      });
      dispatch(
        setAdminWebsiteDetails({ ...adminWebsiteDetails, isLoading: true })
      );
      if (response?.status?.code === 200) {
        const arr = adminWebsiteDetails?.data;
        const newList = arr.filter((item: any) => item.id !== rejectData?.id);
        dispatch(
          setAdminWebsiteDetails({ ...adminWebsiteDetails, data: newList })
        );
        const responseCount = await getAdminWebsiteCount();
        if (responseCount?.status?.code === 200) {
          const total = responseCount?.data?.websiteApprovalData?.totalNewApproval;
          const newTotal = responseCount?.data?.websiteApprovalData?.totalPendingApproval;
          const edit = responseCount?.data?.websiteApprovalData?.totalEditApproval;
          const selected = taskboardDisplay?.selected;
          dispatch(
            setTaskboardDisplay({
              ...taskboardDisplay,
              isActive: true,
              selected: selected,
              total: total,
              edit: edit,
              new: newTotal,
            })
          );
        }
      } else {
        dispatch(
          setAdminWebsiteDetails({
            ...adminWebsiteDetails,
            isLoading: false,
            data: [],
            pageInfo: {
              hasNext: false,
              totalCount: 0,
            },
          })
        );
      }
    } else {
      toast.error(
        response?.status?.errors?.[0]?.websiteNotFound ||
        "Something went wrong",
        {
          position: "top-right",
          theme: "colored",
          autoClose: 5000,
        }
      );

    }
  };
  const handleNo = () => {
    setRejectData({ ...rejectData, display: false });
  };

  return (
    <div className="publisherDeletePopup__Con__out">
      <div className="adminApprovePopup__Con__in">
        <p>
          {" "}
          <span> Reject Website</span>{" "}
          <span>
            <img src={close} onClick={() => handleNo()} alt="" />
          </span>
        </p>
        <div className="maxLengthText">{rejectData.rejectedReason.length}/200 </div>
        <textarea
          className="rejectTextArea"
          name=""
          id=""
          cols={4}
          rows={5}
          placeholder={"Please mention the reason of rejection."}
          onChange={(e) =>
            setRejectData({ ...rejectData, rejectedReason: e.target.value })
          }
          maxLength={200}
        ></textarea>
        {isRejectionError && <span className="reasonError">This field is required </span>}
        <section className="adminApprovePopup__button_Con">
          <button
            className="adminApprovePopup__button--No"
            onClick={() => handleNo()}
          >
            Cancel
          </button>
          <button
            className="adminApprovePopup__button--Yes"
            onClick={() => handleYes()}
          >
            Reject
          </button>
        </section>
      </div>
    </div>
  );
};

export default AdminRejectPopup;
