import React, { useEffect, useMemo, useRef, useState } from "react";
import "./sideHeader.scss";
import sidebarLogo from "../../assets/sidebar/logo.svg";
import wallet from "../../assets/sidebar/wallet.svg";
import plus from "../../assets/sidebar/plus.svg";
import cross from "../../assets/dashboardHeader/cross.svg";
import open from "../../assets/dashboardHeader/open.svg";
import { menuItem, menuItemAdvertiser } from "./sideMenuItem";
import { MenuItem } from "../../utils/types/main";
import notification from "../../assets/dashboardHeader/notification.svg";
import mail from "../../assets/dashboardHeader/mail.svg";
import avatar from "../../assets/dashboardHeader/avatat.svg";
import { logged } from "../../reducers/login/loginReducer";
import { useAppDispatch } from "../../store/reduxHooks";
import avatarLogout from "../../assets/dashboardHeader/avatar-logout.svg";
import calender from "../../assets/dashboardHeader/calendar.svg";
import text from "../../assets/dashboardHeader/text.svg";
import { Link, useLocation } from "react-router-dom";
import { websitePathSet, subWebsitePathSet } from "../../utils/filters/websitePathSet";
import down from "../../assets/sidebar/down.svg";
const SideHeader = ({ roleType }: {
  roleType: any
}) => {
  const [menuDisplay, setMenuDisplay] = useState(false);
  const [isLogoutDisplay, setIsLogoutDisplay] = useState<boolean>(false);
  const [isOutsideClicked, setIsOutsideClicked] = useState<boolean>(false);

  const outSideEvent = useRef<any>(null);
  const location = useLocation();
  const selectedMenu = useMemo(() => websitePathSet(location.pathname), [location.pathname]);
  const subSelectedMenuName = useMemo(() => subWebsitePathSet(location.pathname), [location.pathname]);
  const menuList = roleType === "Advertiser" ? menuItemAdvertiser : menuItem;
  useEffect(() => {
    const outSideCheck = (e: any) => {
      if (
        document
          .getElementById("dashboard__Header__Logout__Con")
          ?.contains(e.target) ||
        document.getElementById("mobile_avatar")?.contains(e.target)
      ) {
        console.log("clicked-inside");
        setIsOutsideClicked(false);
      } else {
        console.log("outside");
        setIsOutsideClicked(true);
        setIsLogoutDisplay(false);
      }
    };
    outSideEvent.current = outSideCheck;
  }, []);

  useEffect(() => {
    if (outSideEvent.current) {
      if (isLogoutDisplay) {
        console.log("added", isLogoutDisplay);
        window.addEventListener("click", outSideEvent.current);
      } else if (!isLogoutDisplay) {
        console.log("removed", isLogoutDisplay);
        window.removeEventListener("click", outSideEvent.current);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLogoutDisplay]);

  const dispatch = useAppDispatch();

  const logout = () => {
    dispatch(logged(false));
  };

  return (
    <div className="sideBar__Con">
      <div className="sideBar__Logo">
        <img
          src={menuDisplay ? cross : open}
          alt=""
          onClick={() => setMenuDisplay(!menuDisplay)}
          className="toggleMenu__Icon"
        />

        <Link to="/dashboard">
          <img src={sidebarLogo} alt="" className="outReach__Logo" />
        </Link>
        <div className="sideHeader__userLogo__Con">
          <img src={notification} alt="" />
          <img src={mail} alt="" />
          <img
            src={avatar}
            alt=""
            onClick={(e) => {
              setIsLogoutDisplay(!isLogoutDisplay);
            }}
            id="mobile_avatar"
          />
          <div
            className="dashboard__Header__Logout__Con"
            id="dashboard__Header__Logout__Con"
            style={{
              display: `${isLogoutDisplay && !isOutsideClicked ? "flex" : "none"
                }`,
            }}
          >
            <img src={avatarLogout} alt="" className="avatar_Logout" />
            <h4>Austin Robertson</h4>
            <div className="dashboard__Header__Logout__mid">
              <img src={calender} alt="" />
              <img src={text} alt="" />
            </div>

            <p>My Account</p>
            <p onClick={() => logout()}>Logout</p>
          </div>
        </div>
      </div>
      <div className="borderLine"></div>
      <div
        className="balance_Con"
        // style={{
        //   display: `${menuDisplay ? "flex" : "none"}`,
        // }}
        style={{ display: 'none' }}
      >
        <div className="balance_Icon_Con">
          <img src={wallet} alt="" />
          <div>
            <p>Balance </p>
            <h4>$0</h4>
          </div>
        </div>
        <div className="plus_Icon_Con" style={{ display: 'none' }}>
          <img src={plus} alt="" />
        </div>
      </div>
      <div
        className="menu__Con"
        style={{
          display: `${menuDisplay ? "flex" : "none"}`,
        }}
      >

        {menuList.map((item: MenuItem, index: number) => (
          <Link
            to={item.link}
            className={`menu__Item ${selectedMenu === item.title ? "sideHeader__Menu__Selected" : ""
              }`}
            key={index}
            reloadDocument
          >
            <p >
              <img src={item.image} alt="" />
              <span>{item.title}</span>
            </p>

            <img src={item.next} alt="" />
          </Link>
        ))}
      </div>
      <div className="balance_Con" style={{ display: 'none' }}>
        <div className="balance_Icon_Con">
          <img src={wallet} alt="" />
          <div>
            <p>Balance </p>
            <h4>$0</h4>
          </div>
        </div>
        <div className="plus_Icon_Con" style={{ display: 'none' }}>
          <img src={plus} alt="" />
        </div>
      </div>
      <div className="menu__Con">
        {menuList.map((item: MenuItem, index: number) => {
          if (item.title !== "Payments") {
            return (
              <Link
                to={item.link}
                className={`menu__Item ${selectedMenu === item.title ? "sideHeader__Menu__Selected" : ""
                  }`}
                key={index}
                reloadDocument
              >
                <p  >
                  <img src={item.image} alt="" />
                  <span>{item.title}</span>
                </p>

                <img src={item.next} alt="" />
              </Link>

            )
          } else {
            return (
              <div
                className="admin__SideHeader__Taskboared__Menu__Con"
                key={index}
              >
                <Link
                  to={item.link}
                  className={`menu__Item ${selectedMenu === item.title ? "sideHeader__Menu__Selected" : ""
                    }`}
                  key={index}
                  reloadDocument
                >
                  <p  >
                    <img src={item.image} alt="" />
                    <span>{item.title}</span>
                  </p>
                  {selectedMenu === item.title ? (
                    <img src={down} alt="" />
                  ) : (
                    <img src={item.next} alt="" />
                  )}
                </Link>
                {selectedMenu === item.title &&
                  <section className="sideHeader__Payment__Menu" >
                    <Link to="/dashboard/paymentDashboard"
                      className={`sub_menu__Item ${subSelectedMenuName === "PAYMENT_DETAILS"
                        ? "sideHeader__Menu__Selected_Payment"
                        : ""
                        } `} >

                      <span>Payment Details</span>

                    </Link>
                    <Link to="/dashboard/paymentWithdraw"
                      className={`sub_menu__Item ${subSelectedMenuName === "PAYMENT_WITHDRAW"
                        ? "sideHeader__Menu__Selected_Payment"
                        : ""
                        } `}

                    >
                      <span>Payment Withdraw</span>

                    </Link>
                    <Link to="/dashboard/invoiceHistory"
                      className={` sub_menu__Item ${subSelectedMenuName === "INVOICE_HISTORY"
                        ? "sideHeader__Menu__Selected_Payment"
                        : ""
                        } `}

                    >
                      <span>Invoice History</span>
                    </Link>
                    <Link to="/dashboard/paymentHistory"
                      className={` sub_menu__Item ${subSelectedMenuName === "PAYMENT_HISTORY"
                        ? "sideHeader__Menu__Selected_Payment"
                        : ""
                        } `}

                    >
                      <span>Payment History</span>
                    </Link>
                  </section>
                }
              </div>
            )
          }
        })}
      </div>
    </div>
  );
};

export default SideHeader;
