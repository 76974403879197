import React from "react";
import "./App.scss";
//import Landing from "./pages/Landing";
import VerifyEmail from "./pages/verifyEmail/VerifyEmail";
import Signup from "./pages/signup/Signup";
import AccountVerifiedPage from "./pages/AccountVerifiedPage/AccountVerifiedPage";
import CreatePasswordPage from "./pages/CreatePasswordpage/CreatePasswordPage";
import ForgotPasswordPage from "./pages/ForgotPasswordPage/ForgotPasswordPage";
import SelectRolePage from "./pages/selectRole/SelectRolePage";
import LoginNow from "./pages/login/LoginNow";
import ContactUs from "./pages/contactUs/ContactUs";
import PrivacyPage from "./pages/privacyPage/PrivacyPage";
import About from "./pages/about/About";
import Dashboard from "./pages/Dashboard/Dashboard";
import { ThemeProvider } from "@mui/material";
import theme from "./muiTheme";
import TermAndCondMid from "./pages/termAncCondMid/TermAndCondMid";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import PublisherWebsites from "./pages/publisherWebsites/PublisherWebsites";
import CouponDashboard from "./pages/couponDashboard/CouponDashboard";
import PageNotFound from "./pages/pageNotFound/PageNotFound";
import { ToastContainer } from "react-toastify";
import AdminDashboard from "./pages/adminDashboard/AdminDashboard";
import AdminTaskboard from "./pages/adminDashboard/AdminTaskboard";
import BlogOverview from "./pages/blogSummary/BlogSummary";
import MarketPlace from "./pages/marketPlace/marketPlace";
import AddAdminWebsite from "./pages/addAdminWebsite/AddAdminWebsite";
import WishlistComp from "./pages/wishlist/wishlist";
import CartComp from "./pages/cart/cart";
import OrderSuccess from "./pages/orderSuccess/orderSuccess";
import OrderDashboard from "./pages/orderDashboard/orderDashboard";
import MyAccount from "./pages/MyAccountPage/myAccountPage";
import PaymentDashboard from "./pages/paymentDashboard/paymentDashboard";
import PaymentHistory from "./pages/paymentHistory/paymentHistory";
import PaymentWithdraw from "./pages/paymentWithdraw/paymentWithdraw";
import InvoiceHistory from "./pages/invoiceHistory/invoiceHistory";
import CustomerDetails from "./pages/customerDetails/CustomerDetails";
import BlogManagement from "./pages/blogManagement/BlogManagement";
import BlogList from "./pages/blogList/BlogList";
import AuthorDetails from "./pages/authorDetails/AuthorDetails";
import ComingSoon from "./pages/comingSoonPage/ComingSoon";
import OrdersComp from "./pages/orders/orders";
import BillingComp from "./pages/billing/billing";
import Services from "./pages/service/service";
import LoginCustAdmin from "./components/login/LoginCustAdmin";
import AdminBillingComp from "./pages/adminBillingComp/AdminBillingComp";
import AdminMarketPlace from "./pages/adminMarketPlace/AdminMarketPlace";
import AllWebsites from "./pages/AllWebsites/AllWebsites";


function App() {

  const getSubdomain = () => {
    const parts = window.location.hostname.split(".");
    return parts.length > 3 ? parts[1] : parts.length > 2 ? parts[0] : null;
  };

  // Redirect conditionally based on subdomain
  const shouldRedirect = (getSubdomain() !== process.env.REACT_APP_OUTREACH_ADMIN_URL_DOMAIN);

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <div className="App">
          <Routes>
            {/* <Route path="/" element={<Landing />} /> */}
            {shouldRedirect ? (
              <Route path="/" element={<Navigate to="/login" replace={true} />} />
            ) : <Route path="/" element={<PageNotFound />} />}
            <Route path="/verifyEmail" element={<VerifyEmail />} />
            <Route path="/signup" element={<Signup />} />
            {shouldRedirect ? (
              <Route path="*" element={<Navigate to="/login" replace={true} />} />
            ) : <Route path="*" element={<PageNotFound />} />}
            {shouldRedirect ? (
              <Route path="/login" element={<LoginNow />} />
            ) : <Route path="/login" element={<PageNotFound />} />}
            <Route path="/loginCustAdmin" element={<LoginCustAdmin />} />
            <Route path="/accountVerified" element={<AccountVerifiedPage />} />
            <Route path="/forgotPassword" element={<CreatePasswordPage />} />
            <Route path="/resetPassword" element={<ForgotPasswordPage />} />
            <Route path="/selectRole" element={<SelectRolePage />} />
            <Route path="/contactUs" element={<ContactUs />} />
            <Route path="/about" element={<About />} />
            <Route path="/term-and-condition" element={<TermAndCondMid />} />
            <Route path="/privacy-policy" element={<PrivacyPage />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/blog/:title" element={<BlogOverview />} />
            <Route path="/dashboard/marketPlace" element={<MarketPlace />} />
            <Route path="/myAccount" element={<MyAccount />} />
            <Route path="/blogs" element={<BlogList />} />
            <Route
              path="/dashboard/publisherWebsites"
              element={<PublisherWebsites />}
            />
            <Route
              path="/dashboard/couponDashboard"
              element={<CouponDashboard />}
            />
            <Route
              path="/dashboard/orderDashboard"
              element={<OrderDashboard />}
            />
            <Route
              path="/dashboard/paymentDashboard"
              element={<PaymentDashboard />}
            />
            <Route
              path="/dashboard/reportDashboard"
              element={<CouponDashboard />}
            />
            <Route path="/admin/dashboard" element={<AdminDashboard />} />
            <Route path="/admin/taskboard" element={<AdminTaskboard />} />
            <Route path="/admin/addWebsite" element={<AddAdminWebsite />} />
            <Route path="/admin/customerDetails/:interest" element={<CustomerDetails />} />
            <Route path="/admin/blogManagement" element={<BlogManagement />} />
            <Route path="/admin/authors" element={<AuthorDetails />} />
            <Route path="/admin/billing" element={<AdminBillingComp />} />
            <Route path="/admin/marketPlace" element={<AdminMarketPlace />} />
            <Route path="/admin/allWebsites/:status" element={<AllWebsites />} />
            <Route path="/dashboard/wishlist" element={<WishlistComp />} />
            <Route path="/dashboard/cart" element={<CartComp />} />
            <Route path="/payment/success" element={<OrderSuccess />} />
            <Route path="/dashboard/paymentHistory" element={<PaymentHistory />} />
            <Route path="/dashboard/paymentWithdraw" element={<PaymentWithdraw />} />
            <Route path="/dashboard/invoiceHistory" element={<InvoiceHistory />} />
            <Route path="/dashboard/billing" element={<BillingComp />} />
            <Route path="/comingSoon" element={<ComingSoon />} />
            {/* <Route path="*" element={<PageNotFound />} /> */}
            <Route path="/admin/orders/:status" element={<OrdersComp />} />
            <Route path="/services" element={<Services />} />
            <Route
              path="*"
              element={<Navigate to="/login" replace={true} />}
            />
          </Routes>
        </div>
      </Router>
      <ToastContainer />
    </ThemeProvider>
  );
}

export default App;
