import React, { useEffect, useState } from "react";
import "./dashboard.scss";
import SideHeader from "../../components/SideHeader/SideHeader";
import DashboardMid from "../../components/DashboardMid/DashboardMid";
import DashboardHeader from "../../components/DashboardHeader/DashboardHeader";
import {
  getUserStats,
  getDashboardStats,
  postUserRole,
} from "../../utils/api/dashboardStats";
import withAuth from "../../hoc/withAuth";
import { useAppDispatch } from "../../store/reduxHooks";
import { setCustomerData } from "../../reducers/dashboard/dashboardStatsReduccer";
import SelectRole from "../../components/selectRole/SelectRole";
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";


const Dashboard = () => {
  let roleType = sessionStorage.getItem("roleType");  
  const dispatch = useAppDispatch();
  const [errorRole, setErrorRole] = useState<{
    display: boolean;
    message: string;
  }>({
    display: false,
    message: "",
  });
  const [selectRole, setSelectRole] = useState<{
    role: string;
    display: boolean;
  }>({
    display: false,
    role: "",
  });

  const getDashboardData = async () => {
    const response = await getDashboardStats();
    console.log(errorRole);
    if (response?.status?.code === 200) {
      dispatch(setCustomerData(response.data.customer));
    }
  };

  const getUserStatsData = async () => {
    const response = await getUserStats();

    if (!response?.data?.customer?.customerProfile?.role) {
      setSelectRole({ role: "", display: true });
    }
  };


  const setUserRole = async (role: string) => {
    if (!role) {
      setErrorRole({
        display: true,
        message: "Please Select Role to continue",
      });
      toast.error("Please Select Role to continue", {
        position: "top-right",
        theme: "colored",
        autoClose: 5000,
      });
      return;
    }
    const response = await postUserRole(role);

    if (response?.status?.code !== 200) {
      setErrorRole({
        display: true,
        message: "Something Went Wrong ",
      });
      toast.error("Something Went Wrong", {
        position: "top-right",
        theme: "colored",
        autoClose: 5000,
      });
      return;
    }
   // roleType
    roleType = role === undefined || role === 'PUBADV' ? 'Advertiser' : role === 'ADVERTISER' ?
     'Advertiser' : role === 'PUBLISHER' ? 'Publisher' : "Admin"  ;
    sessionStorage.setItem("roleType", roleType);
    toast.success(
      response?.status?.msg
        ? response?.status?.msg
        : "Profile updated successfully",
      {
        position: "top-right",
        theme: "colored",
        autoClose: 5000,
      }
    );
    setErrorRole({
      display: false,
      message: "Role Selected Successfully",
    });
    setSelectRole({
      display: false,
      role,
    });
  };



  useEffect(() => {
    getUserStatsData();
    getDashboardData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <div className={`dashboard_Con`}>
        <SideHeader roleType = {roleType} />
        <div className={`dashboard_Con__Right`}>
          <DashboardHeader  />
           {selectRole.display } 
           <DashboardMid roleType={roleType} />
        </div>
      </div>
      {selectRole.display && (
        <SelectRole
          setUserRole={setUserRole}
          className={"selectRole__Unblur"}
        />
      )}
      <ToastContainer />
    </>
  );
};

export default withAuth(Dashboard);
