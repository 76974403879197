import React, { useEffect } from "react";
import SideHeader from "../../components/SideHeader/SideHeader";

import DashboardHeader from "../../components/DashboardHeader/DashboardHeader";

import withAuth from "../../hoc/withAuth";
import PublisherWebsitesMid from "../../components/publisherWebsitesMid/PublisherWebsitesMid";
import { useAppDispatch } from "../../store/reduxHooks";
import { setFormDisplay } from "../../reducers/addWebsite/addWebsitedataReducer";

const PublisherWebsites = () => {
  const roleType = sessionStorage.getItem("roleType");
  const dispatch = useAppDispatch();

  useEffect(() => {

    // eslint-disable-next-line react-hooks/exhaustive-deps
    return ()=>{
      dispatch(
        setFormDisplay({
          begin: true,
          meta: false,
          html: false,
          dns: false,
          details: false,
          isAdd:true,
        })
      );

    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="dashboard_Con">
      <SideHeader roleType={roleType}/>
      <div className="dashboard_Con__Right">
        <DashboardHeader   />
       <PublisherWebsitesMid/>
      </div>
    </div>
  );
};

export default  withAuth(PublisherWebsites);
