import React from "react";
type Props = {
  background: string;
  color: string;
};

const BothSvg: React.FC<Props> = ({ background, color }) => {
  return (
    <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="50" cy="50" r="50" fill={background} fillOpacity="0.08"/>
    <path d="M70.3696 39.8198C70.6898 40.1937 71.0104 40.6209 71.2773 41.0477C71.0104 40.621 70.6898 40.2471 70.3696 39.8198Z" fill="black"/>
    <path d="M71.277 41.0477C71.0102 40.6206 70.6896 40.1934 70.3694 39.8199C70.2091 39.6063 70.0492 39.446 69.8889 39.2324C69.5687 38.8585 69.1948 38.485 68.8209 38.1644C68.2334 37.6306 67.6464 37.1502 67.0056 36.723C66.5784 36.4561 66.1512 36.1892 65.6707 35.9219C65.1902 35.655 64.763 35.4414 64.2826 35.2278C63.5885 34.9077 62.8412 34.6941 62.04 34.4805C61.5063 34.3739 61.0258 34.2669 60.4916 34.1603C59.6905 34.0537 58.8899 34 58.0355 34C55.0989 34 52.376 34.8011 50.0264 36.1355C47.6773 34.7474 44.9544 34 42.0173 34C33.1543 34 26 41.1547 26 50.0173C26 58.8803 33.1547 66.0346 42.0173 66.0346C44.9539 66.0346 47.6769 65.2335 50.0264 63.8992C52.3755 65.2873 55.0985 66.0346 58.0355 66.0346C58.8899 66.0346 59.6906 65.9813 60.4916 65.8743C61.0254 65.7677 61.5596 65.6607 62.04 65.5542C62.7874 65.3406 63.5352 65.127 64.2826 64.8068C64.7631 64.5932 65.2435 64.3796 65.6707 64.1127C66.1512 63.8459 66.5783 63.579 67.0056 63.3116C67.6463 62.8845 68.2334 62.404 68.8209 61.8702C69.1948 61.5501 69.5683 61.1762 69.8889 60.8023C70.0492 60.5887 70.2091 60.4284 70.3694 60.2148C70.6895 59.8409 71.0102 59.4137 71.277 58.9869C72.9858 56.4243 74 53.3273 74 50.0169C74 46.7064 73.0391 43.6095 71.277 41.0469V41.0477ZM47.1434 61.7642C45.595 62.4582 43.8329 62.8321 42.0176 62.8321C34.9698 62.8321 29.2033 57.0656 29.2033 50.0178C29.2033 42.9701 34.9698 37.2035 42.0176 37.2035C43.8329 37.2035 45.5949 37.5774 47.1434 38.2715C43.9933 41.2081 42.0176 45.3725 42.0176 50.0181C42.0176 54.6638 43.9932 58.8279 47.1434 61.7648V61.7642ZM58.0353 62.8321C56.22 62.8321 54.458 62.4582 52.9095 61.7642C56.0596 58.8276 58.0353 54.6632 58.0353 50.0175C58.0353 45.3719 56.0598 41.2077 52.9095 38.2709C54.4579 37.5768 56.22 37.2029 58.0353 37.2029C65.0831 37.2029 70.8496 42.9695 70.8496 50.0172C70.8496 57.0654 65.0834 62.8315 58.0353 62.8315V62.8321Z" fill={color}/>
    </svg>
    
  );
};

export default BothSvg;
