import React, { useState } from "react";

import { checkWebsiteEligibility } from "../../utils/api/addWebsite";
import { useAppDispatch } from "../../store/reduxHooks";
import {
  setAddWebsiteData,
  setFormDisplay,
} from "../../reducers/addWebsite/addWebsitedataReducer";
import { urlValidator } from "../../utils/validators/registerValidator";
type Props = {};

const AddWebsiteFormBegin: React.FC<Props> = () => {
  const [inputWebsite, setInputWebsite] = useState<string>("https://");
  const [isInvalidWebsite, setInvalidWebsite] = useState<{
    display: boolean,
    message: string,
  }>({
    display: false,
    message: "",
  });
  const dispatch = useAppDispatch();
  // const { addWebsiteState } = useAppSelector((state) => ({
  //   addWebsiteState: state?.addWebsiteState,
  // }));
  const isWebSiteEligible = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    params: string
  ) => {
    e.preventDefault();
    const websiteData = await checkWebsiteEligibility(params);

    if (websiteData?.status?.code === 200 && !urlValidator(params).isError) {
      console.log(websiteData.data);
      dispatch(setAddWebsiteData(websiteData.data));
      dispatch(
        setFormDisplay({
          begin: false,
          meta: true,
          html: false,
          dns: false,
          details: false,
          isAdd: true,
        })
      );

      setInvalidWebsite({
        display: false,
        message: "",
      });
    } else {
      setInvalidWebsite({
        display: true,
        message: urlValidator(params).isError
          ? "Please enter a valid website"
          : "Website is not eligible",
      });
      dispatch(
        setFormDisplay({
          begin: true,
          meta: false,
          html: false,
          dns: false,
          details: false,
          isAdd: true,
        })
      );
    }
  };
  return (
    <div className="pubLisherAddWebsite__form__begin">
      <div className="pubLisherAddWebsite__form__begin__label">
        <h4>Let’s Begin</h4>
        <p>
          Before moving further, let’s validate if website already registered
          with us or not.
        </p>
      </div>
      <div className="pubLisherAddWebsite__form__begin__input_Con">
        <label htmlFor="">Enter website URL (http or https)</label>
        <div>
          <input
            type="text"
            value={inputWebsite}
            onChange={(e) => {
              setInputWebsite(e.target.value)
              setInvalidWebsite({ ...isInvalidWebsite, display: false })
            }}
          />

          {/* <img
            src={redCross}
            alt=""
            className="redCross"
            style={{
              visibility: `${isInvalidWebsite?.display ? "visible" : "hidden"}`,
            }}
          /> */}

          <button onClick={(e) => isWebSiteEligible(e, inputWebsite)}>
            Validate
          </button>
        </div>
        {isInvalidWebsite?.display && <p>{isInvalidWebsite?.message}</p>}
      </div>
    </div>
  );
};

export default AddWebsiteFormBegin;
