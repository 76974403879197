import { configureStore } from "@reduxjs/toolkit";
import loginReducer from "../reducers/login/loginReducer";
import dashboardStatsReducer from "../reducers/dashboard/dashboardStatsReduccer";
import addWebsitedataReducer from "../reducers/addWebsite/addWebsitedataReducer";
import registerReducer from "../reducers/register/registerReducer";
import adminReducer from "../reducers/admin/adminReducer";

const store = configureStore({
  reducer: {
    login: loginReducer,
    dashboardState: dashboardStatsReducer,
    addWebsiteState: addWebsitedataReducer,
    registerEmail: registerReducer,
    adminState: adminReducer,
  },
});

export default store;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
