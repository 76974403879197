import React, { useEffect, useState } from "react";
import "./BlogList.scss";
import Footer from "../../components/footer/Footer";
import Header from "../../components/Header";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import moment from "moment";
import { adminBlogPostSummary, fetchCategoriesList } from "../../utils/api/blogpost";
import { fetchAuthorApi } from "../../utils/api/author";
//import { BlogType } from "../../utils/types/main";
import { CircularProgress } from "@mui/material";
import { Link } from "react-router-dom";
import search from "../../assets/add_Websites/search.svg";
import { Tooltip } from 'react-tooltip'
import parse from 'html-react-parser';

const BlogList = () => {
  const [blogs, setBlogs] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [hasMoreData, setHasMoreData] = useState<boolean>(true);
  const [blogCategories, setBlogCategories] = useState<any>([]);
  const [blogCategoriesMap, setBlogCategoriesMap] = useState(new Map());
  const [totalBlogCount, setTotalBlogCount] = useState<number>(0);
  const [selectedCategories, setSelectedCategories] = useState<string | undefined>(undefined);
  const [searchInput, setSearchInput] = useState<string>("");
  const [authorDetailsObj, setAuthorDetailsObj] = useState<any>({});
  const blogData = async () => {
    setSelectedCategories(undefined);
    setSearchInput('');
    const response = await adminBlogPostSummary(0, 9, true, undefined, undefined, undefined);
    if (response?.status?.code === 200) {
      setBlogs(response.data.blogPost.blog);
      setHasMoreData(response?.data?.blogPost?.pageInfo?.hasNext);
      setLoading(true);
      setPageNumber(1);
    } else {
      setLoading(true);
    }
  };

  const blogDataCategoryFiltered = async (selectedCategory: string) => {
    setSelectedCategories(selectedCategory);
    setSearchInput("");
    const response = await adminBlogPostSummary(0, 9, true, selectedCategory, undefined, undefined);
    if (response?.status?.code === 200) {
      setBlogs(response.data.blogPost.blog);
      setHasMoreData(response?.data?.blogPost?.pageInfo?.hasNext);
      setLoading(true);
      setPageNumber(1);
    } else {
      setLoading(true);
    }
  };

  const fetchMoreData = async () => {
    const response = await adminBlogPostSummary(pageNumber, 9, true, selectedCategories, searchInput, authorDetailsObj.id);
    if (response?.status?.code === 200) {
      setHasMoreData(response?.data?.blogPost?.pageInfo?.hasNext);
      const list = blogs;
      const mergedResult = [...list, ...response.data.blogPost.blog];
      setPageNumber(pageNumber + 1);
      setBlogs(mergedResult);
    }
  };


  const categoriesList = async (setBlogCategoriesMap: React.Dispatch<any>) => {
    const response = await fetchCategoriesList();
    if (response?.status?.code === 200) {
      setBlogCategories(response?.data?.blog?.blogCategories);
      setTotalBlogCount(response?.data?.blog?.totalBlogCount)
      const map = new Map();
      response?.data?.blog?.blogCategories.forEach((obj: { id: any; title: any; }) => {
        map.set(obj.id, obj.title);
      });
      setBlogCategoriesMap(map);
    } else {
      setLoading(true);
    }
  };


  const getCategoryName = (id: String) => {
    if (blogCategoriesMap === undefined) {
      return "";
    }
    return blogCategoriesMap?.get(id);
  };

  const setBlogSearchData = (e: any) => {
    //  var inputType = e.nativeEvent.inputType;
    //  if (inputType !== 'deleteContentForward' && inputType !== 'deleteContentBackward') {
    setSearchInput(e.target.value);
    //  }
  };

  const searchBlogData = async () => {

    const response = await adminBlogPostSummary(0, 9, true, selectedCategories, searchInput, undefined);
    if (response?.status?.code === 200) {
      setBlogs(response.data.blogPost.blog);
      setHasMoreData(response?.data?.blogPost?.pageInfo?.hasNext);
      setLoading(true);
      setPageNumber(1);
    } else {
      setLoading(true);
    }
    //   getCustomerDataCustom(verifiedFilter, roleFilter, searchInput);
  };

  const getSlicedCategoryStr = (blogItem: any) => {
    let returnStr = "";
    for (var i = 1; i < blogItem.categoryIds.length; i++) {
      returnStr += getCategoryName(blogItem.categoryIds[i]);
      returnStr += (blogItem.categoryIds.length - 1 !== i) ? " , " : ""
    }
    return returnStr;
  };

  const getAuthorStr = (blogItem: any) => {
    let returnStr = "";
    const author = blogItem?.author;
    if (author) {
      returnStr = blogItem?.author['name'];
    }
    return returnStr;
  };

  const clearSearchBlogData = async () => {
    setSearchInput('');
    const response = await adminBlogPostSummary(0, 9, true, selectedCategories, '', undefined);
    if (response?.status?.code === 200) {
      setBlogs(response.data.blogPost.blog);
      setHasMoreData(response?.data?.blogPost?.pageInfo?.hasNext);
      setLoading(true);
      setPageNumber(1);
    } else {
      setLoading(true);
    }
    //  getCustomerDataCustom(verifiedFilter, roleFilter, '');
  };

  const fetchAuthor = async (authorObj: any) => {
    console.log(authorObj);
    // setIsLoading(true);
    let author
    const response = await fetchAuthorApi(authorObj.author.id);
    if (response?.status?.code === 200) {
      author = response?.data?.blog?.author;
      author['name'] = authorObj.author.name;
      setAuthorDetailsObj(author);
      console.log(response?.data?.blog?.author);
      // setAuthorName(authorObj.name);
      // setBiography(response?.data?.blog?.author?.biography);
      // setAuthorObjId(authorObj.id);
      // setAuthorImageUrl(authorObj.imageUrl);
      // setIsLoading(false);
      // setIsAuthorListView(false);
      // authorObj.biography = response?.data?.blog?.author?.biography;
      // setAuthorObj(authorObj);
      // setSelectedFile({});
      // setIsEditAuthor(true);
      // setAuthorNameError("");
    } else {
      // setIsLoading(false);
      // toast.error("Something went wrong", {
      //     position: "top-right",
      //     theme: "colored",
      //     autoClose: 5000,
      // });
    }
    const blogResponse = await adminBlogPostSummary(0, 9, true, selectedCategories, '', author.id);
    if (blogResponse?.status?.code === 200) {
      setBlogs(blogResponse.data.blogPost.blog);
      setHasMoreData(blogResponse?.data?.blogPost?.pageInfo?.hasNext);
      setLoading(true);
      setPageNumber(1);
    } else {
      setLoading(true);
    }
  };
  useEffect(() => {

    categoriesList(setBlogCategoriesMap);
    blogData();
  }, []);

  return (
    <div className="about-bg">
      <Header />
      <div className="blog_List_Con" id="blog">
        <div className="blog__header">
          {" "}
          <h1>Blog</h1>{" "}
        </div>
        {authorDetailsObj.biography && (<div className="blog_author_details">
          <h2>{authorDetailsObj['name']}</h2>
          <div className="padding20 min_height_90px">
            <div className="width100px floatLeft">
              <img alt="profile" src={authorDetailsObj.imageUrl} className="author_pic"></img>
              {/* <CardMedia
                      component="img"
                      height="90"
                      image={authorDetailsObj.imageUrl}
                      style={{borderRadius:50}}
                      alt="green iguana"
                    /> */}
            </div>
            <div> {parse(authorDetailsObj['biography'])} </div> </div>
        </div>
        )}
        {!loading && (
          <div className="LoaderWrapper">
            <CircularProgress />
          </div>
        )}


        {loading && (
          <>
            <div className="flex">



              {!authorDetailsObj.biography && <div className="width20blog padding_top_15px" >
                <div className="side-blog-count blog-search-div">
                  <div className="sub-category"> Blog Search
                    <div className="margin-top-4perc"> <input
                      className="searchMarketPlace"
                      type="text"
                      value={searchInput}
                      placeholder='Search Blog'
                      onChange={(e: any) => setBlogSearchData(e)}
                    />
                      {searchInput !== '' && <img className="search-img" src={search} alt="" onClick={() => { searchBlogData(); }} />}
                      {searchInput !== '' && <span className="material-icons lightGrey close-img" onClick={() => { clearSearchBlogData(); }}>close</span>}
                    </div>
                  </div></div>
                <div className="side-blog-count ">
                  <div className="sub-category" onClick={() => blogData()}> All Blogs - {totalBlogCount} </div>
                  {blogCategories?.map((category: any, index: number) => (
                    <span key={index} onClick={() => blogDataCategoryFiltered(category.id)}>
                      {(category.blogCount > 0) && <div className="sub-category"  >
                        {category.title} - {category.blogCount}
                      </div>}
                    </span>
                  ))}
                </div>
              </div>}
              <div className="blog__Card width80">
                {blogs?.map((blogItem: any, index: number) => (
                  <Card
                    elevation={0}
                    sx={{ width: 350, borderRadius: 3, margin: 1, }}
                    key={index}
                  >

                    <Link
                      to={'/blog/' + blogItem.permaLink}
                      state={{ data: blogItem }} style={{ textDecoration: 'none' }}>
                      <CardMedia
                        component="img"
                        height="208"
                        image={blogItem.imageUrl}
                        sx={{ objectFit: 'fill' }}
                        alt="green iguana"
                      />
                      <CardContent style={{ paddingBottom: 10 }} >
                        <div className="blog_text">
                          <div className="width65">
                            <div className="blog_category_div">{getCategoryName(blogItem?.categoryIds[0])} </div>
                            <Tooltip data-class="tool" id="show-Category" place="top" />
                            {blogItem?.categoryIds?.length > 1 &&

                              <div className="blog_category_div" data-class="tool1"
                                data-tooltip-id="show-Category" data-tooltip-content={getSlicedCategoryStr(blogItem)}>+{blogItem?.categoryIds?.length - 1} </div>
                            }
                          </div>
                          <p className="blog_date">{moment(blogItem.updatedDate).format("ll")}</p>
                        </div>
                        <div className="blog_text_content blog_tt">
                          <span>{blogItem.title}</span>
                        </div>

                      </CardContent>
                    </Link>
                    <div className="blog_text_content author_text">
                      By <span onClick={() => fetchAuthor(blogItem)} className="author_name_text">{getAuthorStr(blogItem)}</span>
                    </div>
                  </Card>
                ))}

              </div>

            </div>
            <div>{hasMoreData && <button className="paymentDetailsBtn marginLeft50" onClick={() => fetchMoreData()} >
              Load More
            </button>}</div>
          </>
        )}
      </div>

      <div className="footer-bg">
        <Footer />
      </div>
    </div>
  );
};

export default BlogList;
