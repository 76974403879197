import React, { useState, useRef, ChangeEvent, KeyboardEvent } from 'react';
import './OtpInput.scss'; // Import CSS file for styling

interface OtpInputProps {
  numInputs: number;
  onOtpChange: (otpValue: string) => void; // Callback function to notify parent of OTP change
}

const OtpInput: React.FC<OtpInputProps> = ({ numInputs, onOtpChange }) => {
  const [otp, setOtp] = useState<Array<string | undefined>>(new Array(numInputs).fill(''));
  const inputRefs = useRef<HTMLInputElement[]>([]);

  const handleChange = (index: number, event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    // Check if the input value is numeric
    if (isNaN(Number(value))) return; // Only allow numeric input

    const newOtp = [...otp];

    // Update the OTP value at the specified index
    newOtp[index] = value === '' ? undefined : value;

    // Set the OTP state
    setOtp(newOtp);

    // Move to the next input field if the value is not empty
    if (value && index < numInputs - 1) {
      inputRefs.current[index + 1].focus();
    }

    // Notify the parent component of the OTP change
    onOtpChange(newOtp.filter(Boolean).join(''));

    // Clear the input value if it's undefined
    if (value === '' && index > 0) {
      inputRefs.current[index].value = '';
    }
  };

  const handleKeyDown = (index: number, event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Backspace' && !otp[index] && index > 0) {
      // Move to previous input field on backspace if current field is empty
      inputRefs.current[index - 1].focus();
    }
  };

  return (
    <div className='textAlignCenter'>
      {otp.map((value, index) => (
        <input
          key={index}
          type="text"
          maxLength={1}
          value={value}
          onChange={(e) => handleChange(index, e)}
          onKeyDown={(e) => handleKeyDown(index, e)}
          ref={(ref) => (inputRefs.current[index] = ref as HTMLInputElement)}
          style={{ width: "30px" }}
          className='otp-input'
        />
      ))}
    </div>
  );
};

export default OtpInput;