import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../store/reduxHooks";
export const loggedTrue = (Component: React.FC) => {
  return (props: any) => {
    const navigate = useNavigate();
    const { isLoggedIn, customerProfile } = useAppSelector((state) => ({
      isLoggedIn: state.login.isLoggedIn,
      customerProfile: state?.login?.customerProfile,
    }));
    useEffect(() => {
      if (isLoggedIn) {
       console.log(customerProfile)
        if (customerProfile?.role === "ADMIN") {
          sessionStorage.setItem("roleType", "Admin");
          navigate("/admin/dashboard");
        } else if (customerProfile?.role === "PUBLISHER") {
          sessionStorage.setItem("roleType", "Publisher");
          navigate("/dashboard");
        } else {
          sessionStorage.setItem("roleType", "Advertiser");
          navigate("/dashboard");
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoggedIn]);
    if (!isLoggedIn) {
      return <Component {...props} />;
    } else {
      return null;
    }
  };
};
