import { useState, useEffect, useRef } from "react";
import "./myAccountPage.scss";
import avatar from "../../assets/dashboardHeader/avatat.svg";
import withAuth from "../../hoc/withAuth";
import { getUserStats , postProfileUpdate } from "../../utils/api/dashboardStats";
import { useNavigate } from "react-router-dom";
import avatarLogout from "../../assets/dashboardHeader/avatar-logout.svg";
import calender from "../../assets/dashboardHeader/calendar.svg";
import text from "../../assets/dashboardHeader/text.svg";
import { useAppDispatch } from "../../store/reduxHooks";
import { logged } from "../../reducers/login/loginReducer";
import sidebarLogo from "../../assets/sidebar/logo.svg";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

const MyAccount = () => {
  let navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [currData, setCurrData] = useState<any>({});
  const [isLogoutDisplay, setIsLogoutDisplay] = useState<boolean>(false);
  const [isOutsideClicked, setIsOutsideClicked] = useState<boolean>(false);
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [title, setTitle] = useState<string>("");
  const [email, setEmail] = useState<string>('');
  const [firstNameError, setFirstNameError] = useState<string>('');
  const outSideEvent = useRef<any>(null);

  const getUserStatsData = async () => {
    const response = await getUserStats();
    if (response?.status?.code === 200){
      setCurrData(response.data);
      setFirstName(response?.data?.customer?.customerProfile?.firstName);
      setLastName(response?.data?.customer?.customerProfile?.lastName);
      setEmail(response?.data?.customer?.customerProfile?.emailId);
      setTitle(response?.data?.customer?.customerProfile?.title);
    }
  };

  const logout = () => {
    dispatch(logged(false));
  };

  const navigateToMyAccountPage = () => {
    sessionStorage.setItem("roleType", "Advertiser");
    navigate("/myAccount");
  };

  const navigateToDashboard = () => {
    sessionStorage.setItem("roleType", "Advertiser");
    navigate("/dashboard");
  };

  const getRoleType = () => {
    const role = currData?.customer?.customerProfile?.role;
    if (role) {
      if (role === 'ADVERTISER') {
        return 'Advertiser';
      } else if (role === 'PUBLISHER') {
        return 'Publisher';
      } else if (role === 'PUBADV') {
        return 'Advertiser/Publisher';
      } else {
        return '';
      }
    } else {
      return '';
    }
  };

  const profileObj = (firstName : string, lastName : string , role : string) => { 
    return { firstName: firstName, lastName: lastName , role:role} };

  const handleUpdate = async() => {
    setFirstNameError('');
    if (firstName === "") {
      setFirstNameError("First name is required");
      return;
    }
    if (firstName.length > 50) {
      setFirstNameError("Name Cannot be length of 50 characters");
      return;
    }
    const profileUpdatedObj = profileObj(
      firstName,
      lastName,
      currData?.customer?.customerProfile?.role,
    )
    const response = await postProfileUpdate(profileUpdatedObj);
    if (response?.status?.code === 200){
      console.log("Profile Updated");
      setTitle(firstName);
      toast.success('Profile has been updated successfully.', {
        position: "top-right",
        theme: "colored",
        autoClose: 5000,
      });
    }else{
      toast.error('Something Went Wrong', {
        position: "top-right",
        theme: "colored",
        autoClose: 5000,
      });
    }
  };


  useEffect(() => {
    const outSideCheck = (e: any) => {
      if (
        document
          .getElementById("dashboard__Header__Logout__Con")
          ?.contains(e.target) ||
        document
          .getElementById("dashboard__Header__UserInfo__Con")
          ?.contains(e.target)
      ) {
        setIsOutsideClicked(false);
      } else {
        setIsOutsideClicked(true);
        setIsLogoutDisplay(false);
      }
    };
    outSideEvent.current = outSideCheck;
  }, []);

  useEffect(() => {
    if (outSideEvent.current) {
      if (isLogoutDisplay) {
        window.addEventListener("click", outSideEvent.current);
      } else if (!isLogoutDisplay) {
        window.removeEventListener("click", outSideEvent.current);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLogoutDisplay]);

  useEffect(() => {
    getUserStatsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="dashboard_Con">
       <ToastContainer />
      <div className="dashboard_Con__Right">
        <div className="dashboard__Header__Con">
          <div className="dashboard__Header__Con__Left">
            <Link to="/dashboard">
              <img src={sidebarLogo} alt="" className="outReach__Logo" />
            </Link>
          </div>
          <div className="dashboard__Header__Con__Right">
            <img src={avatar} alt="" />
            <div
              className="dashboard__Header__UserInfo__Con"
              id="dashboard__Header__UserInfo__Con"
              onClick={(e) => {
                setIsLogoutDisplay(!isLogoutDisplay);
              }}
            >
              <h4>{title}</h4>
              <p>{getRoleType()}</p>
            </div>
          </div>
          <div
            className="dashboard__Header__Logout__Con"
            id="dashboard__Header__Logout__Con"
            style={{
              display: `${isLogoutDisplay && !isOutsideClicked ? "flex" : "none"}`,
            }}
          >
            <img src={avatarLogout} alt="" className="avatar_Logout" />
            <h4>{ }</h4>
            <div className="dashboard__Header__Logout__mid">
              <img src={calender} alt="" />
              <img src={text} alt="" />
            </div>

            <p onClick={() => navigateToMyAccountPage()}>My Account</p>
            <p onClick={() => logout()}>Logout</p>
          </div>
        </div>
        <div className="publisherWebsiteMid__Con">
          <div className="myAccountCenterDiv">
            <h2 className="myProfileText">
              My Profile
            </h2>
            <div className="myAccountDetails">

              <div className="myAccountUserInfo_Con" >
                <div>
                  <img src={avatar} alt="" className="myAccountAvatarImage" />
                </div>
                <div className="myAccountNameText">
                  <h2>{title}</h2>
                  <p>{email}</p>
                </div>
              </div>
              <div className="myAccountUserInfo_Con" >
                <div className="signupMid_Left-input_Con">
                  <div>
                    <label htmlFor="" className="input-label">
                      First Name <span className="red">*</span>
                    </label>
                    <input
                      type="text"
                      placeholder="Enter first name"
                      value={firstName}
                      maxLength={50}
                      onChange={e => setFirstName(e.target.value)}
                    />
                    {firstNameError && <span className="red">{firstNameError}</span>}
                  </div>
                  <div>
                    <label htmlFor="" className="label-last-Name">
                      Last Name
                    </label>
                    <input
                      type="text"
                      placeholder="Enter last name"
                      value={lastName}
                      maxLength={50}
                      style={{ border: `1px solid #aaaaaa` }}
                      onChange={e => setLastName(e.target.value)}
                    />
                    {firstNameError && <span className="red"> &nbsp;</span>}
                  </div>
                </div>
              </div>
              <div className="myAccountUserInfo_Con" >
                <div className="signupMid_Left-input_Con">
                  <div>
                    <label htmlFor="" className="input-label">
                      Interest As  
                    </label>
                    <input
                      className="disabledBackGround"
                      type="select"
                      placeholder={getRoleType()||''}
                      value={''}
                      disabled
                    />
                  </div>
                </div>
              </div>
              <div className="myAccountUserInfo_Con width100 displayBlock padding2Perc" >
                  <button className="addToCart_btn floatRight" onClick={() => { handleUpdate() }}> Update </button>
                  <button className="addToCart_btn goToDashBtn floatRight" onClick={() => { navigateToDashboard() }}> Go To Dashboard </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withAuth(MyAccount);
