import React, { useEffect, useRef, useState } from "react";
import "./dashboardHeader.scss";
// import notification from "../../assets/dashboardHeader/notification.svg";
// import mail from "../../assets/dashboardHeader/mail.svg";
import avatar from "../../assets/dashboardHeader/avatat.svg";
import avatarLogout from "../../assets/dashboardHeader/avatar-logout.svg";
import calender from "../../assets/dashboardHeader/calendar.svg";
import text from "../../assets/dashboardHeader/text.svg";
import { useAppDispatch } from "../../store/reduxHooks";
import { logged } from "../../reducers/login/loginReducer";
import { useAppSelector } from "../../store/reduxHooks";
// import { Link } from "react-router-dom";
// import { getUserStats } from "../../utils/api/dashboardStats";
// type Props = {
//   currData: any;
//   setCurrData: React.Dispatch<any>;
// };

const DashboardHeaderAdmin = ( ) => {
  const [isLogoutDisplay, setIsLogoutDisplay] = useState<boolean>(false);
  const [isOutsideClicked, setIsOutsideClicked] = useState<boolean>(false);
//   const [advertiser, setAdverTiser] = useState<string>("Publisher");
  const outSideEvent = useRef<any>(null);
  const {  customerProfile } = useAppSelector((state) => ({
    customerProfile: state?.login?.customerProfile,
  }));


  // useEffect(() => {
  //   getUserStatsData();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);
  useEffect(() => {
    const outSideCheck = (e: any) => {
      if (
        document
          .getElementById("dashboard__Header__Logout__Con")
          ?.contains(e.target) ||
        document
          .getElementById("dashboard__Header__UserInfo__Con")
          ?.contains(e.target)
      ) {
        setIsOutsideClicked(false);
      } else {
        setIsOutsideClicked(true);
        setIsLogoutDisplay(false);
      }
    };
    outSideEvent.current = outSideCheck;
  }, []);

  useEffect(() => {
    if (outSideEvent.current) {
      if (isLogoutDisplay) {
        window.addEventListener("click", outSideEvent.current);
      } else if (!isLogoutDisplay) {
        window.removeEventListener("click", outSideEvent.current);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLogoutDisplay]);

  const dispatch = useAppDispatch();

  const logout = () => {
    dispatch(logged(false));
  };

  return (
    <div className="dashboard__Header__Con">
      <div className="dashboard__Header__Con__Left">
   
        {/* <Link to="/dashboard/publisherWebsites" className="addWebSite-btn">
          Add Website
        </Link> */}
      </div>
      <div className="dashboard__Header__Con__Right">
        {/* <img src={notification} alt="" />
        <img src={mail} alt="" /> */}
        <img
          src={avatar}
          alt=""
        
        />
        <div
          className="dashboard__Header__UserInfo__Con"
          id="dashboard__Header__UserInfo__Con"
          onClick={(e) => {
            setIsLogoutDisplay(!isLogoutDisplay);
          }}
        >
          <h4>{customerProfile?.title}</h4>
          <p>{customerProfile?.role}</p>
        </div>
      </div>
      <div
        className="dashboard__Header__Logout__Con"
        id="dashboard__Header__Logout__Con"
        style={{
          display: `${isLogoutDisplay && !isOutsideClicked ? "flex" : "none"}`,
        }}
      >
        <img src={avatarLogout} alt="" className="avatar_Logout" />
        <h4>{}</h4>
        <div className="dashboard__Header__Logout__mid">
          <img src={calender} alt="" />
          <img src={text} alt="" />
        </div>

        <p>My Account</p>
        <p onClick={() => logout()}>Logout</p>
      </div>
    </div>
  );
};

export default DashboardHeaderAdmin;
