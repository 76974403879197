import signupImage from "../../assets/signup/login.png";
import google from "../../assets/signup/google.svg";
import fb from "../../assets/signup/fb.svg";
import eye from "../../assets/signup/eye.svg";
import Visibilityoff from "../../assets/signup/visibilityoff.svg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./login.scss";
import { useState, useEffect } from "react";
import { LoginPayload } from "../../utils/types/main";
import { loginInitial } from "../../utils/constants/selected";
import { loginValidator } from "../../utils/validators/registerValidator";
import { Link } from "react-router-dom";
import { loginPost, resendVerificationLink } from "../../utils/api/login";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../store/reduxHooks";
import { logged, setCustomerProfile } from "../../reducers/login/loginReducer";
//import Loader from "../loader/Loader";
import { getUserStats } from "../../utils/api/dashboardStats";
import ReCaptchaComponent, { reGenerateToken } from '../../pages/ReCaptchaComponent/ReCaptchaComponent';
import Popup from '../popup/popup';
import parse from 'html-react-parser';
import AppLoader from "../../components/defaultLoader/DefaultLoader";
import OtpInput from "../../pages/otpInput/OtpInput";

const Login = () => {
  const [errorData, SetErrorData] = useState<any>("");
  const [passwordShown, setPasswordShown] = useState<boolean>(false);
  const [loginData, setLoginData] = useState<LoginPayload>(loginInitial);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isVerifiedUserPopupOpen, setIsVerifiedUserPopupOpen] = useState(false);
  const [isAdminOtpGenerated, setIsAdminOtpGenerated] = useState(false);
  const [verifiedUserError, setVerifiedUserError] = useState("");
  const [timer, setTimer] = useState(90); // State for the timer count
  const [timerExpired, setTimerExpired] = useState(false);
  const [retryOtpLeft, setRetryOtpLeft] = useState<number | undefined>(5);
  const [otpStr, setOtpStr] = useState<string | undefined>(undefined);
  const [isAccountLocked, setIsAccountLocked] = useState(false);
  // Callback function to update OTP value
  const handleOtpChange = (otpValue: string | undefined) => {
    setOtpStr(otpValue);
  };

  const navigateToLogin = () => {
    setIsAccountLocked(false)
    setIsAdminOtpGenerated(false);
  }

  const toggleVerifiedUserPopup = (content: string) => {
    setVerifiedUserError(content);
    setIsVerifiedUserPopupOpen(!isVerifiedUserPopupOpen);
  }

  const sendVerficationLink = async () => {
    setLoading(true);
    reGenerateToken().then(async (captchaToken) => {
      const response = await resendVerificationLink(loginData.emailId, captchaToken);
      if (response?.status?.code === 200) {
        toast.success(
          "Verification mail sent successfully.", {
          position: "top-right",
          theme: "colored",
          autoClose: 5000,
        }

        );
        navigate("/verifyEmail");
      } else {
        toast.error("Something went wrong please try again", {
          position: "top-right",
          theme: "colored",
          autoClose: 5000,
        });
      }
    })
    toggleVerifiedUserPopup("");
  }



  const loginSubmit = async (otpInput: string | undefined) => {
    const { error, isError } = loginValidator(loginData);

    if (isError) {
      SetErrorData(error);
    } else {
      SetErrorData(error);
      reGenerateToken().then(async (captchaToken) => {
        try {
          const response = await loginPost(loginData, captchaToken, otpInput);
          if (response?.status?.code === 200) {
            const isOtpGenerated = response?.data?.customer?.isOtpGenerated;
            const retryOtpLeft = response?.data?.customer?.retryOtpLeft;
            setLoading(false);
            if (isOtpGenerated) {
              setIsAdminOtpGenerated(isOtpGenerated);
              startTimer(); // Start the timer when OTP is generated
            } else {
              if (retryOtpLeft || retryOtpLeft === 0) {
                if (retryOtpLeft === 0) {
                  setLoginData(loginInitial);
                  navigateToLogin();
                  toast.error("Maximum OTP retry limit reached.",
                    {
                      position: "top-right",
                      theme: "colored",
                      autoClose: 5000,
                    }
                  );
                  return;
                }
                setRetryOtpLeft(retryOtpLeft);
                toast.error(
                  "Please enter valid OTP.",
                  {
                    position: "top-right",
                    theme: "colored",
                    autoClose: 5000,
                  }
                );
              } else {
                const token = response?.data?.auth?.accessToken;
                const expiryTime = response?.data?.auth?.expiresAt || (Date.now() + (10 * 60 * 60 * 1000));
                sessionStorage.setItem('access-token', token);

                sessionStorage.setItem('tokenExpiryTime', '' + expiryTime);
                setLogoutExpiration(expiryTime);
                const userProfileData = await getUserStats();
                if (userProfileData?.status?.code === 200) {
                  dispatch(
                    setCustomerProfile(userProfileData?.data?.customer?.customerProfile)
                  );
                }
                navigate("/admin/dashboard");
                dispatch(logged(true));
                setLoading(true);
              }
            }
          } else if (response?.status?.code === 403 &&
            response?.status?.description === 'User is disabled') {
            setLoading(false);
            console.log("inside verified user")
            toggleVerifiedUserPopup(response?.status?.errors[0]?.['emailNotVerified'])

          }else if (response?.status?.code === 403 &&
            response?.status?.description === 'User account is locked') {
            setLoading(false);
            console.log("User account is locked")
            setIsAccountLocked(true);
          } else {
            setLoading(false);
            dispatch(logged(false));
            toast.error(
              "You entered an incorrect email or password. Please try again or try resetting your password.",
              {
                position: "top-right",
                theme: "colored",
                autoClose: 5000,
              }
            );
          }
        } catch (error) {
          // Handle any errors from loginPost or getUserStats
          console.error(error);
        }
      })
    }
  };
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  const setLogoutExpiration = (expireAt: any) => {
    const currentTime = new Date();
    const delay = expireAt - currentTime.getTime();
    if (delay > 0) {
      const timerId = setTimeout(() => {
        dispatch(logged(false));
      }, delay);
      return () => {
        clearTimeout(timerId);
      };
    }
  }

  const handleKeyPress = (e: any) => {
    if (e.key === 'Enter') {
      loginSubmit(otpStr);
    }
  }

  const handleVerify = (token: string | null) => {
    //console.log(" tedt ",token);
    //  setRecaptchaToken(token);
  };

  const startTimer = () => {
    setTimer(90); // Reset timer to 60 seconds
    const timerInterval = setInterval(() => {
      setTimer((prevTimer) => {
        if (prevTimer === 0) {
          clearInterval(timerInterval); // Stop the timer when it reaches 0
          setTimerExpired(true); // Set timerExpired to true when timer reaches 0
          return prevTimer;
        }
        return prevTimer - 1;
      });
    }, 1000);
  };

  // Function to format seconds into minutes and seconds
  const formatTime = (seconds: number): string => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
  };

  const handleResendClick = () => {
    // Logic to resend OTP
    setLoading(true);
    handleOtpChange(undefined); // Clear the OTP input in the payload
    setOtpStr(undefined);
    setTimer(90); // Reset timer
    setTimerExpired(false); // Reset timer expiration state
    setTimeout(() => {
      loginSubmit(undefined);
      console.log('Delayed message after 2 seconds!');
    }, 3000);

  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="loginnow_Con">
      {loading && <AppLoader size="lg" ></AppLoader>}
      {/* {loading && <Loader size="lg" />} */}
      <ReCaptchaComponent onVerify={handleVerify} />
      {!isAdminOtpGenerated && !isAccountLocked &&
        <div className="loginMid_Left">
          <h2>Log in Now</h2>
          <h5>Welcome back! Please enter your details.</h5>
          <div className="loginMid_Left-btn_Con" style={{ display: "none" }}>
            <button>
              <span>
                <img src={google} alt="google" />
              </span>
              Log in with Google
            </button>
            <button>
              <span>
                <img src={fb} alt="facebook" />
              </span>
              Log in with Facebook
            </button>
          </div>
          <div className="loginMid_Left-line_Con" style={{ display: "none" }}>
            <span></span>
            <p>or Log in with email</p>
            <span></span>
          </div>

          <div className="login-input">
            <label htmlFor="">Email Address</label>
            <input
              type="text"
              placeholder="Enter your email address"
              value={loginData.emailId.trim()}
              onChange={(e) =>
                setLoginData({ ...loginData, emailId: e.target.value })
              }
              style={{
                border: `${errorData?.emailId ? "1px solid #FF0000" : "1px solid #aaaaaa"
                  }`,
              }}
            />
            {errorData && <span>{errorData?.emailId}</span>}
          </div>

          <div className="login-input">
            <label htmlFor="" className="login-input-label">
              Password
            </label>
            <input
              type={passwordShown ? "text" : "password"}
              placeholder="Enter your password"
              value={loginData.password.trim()}
              onChange={(e) =>
                setLoginData({ ...loginData, password: e.target.value })
              }
              onKeyDown={handleKeyPress}
              style={{
                border: `${errorData?.password ? "1px solid #FF0000" : "1px solid #aaaaaa"
                  }`,
              }}
            />
            <div>
              <img
                src={!passwordShown ? Visibilityoff : eye}
                className={!passwordShown ? "Visibility-off" : ""}
                onClick={togglePasswordVisiblity}
                style={{ cursor: "pointer" }}
                alt=""
              />
            </div>
            {errorData && <span>{errorData?.password}</span>}
          </div>
          <div className="remember_password " >
            <div style={{ display: "none" }}>
              {" "}
              <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike" />
              <label htmlFor="vehicle1"> Remember me</label>
            </div>
            <Link to="/resetPassword" style={{ textDecoration: "none" }}>
              <h5>Forgot password?</h5>
            </Link>
          </div>
          <div className="login-btn_area">
            <button className="login-btn" onClick={() => loginSubmit(undefined)}>
              Login
            </button>
          </div>
          <h4>
            Don’t have an account?{" "}
            <Link to="/signup" style={{ textDecoration: "none" }}>
              {" "}
              <span>Sign Up for Free</span>
            </Link>
          </h4>
        </div>
      }
      {isAdminOtpGenerated && !isAccountLocked &&
        <div className="loginMid_otp">

          <h4>We have sent a <b>One Time Password</b> on your email address.</h4>
          <h3 className="textAlignCenter orange">Please enter OTP</h3>
          {/* Display the timer below the OTP input */}
          <h3 className="textAlignCenter "> {formatTime(timer)} </h3>
          <OtpInput numInputs={6} onOtpChange={handleOtpChange} />
          <div className="login-btn_area">
            <button className={`login-btn ${!timerExpired ? 'greyBackground' : ''}`} onClick={() => handleResendClick()}
              disabled={!timerExpired}
            >
              Resend OTP
            </button>
            <button className={`login-btn ${timerExpired ? 'greyBackground' : ''}`} onClick={() => loginSubmit(otpStr)} disabled={timerExpired}>
              Verify OTP
            </button>
            <h3 className="textAlignCenter "> Attempts Left {retryOtpLeft} </h3>
          </div>
          <h4 className="margin_top_20_perc">
            Back To{" "}
            <Link to="/login" style={{ textDecoration: "none" }}>
              {" "}
              <span onClick={() => navigateToLogin()}>Log In</span>
            </Link>
          </h4>
        </div>
      }
      {isAccountLocked &&
        <div className="loginMid_otp accountLockedPadding">

          <h4>Your account is locked due to security reasons.</h4>
          <h4>Please try after few hours.</h4>
          
          <h4 className="margin_top_20_perc">
            Back To{" "}
            <Link to="/login" style={{ textDecoration: "none" }}>
              {" "}
              <span onClick={() => navigateToLogin()}>Log In</span>
            </Link>
          </h4>
        </div>
      }
      <div className="loginMid_Right">
        <img src={signupImage} alt="Signup" />
      </div>
      <ToastContainer />
      {isVerifiedUserPopupOpen && <Popup
        content={<>
          <div><div className="popup-header">Verify Mail</div>
            <div className="popup-content-verify-mail">{parse(verifiedUserError)}</div>
            <div className="btnDiv">

              <button className=" addToCart_btn" onClick={() => { sendVerficationLink(); }} > Send verification mail  </button>
              <button className="wishList_btn addToCart_btn" onClick={() => { toggleVerifiedUserPopup(""); }} > Close  </button>
            </div>
          </div>
        </>}
        handleClose={toggleVerifiedUserPopup}
      />}
    </div>
  );
};

export default Login;
