import signupImage from "../../assets/signup/signup.png";
import eye from "../../assets/signup/eye.svg";
import "./accountVerified.scss";
import { Link } from "react-router-dom";
 const AccountVerified = () => {
  return (
    <div className="accountverified_Con">
    <div className="accountverified_Left">
      <h2>Account Verified</h2>
      <h5>Welcome to Outreach Kart! Your account has been successfully verified. Please enter your details to login.</h5>
  
      <div className="accountverified-input">
        <label htmlFor="">
          Email address 
        </label>
        <input type="text" placeholder="Enter your email address" />
      </div>
      <div className="accountverified-input">
        <label htmlFor="">
          Password 
        </label>
        <input type="password" placeholder="Enter password" />
        <div>
          <img src={eye} alt="" />
        </div>
      </div>
      <div className="accountverified_password">
        <div>
          {" "}
          <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike" />
          <label htmlFor="vehicle1"> Remember me</label>
        </div>
        <Link to="/forgotPassword" style={{textDecoration:"none"}}>
        <h5>Forgot password?</h5></Link>
      </div>
      <div  className="accountverified-btn_area" ><button className="accountverified-btn">Login</button></div>
      <h4>
        Don’t have an account? <span>Sign Up for Free</span>
      </h4>
    </div>
    <div className="accountverified_Right">
      <img src={signupImage} alt="Signup" />
    </div>
  </div>
  )
}
export default AccountVerified;