import axios from "axios";
import {  WEBSITE_CATEGORIES } from "../constants/url";

export const getWebsiteCategories = async () => {
  try {
    const accessToken = sessionStorage.getItem('access-token');
    const response = await axios.get(process.env.REACT_APP_BASE + WEBSITE_CATEGORIES,	{ headers: { 'Authorization': 'Bearer '+accessToken}}
    );
    return response.data;
  } catch (err) {
    console.log(err);
    return err;
  }
};
