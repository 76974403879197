import React, { useEffect } from "react";
import "./orderSuccess.scss";
import { paymentSuccessApi } from "../../utils/api/marketPlace";
import Loader from "../../components/loader/Loader";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const OrderSuccess = () => {
    let navigate = useNavigate();

    const paymentSuccess = async () => {
        const search = window.location.search;
        const response = await paymentSuccessApi(search);
        if (response?.status?.code === 200) {
            toast.success("Order placed successfully", {
                position: "top-right",
                theme: "colored",
                autoClose: 3000,
              });
              navigate("/dashboard/orderDashboard");
        }
    };


    useEffect(() => {
        paymentSuccess();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (
        <div className='signup-bg-order' >
            <Loader size="lg" text="Processing Order" />
            <ToastContainer />
        </div>
    )
}

export default OrderSuccess