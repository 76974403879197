import Footer from "../../components/footer/Footer";
import Header from "../../components/Header";
import TermAndCond from "../../components/termAndCond/TermAndCond";
import "./termAndCondMid.scss";

const TermAndCondMid = () => {
  return (
    <div className="about-bg">
      <Header />
      <TermAndCond />
      <div className="footer-bg">
        <Footer />
      </div>
    </div>
  );
};

export default TermAndCondMid;
