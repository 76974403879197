import React, { useState } from "react";
// import { activateCustomerWebsite } from "../../utils/api/addWebsite";
import { toast } from "react-toastify";
import { useAppDispatch, useAppSelector } from "../../store/reduxHooks";
import { setActiveSuccess } from "../../reducers/addWebsite/addWebsitedataReducer";
import { postAdminApproveWebsite, getAdminWebsiteCount } from "../../utils/api/adminWebsite";
import { addAdminWebsiteDetailsPost } from "../../utils/api/postAddWebsiteDetails";
import { setAdminWebsiteDetails, setTaskboardDisplay } from "../../reducers/admin/adminReducer";
import { ApproveWebsiteDetailsError } from "../../utils/types/main";
import { approveWebsiteValidator } from "../../utils/validators/addWebsitevalidators";
import AdminRejectPopup from "./AdminRejectPopup";
import parse from 'html-react-parser';

interface Hash {
    [key: string]: boolean;
}

type Props = {
    setApproveAndViewData: React.Dispatch<
        React.SetStateAction<{
            display: boolean;
            websiteObj: any;
            categories: any,
            isNewWebsiteApproval: boolean,
            updatedKeyhash: Hash,
            isAllWebsite: boolean,
        }>
    >;
    approveAndViewData: {
        display: boolean;
        websiteObj: any;
        categories: any,
        isNewWebsiteApproval: boolean,
        updatedKeyhash: Hash
        isAllWebsite: boolean,
    };
    sendUpdatedList?: (data: any) => void;
};



const AdminViewAndApprovePopup: React.FC<Props> = ({
    setApproveAndViewData,
    approveAndViewData,
    sendUpdatedList,
}) => {
    const dispatch = useAppDispatch();
    const { taskboardDisplay, adminWebsiteDetails } = useAppSelector((state) => ({
        taskboardDisplay: state.adminState.taskboardDisplay,
        adminWebsiteDetails: state?.adminState?.adminWebsiteDetails,
    }));
    const [updatedKeyHash] = useState<Hash>(approveAndViewData?.updatedKeyhash);
    const [websiteObj] = useState<any>(approveAndViewData?.websiteObj);
    const [websiteCategories] = useState<any>(approveAndViewData?.categories);
    const [approvalObj, setApprovalObj] = useState(
        {
            websiteId: websiteObj?.id,
            domainAuthority: websiteObj?.domainAuthority || '',
            traffic: websiteObj?.traffic || '',
            referingDomain: websiteObj?.referingDomain || '',
            spamScore: websiteObj?.spamScore || (websiteObj?.spamScore  !== 0 ? '' : 0),
        }
    );

    const [rejectData, setRejectData] = useState<{
        display: boolean;
        id: string;
        rejectedReason: string;
    }>({
        display: false,
        id: "",
        rejectedReason: "",
    });

    const [displayError, setDisplayError] = useState<ApproveWebsiteDetailsError>({
        isError: false,
        domainAuthority: false,
        traffic: false,
        referingDomain: false,
        spamScore: false,
    })


    const handleReject = (id: string) => {
        setRejectData({
            ...rejectData,
            display: true,
            id,
        });
        // dispatch(setActiveSuccess(false));
    };


    const handleYes = async () => {
        const innerDisplay = approveWebsiteValidator(
            approvalObj,
            displayError,
            setDisplayError
        );
        if (!innerDisplay.isError) {
            setApproveAndViewData({ ...approveAndViewData, display: false });
            dispatch(
                setAdminWebsiteDetails({ ...adminWebsiteDetails, isLoading: true })
            );
            approvalObj.websiteId = approveAndViewData?.websiteObj?.id;

            const response = await postAdminApproveWebsite(approvalObj);
            if (response?.status?.code === 200) {
                toast.success("Website approved successfully.", {
                    position: "top-right",
                    theme: "colored",
                    autoClose: 5000,
                });

                if (response?.status?.code === 200) {
                    const arr = adminWebsiteDetails?.data;
                    const newList = arr.filter((item: any) => item.id !== approveAndViewData?.websiteObj?.id);
                    const responseCount = await getAdminWebsiteCount();
                    if (responseCount?.status?.code === 200) {
                        const total = responseCount?.data?.websiteApprovalData?.totalNewApproval;
                        const newTotal = responseCount?.data?.websiteApprovalData?.totalPendingApproval;
                        const edit = responseCount?.data?.websiteApprovalData?.totalEditApproval;
                        const selected = taskboardDisplay?.selected;
                        dispatch(
                            setTaskboardDisplay({
                                ...taskboardDisplay,
                                isActive: true,
                                selected: selected,
                                total: total,
                                edit: edit,
                                new: newTotal,
                            })
                        );
                    }
                    dispatch(
                        setAdminWebsiteDetails({ ...adminWebsiteDetails, data: newList })
                    );
                } else {
                    dispatch(
                        setAdminWebsiteDetails({
                            ...adminWebsiteDetails,
                            isLoading: false,
                            data: [],
                            pageInfo: {
                                hasNext: false,
                                totalCount: 0,
                            },
                        })
                    );
                }

            } else {
                toast.error(
                    response?.status?.errors?.[0]?.websiteNotFound ||
                    "Something went wrong",
                    {
                        position: "top-right",
                        theme: "colored",
                        autoClose: 5000,
                    }
                );
                dispatch(setActiveSuccess(true))
            }
        }
    };

    const handleUpdate = async () => {
        const innerDisplay = approveWebsiteValidator(
            approvalObj,
            displayError,
            setDisplayError
        );
        if (!innerDisplay.isError) {
            
            dispatch(
                setAdminWebsiteDetails({ ...adminWebsiteDetails, isLoading: true })
            );
            // approvalObj.websiteId = approveAndViewData?.websiteObj?.id;
            const updateObj = {...approveAndViewData.websiteObj, ...approvalObj }
            const response = await addAdminWebsiteDetailsPost(updateObj);
            if (response?.status?.code === 200) {
                toast.success("Website updated successfully.", {
                    position: "top-right",
                    theme: "colored",
                    autoClose: 5000,
                });
                if (typeof sendUpdatedList === 'function') {
                    sendUpdatedList(updateObj);
                }
                setApproveAndViewData({ ...approveAndViewData, display: false });
                   // Use an explicit check before calling the function

                // if (response?.status?.code === 200) {
                //     const arr = adminWebsiteDetails?.data;
                //     const newList = arr.filter((item: any) => item.id !== approveAndViewData?.websiteObj?.id);
                //     const responseCount = await getAdminWebsiteCount();
                //     if (responseCount?.status?.code === 200) {
                //         const total = responseCount?.data?.websiteApprovalData?.totalNewApproval;
                //         const newTotal = responseCount?.data?.websiteApprovalData?.totalPendingApproval;
                //         const edit = responseCount?.data?.websiteApprovalData?.totalEditApproval;
                //         const selected = taskboardDisplay?.selected;
                //         dispatch(
                //             setTaskboardDisplay({
                //                 ...taskboardDisplay,
                //                 isActive: true,
                //                 selected: selected,
                //                 total: total,
                //                 edit: edit,
                //                 new: newTotal,
                //             })
                //         );
                //     }
                //     dispatch(
                //         setAdminWebsiteDetails({ ...adminWebsiteDetails, data: newList })
                //     );
                // } else {
                //     dispatch(
                //         setAdminWebsiteDetails({
                //             ...adminWebsiteDetails,
                //             isLoading: false,
                //             data: [],
                //             pageInfo: {
                //                 hasNext: false,
                //                 totalCount: 0,
                //             },
                //         })
                //     );
                // }

            } else {
                toast.error(
                    response?.status?.errors?.[0]?.websiteNotFound ||
                    "Something went wrong",
                    {
                        position: "top-right",
                        theme: "colored",
                        autoClose: 5000,
                    }
                );
                dispatch(setActiveSuccess(true))
            }
        }
    };

    const handleNo = () => {
        setApproveAndViewData({ ...approveAndViewData, display: false });
    };

    const getCategoryList = (categoryList: [string]) => {
        let categoryNames = ""
        for (var i = 0; i < categoryList.length; i++) {
            categoryNames += getCategoryName(categoryList[i]);
            if (i !== categoryList.length - 1) {
                categoryNames += ', ';
            }
        }
        return categoryNames;
    };

    const getCategoryName = (id: string) => {
        return websiteCategories?.get(id)
    };

    const isUpdatedField = (name: string) => {
        return updatedKeyHash?.[name] !== undefined;
    };

    const parseFloatVal = (val: any) => {
        if (val === '') {
            return '';
        }
        if (val > 9007199254740991) {
            var temp = val.toString().substring(0, val.toString().length - 1);
            var tempVal = parseFloat(temp);
            if (tempVal > 9007199254740991) {
                return 9007199254740991;
            } else {
                return tempVal;
            }
        } else {
            if (isNaN(parseFloat(val))) {
                return '';
            } else {
                return parseFloat(val);
            }
        }
    }

    const getPostValidityType = (postValidityType: string) => {
        if (postValidityType === 'YEARLY') {
            return 'Yearly';
        } else if (postValidityType === 'HALF_YEARLY') {
            return 'Half Yearly';
        } else if (postValidityType === 'LIFETIME') {
            return 'Lifetime';
        }
    };

    const getBacklinkType = (backlinkType: any) => {
        if (backlinkType === 'DOFOLLOW') {
            return 'Dofollow';
        } else if (backlinkType === 'NOFOLLOW') {
            return 'Nofollow';
        }
    };

    return (
        <div className="aprroval_conainer">
            <div className="width100 website_approval_header" >
                {approveAndViewData?.isAllWebsite && <>
                    Website Update
                </>}
                {!approveAndViewData?.isAllWebsite && <>
                    {adminWebsiteDetails?.isNewWebsiteApproval && <>New Website Approval</>}
                    {!adminWebsiteDetails?.isNewWebsiteApproval && <>Edit Website Approval</>}
                </>}
            </div>
            {!adminWebsiteDetails?.isNewWebsiteApproval &&
                <div className="disclaimer_updates red">
                    <i>* Updates done by publisher are highlighted. Please review them before approving the website.</i>
                </div>
            }
            <div className="approval_info_container width100">
                <div className="container_admin_approval">
                    <div className="header_admin_approval" >General Info</div>
                    <div className="width100 flexrow approvalRow">
                        <div className={`width50 flexrow ${isUpdatedField('websiteUrl') ? "red" : ""}`} >
                            <div className="width40 flexrow bold">Website Url : </div>
                            <div className="width60 flexrow"> {approveAndViewData?.websiteObj?.websiteUrl}</div>
                        </div>
                        <div className={`width50 flexrow ${isUpdatedField('emailId') ? "red" : ""}`} >
                            <div className="width40 flexrow bold">Email ID :</div>
                            <div className="width60 flexrow">{approveAndViewData?.websiteObj?.emailId}</div>
                        </div>
                    </div>

                    <div className="width100 flexrow approvalRow">
                        <div className={`width50 flexrow ${isUpdatedField('websiteName') ? "red" : ""}`} >
                            <div className="width40 flexrow bold">Website Name : </div>
                            <div className="width60 flexrow"> {approveAndViewData?.websiteObj?.websiteName}</div>
                        </div>
                        <div className={`width50 flexrow ${isUpdatedField('websiteCategoryIdList') ? "red" : ""}`} >
                            <div className="width40 flexrow bold">Post Categories:</div>
                            <div className="width60 flexrow">{getCategoryList(approveAndViewData?.websiteObj?.websiteCategoryIdList)}</div>
                        </div>
                    </div>
                </div>

                <div className="container_admin_approval">
                    <div className="header_admin_approval">Price</div>
                    <div className="width100 flexrow approvalRow">
                        <div className={`width50 flexrow ${isUpdatedField('guestPostPrice') ? "red" : ""}`} >
                            <div className="width40 flexrow bold"> General Post : </div>
                            <div className="width60 flexrow">$ {approveAndViewData?.websiteObj?.guestPostPrice}</div>
                        </div>
                        <div className={`width50 flexrow ${isUpdatedField('linkInsertionPrice') ? "red" : ""}`} >
                            <div className="width40 flexrow bold"> Link Insertion :</div>
                            <div className="width60 flexrow">$ {approveAndViewData?.websiteObj?.linkInsertionPrice}</div>
                        </div>
                        <div className={`width50 flexrow ${isUpdatedField('cryptoPostPrice') ? "red" : ""}`} >
                            <div className="width40 flexrow bold"> Crypto/Finance : </div>
                            <div className="width60 flexrow"> $ {approveAndViewData?.websiteObj?.cryptoPostPrice}</div>
                        </div>
                    </div>
                    {(approveAndViewData?.websiteObj?.isSenstiveAccepted || isUpdatedField('isSenstiveAccepted')) &&
                        <div className="width100 flexrow approvalRow">
                            <div className={`width50 flexrow ${isUpdatedField('casinoPostPrice') ? "red" : ""}`} >
                                <div className="width40 flexrow bold"> Casino Post : </div>
                                <div className="width60 flexrow">$ {approveAndViewData?.websiteObj?.casinoPostPrice || 0}</div>
                            </div>
                            <div className={`width50 flexrow ${isUpdatedField('cbdPostPrice') ? "red" : ""}`} >
                                <div className="width40 flexrow bold"> CBD/Vape :</div>
                                <div className="width60 flexrow">$ {approveAndViewData?.websiteObj?.cbdPostPrice || 0}</div>
                            </div>
                            <div className={`width50 flexrow ${isUpdatedField('adultPostPrice') ? "red" : ""}`} >
                                <div className="width40 flexrow bold"> Adult : </div>
                                <div className="width60 flexrow"> $ {approveAndViewData?.websiteObj?.adultPostPrice || 0}</div>
                            </div>
                        </div>
                    }
                </div>
                <div className="container_admin_approval">
                    <div className="header_admin_approval">Guidelines</div>
                    <div className="width100 flexrow approvalRow">
                        <div className={`width60 flexrow ${isUpdatedField('minPostWordCount') ? "red" : ""}`} >
                            <div className="width40 flexrow bold">Minimum Post Word Count : </div>
                            <div className="width60 flexrow"> {approveAndViewData?.websiteObj?.minPostWordCount}</div>
                        </div>
                        <div className={`width60 flexrow ${isUpdatedField('guaranteePostValidity') ? "red" : ""}`} >
                            <div className="width40 flexrow bold">Guarantee Post Validity :</div>
                            <div className="width60 flexrow">{getPostValidityType(approveAndViewData?.websiteObj?.guaranteePostValidity)}</div>
                        </div>
                    </div>
                    <div className="width100 flexrow approvalRow">
                        <div className={`width50 flexrow ${isUpdatedField('backLinkType') ? "red" : ""}`} >
                            <div className="width40 flexrow bold">Backlink Type : </div>
                            <div className="width60 flexrow"> {getBacklinkType(approveAndViewData?.websiteObj?.backLinkType)}</div>
                        </div>
                        <div className={`width50 flexrow ${isUpdatedField('maxBackLinks') ? "red" : ""}`} >
                            <div className="width40 flexrow bold">Maximum Backlinks :</div>
                            <div className="width60 flexrow">{approveAndViewData?.websiteObj?.maxBackLinks}</div>
                        </div>
                    </div>

                    <div className="width100 flexrow approvalRow">
                        <div className={`width50 flexrow ${isUpdatedField('sampleUrl') ? "red" : ""}`} >
                            <div className="width40 flexrow bold">Sample URL : </div>
                            <div className="width60 flexrow"> {approveAndViewData?.websiteObj?.sampleUrl}</div>
                        </div>
                        <div className={`width50 flexrow ${isUpdatedField('tat') ? "red" : ""}`} >
                            <div className="width50 flexrow bold">Turn Around Time in Days (TAT) : </div>
                            <div className="width50 flexrow">{approveAndViewData?.websiteObj?.tat}</div>
                        </div>
                    </div>
                    <div className={`width100  ${isUpdatedField('postGuideline') ? "red" : ""}`}>
                        <div className="width100 bold">Post Guidelines :</div>

                        <div className="width100 post_guideline_approval ">{parse(approveAndViewData?.websiteObj?.postGuideline)}</div>
                    </div>
                </div>
                <div className="container_admin_approval">
                    <div className="header_admin_approval" >Contact Details</div>
                    <div className="width100 flexrow approvalRow">
                        <div className={`width50 flexrow ${isUpdatedField('contactNumber') ? "red" : ""}`} >
                            <div className="width40 flexrow bold">Skype ID :</div>
                            <div className="width60 flexrow">{approveAndViewData?.websiteObj?.contactNumber}</div>
                        </div>
                        <div className={`width50 flexrow ${isUpdatedField('skypeId') ? "red" : ""}`} >
                            <div className="width40 flexrow bold">Contact Number : </div>
                            <div className="width60 flexrow"> {approveAndViewData?.websiteObj?.skypeId}</div>
                        </div>

                    </div>
                </div>
                <div className="container_admin_approval">
                    <div className="header_admin_approval">Website Vital Info</div>
                    <div className="width100 flexrow approvalRow">
                        <div className="width50 flexrow">
                            <div className="width40 "><span className="bold">Domain Authority <span className="requiredColor" >*</span> : </span>
                                <span className="errorText">
                                    {displayError.domainAuthority && (
                                        <span>
                                            {" "}
                                            <span>{displayError.domainAuthority}</span>{" "}
                                        </span>
                                    )}</span></div>
                            <div className="width60 flexrow inputRowPadding">
                                <input
                                    id="outlined-number"
                                    type="number"
                                    className="approvalInpBtn"
                                    placeholder="Enter Domain Authority"
                                    value={approvalObj.domainAuthority}
                                    onChange={(e: any) =>
                                        setApprovalObj({
                                            ...approvalObj,
                                            domainAuthority: parseFloatVal(e.target.value),
                                        })
                                    }
                                />
                            </div>
                        </div>
                        <div className="width50 flexrow">
                            <div className="width40 "> <span className="bold">Traffic <span className="requiredColor">*</span> :</span>
                                {displayError.traffic && (
                                    <p className="errorText">
                                        {" "}
                                        <span>{displayError.traffic}</span>{" "}
                                    </p>
                                )}</div>
                            <div className="width60 flexrow inputRowPadding">
                                <input
                                    id="outlined-number"
                                    type="number"
                                    className="approvalInpBtn"
                                    placeholder="Enter Traffic"
                                    value={approvalObj.traffic}
                                    onChange={(e: any) =>
                                        setApprovalObj({
                                            ...approvalObj,
                                            traffic: parseFloatVal(e.target.value),
                                        })
                                    }
                                />
                            </div>
                        </div>
                    </div>
                    <div className="width100 flexrow approvalRow">
                        <div className="width50 flexrow">
                            <div className="width40 "> <span className="bold">Domain Rating <span className="requiredColor">*</span> :</span>
                                {displayError.referingDomain && (
                                    <p className="errorText">
                                        {" "}
                                        <span>{displayError.referingDomain}</span>{" "}
                                    </p>
                                )}</div>
                            <div className="width60 flexrow inputRowPadding">
                                <input
                                    id="outlined-number"
                                    className="approvalInpBtn"
                                    type="number"
                                    placeholder="Enter Domain Rating"
                                    value={approvalObj.referingDomain}
                                    onChange={(e: any) =>
                                        setApprovalObj({
                                            ...approvalObj,
                                            referingDomain: parseFloatVal(e.target.value),
                                        })
                                    }
                                />
                            </div>
                        </div>
                        <div className="width50 flexrow">
                            <div className="width40 "><span className="bold">Spam Score <span className="requiredColor">*</span> :</span>
                                {displayError.spamScore && (
                                    <p className="errorText">
                                        {" "}
                                        <span>{displayError.spamScore}</span>{" "}
                                    </p>
                                )}
                            </div>
                            <div className="width60 flexrow inputRowPadding">
                                <input
                                    id="outlined-number"
                                    type="number"
                                    value={approvalObj.spamScore}
                                    className="approvalInpBtn"
                                    placeholder="Enter Spam Score"
                                    onChange={(e: any) =>
                                        setApprovalObj({
                                            ...approvalObj,
                                            spamScore: parseFloatVal(e.target.value),
                                        })
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {!approveAndViewData.isAllWebsite && <>
                <div className="width100 text-align-right admin_buttons_padding">

                    <button
                        className="admin_save_and_approve"
                        onClick={() => handleYes()}
                    > <b>  Approve </b>
                    </button>
                    <button
                        className="admin_save_and_approve admin_reject"
                        onClick={() => handleReject(approvalObj.websiteId)} > <b>  Reject </b>
                    </button>
                    <button
                        className="admin_cancel"
                        onClick={() => handleNo()} > <b>  Cancel </b>
                    </button>
                </div>
            </>}
            {approveAndViewData.isAllWebsite && <>
                <div className="width100 text-align-right admin_buttons_padding">

                    <button
                        className="admin_save_and_approve"
                        onClick={() => handleUpdate()}
                    > <b>  Save </b>
                    </button>
                    <button
                        className="admin_cancel"
                        onClick={() => handleNo()} > <b>  Cancel </b>
                    </button>
                </div>
            </>}
            {rejectData?.display && (
                <AdminRejectPopup
                    setRejectData={setRejectData}
                    rejectData={rejectData}
                />
            )
            }
        </div>


    );
};

export default AdminViewAndApprovePopup;
