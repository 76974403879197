import { BlogTypeAdmin, BlogError } from "../../utils/types/main";

export const validateBlog = (
    blog: BlogTypeAdmin,
    isEditBlog:boolean,
    selectedFile : any
) => {
    console.log(" blog  -->  ",blog)
    let innerDisplay: BlogError = {
        isError: false,
        imageUrlError: "",
        titleError: "",
        tagLineError: "",
        descriptionError: ""
    };

    if (!blog.title) {
        innerDisplay = {
            ...innerDisplay,
            isError: true,
            titleError: "Title is required",
        };
    } else {
        innerDisplay = { ...innerDisplay, titleError: "" };
    }


    if (!blog.permaLink) {
        innerDisplay = {
            ...innerDisplay,
            isError: true,
            tagLineError: "Permalink is required",
        };
    } else {
        if(/[ `!@#$%^&*()_+=[\]{};':"\\|,.<>/?~]/.test(blog.permaLink)){
            innerDisplay = {
                ...innerDisplay,
                isError: true,
                tagLineError: "Special characters are not allowed in Permalink",
            };
        }else{
            innerDisplay = { ...innerDisplay, tagLineError: "" };
        }
        
    }

    if (!selectedFile && !isEditBlog) {
        innerDisplay = {
            ...innerDisplay,
            isError: true,
            imageUrlError: "Image is required",
        };
    } else {
        innerDisplay = { ...innerDisplay, imageUrlError: "" };
    }


    if (!blog.description) {
        innerDisplay = {
            ...innerDisplay,
            isError: true,
            descriptionError: "Description is required",
        };
    } else {
        const length = blog.description.replaceAll(/<.*?>/gi, "").length;
        console.log(length);
        if(length === 0){
            innerDisplay = {
                ...innerDisplay,
                isError: true,
                descriptionError: "Description is required",
            };
        }else{
            innerDisplay = { ...innerDisplay, descriptionError: "" };
        }
       
    }
  //  setDisplayError(innerDisplay);
    console.log(" displayError  -->  ",innerDisplay)
    return innerDisplay;
};