import "./signupMid.scss";
import signupImage from "../../assets/signup/signup.png";
import google from "../../assets/signup/google.svg";
import fb from "../../assets/signup/fb.svg";
import eye from "../../assets/signup/eye.svg";
import checkmark from "../../assets/signup/checkmark.svg";
import closemark from "../../assets/signup/closemark.svg";
import { useState, useEffect } from "react";
import { RegisterPayload } from "../../utils/types/main";
import { registerInitial } from "../../utils/constants/selected";
import { registerValidator } from "../../utils/validators/registerValidator";
import { Link, useNavigate } from "react-router-dom";
import Visibilityoff from "../../assets/signup/visibilityoff.svg";
import { useAppSelector } from "../../store/reduxHooks";
import { registerPost } from "../../utils/api/register";
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";
import ReCaptchaComponent , { reGenerateToken } from '../../pages/ReCaptchaComponent/ReCaptchaComponent';

const SignUpMid = () => {
  const navigate = useNavigate();
  const emailId = useAppSelector((state) => state.registerEmail.email);
 // const [recaptchaToken, setRecaptchaToken] = useState<string | null>(null);


  const handleVerify = (token: string | null) => {
    //console.log(" tedt ",token);
  //  setRecaptchaToken(token);
  };

  const [errorData, SetErrorData] = useState<any>("");
  const [passwordShown, setPasswordShown] = useState<boolean>(false);
  const [passwordShow, setPasswordShow] = useState<boolean>(false);
  const [isValidationActive, setisActiveValidation] = useState<boolean>(false);
  const [passwordMatch, setPasswordMatch] = useState<{
    display: boolean;
    minCheck: boolean;
    minMessage: string;
    maxCheck: boolean;
    maxMessage: string;
    lowerCheck: boolean;
    lowerMessage: string;
    upperCheck: boolean;
    upperMessage: string;
    numberCheck: boolean;
    numberMessage: string;
    specialCheck: boolean;
    specialDisplay: string;
  }>({
    display: false,
    minCheck: false,
    minMessage: "Minimum 6 characters",
    maxCheck: false,
    maxMessage: "Maximum 15 characters",
    lowerCheck: false,
    lowerMessage: "At least 1 lower character",
    upperCheck: false,
    upperMessage: "At least 1 upper character",
    numberCheck: false,
    numberMessage: "At least 1 number",
    specialCheck: false,
    specialDisplay: "At least 1 special character",
  });
  const passwordCheck = (password: string) => {
    const currentCheck: {
      minCheck: boolean;
      maxCheck: boolean;
      lowerCheck: boolean;
      upperCheck: boolean;
      numberCheck: boolean;
      specialCheck: boolean;
    } = {
      minCheck: false,
      maxCheck: false,
      lowerCheck: false,
      upperCheck: false,
      numberCheck: false,
      specialCheck: false,
    };
    if (/[a-z]/.test(password)) {
      currentCheck.lowerCheck = true;
    } else {
      currentCheck.lowerCheck = false;
    }
    if (/[A-Z]/.test(password)) {
      currentCheck.upperCheck = true;
    } else {
      currentCheck.upperCheck = false;
    }
    if (/[0-9]/.test(password)) {
      currentCheck.numberCheck = true;
    } else {
      currentCheck.numberCheck = false;
    }
    if (/[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(password)) {
      currentCheck.specialCheck = true;
    } else {
      currentCheck.specialCheck = false;
    }
    if (password.length > 5) {
      currentCheck.minCheck = true;
    } else {
      currentCheck.minCheck = false;
    }
    if (password.length < 16 && password !== "") {
      currentCheck.maxCheck = true;
    } else {
      currentCheck.maxCheck = false;
    }
    setPasswordMatch({
      ...passwordMatch,
      lowerCheck: currentCheck.lowerCheck,
      upperCheck: currentCheck.upperCheck,
      numberCheck: currentCheck.numberCheck,
      specialCheck: currentCheck.specialCheck,
      minCheck: currentCheck.minCheck,
      maxCheck: currentCheck.maxCheck,
    });
  };
  const [registerData, setRegisterData] =
    useState<RegisterPayload>(registerInitial);

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };
  const togglePasswordVisible = () => {
    setPasswordShow(passwordShow ? false : true);
  };
  const registerSend = async () => {
    setisActiveValidation(true)
    const { error, isError } = registerValidator(registerData);

    if (isError) {
      SetErrorData(error);
    } else {
      try {
        reGenerateToken().then((captchaToken) => {
            //console.log("captchaToken   --> ", captchaToken);
            return registerPost(registerData, captchaToken);
          }).then((response) => {
      console.log(response?.status?.description, response);
      if (response?.status?.code === 200) {
        navigate("/verifyEmail");
      }
       else if (
        response?.status?.errors?.[0]?.emailNotFound ===
        "Email not found! Please provide valid email address"
      ) {
        toast.error(
          "Email address is not verified",
          {
            position: "top-right",
            theme: "colored",
            autoClose: 5000,
          }
        );
      }
      else if (
        response?.status?.description ===
        "User already exists for this email"
      ) {
        toast.error(
          "User already exist in the system. Please try login OR resetting the password using forgot password option from Login page.",
          {
            position: "top-right",
            theme: "colored",
            autoClose: 5000,
          }
        );
      }
      else if (
        response?.status?.errors?.[0]?.firstName ===
        "Special character not allowed"
      ) {
        toast.error(
          "Special character not allowed in first name",
          {
            position: "top-right",
            theme: "colored",
            autoClose: 5000,
          }
        );
      }
      else {
        toast.error("Please enter valid credentials", {
          position: "top-right",
          theme: "colored",
          autoClose: 5000,
        });
      }
    })
    .catch((error) => {
      console.error("Error while registering:", error);
      // Handle error
    });
} catch (error) {
  console.error("Error while registering:", error);
  // Handle error
}
}
};
  const validationOnly = (registerData: RegisterPayload) => {
    const { error } = registerValidator(registerData);
    SetErrorData(error);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="signupMid_Con">
      <ReCaptchaComponent onVerify={handleVerify} />
        <div className="signupMid_Left">
          <h2>Sign Up Now</h2>
          <h5>Want to rank higher on search engines?</h5>
          <div className="signupMid_Left-btn_Con" style={{ display: "none" }}>
            <button>
              <span>
                <img src={google} alt="google" />
              </span>
              Sign up with Google
            </button>
            <button>
              <span>
                <img src={fb} alt="facebook" />
              </span>
              Sign up with Facebook
            </button>
          </div>
          <div
            className="signupMid_Left-line_Con"
            style={{ visibility: "hidden" }}
          >
            <span></span>
            <p>or Sign up with email</p>
            <span></span>
          </div>
          <div className="signupMid_Left-input_Con">
            <div>
              <label htmlFor="" className="input-label">
                First Name
              </label>
              <input
                type="text"
                placeholder="Enter first name"
                value={registerData.firstName}
                onChange={(e) => {
                if(isValidationActive){
                  validationOnly({
                    ...registerData,
                    firstName: e.target.value,
                  });
                }
               
                  if (registerData.firstName.length > 50) return;
                  setRegisterData({
                    ...registerData,
                    firstName: e.target.value,
                  });
                }}
                style={{
                  border: `${
                    errorData?.firstName
                      ? "1px solid #FF0000"
                      : "1px solid #aaaaaa"
                  }`,
                }}
                maxLength={50}
              />
              {errorData && (
                <span className="signup__Password__Match__Con__Red">
                  {errorData?.firstName}
                </span>
              )}
            </div>
            <div>
              <label htmlFor="" className="label-last-Name">
                Last Name
              </label>
              <input
                type="text"
                placeholder="Enter last name"
                value={registerData.lastName}
                onChange={(e) => {
                  setRegisterData({
                    ...registerData,
                    lastName: e.target.value,
                  });
                }}
                maxLength={50}
                style={{ border: `1px solid #aaaaaa` }}
              />
              {errorData && (
                <span style={{ visibility: "hidden" }}>
                  {errorData?.firstName}
                </span>
              )}
            </div>
          </div>
          <div className="signupMid-input">
            <label htmlFor="" className="input-label">
              Email Address
            </label>
            <input
              type="text"
              // defaultValue={email}
              placeholder="Enter your email address"
              defaultValue={emailId}
              onChange={(e) =>{
              
                setRegisterData({ ...registerData, emailId: e.target.value })
               if(!isValidationActive) return
                validationOnly({
                  ...registerData, emailId: e.target.value
                });
              }
               
              }
              style={{
                border: `${
                  errorData?.emailId ? "1px solid #FF0000" : "1px solid #aaaaaa"
                }`,
              }}
            />
            {errorData && (
              <span className="signup__Password__Match__Con__Red">
                {errorData?.emailId}
              </span>
            )}
          </div>
          <div className="signupMid-input">
            <label htmlFor="" className="input-label">
              Password
            </label>
            <input
              type={passwordShown ? "text" : "password"}
              placeholder="*****"
              value={registerData.password}
              onChange={(e) => {
               if(isValidationActive){
                validationOnly({
                  ...registerData, password: e.target.value 
                });
               }
              
                setRegisterData({ ...registerData, password: e.target.value });
                passwordCheck(e.target.value);
              }}
              onFocus={() =>
                setPasswordMatch({ ...passwordMatch, display: true })
              }
              onBlur={() =>
                setPasswordMatch({ ...passwordMatch, display: false })
              }
              style={{
                border: `${
                  errorData?.password
                    ? "1px solid #FF0000"
                    : "1px solid #aaaaaa"
                }`,
              }}
            />
            <div>
              <img
                onClick={togglePasswordVisiblity}
                className={!passwordShown ? "Visibility-off" : ""}
                src={!passwordShown ? Visibilityoff : eye}
                style={{ cursor: "pointer" }}
                alt=""
              />
            </div>
            {errorData && (
              <span className="signup__Password__Match__Con__Red">
                {errorData?.password}
              </span>
            )}
            {((passwordMatch?.display || isValidationActive)&&!errorData?.password)   && (
              <section className="signup__Password__Match__Con">
                <p>
                  {passwordMatch.minCheck ? (
                    <img
                      src={checkmark}
                      alt=""
                      className="signup__Password__Match__Con__img"
                    />
                  ) : (
                    <img
                      src={closemark}
                      alt=""
                      className="signup__Password__Match__Con__img"
                    />
                  )}{" "}
                  <span
                    className={`${
                      passwordMatch.minCheck
                        ? "signup__Password__Match__Con__Green"
                        : "signup__Password__Match__Con__Red"
                    }`}
                  >
                    {passwordMatch.minMessage}
                  </span>{" "}
                </p>
                <p>
                  {passwordMatch.maxCheck ? (
                    <img
                      src={checkmark}
                      alt=""
                      className="signup__Password__Match__Con__img"
                    />
                  ) : (
                    <img
                      src={closemark}
                      alt=""
                      className="signup__Password__Match__Con__img"
                    />
                  )}{" "}
                  <span
                    className={`${
                      passwordMatch.maxCheck
                        ? "signup__Password__Match__Con__Green"
                        : "signup__Password__Match__Con__Red"
                    }`}
                  >
                    {passwordMatch.maxMessage}
                  </span>{" "}
                </p>
                <p>
                  {passwordMatch.lowerCheck ? (
                    <img
                      src={checkmark}
                      alt=""
                      className="signup__Password__Match__Con__img"
                    />
                  ) : (
                    <img
                      src={closemark}
                      alt=""
                      className="signup__Password__Match__Con__img"
                    />
                  )}{" "}
                  <span
                    className={`${
                      passwordMatch.lowerCheck
                        ? "signup__Password__Match__Con__Green"
                        : "signup__Password__Match__Con__Red"
                    }`}
                  >
                    {passwordMatch.lowerMessage}
                  </span>{" "}
                </p>
                <p>
                  {" "}
                  {passwordMatch.upperCheck ? (
                    <img
                      src={checkmark}
                      alt=""
                      className="signup__Password__Match__Con__img"
                    />
                  ) : (
                    <img
                      src={closemark}
                      alt=""
                      className="signup__Password__Match__Con__img"
                    />
                  )}{" "}
                  <span
                    className={`${
                      passwordMatch.upperCheck
                        ? "signup__Password__Match__Con__Green"
                        : "signup__Password__Match__Con__Red"
                    }`}
                  >
                    {passwordMatch.upperMessage}
                  </span>{" "}
                </p>
                <p>
                  {passwordMatch.numberCheck ? (
                    <img
                      src={checkmark}
                      alt=""
                      className="signup__Password__Match__Con__img"
                    />
                  ) : (
                    <img
                      src={closemark}
                      alt=""
                      className="signup__Password__Match__Con__img"
                    />
                  )}{" "}
                  <span
                    className={`${
                      passwordMatch.numberCheck
                        ? "signup__Password__Match__Con__Green"
                        : "signup__Password__Match__Con__Red"
                    }`}
                  >
                    {passwordMatch.numberMessage}
                  </span>
                </p>
                <p>
                  {passwordMatch.specialCheck ? (
                    <img
                      src={checkmark}
                      alt=""
                      className="signup__Password__Match__Con__img"
                    />
                  ) : (
                    <img
                      src={closemark}
                      alt=""
                      className="signup__Password__Match__Con__img"
                    />
                  )}{" "}
                  <span
                    className={`${
                      passwordMatch.specialCheck
                        ? "signup__Password__Match__Con__Green"
                        : "signup__Password__Match__Con__Red"
                    }`}
                  >
                    {passwordMatch.specialDisplay}
                  </span>
                </p>
              </section>
            )}
          </div>
          <div className="signupMid-input">
            <label htmlFor="" className="input-label">
              Confirm Password
            </label>
            <input
              type={passwordShow ? "text" : "password"}
              placeholder="*****"
              value={registerData.confirmPassword}
              onChange={(e) =>{
             
                setRegisterData({
                  ...registerData,
                  confirmPassword: e.target.value,
                })
                if(!isValidationActive)return 
                validationOnly({
                  ...registerData,
                  confirmPassword: e.target.value,
                });
              }
               
              }
              style={{
                border: `${
                  errorData?.confirmPassword
                    ? "1px solid #FF0000"
                    : "1px solid #aaaaaa"
                }`,
              }}
            />
            <div>
              <img
                onClick={togglePasswordVisible}
                className={!passwordShow ? "Visibility-off" : ""}
                src={!passwordShow ? Visibilityoff : eye}
                alt="visibility"
                style={{ cursor: "pointer" }}
              />
            </div>
            {errorData && (
              <span className="signup__Password__Match__Con__Red">
                {errorData?.confirmPassword}
              </span>
            )}
          </div>
          <p>
            By registering an account, you agree to our{" "}
            <a
              className="footer_link"
              href="/term-and-condition"
              target="_blank"
              rel="noopener noreferrer"
            >
              {" "}
              terms & conditions{" "}
            </a>{" "}
            and
            <a
              className="footer_link"
              href="/privacy-policy"
              target="_blank"
              rel="noopener noreferrer"
            >
              {" "}
              privacy policy.
            </a>
          </p>

          <button
            className="signup-register-btn"
            onClick={() => registerSend()}
          >
            Get Registered
          </button>
          <h4>
            Already have an account?{" "}
            <Link to="/login" className="footer_link">
              <span> Log in </span>
            </Link>
          </h4>
        </div>
        <div className="signupMid_Right">
          <img src={signupImage} alt="Signup" />
        </div>
        <ToastContainer />
      </div>
    </>
  );
};

export default SignUpMid;
