import React, { useEffect, useState, useRef } from "react";
import "./AuthorDetails.scss";
import withAuth from "../../hoc/withAuth";
import "react-toastify/dist/ReactToastify.css";
import SideHeaderAdmin from "../../components/SideHeader/SideHeaderAdmin";
import DashboardHeaderAdmin from "../../components/DashboardHeader/DashboardHeaderAdmin";
import { authorsListApi, addAuthorApi, fetchAuthorApi } from "../../utils/api/author";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import search from "../../assets/add_Websites/search.svg";
import InfiniteScroll from 'react-infinite-scroll-component';
import AppLoader from "../../components/defaultLoader/DefaultLoader";
import JoditEditor from 'jodit-react';
import info from "../../assets/add_Websites/info.svg";

const AuthorDetails = () => {
    //const dispatch = useAppDispatch();
    const tableCells = [1, 2, 3, 4, 5, 6];
    const [searchInput, setSearchInput] = useState<string>("");
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [hasMoreData, setHasMoreData] = useState<boolean>(true);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isAuthorListView, setIsAuthorListView] = useState<boolean>(true);
    const [authorsList, setAuthorsList] = useState<any>([]);
    const [authorObj, setAuthorObj] = useState<any>({});
    const editor = useRef(null);
    const [selectedFile, setSelectedFile] = useState<any>();
    const [authorName, setAuthorName] = useState<string>("");
    const [biography, setBiography] = useState<string>("");
    const [authorImageUrl, setAuthorImageUrl] = useState<string>("");
    const [authorObjId, setAuthorObjId] = useState<string>("");
    const [isEditAuthor, setIsEditAuthor] = useState<boolean>(false);
    const [authorNameError, setAuthorNameError] = useState<string>("");
    
    const getAuthorsData = async () => {
        setSearchInput('');
        const response = await authorsListApi(0, 10, undefined);
        console.log(response)
        if (response?.status?.code === 200) {
            setPageNumber(1);
            setAuthorsList(response?.data?.blog?.authors);
            setHasMoreData(response?.data?.blog?.pageInfo?.hasNext);
            setIsLoading(false);
        } else {
            setIsLoading(false);
            toast.error("Something went wrong", {
                position: "top-right",
                theme: "colored",
                autoClose: 5000,
            });
        }
    };

    const fetchMoreData = async () => {
        const response = await authorsListApi(pageNumber, 10, searchInput);
        if (response?.status?.code === 200) {
            setHasMoreData(response?.data?.blogPost?.pageInfo?.hasNext);
            const list = authorsList;
            const mergedResult = [...list, ...response?.data?.blog?.authors];
            setPageNumber(pageNumber + 1);
            setAuthorsList(mergedResult);
        }
    };

    const fetchAuthor = async (authorObj: any) => {
        console.log(authorObj);
        setIsLoading(true);
        const response = await fetchAuthorApi(authorObj.id);
        if (response?.status?.code === 200) {
            setAuthorName(authorObj.name);
            setBiography(response?.data?.blog?.author?.biography);
            setAuthorObjId(authorObj.id);
            setAuthorImageUrl(authorObj.imageUrl);
            setIsLoading(false);
            setIsAuthorListView(false);
            authorObj.biography = response?.data?.blog?.author?.biography;
            setAuthorObj(authorObj);
            setSelectedFile({});
            setIsEditAuthor(true);
            setAuthorNameError("");
        } else {
            setIsLoading(false);
            toast.error("Something went wrong", {
                position: "top-right",
                theme: "colored",
                autoClose: 5000,
            });
        }
    };

    const navigateBack = async () => {
        setIsAuthorListView(true);
    };

    const addAuthor = async () => {
        setIsAuthorListView(false);
        setAuthorName("");
        setBiography("");
        setSelectedFile({});
        setIsEditAuthor(false);
        setAuthorNameError("");
    };

    const saveAuthor = async () => {
        setIsLoading(true);
        if (!authorName) {
             setAuthorNameError("Author name is required.");
             setIsLoading(false);
             return;
        }
        authorObj.name = authorName;
        authorObj.biography = biography;
        authorObj.imageUrl = authorImageUrl;
        authorObj.id = authorObjId;
        const response = await addAuthorApi(authorObj, selectedFile);
        if (response?.status?.code === 200) {
            const msg = isEditAuthor ? "Author updated Succesfully" : "Author added Succesfully";
            toast.success(msg, {
                position: "top-right",
                theme: "colored",
                autoClose: 5000,
            });
            getAuthorsData();
            setIsAuthorListView(true);
        } else {
            setIsLoading(false);
            toast.error("Something went wrong", {
                position: "top-right",
                theme: "colored",
                autoClose: 5000,
            });
        }
    };


    const searchCustomerData = async () => {
        setPageNumber(0);
        const response = await authorsListApi(0, 10, searchInput);
        if (response?.status?.code === 200) {
            setPageNumber(1);
            setAuthorsList(response?.data?.blog?.authors);
            setHasMoreData(response?.data?.blog?.pageInfo?.hasNext);
            setIsLoading(false);
        } else {
            setIsLoading(false);
            toast.error("Something went wrong", {
                position: "top-right",
                theme: "colored",
                autoClose: 5000,
            });
        }
        //   getCustomerDataCustom(verifiedFilter, roleFilter, searchInput);
    };



    const clearSearchCustomerData = () => {
        setSearchInput('');
        setIsLoading(true);
        getAuthorsData();
    };

    const setSearchCustomerData = (e: any) => {
     //   var inputType = e.nativeEvent.inputType;
    //    if (inputType !== 'deleteContentForward' && inputType !== 'deleteContentBackward') {
            setSearchInput(e.target.value);
     //   }
    };

    const setDescription = (val: any) => {
        setBiography(val);
    }

    const handleFileChange = (e: any) => {
        if (e.target.files) {
            const fileSize = e.target.files[0].size;
            const fileMb = fileSize / 1024 ** 2;
            if (fileMb >= 2) {
                toast.error("Please select a file less than 2MB.", {
                    position: "top-right",
                    theme: "colored",
                    autoClose: 5000,
                });
                return;
            }
            setSelectedFile(e.target.files[0]);
        }
    };

    const joditConfig = {
        readonly: false,
        autofocus: false,
        tabIndex: 1,
        askBeforePasteHTML: false,
        askBeforePasteFromWord: false,
        placeholder: 'Please enter author’s biography',
        beautyHTML: true,
        height: 350,
        width: 1350,
    }

    useEffect(() => {
        getAuthorsData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (

        <div className={`dashboard_Con   `}>
            <SideHeaderAdmin />

            <div className={`dashboard_Con__Right`}>
                <DashboardHeaderAdmin />
                <div className="publisherWebsiteMid__Con">
                    {isLoading && <AppLoader size="lg" ></AppLoader>}
                    {isAuthorListView &&
                        <div className="pubLisherAddWebsite__Con">
                            <h4>Author’s Biography
                                <button className="paymentDetailsBtn marginLeft3" onClick={() => addAuthor()} >
                                    Add Author
                                </button>
                                <div className="searchDiv floatRight">
                                    <input
                                        className="searchMarketPlace"
                                        type="text"
                                        value={searchInput}
                                        placeholder='Search Author'
                                        onChange={(e: any) => setSearchCustomerData(e)}
                                    />
                                    {searchInput !== '' && <img className="search-img" src={search} alt="" onClick={() => { searchCustomerData(); }} />}
                                    {searchInput !== '' && <span className="material-icons lightGrey close-img" onClick={() => { clearSearchCustomerData(); }}>close</span>}
                                </div>
                            </h4>
                            <div className="PublisherSummaryTable__Con noPadding">
                                <section className="PublisherSummaryTable__Con__Table__Header noMargin">
                                    <article>
                                        <p className="profile_img_align">Profile Pic</p>
                                        <p>Author’s Name</p>
                                        <h6>Action</h6>
                                    </article>
                                    <InfiniteScroll
                                        dataLength={authorsList.length}
                                        next={fetchMoreData}
                                        hasMore={hasMoreData}
                                        loader={''}
                                        height={'calc(62vh - 10px)'}
                                    >
                                        {authorsList.length !== 0 &&
                                            authorsList?.map((cell: any, index: number) => (
                                                <div
                                                    className={`PublisherSummaryTable__Con__Table__Cell ${index % 2 === 0
                                                        ? "PublisherSummaryTable__Con__Table__Cell__grey__bg"
                                                        : ""
                                                        } ${index === tableCells.length - 1
                                                            ? "PublisherSummaryTable__Con__Table__Cell__last__border"
                                                            : ""
                                                        } `}
                                                    key={index}
                                                >

                                                    <p className="WebsiteUrlPublisherSummaryTable profile_img_align">
                                                        <img className="profile_pic_img" src={cell.imageUrl} alt="" />
                                                    </p>

                                                    <p>{(cell?.name)}</p>
                                                    <h6>
                                                        {/* {!cell.isAddedInCart && <span onClick={() => fetchCustomerWebsite(cell)}> */}
                                                        <span onClick={() => fetchAuthor(cell)} >Edit</span>
                                                    </h6>

                                                </div>

                                            ))}
                                    </InfiniteScroll>
                                </section>

                            </div>
                        </div>}
                    {!isAuthorListView &&

                        <div className="pubLisherAddWebsite__Con height100">
                            <h4> {isEditAuthor && <>Edit Author</>} {!isEditAuthor && <>Add Author</>}
                                <button className="paymentDetailsBtn floatRight backButtonblue" onClick={() => navigateBack()} >
                                    Back
                                </button>
                            </h4>
                            <div className="PublisherSummaryTable__Con noPadding fontSize20 height100">
                                <div className="PublisherSummaryTable__Con__Table__Header noMargin addBlogPadding height100">


                                    <div className="width100 displayFlex rowDiv">
                                        <div className="width150px"> Author Name<span className="red">*</span></div> <div className="width80"> <input
                                            type="text"
                                            placeholder="Enter name"
                                            value={authorName}
                                            className="addBlogInput"
                                            onChange={(e: any) => setAuthorName(e.target.value)}
                                        />
                                        {authorNameError && <p className="blog_error">
                                            {" "}
                                            <img src={info} alt="" />{" "}
                                            <span>{authorNameError}</span>{" "}
                                        </p>
                                        }
                                        </div>
                                    </div>

                                    <div className="width100 displayFlex rowDiv">
                                        <div className="width150px">  Upload Image
                                        </div> <div className="width80 ">
                                            {/* <input type="text"
                                    placeholder="Select Image"
                                    value={blogObj.imageUrl}
                                    className="addBlogInput"
                                    onChange={(e: any) =>
                                        setBlogObj({
                                        ...blogObj,
                                        imageUrl: e.target.value,
                                        })
                                    }
                                /> */}
                                            <input type="file" id="myfile" name="myfile" placeholder="Drop your file here" value={""}
                                                onChange={(e) => { handleFileChange(e) }} />
                                            {selectedFile &&
                                                <span>{selectedFile?.name} </span>}

                                        </div>
                                    </div>


                                    <div className="width100 displayFlex rowDiv">
                                        Biography  </div>
                                    <div className="height100">
                                        < JoditEditor
                                            ref={editor}
                                            value={authorObj.biography}
                                            config={joditConfig}
                                            // tabIndex of textarea
                                            onBlur={newContent => setDescription(newContent)} // preferred to use only this option to update the content for performance reasons
                                            onChange={newContent => { }}

                                        />

                                    </div>
                                    <div className="width100 rowDiv center">
                                        <button className="paymentDetailsBtn marginLeft3 backButtonblue test" onClick={() => saveAuthor()} >
                                            Save
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                    }
                </div>
                <ToastContainer />
            </div>
        </div>
    );
};
export default withAuth(AuthorDetails);
