import React, { useEffect, useRef, useState } from "react";
import "./dashboardHeader.scss";
// import notification from "../../assets/dashboardHeader/notification.svg";
// import mail from "../../assets/dashboardHeader/mail.svg";
import avatar from "../../assets/dashboardHeader/avatat.svg";
import avatarLogout from "../../assets/dashboardHeader/avatar-logout.svg";
import calender from "../../assets/dashboardHeader/calendar.svg";
import text from "../../assets/dashboardHeader/text.svg";
import { useAppDispatch } from "../../store/reduxHooks";
import { logged } from "../../reducers/login/loginReducer";
import { useNavigate } from "react-router-dom";
//import { accessToken } from "../../utils/api/sessionStorageHandler";
import { bagCount } from "../../utils/api/marketPlace";
import { useAppSelector } from "../../store/reduxHooks";
// import { Link } from "react-router-dom";
// import { getUserStats } from "../../utils/api/dashboardStats";

const DashboardHeader = () => {
  let navigate = useNavigate();
  const [isLogoutDisplay, setIsLogoutDisplay] = useState<boolean>(false);
  const [isOutsideClicked, setIsOutsideClicked] = useState<boolean>(false);
  const [wishCount, setWishCount] = useState<number>(0);
  const [cartCount, setCartCount] = useState<number>(0);
  const roleTypeSession = sessionStorage.getItem("roleType");
  const roleType = roleTypeSession === undefined ? 'Publisher' : roleTypeSession;
  //const [advertiser, setAdverTiser] = useState<string>(''+roleType);
  const outSideEvent = useRef<any>(null);
  const {  customerProfile } = useAppSelector((state) => ({
    customerProfile: state?.login?.customerProfile,
  }));

  const getBagCount = async (type : string) => {
       
       const response = await bagCount(type );
       if (response?.status?.code === 200 ) {
         console.log(type,"   count  ",response)
        if(type === 'WISH'){
          setWishCount(response?.data?.bag?.count);
        }else{
          setCartCount(response?.data?.bag?.count);
        }
       }
     };

  // useEffect(() => {
  //   getUserStatsData();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  useEffect(() => {
    const outSideCheck = (e: any) => {
      if (
        document
          .getElementById("dashboard__Header__Logout__Con")
          ?.contains(e.target) ||
        document
          .getElementById("dashboard__Header__UserInfo__Con")
          ?.contains(e.target)
      ) {
        setIsOutsideClicked(false);
      } else {
        setIsOutsideClicked(true);
        setIsLogoutDisplay(false);
      }
    };
    outSideEvent.current = outSideCheck;
  }, []);

  useEffect(() => {
    if (outSideEvent.current) {
      if (isLogoutDisplay) {
        window.addEventListener("click", outSideEvent.current);
      } else if (!isLogoutDisplay) {
        window.removeEventListener("click", outSideEvent.current);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLogoutDisplay]);

  useEffect(() => {
    getBagCount("WISH");
    getBagCount("CART");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dispatch = useAppDispatch();

  const logout = () => {
    dispatch(logged(false));
  };

  const navigateToPublisherDashboard = () => {
    sessionStorage.setItem("roleType", "Publisher");
    navigate("/dashboard");
  };

  const navigateToAdvertiserDashboard = () => {
    sessionStorage.setItem("roleType", "Advertiser");
    navigate("/dashboard");
  };

  const navigateToMyAccountPage = () => {
    sessionStorage.setItem("roleType", "Advertiser");
    navigate("/myAccount");
  };
  
  const getRoleType = () => {
    const role = customerProfile?.role;
    if(role){
      if(role === 'ADVERTISER'){
        return 'Advertiser';
      }else if(role === 'PUBLISHER'){
        return 'Publisher';
      }else if (role === 'PUBADV'){
        return 'Advertiser/Publisher';
      }else{
        return '';
      }
    }else{
      return '';
    }
  };

  return (
    <div className="dashboard__Header__Con">
      <div className="dashboard__Header__Con__Left">
        <div className="advertiser__Toggle__Con">
          <button
            className={`${
              roleType === "Advertiser"
                ? "dashboard__Header_toggle__green"
                : "dashboard__Header_toggle__white"
            }`}
           
            // onClick={() => {setAdverTiser("Advertiser");navigateToAdvertiserDashboard();}}
            onClick={() => {navigateToAdvertiserDashboard();}}
          >
            Advertiser
          </button>
          <button
            className={`${
              roleType === "Publisher"
                ? "dashboard__Header_toggle__green"
                : "dashboard__Header_toggle__white"
            }`}
            //  onClick={() => {setAdverTiser("Publisher");navigateToPublisherDashboard();}}
            onClick={() => {navigateToPublisherDashboard();}}
          >
            Publisher
          </button>
        </div>
        {/* <Link to="/dashboard/publisherWebsites" className="addWebSite-btn">
          Add Website
        </Link> */}
      </div>
      <div className="dashboard__Header__Con__Right">
       {roleType === 'Advertiser' && 
       <div>
        <div className="button">
            <a href="/dashboard/cart" className="material-icons lightGrey textDecorationNone">shopping_cart</a>
            <span className={`${cartCount > 0 ? 'button__badge show' : 'button__badge hide'}`} id="cartCount">{cartCount}</span>
          </div>
          <div className="button">
            <a href="/dashboard/wishlist" className="material-icons lightGrey textDecorationNone">favorite</a>
             <span className={`${wishCount > 0 ? 'button__badge show' : 'button__badge hide'}`} id="wishCount" >{wishCount}</span>
          </div>
        </div>
       }
        {/* <div className="button">
          <img src={notification} alt="" />
        </div>
        <div className="button">
          <img src={mail} alt="" />
        </div> */}
        <img
          src={avatar}
          alt=""
        
        />
        <div
          className="dashboard__Header__UserInfo__Con"
          id="dashboard__Header__UserInfo__Con"
          onClick={(e) => {
            setIsLogoutDisplay(!isLogoutDisplay);
          }}
        >
          <h4>{customerProfile?.title}</h4>
          <p>{getRoleType()}</p>
        </div>
      </div>
      <div
        className="dashboard__Header__Logout__Con"
        id="dashboard__Header__Logout__Con"
        style={{
          display: `${isLogoutDisplay && !isOutsideClicked ? "flex" : "none"}`,
        }}
      >
        <img src={avatarLogout} alt="" className="avatar_Logout" />
        <h4>{}</h4>
        <div className="dashboard__Header__Logout__mid">
          <img src={calender} alt="" />
          <img src={text} alt="" />
        </div>

        <p onClick={() => navigateToMyAccountPage()}>My Account</p>
        <p onClick={() => logout()}>Logout</p>
      </div>
    </div>
  );
};

export default DashboardHeader;
