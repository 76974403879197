import React, { useEffect, useState } from "react";
import "./billing.scss";
import { getDateFormatted,getBillingStatus} from "../../utils/filters/nameFilters";
import { fetchAdvertiserBillingList } from "../../utils/api/billing";
import SideHeader from "../../components/SideHeader/SideHeader";
import DashboardHeader from "../../components/DashboardHeader/DashboardHeader";
import AppLoader from "../../components/defaultLoader/DefaultLoader";
import { ToastContainer } from "react-toastify";

const BillingComp = () => {
  const tableCells = [1, 2, 3, 4, 5, 6];
  const [invoiceList, setInvoiceList] = useState<any>([]);
  const roleType = sessionStorage.getItem("roleType");
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const fetchWishlist = async () => {
    const response = await fetchAdvertiserBillingList();
    if (response?.status?.code === 200) {
      setInvoiceList(response?.data?.payment?.invoices);
      setIsLoading(false);
    }
  };



  useEffect(() => {
    fetchWishlist();
  }, []);

  return (
    <div className="dashboard_Con">
      <ToastContainer />
      {isLoading && <AppLoader size="lg" ></AppLoader>}
      <SideHeader roleType={roleType} />
      <div className="dashboard_Con__Right">
        <DashboardHeader  />
        <div className="publisherWebsiteMid__Con">
          <div className="PublisherSummaryTable__Con">

            <section className="PublisherSummaryTable__Con__Inner">
              <header className="PublisherSummaryTable__Con__Header">
                <p>Billing</p>
              </header>
            </section>

            <section className="PublisherSummaryTable__Con__Table__Header">
              <article>
                <h5>Website URL</h5>
                <p>Order Date</p>
                <p>Invoice Number</p>
                <h5>Description</h5>
                <p>Invoice Date</p>
                <p>Amount ($)</p>
                <p>Payment Date</p>
                <p>Status</p>
                <h6>Invoice</h6>
              </article>
              {invoiceList.length !== 0 &&
                invoiceList?.map((cell: any, index: number) => (
                  <div
                    className={`PublisherSummaryTable__Con__Table__Cell ${index % 2 === 0
                        ? "PublisherSummaryTable__Con__Table__Cell__grey__bg"
                        : ""
                      } ${index === tableCells.length - 1
                        ? "PublisherSummaryTable__Con__Table__Cell__last__border"
                        : ""
                      } `}
                    key={index}
                  >
                    <a
                      href={cell?.websiteUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <p style={{ textAlign: 'left' }} className="WebsiteUrlPublisherSummaryTable">{cell?.websiteUrl}</p>
                    </a>
                    <p>{getDateFormatted(cell?.order?.createdDate)}</p>
                    <p>{(cell?.invoiceNumber)}</p>
                    <h5>{(cell?.description)}</h5>
                    <p>{getDateFormatted(cell?.invoiceDate)}</p>
                    <p>{(cell?.order?.priceWithCommission)}</p>
                   
                    <p>{getDateFormatted(cell?.order?.updatedDate)}</p>
                    <p>{getBillingStatus(cell?.status)}</p>
                    <h6>
                    <span>
                      <a href={cell?.invoiceUrl} target="_blank" rel="noopener noreferrer"
                       className="material-icons lightGrey textDecorationNone fontsize18" title="View/Download Invoice">download</a>
                     </span>
                    </h6>

                  </div>
                ))}
            </section>
          </div>
          <p className="publisherWebsiteMid__Footer">
        © 2024 Powered By Outreach Kart | All Rights Reserved
      </p>
        </div>

      </div>

    </div>
  );
};

export default BillingComp;
