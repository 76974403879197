import "./loginnow.scss";
//import Footer from "../../components/footer/Footer";
import Header from "../../components/Header";
import Login from "../../components/login/Login";
import { loggedTrue } from "../../hoc/loggedTrue";
const LoginNow = () => {
  return (
    <div className="login-bg">
      <Header />
      <Login />
      {/* <div className="footer-bg">
        <Footer />
      </div> */}
    </div>
  );
};

export default loggedTrue(LoginNow);
