import "./footer.scss";
import fb from "../../assets/footer/fb.svg";
import insta from "../../assets/footer/insta.svg";
import yt from "../../assets/footer/yt.svg";
import { Link } from "react-router-dom"; 
const Footer = () => {
  return (
    <div className="footer_con">
      <div className="footer_content">
        <div className="footer_header">
          <p className="footer_header-title" >Services</p>
          <p  ><Link to="/login" className="footer-text"> Buy Guest Post</Link></p> <p><Link to="/contactUs" className="footer-text">Write For Us</Link></p> <p><Link to="/contactUs" className="footer-text">Hire Content Writer</Link></p>
          <p><Link to="/login" className="footer-text">Become A Seller</Link></p>
        </div>
        <div className="footer_header hidden">
          <p className="footer_header-title" >Company</p>
          <p> <Link to="/about" className="footer-text">About Us</Link></p> <p><Link to="/about" className="footer-text">Our Team</Link></p> <p><Link to="/contactUs" className="footer-text">Contact</Link></p>
        </div>
        <div className="footer_header hidden">
          <p className="footer_header-title" >Legal</p>
          <p><a href="https://outreachkart.com/terms-conditions/" className="footer-text">Terms & Conditions</a></p>
           <p><a href="https://outreachkart.com/privacy-policy/" className="footer-text">Privacy Policy</a></p> 
           <p><a href="https://outreachkart.com/refund-policy/" className="footer-text" >Refund Policy </a></p>
        </div>
        <div className="footer_header">
          <p className="footer_header-title" >Contact</p>
          <p><a href="https://outreachkart.com/about-us/" className="footer-text">sales@outreachkart.com</a></p>
          <span>
          <div className="footer-text">
          <a href="https://www.facebook.com/outreachkart" target="_blank" rel="noopener noreferrer">
            <img src={yt} alt="" /> 
            </a>
            <a href="https://www.instagram.com/outreach.kart.official" target="_blank" rel="noopener noreferrer">
            <img src={insta} alt="" />{" "}
            </a>
            <a href="https://www.linkedin.com/company/outreachkart" target="_blank" rel="noopener noreferrer">
            <img src={fb} alt="" /></a>
            </div>
          </span>
        </div>
      </div>
      <div className="footer_text">
        <p>© 2024 Powered By Outreach Kart | All Rights Reserved</p>
      </div>
    </div>
  );
};

export default Footer;
