import React, { useEffect, useMemo, useRef, useState } from "react";
import "./sideHeaderAdmin.scss";
import sidebarLogo from "../../assets/sidebar/logo.svg";
import down from "../../assets/sidebar/down.svg";
import cross from "../../assets/dashboardHeader/cross.svg";
import open from "../../assets/dashboardHeader/open.svg";
import { menuItem } from "./sideMenuItemAdmin";
import { MenuItem } from "../../utils/types/main";
import notification from "../../assets/dashboardHeader/notification.svg";
import mail from "../../assets/dashboardHeader/mail.svg";
import avatar from "../../assets/dashboardHeader/avatat.svg";
import { logged } from "../../reducers/login/loginReducer";
import { useAppDispatch, useAppSelector } from "../../store/reduxHooks";
import avatarLogout from "../../assets/dashboardHeader/avatar-logout.svg";
import calender from "../../assets/dashboardHeader/calendar.svg";
import text from "../../assets/dashboardHeader/text.svg";
import { Link, useLocation } from "react-router-dom";
import { adminPathSet } from "../../utils/filters/websitePathSet";
import {
  setAdminWebsiteDetails,
  setTaskboardDisplay,
} from "../../reducers/admin/adminReducer";
import {
  getAdminWebsiteCount,
  getAdminWebsiteDetails,
} from "../../utils/api/adminWebsite";
const SideHeaderAdmin = () => {
  const [menuDisplay, setMenuDisplay] = useState(false);
  const [isLogoutDisplay, setIsLogoutDisplay] = useState<boolean>(false);
  const [isOutsideClicked, setIsOutsideClicked] = useState<boolean>(false);

  const outSideEvent = useRef<any>(null);
  const location = useLocation();
  const selectedMenu = useMemo(
    () => adminPathSet(location.pathname),
    [location.pathname]
  );
  const { taskboardDisplay, adminWebsiteDetails } = useAppSelector((state) => ({
    taskboardDisplay: state.adminState.taskboardDisplay,
    adminWebsiteDetails: state?.adminState?.adminWebsiteDetails,
  }));

  useEffect(() => {
    const outSideCheck = (e: any) => {
      if (
        document
          .getElementById("dashboard__Header__Logout__Con")
          ?.contains(e.target) ||
        document.getElementById("mobile_avatar")?.contains(e.target)
      ) {
        console.log("clicked-inside");
        setIsOutsideClicked(false);
      } else {
        console.log("outside");
        setIsOutsideClicked(true);
        setIsLogoutDisplay(false);
      }
    };
    outSideEvent.current = outSideCheck;
  }, []);

  useEffect(() => {
    if (outSideEvent.current) {
      if (isLogoutDisplay) {
        console.log("added", isLogoutDisplay);
        window.addEventListener("click", outSideEvent.current);
      } else if (!isLogoutDisplay) {
        console.log("removed", isLogoutDisplay);
        window.removeEventListener("click", outSideEvent.current);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLogoutDisplay]);

  const dispatch = useAppDispatch();

  const logout = () => {
    dispatch(logged(false));
  };

  const handleTaskBoardClick = () => {
    dispatch(
      setTaskboardDisplay(taskboardDisplay)
    );
  };

  const otherMenuItemClick = () => {
    dispatch(
      setTaskboardDisplay({
        isActive: false,
        total: 0,
        new: 0,
        edit: 0,
        selected: "New",
        isReload: false
      })
    );
  };
  const adminWebsiteFilter = async (isNew: boolean = true) => {
    dispatch(
      setAdminWebsiteDetails({ ...adminWebsiteDetails, isLoading: true })
    );
    const response = await getAdminWebsiteDetails(
      0,
      10,
      isNew ? "NEW_APPROVAL" : "EDIT_APPROVAL"
    );

    if (response?.status?.code === 200) {
      dispatch(
        setAdminWebsiteDetails({
          ...adminWebsiteDetails,
          isLoading: false,
          pageCount: 0,
          data: response?.data?.websiteApprovalData?.websites || [],
          pageInfo: response?.data?.websiteApprovalData?.pageInfo || {
            hasNext: false,
            totalCount: 0,
          },
          isNewWebsiteApproval: isNew,
        })
      );
    } else {
      dispatch(
        setAdminWebsiteDetails({
          ...adminWebsiteDetails,
          isLoading: false,
          data: [],
          pageInfo: {
            hasNext: false,
            totalCount: 0,
          },
        })
      );
    }
  };

  const initialCountSet = async () => {
    const responseCount = await getAdminWebsiteCount();
    if (responseCount?.status?.code === 200) {
      dispatch(
        setTaskboardDisplay({
          ...taskboardDisplay,
          isActive: true,
          selected: "New",
          total: responseCount?.data?.websiteApprovalData?.totalPendingApproval,
          edit: responseCount?.data?.websiteApprovalData?.totalEditApproval,
          new: responseCount?.data?.websiteApprovalData?.totalNewApproval,
          isReload: false
        })
      );
    } else {
      dispatch(
        setTaskboardDisplay({
          ...taskboardDisplay,
          isActive: true,
          selected: "New",
        })
      );
    }
  };

  useEffect(() => {
    if (location?.pathname === "/admin/taskboard") {
      initialCountSet();
      adminWebsiteFilter();
    } else {
      dispatch(
        setTaskboardDisplay({
          ...taskboardDisplay,
          isActive: false,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return (
    <div className="sideBar__Con">
      <div className="sideBar__Logo">
        <img
          src={menuDisplay ? cross : open}
          alt=""
          onClick={() => setMenuDisplay(!menuDisplay)}
          className="toggleMenu__Icon"
        />

        <Link to="/admin/dashboard">
          <img src={sidebarLogo} alt="" className="outReach__Logo" />
        </Link>
        <div className="sideHeader__userLogo__Con">
          <img src={notification} alt="" />
          <img src={mail} alt="" />
          <img
            src={avatar}
            alt=""
            onClick={(e) => {
              setIsLogoutDisplay(!isLogoutDisplay);
            }}
            id="mobile_avatar"
          />
          <div
            className="dashboard__Header__Logout__Con"
            id="dashboard__Header__Logout__Con"
            style={{
              display: `${isLogoutDisplay && !isOutsideClicked ? "flex" : "none"
                }`,
            }}
          >
            <img src={avatarLogout} alt="" className="avatar_Logout" />
            <h4>Austin Robertson</h4>
            <div className="dashboard__Header__Logout__mid">
              <img src={calender} alt="" />
              <img src={text} alt="" />
            </div>

            <p>My Account</p>
            <p onClick={() => logout()}>Logout</p>
          </div>
        </div>
      </div>
      <div className="borderLine"></div>

      <div
        className="menu__Con"
        style={{
          display: `${menuDisplay ? "flex" : "none"}`,
        }}
      >
        {menuItem.map((item: MenuItem, index: number) => {
          if (item.title === "Taskboard") {
            return (
              <div
                className="admin__SideHeader__Taskboared__Menu__Con"
                key={index}
              >
                <Link
                  to={item.link}
                  className={`menu__Item ${selectedMenu === item.title
                    ? "sideHeader__Menu__Selected"
                    : ""
                    }`}
                >
                  <p>
                    <img src={item.image} alt="" />
                    <span>{item.title}</span>
                  </p>

                  <img src={item.next} alt="" />
                </Link>
                <section className="admin__SideHeader__Taskboared__Menu">
                  <p>
                    {" "}
                    <span>New Website Approval</span>
                  </p>
                  <p>
                    {" "}
                    <span>Edit Website Approval</span>
                  </p>
                </section>
              </div>
            );
          } else {
            return (
              <Link
                to={item.link}
                className={`menu__Item ${selectedMenu === item.title
                  ? "sideHeader__Menu__Selected"
                  : ""
                  }`}
                key={index}
              >
                <p>
                  <img src={item.image} alt="" />
                  <span>{item.title}</span>
                </p>

                <img src={item.next} alt="" />
              </Link>
            );
          }
        })}
      </div>

      <div className="menu__Con">
        {menuItem.map((item: MenuItem, index: number) => {
          if (item.title === "Taskboard") {
            return (
              <div
                className="admin__SideHeader__Taskboared__Menu__Con"
                key={index}
              >
                <Link
                  to={item.link}
                  className={`menu__Item ${selectedMenu === item.title
                    ? "sideHeader__Menu__Selected"
                    : ""
                    }`}
                  reloadDocument
                  onClick={() => handleTaskBoardClick()}
                >
                  <p>
                    <img src={item.image} alt="" />
                    <span>{item.title}</span>
                    {taskboardDisplay?.isActive && (
                      <span className="admin__SideHeader__Taskboared__Total_Count">
                        {taskboardDisplay?.total || 0}
                      </span>
                    )}
                  </p>

                  {taskboardDisplay?.isActive ? (
                    <img src={down} alt="" />
                  ) : (
                    <img src={item.next} alt="" />
                  )}
                </Link>
                {taskboardDisplay?.isActive && (
                  <section className="admin__SideHeader__Taskboared__Menu">
                    <p
                      className={`${taskboardDisplay?.selected === "New"
                        ? "admin__SideHeader__Taskboared__Menu__Item"
                        : "admin__SideHeader__Taskboared__Menu__Item__Inactive"
                        } `}
                      onClick={() => {
                        dispatch(
                          setTaskboardDisplay({
                            ...taskboardDisplay,
                            selected: "New",
                            isReload: true
                          })
                        );

                        adminWebsiteFilter();
                      }}
                    >
                      {" "}
                      <span>
                        {taskboardDisplay?.new || 0}
                      </span>{" "}
                      <span>New Website Approval</span>
                    </p>
                    <p
                      className={`${taskboardDisplay?.selected === "Edit"
                        ? "admin__SideHeader__Taskboared__Menu__Item"
                        : "admin__SideHeader__Taskboared__Menu__Item__Inactive"
                        } `}
                      onClick={() => {
                        dispatch(
                          setTaskboardDisplay({
                            ...taskboardDisplay,
                            selected: "Edit",
                            isReload: true
                          })
                        );

                        adminWebsiteFilter(false);
                      }}
                    >
                      {" "}
                      <span>
                        {" "}
                        {taskboardDisplay?.edit || 0}
                      </span>{" "}
                      <span>Edit Website Approval</span>
                    </p>
                  </section>
                )}
              </div>
            );
          } else {
            return (
              <Link
                onClick={() => otherMenuItemClick()}
                to={item.link}
                className={`menu__Item ${selectedMenu === item.title
                  ? "sideHeader__Menu__Selected"
                  : ""
                  }`}
                key={index}
                reloadDocument
              >
                <p>
                  <img src={item.image} alt="" />
                  <span>{item.title}</span>
                </p>

                <img src={item.next} alt="" />
              </Link>
            );
          }
        })}
      </div>
    </div>
  );
};

export default SideHeaderAdmin;
