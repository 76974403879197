import dashboard from "../../assets/sidebar/dashboard.svg";
import website from "../../assets/sidebar/websites.svg";

import arrow from "../../assets/sidebar/Shape.svg";
import { MenuItem } from "../../utils/types/main";

export const menuItem: MenuItem[] = [
  {
    title: "Dashboard",
    image: dashboard,
    next: arrow,
    link: "/admin/dashboard",
  },
  {
    title: "Taskboard",
    image: website,
    next: arrow,
    link: "/admin/taskboard",
  },
  {
    title: "Add Website",
    image: dashboard,
    next: arrow,
    link: "/admin/addWebsite",
  },
  {
    title: "Order Management",
    image: dashboard,
    next: arrow,
    link: "/admin/orders/all",
  },
  {
    title: "Customer Details",
    image: dashboard,
    next: arrow,
    link: "/admin/customerDetails/all",
  },
  // {
  //   title: "Blog Management",
  //   image: dashboard,
  //   next: arrow,
  //   link: "/admin/blogManagement",
  // },
  // {
  //   title: "Author’s Biography",
  //   image: dashboard,
  //   next: arrow,
  //   link: "/admin/authors",
  // },
  {
    title: "Billing Management",
    image: dashboard,
    next: arrow,
    link: "/admin/billing",
  },
  {
    title: "Marketplace",
    image: website,
    next: arrow,
    link: "/admin/marketPlace",
  },
  {
    title: "All Websites",
    image: website,
    next: arrow,
    link: "/admin/allWebsites/All",
  } 
];
