import React from "react";
import { inActivateCustomerWebsite,activateCustomerWebsite } from "../../utils/api/addWebsite";
import { toast } from "react-toastify";
import { useAppDispatch } from "../../store/reduxHooks";
import { setActiveSuccess } from "../../reducers/addWebsite/addWebsitedataReducer";
type Props = {
  setInactiveData: React.Dispatch<
    React.SetStateAction<{
      display: boolean;
      id: string;
      isActivateAction:boolean;
    }>
  >;
  inactiveData: {
    display: boolean;
    id: string;
    isActivateAction:boolean;
  };
};

const PublisherActivePopup: React.FC<Props> = ({
  setInactiveData,
  inactiveData,
}) => {
  const dispatch = useAppDispatch();
  const handleYes = async () => {
    setInactiveData({ ...inactiveData, display: false });
    // const response = await activateCustomerWebsite(inactiveData?.id);
    const response = await inActivateCustomerWebsite(inactiveData?.id);
    if (response?.status?.code === 200) {
      toast.success("Website inactivated successfully", {
        position: "top-right",
        theme: "colored",
        autoClose: 5000,
      });
      dispatch(setActiveSuccess(true))
    } else {
      toast.error(
        response?.status?.errors?.[0]?.websiteNotFound ||
        "Something went wrong",
        {
          position: "top-right",
          theme: "colored",
          autoClose: 5000,
        }
      );
      dispatch(setActiveSuccess(true))
    }
  };

  const handleActivate = async () => {
    setInactiveData({ ...inactiveData, display: false });
    // const response = await activateCustomerWebsite(inactiveData?.id);
    const response = await activateCustomerWebsite(inactiveData?.id);
    if (response?.status?.code === 200) {
      toast.success("Website activated successfully", {
        position: "top-right",
        theme: "colored",
        autoClose: 5000,
      });
      dispatch(setActiveSuccess(true))
    } else {
      toast.error(
        response?.status?.errors?.[0]?.websiteNotFound ||
        "Something went wrong",
        {
          position: "top-right",
          theme: "colored",
          autoClose: 5000,
        }
      );
      dispatch(setActiveSuccess(true))
    }
  };

  const handleNo = () => {
    setInactiveData({ ...inactiveData, display: false });
  };

  return (
    <div className="publisherDeletePopup__Con__out">
      {!inactiveData.isActivateAction && <div className="publisherDeletePopup__Con__in">
        <p>Are you sure you want to inactive this website?</p>
        <section className="publisherDeletePopup__button_Con">
          <button
            className="publisherDeletePopup__button--Yes"
            onClick={() => handleYes()}
          >
            Yes
          </button>
          <button
            className="publisherDeletePopup__button--No"
            onClick={() => handleNo()}
          >
            No
          </button>
        </section>
      </div>
      }
       {inactiveData.isActivateAction && <div className="publisherDeletePopup__Con__in">
        <p>Are you sure you want to activate this website?</p>
        <section className="publisherDeletePopup__button_Con">
          <button
            className="publisherDeletePopup__button--Yes"
            onClick={() => handleActivate()}
          >
            Yes
          </button>
          <button
            className="publisherDeletePopup__button--No"
            onClick={() => handleNo()}
          >
            No
          </button>
        </section>
      </div>
      }
    </div>
  );
};

export default PublisherActivePopup;
