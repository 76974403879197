import { useEffect,useState } from "react";
import "./blogSummary.scss";
import { fetchBlogFromPermlink,fetchCategoriesList } from "../../utils/api/blogpost";
import parse from 'html-react-parser';
import { CardMedia } from "@mui/material";
import AppLoader from "../defaultLoader/DefaultLoader";
import { fetchAuthorApi } from "../../utils/api/author";
import moment from "moment";

const BlogSummary = (data:any) => {

  const [blogDescription, setBlogDescription] = useState<string>("");
  const [blogTitle, setBlogTitle] = useState<string>("");
  const [imgUrl, setImgUrl] = useState<string>("");
  const [blogItem, setBlogItem] = useState<any>({});
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [blogCategoriesMap, setBlogCategoriesMap] = useState(new Map());
  const [authorDetailsObj, setAuthorDetailsObj] = useState<any>({});
  const [isLoadingError, setIsLoadingError] = useState<boolean>(true);

  const fetchBlog = async (blogObj:any) => {
    
    const response = await fetchBlogFromPermlink( blogObj?.data);
    console.log("response   -->>>  ",response);
      if (response?.status?.code === 200) {
       // console.log(response?.data);
        setBlogDescription(response?.data?.blog?.blogDetail?.description);
        setBlogTitle(response?.data?.blog?.blogDetail?.title);
        setBlogItem(response?.data?.blog?.blogDetail);
        fetchAuthor(response?.data?.blog?.blogDetail?.author);
        setImgUrl(response?.data?.blog?.blogDetail?.imageUrl);
        setIsLoading(false);
        setIsLoadingError(false);
      }else{
        setIsLoading(false);
        setIsLoadingError(true);
        setBlogTitle("There is some technical issue. Please visit this page later.");
      }
    }

    const categoriesList = async () => {
      const response = await fetchCategoriesList();
      if (response?.status?.code === 200) {
        const map = new Map();
        response?.data?.blog?.blogCategories.forEach((obj: { id: any; title: any; }) => {
          map.set(obj.id, obj.title);
        });
        setBlogCategoriesMap(map);
      } else {
        setIsLoading(false);
      }
    }

    const getCategoryName = (id: String) => {
      return blogCategoriesMap?.get(id)
    };

    const fetchAuthor = async (authorObj: any) => {
      console.log(authorObj);
     // setIsLoading(true);
     let author
      const response = await fetchAuthorApi(authorObj.id);
      if (response?.status?.code === 200) {
          author= response?.data?.blog?.author;
          author['name'] = authorObj.name;
          author= response?.data?.blog?.author;
          setAuthorDetailsObj(author);
          console.log(response?.data?.blog?.author);
      }
    };

  useEffect(() => {
    console.log(data);
    categoriesList();
    fetchBlog(data);
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="blogSummary_Con">
       {isLoading && <AppLoader size="lg" ></AppLoader>}
      <div>
      {isLoadingError && <>
        <h4> {blogTitle}</h4>
        </>
  }

        {!isLoadingError && <>
      <CardMedia
                    component="img"
                    className="blog_card_img_summary"
                    image={imgUrl}
                    alt="green iguana"
                  />
        <h4> {blogTitle}</h4>
        <div className="floatLeft width100 cat_div_blog_paddingP">
        {blogItem?.categoryIds?.map((id: any) => (
              <div className="blog_category_div fontSize18" key={id} > {getCategoryName(id)} </div>
                
              ))}
        </div>
        <div className="author_section">
        <div className="floatLeft">
          <img alt="profile" src={authorDetailsObj.imageUrl} className="author_pic_blog_summary"></img>
        </div>
        <div className="floatLeft author_by_blog_text">
          <div> <b>By {authorDetailsObj.name} </b></div>
          <div><b>{moment(blogItem.updatedDate).format("ll")}</b></div>
        </div>
        </div>

        {parse(blogDescription)}
        
   
    </>
  }
  </div>
   </div>
  );
};

export default BlogSummary;
