import React, { useEffect, useState } from "react";
import "./OrderDashboardMid.scss";
import {
  getPriceType, websiteNAMEFilter, getStatus, getRemarksHeaderMsg, getRemarksHeader
  , getRemarksHeaderMsgEdit, getRemarksHeaderEdit, getRemarksPlaceholder, getOrderActionMessage, getRemarksbtnName
} from "../../utils/filters/nameFilters";
//import Loader from "../loader/Loader";
import { getOrderListApi, orderActionApi, fetchRemarksApi, downloadAttachmentApi, getOrderCountApi } from "../../utils/api/marketPlace";
import InfiniteScroll from 'react-infinite-scroll-component';
import Popup from '../popup/popup';
import { Tooltip } from 'react-tooltip'
// import daIcon from '../../assets/marketPlace/DA_Icon.svg'
// import rdIcon from '../../assets/marketPlace/RD_Icon.svg'
// import trafficIcon from '../../assets/marketPlace/Traffic_Icon.svg'
import checkIcon from '../../assets/marketPlace/check_Icon.svg'
import questionIcon from '../../assets/marketPlace/Question_Icon.svg'
//import tatIcon from '../../assets/marketPlace/TAT_Icon.svg'
import { getWebsiteCategories } from "../../utils/api/getWebsiteCategories";
import info from "../../assets/add_Websites/info.svg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { formatTraffic } from "../../utils/filters/nameFilters";
import AppLoader from "../../components/defaultLoader/DefaultLoader";
import parse from 'html-react-parser';
import Remark from "../../assets/register/remarks_icon.png";

// Define the structure of the stats array items
interface Stat {
  statName: string;
  statValue: number;
}

const OrderDashboardMid = ({ role }: {
  role: any
}) => {
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [hasMoreData, setHasMoreData] = useState<boolean>(false);
  // const [isTableLoading, setIstableLoading] = useState<boolean>(true);
  const tableCells = [1, 2, 3, 4, 5, 6, 7, 8];
  const [orderList, setOrderList] = useState<any>([]);
  const [activeFilter, setActiveFilter] = useState<string>("PENDING");
  const [resubmitOrderError, setResubmitOrderError] = useState<any>({});
  const roleType = role === "Advertiser" ? 'advertiser' : 'publisher';
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [statMap, setStatMap] = useState<Record<string, number>>({});

  const fetchOrderCount = async () => {
    const response = await getOrderCountApi(roleType);
    if (response?.status?.code === 200) {
      const map = response?.data?.order?.stats?.reduce((acc: Record<string, number>, curr: Stat) => {
        acc[curr.statName] = curr.statValue;
        return acc;
      }, {} as Record<string, number>);

      setStatMap(map);
      setIsLoading(false);
    } else {
      toast.error("Something went wrong", {
        position: "top-right",
        theme: "colored",
        autoClose: 5000,
      });
      setIsLoading(false);
    }
  };

  const getStatCountByKey = (key: string) => {
    if (statMap) {
      const statCount = statMap[key];
      if (statCount) {
        return '(' + statCount + ')';
      }
    }
    return '';
  }

  const fetchOrderlist = async () => {
    const response = await getOrderListApi(roleType, 12, 0, activeFilter);
    console.log(" fetchOrderlist  ", response)
    if (response?.status?.code === 200) {
      setPageNumber(1);
      if (role === "Advertiser") {
        setOrderList(response?.data?.advertiser?.orders);
        setHasMoreData(response?.data?.advertiser?.pageInfo?.hasNext);
      } else {
        setOrderList(response?.data?.publisher?.orders);
        setHasMoreData(response?.data?.publisher?.pageInfo?.hasNext);
      }
      setIsLoading(false);
    } else {
      toast.error("Something went wrong", {
        position: "top-right",
        theme: "colored",
        autoClose: 5000,
      });
      setIsLoading(false);
    }
  };

  const orderAction = async (orderObj: any, actionType: string, remarks: string, resubmitObj: any) => {
    // if (true) {
    //   console.log('  orderObj.attachmentFile  --->>>>  ',orderObj.attachmentFile)
    //   return;
    // }
    setIsLoading(true);
    const response = await orderActionApi(orderObj.id, actionType, role, remarks, resubmitObj, orderObj.attachmentFile);
    if (response?.status?.code === 200) {
      if (activeFilter !== 'ALL') {
        const list = orderList;
        const newList = list.filter((item: any) => item.id !== orderObj.id);
        setOrderList(newList);
      } else {
        const list = orderList;
        const newList = list.map((item: any) => {
          if (item.id === orderObj.id) {
            item.status = actionType;
            return item;
          } else {
            return item;
          }
        });
        setOrderList(newList);
      }
      fetchOrderCount();
      setRemarks('');
      setIsAddRemarkPopupOpen(false);
      setIsResubmitOrderOpen(false);
      toast.success(getOrderActionMessage(actionType), {
        position: "top-right",
        theme: "colored",
        autoClose: 5000,
      });
      setIsLoading(false);
    } else {
      toast.error('Something Went Wrong', {
        position: "top-right",
        theme: "colored",
        autoClose: 5000,
      });
      setIsLoading(false);
    }
  };

  const remarksOrderAction = async () => {
    if (!remarks) {
      setShowRemarksError(true);
      return;
    }
    setShowRemarksError(false);
    const actionType = selectedActionTypeForEdit === 'REVISION_NEEDED' ?
      roleType === 'advertiser' ? "ADV_REVISION_NEEDED" : "PUB_REVISION_NEEDED" : selectedActionTypeForEdit;
    orderAction(selectedOrderForEdit, actionType, remarks, undefined);

  };

  const handleFileChange = (cartObj: any, e: any) => {
    if (e.target.files) {
      const fileSize = e.target.files[0].size;
      const fileMb = fileSize / 1024 ** 2;
      if (fileMb >= 2) {
        toast.error("Please select a file less than 2MB.", {
          position: "top-right",
          theme: "colored",
          autoClose: 5000,
        });
        return;
      }
      cartObj.attachmentFile = e.target.files[0];
      const updatedList = orderList.map((cart: any) => {
        if (cart.id === cartObj.id) {
          return cartObj;
        } else {
          return cart;
        }
      });
      setOrderList(updatedList);
      setSelectedResubmitOrder(cartObj);
    }
  };


  const cartObj = (instructions: string, anchorText: string, targetUrl: string, existingPostUrl: string) => {
    return { instructions: instructions, anchorText: anchorText, targetUrl: targetUrl, existingPostUrl: existingPostUrl }
  }
  const cartErrorObj = (anchorTextError: string, targetUrlError: string, existingPostUrlError: string, fileError: string) => {
    return { anchorTextError: anchorTextError, targetUrlError: targetUrlError, existingPostUrlError: existingPostUrlError, fileError: fileError }
  }

  const reSubmitOrderAction = async () => {
    let isError = false;
    if (selectedResubmitOrder.priceType === 'LINK_INSERTION') {
      const cartErrorObject = cartErrorObj("", "", "", "");

      if (!selectedResubmitOrder.anchorText) {
        cartErrorObject.anchorTextError = "Anchor text is required";
        isError = true;
      }
      if (!selectedResubmitOrder.targetUrl) {
        cartErrorObject.targetUrlError = "Target link is required"
        isError = true;
      }
      if (!selectedResubmitOrder.existingPostUrl) {
        cartErrorObject.existingPostUrlError = "Existing post url is required"
        isError = true;
      }
      if (isError) {
        setResubmitOrderError(cartErrorObject);
        return;
      }

    } else {
      const cartErrorObject = cartErrorObj("", "", "", "");
      if (!selectedResubmitOrder.attachmentFile && !selectedResubmitOrder.fileName) {
        cartErrorObject.fileError = "This field is required"
        isError = true;
      }
      if (isError) {
        setResubmitOrderError(cartErrorObject);
        return;
      }
    }
    const cartUpdatedObj = cartObj(selectedResubmitOrder.instructions, selectedResubmitOrder.anchorText,
      selectedResubmitOrder.targetUrl, selectedResubmitOrder.existingPostUrl);
    orderAction(selectedResubmitOrder, 'PENDING', remarks, cartUpdatedObj);
  };

  const fetchFilteredOrderlist = async (filter: any) => {
    // setIstableLoading(true);
    setIsLoading(true);
    setOrderList([]);
    setActiveFilter(filter);
    const response = await getOrderListApi(roleType, 12, 0, filter);
    console.log(" fetchOrderlist  ", response)
    if (response?.status?.code === 200) {
      setPageNumber(1);
      if (role === "Advertiser") {
        setOrderList(response?.data?.advertiser?.orders);
        setHasMoreData(response?.data?.advertiser?.pageInfo?.hasNext);
      } else {
        setOrderList(response?.data?.publisher?.orders);
        setHasMoreData(response?.data?.publisher?.pageInfo?.hasNext);
      }
      setIsLoading(false);
      //setIstableLoading(false);
    } else {
      toast.error("Something went wrong", {
        position: "top-right",
        theme: "colored",
        autoClose: 5000,
      });
      setIsLoading(false);
    }

  }

  const fetchMoreData = async () => {
    //   setIsPageLoading(true);

    const response = await getOrderListApi(roleType, 12, pageNumber, activeFilter);
    if (
      response?.status?.code === 200 //&&
      // response?.data?.marketplace?.websites.length !== 0
    ) {
      console.log(pageNumber, "response  -->  fetchMoreData", response);

      const list = orderList;
      let mergedResult;
      if (role === "Advertiser") {
        setHasMoreData(response?.data?.advertiser?.pageInfo?.hasNext);
        mergedResult = [...list, ...response?.data?.advertiser?.orders];
      } else {
        setHasMoreData(response?.data?.publisher?.pageInfo?.hasNext);
        mergedResult = [...list, ...response?.data?.publisher?.orders];
      }
      setPageNumber(pageNumber + 1);
      setOrderList(mergedResult);
    }
  };

  function dateToYMD(createdDate: string) {
    var date = new Date(createdDate);
    var d = date.getDate();
    var m = date.getMonth() + 1; //Month from 0 to 11
    var y = date.getFullYear();
    return (d <= 9 ? '0' + d : d) + '-' + (m <= 9 ? '0' + m : m) + '-' + y;
  }

  function dateToYMDDueDate(createdDate: string, tat: number) {
    var date = new Date(createdDate);
    var tatDate = new Date(date.setDate(date.getDate() + tat));
    var d = tatDate.getDate();
    var m = tatDate.getMonth() + 1; //Month from 0 to 11
    var y = tatDate.getFullYear();
    return (d <= 9 ? '0' + d : d) + '-' + (m <= 9 ? '0' + m : m) + '-' + y;
  }

  const [isOpen, setIsOpen] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState<any>();
  const toggleOrderDetailsPopup = (content: any) => {
    setSelectedOrder(content);
    setIsOpen(!isOpen);
  }

  const [isAddRemarkPopupOpen, setIsAddRemarkPopupOpen] = useState(false);
  const [remarksHeader, setRemarksHeader] = useState('');
  const [remarksHeaderMsg, setRemarksHeaderMsg] = useState('');
  const [remarks, setRemarks] = useState('');
  const [showRemarksError, setShowRemarksError] = useState(false);
  const [selectedOrderForEdit, setSelectedOrderForEdit] = useState<any>();
  const [selectedActionTypeForEdit, setSelectedActionTypeForEdit] = useState('');
  const [isRemarkDisabled, setIsRemarkDisabled] = useState(false);
  const [remarksPlaceholder, setRemarksPlaceholder] = useState('');
  const [remarksSubmittBtnName, setRemarksSubmittBtnName] = useState('');
  const [remarksMaxLength, setRemarksMaxLength] = useState(0);
  const toggleOrderEdtPopup = (content: any, actionType: string) => {
    setRemarksHeader(getRemarksHeaderEdit(actionType));
    setRemarksHeaderMsg(getRemarksHeaderMsgEdit(actionType));
    setSelectedOrderForEdit(content);
    setIsAddRemarkPopupOpen(true);
    setSelectedActionTypeForEdit(actionType);
    setRemarksPlaceholder(getRemarksPlaceholder(actionType));
    setRemarksSubmittBtnName(getRemarksbtnName(actionType));
    setRemarks('');
    setShowRemarksError(false);
    if (actionType === 'REVISION_NEEDED' || actionType === 'PUB_REVISION_NEEDED' || actionType === 'ADV_REVISION_NEEDED') {
      setRemarksMaxLength(2000);
    } else {
      setRemarksMaxLength(200);
    }
    setIsRemarkDisabled(false);
  }

  const fechRemarks = async (orderObj: any, actionType: string) => {
    setIsLoading(true);
    const response = await fetchRemarksApi(actionType, orderObj.id);
    setShowRemarksError(false);
    console.log("  response ---> ", response);
    if (response?.status?.code === 200) {
      //setRemarks();
      if (actionType === 'REVISION_NEEDED' || actionType === 'PUB_REVISION_NEEDED' || actionType === 'ADV_REVISION_NEEDED') {
        setRemarksMaxLength(2000);
      } else {
        setRemarksMaxLength(200);
      }
      setRemarksHeader(getRemarksHeader(orderObj));
      setRemarksHeaderMsg(getRemarksHeaderMsg(orderObj));
      setIsAddRemarkPopupOpen(!isAddRemarkPopupOpen);
      setIsRemarkDisabled(true);
      setRemarks(response?.data?.remarks);
      setIsLoading(false);
    } else {
      toast.error("Something went wrong", {
        position: "top-right",
        theme: "colored",
        autoClose: 5000,
      });
      setIsLoading(false);
    }
  };

  const onlyToggleOrderEdtPopup = () => {
    setIsAddRemarkPopupOpen(!isAddRemarkPopupOpen);
  }

  const onlyToggleResubmitOrderPopup = () => {
    setIsResubmitOrderOpen(!isResubmitOrderOpen);
  }

  const getPostValidityType = (postValidityType: string) => {
    if (postValidityType === 'YEARLY') {
      return 'Yearly';
    } else if (postValidityType === 'HALF_YEARLY') {
      return 'Half Yearly';
    } else if (postValidityType === 'LIFETIME') {
      return 'Lifetime';
    }
  };

  const getBacklinkType = (backlinkType: any) => {
    if (backlinkType === 'DOFOLLOW') {
      return 'Dofollow';
    } else if (backlinkType === 'NOFOLLOW') {
      return 'Nofollow';
    }
  };

  const [isResubmitOrderOpen, setIsResubmitOrderOpen] = useState(false);
  const [selectedResubmitOrder, setSelectedResubmitOrder] = useState<any>();
  const toggleResubmitOrderPopup = (content: any) => {
    setSelectedResubmitOrder(content);
    setIsResubmitOrderOpen(!isResubmitOrderOpen);
  }

  const [isToolTipOpen, setIsToolTipOpen] = useState(false);
  const [guidelineContent, setGuidelineContent] = useState("");
  const togglePopup = (content: string) => {
    setGuidelineContent(content);
    setIsToolTipOpen(!isToolTipOpen);
  }

  const [websiteCategories, setWebsiteCategories] = useState<any>({});
  const getCategoryName = (id: String, index: number) => {
    if (index === 0) {
      return websiteCategories?.get(id);
    } else {
      return ' , ' + websiteCategories?.get(id) + ' ';
    }
  };

  const getCategories = async (setWebsiteCategories: React.Dispatch<any>) => {
    const response = await getWebsiteCategories();
    if (response?.status?.code === 200) {
      const map = new Map();
      response?.data?.websiteCategories?.categories.forEach((obj: { id: any; friendlyName: any; }) => {
        map.set(obj.id, obj.friendlyName);
      });
      setWebsiteCategories(map);
    }
  };



  const downloadAttachment = async (orderObj: any) => {
    await downloadAttachmentApi(orderObj);
  };

  const handleUpdate = (obj: any, e: any, field: string) => {
    console.log(e.target.value);
    if (field === 'existingPostUrl') {
      obj.existingPostUrl = e.target.value;
    } else if (field === 'anchorText') {
      obj.anchorText = e.target.value;
    } else if (field === 'instructions') {
      obj.instructions = e.target.value;
    } else {
      obj.targetUrl = e.target.value;
    }
    const updatedList = orderList.map((cart: any) => {
      if (cart.id === obj.id) {
        return obj;
      } else {
        return cart;
      }
    });
    setOrderList(updatedList);
  };

  function calculateCols() {
    // Get the screen width
    const screenWidth = window.innerWidth;
    const userAgent = window.navigator.userAgent;

    if (userAgent.indexOf('Mac') !== -1) {
      return Math.floor(screenWidth / 24.2);
    } else {
      return Math.floor(screenWidth / 22.2);
    }
    // Calculate the number of columns based on screen width
    // You can adjust this calculation according to your needs
    // For example, you might want to set a minimum and maximum number of columns
    // or adjust the ratio between screen width and number of columns
    // const cols = Math.floor(screenWidth / 11); // Adjust the divisor as needed
    // return cols;
  }

  function calculateResubmitCols() {
    // Get the screen width
    const userAgent = window.navigator.userAgent;
    if (userAgent.indexOf('Mac') !== -1) {
      return 110;
    } else {
      return 130;
    }
  }


  useEffect(() => {
    fetchOrderCount();
    fetchOrderlist();
    //setIstableLoading(false);
    getCategories(setWebsiteCategories);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (

    <div className="publisherWebsiteMid__Con">
      <ToastContainer />
      {isLoading && <AppLoader size="lg" ></AppLoader>}
      <div className="OrderSummaryTable__Con">
        <section className="PublisherSummaryTable__Con__Inner">
          <header className="PublisherSummaryTable__Con__Header">
            <p>My Orders</p>
          </header>
        </section>
        <section className="PublisherSummaryTable__Con__Inner statusSection">
          <div className="displayFlex">
            <div className={"statusCell borderRightNone " + (activeFilter === 'PENDING' ? 'selectedFilter' : '')} onClick={() => fetchFilteredOrderlist('PENDING')} >New {getStatCountByKey('pending')}</div>
            <div className={"statusCell borderRightNone " + (activeFilter === 'IN_PROGRESS' ? 'selectedFilter' : '')} onClick={() => fetchFilteredOrderlist('IN_PROGRESS')}>In Progress {getStatCountByKey('inProgress')}</div>
            <div className={"statusCell borderRightNone " + (activeFilter === 'REVISION_NEEDED' ? 'selectedFilter' : '')} onClick={() => fetchFilteredOrderlist('REVISION_NEEDED')}>Revision Needed {getStatCountByKey('revisionNeeded')}</div>
            <div className={"statusCell borderRightNone " + (activeFilter === 'DELIVERED' ? 'selectedFilter' : '')} onClick={() => fetchFilteredOrderlist('DELIVERED')}>Delivered {getStatCountByKey('delivered')}</div>
            <div className={"statusCell borderRightNone " + (activeFilter === 'COMPLETED' ? 'selectedFilter' : '')} onClick={() => fetchFilteredOrderlist('COMPLETED')} >Completed {getStatCountByKey('completed')}</div>
            <div className={"statusCell borderRightNone " + (activeFilter === 'REJECTED' ? 'selectedFilter' : '')} onClick={() => fetchFilteredOrderlist('REJECTED')} >Rejected {getStatCountByKey('rejected')}</div>
            <div className={"statusCell borderRightNone " + (activeFilter === 'CANCELLED' ? 'selectedFilter' : '')} onClick={() => fetchFilteredOrderlist('CANCELLED')} >Cancelled {getStatCountByKey('cancelled')}</div>
            <div className={"statusCell " + (activeFilter === 'ALL' ? 'selectedFilter' : '')} onClick={() => fetchFilteredOrderlist('ALL')}>
              All {getStatCountByKey('total')}</div>
          </div>
        </section>
        <section className="PublisherSummaryTable__Con__Table__Header">
          <article>
            <p>Date</p>
            <p>Order ID</p>
            <h5>Website</h5>
            <p>Price ($)</p>
            <p>Type</p>
            <p>Due Time</p>
            <p>Status</p>
            {(activeFilter === 'REVISION_NEEDED' || activeFilter === 'DELIVERED' || activeFilter === 'REJECTED' ||
              activeFilter === 'CANCELLED' || activeFilter === 'ALL') &&
              <p> Remarks </p>}
            <h6>Action</h6>
          </article>
          {orderList.length !== 0 &&
            <InfiniteScroll dataLength={orderList.length}
              next={fetchMoreData} hasMore={hasMoreData}
              loader={''}
              height={'calc(58vh - 10px)'} >
              {orderList.length !== 0 &&
                orderList?.map((cell: any, index: number) => (
                  <div
                    className={`OrderSummaryTable__Con__Table__Cell ${index % 2 === 0
                      ? "OrderSummaryTable__Con__Table__Cell__grey__bg"
                      : ""
                      } ${index === tableCells.length - 1
                        ? "OrderSummaryTable__Con__Table__Cell__last__border"
                        : ""
                      } `}
                    key={index}
                  >
                    {/* <a
                      href={cell?.websiteUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    > */}
                    <p >{dateToYMD(cell?.createdDate)}</p>
                    {/* </a> */}
                    <p>{cell?.orderId}</p>
                    <h5>{websiteNAMEFilter(cell?.websiteUrl)}</h5>
                    <p>{cell?.price}</p>
                    <p>{getPriceType(cell?.priceType)}</p>
                    <p>{dateToYMDDueDate(cell?.createdDate, cell?.tat)}</p>
                    <p>{getStatus(cell?.status)}</p>
                    {(activeFilter === 'REVISION_NEEDED' || activeFilter === 'DELIVERED' || activeFilter === 'REJECTED' || activeFilter === 'CANCELLED' || activeFilter === 'ALL') && <>
                      {((cell?.status === 'PUB_REVISION_NEEDED' && roleType === 'advertiser') || cell?.status === 'REJECTED' || cell?.status === 'ADV_REVISION_NEEDED' ||
                        (cell?.status === 'DELIVERED' && roleType === 'advertiser') || cell?.status === 'CANCELLED') &&
                        <>
                          {/* <p className="actionButtonTextSpan fontSizeViewText" onClick={() =>  fechRemarks(cell, cell?.status)}>View</p>  */}
                          <p className=" material-icons fontSizeViewText lightGrey" onClick={() => fechRemarks(cell, cell?.status)}>
                            <img className="advertisers__image" src={Remark} alt="frame" />
                          </p> </>}
                      {!((cell?.status === 'PUB_REVISION_NEEDED' && roleType === 'advertiser') || cell?.status === 'REJECTED' || cell?.status === 'ADV_REVISION_NEEDED' ||
                        (cell?.status === 'DELIVERED' && roleType === 'advertiser') || cell?.status === 'CANCELLED') &&
                        <>
                          <p></p>
                        </>
                      }</>}
                    {roleType === 'advertiser' &&

                      <h4>
                        {(cell?.status === 'PENDING' || cell?.status === 'IN_PROGRESS') &&
                          <div className="actionDiv">
                            {cell?.status === 'PENDING'
                              && <div className="actionSummaryDiv noPadding">Waiting for publisher to accept the order</div>}
                            {cell?.status === 'IN_PROGRESS'
                              && <div className="actionSummaryDiv noPadding">Order accepted by advertiser </div>}
                            <div className="noPadding textAlignCenter">
                              <span className="actionButtonTextSpan" onClick={() => { toggleOrderDetailsPopup(cell); }} > View Details  </span>
                              <span className="actionButtonTextSpan" onClick={() => toggleOrderEdtPopup(cell, 'CANCELLED')}> Cancel</span>
                            </div>
                          </div>
                        }
                        {(cell?.status === 'PUB_REVISION_NEEDED') &&
                          <div className="actionDiv">
                            <div className="actionSummaryDiv noPadding">Publisher requested more information</div>
                            <div className="noPadding textAlignCenter">
                              <span className="actionButtonTextSpan" onClick={() => toggleResubmitOrderPopup(cell)}>Re-submit  </span>
                              {/* <span className="actionButtonTextSpan" onClick={() => orderAction(cell, 'CANCELLED', '',undefined)}>Cancel</span> */}
                              <span className="actionButtonTextSpan" onClick={() => toggleOrderEdtPopup(cell, 'CANCELLED')}>Cancel  </span>
                              <div>
                                <span className="actionButtonTextSpan" onClick={() => { toggleOrderDetailsPopup(cell); }} > View Details  </span>
                                {/* <span className="actionButtonTextSpan" onClick={() => fechRemarks(cell, cell?.status)}>Remarks</span> */}
                              </div>
                            </div>
                          </div>
                        }
                        {(cell?.status === 'ADV_REVISION_NEEDED') &&
                          <div className="actionDiv">
                            <div className="actionSummaryDiv noPadding">Advertiser requested more information</div>
                            <div className="noPadding textAlignCenter">
                              <span className="actionButtonTextSpan" onClick={() => { toggleOrderDetailsPopup(cell); }} >View Details  </span>
                            </div>
                          </div>
                        }
                        {(cell?.status === 'DELIVERED') &&
                          <div className="actionDiv">
                            <div className="actionSummaryDiv noPadding">Order delivered by publisher</div>
                            <div className="noPadding textAlignCenter">
                              <span className="actionButtonTextSpan" onClick={() => toggleOrderEdtPopup(cell, 'ADV_REVISION_NEEDED')}>Revision Needed  </span>
                              <span className="actionButtonTextSpan" onClick={() => toggleOrderEdtPopup(cell, 'CANCELLED')}>Cancel  </span>
                              <span className="actionButtonTextSpan" onClick={() => orderAction(cell, 'COMPLETED', '', undefined)}>Complete</span>
                              <span className="actionButtonTextSpan" onClick={() => { toggleOrderDetailsPopup(cell); }} > View Details  </span>
                              {/* <span className="actionButtonTextSpan" onClick={() => fechRemarks(cell, cell?.status)}>Remarks</span> */}
                            </div>
                          </div>
                        }
                        {cell?.status === 'REJECTED' &&
                          <div className="actionDiv">
                            <div className="actionSummaryDiv noPadding">Order rejected by publisher</div>
                            <div className="noPadding textAlignCenter">
                              <span className="actionButtonTextSpan" onClick={() => { toggleOrderDetailsPopup(cell); }} >View Details  </span>
                              {/* <span className="actionButtonTextSpan" onClick={() => fechRemarks(cell, cell?.status)}>Remarks</span> */}
                            </div>
                          </div>
                        }
                        {cell?.status === 'CANCELLED' &&
                          <div className="actionDiv">
                            <div className="actionSummaryDiv noPadding">Order cancelled by Advertiser</div>
                            <div className="noPadding textAlignCenter">
                              <span className="actionButtonTextSpan" onClick={() => { toggleOrderDetailsPopup(cell); }} >View Details  </span>
                              {/* <span className="actionButtonTextSpan" onClick={() => fechRemarks(cell, cell?.status)}>Remarks</span> */}
                            </div>
                          </div>
                        }
                        {(cell?.status === 'COMPLETED') &&
                          <div className="actionDiv">
                            <div className="actionSummaryDiv noPadding">Order Completed</div>
                            <div className="noPadding textAlignCenter">
                              <span className="actionButtonTextSpan" onClick={() => { toggleOrderDetailsPopup(cell); }} >View Details  </span>
                            </div>
                          </div>
                        }
                        {/* <span className="actionButtonTextSpan" onClick={() => orderAction(cell, 'PENDING', '',undefined)}>Pending  </span> */}
                      </h4>
                    }
                    {roleType === 'publisher' &&
                      <h4>
                        {/* <span className="actionButtonTextSpan" onClick={() => orderAction(cell, 'PENDING', '',undefined)}>Pending  </span> */}
                        {cell?.status === 'PENDING' &&
                          <div className="noPadding textAlignCenter">
                            <span className="actionButtonTextSpan" onClick={() => orderAction(cell, 'IN_PROGRESS', '', undefined)}>Accept  </span>
                            <span className="actionButtonTextSpan" onClick={() => toggleOrderEdtPopup(cell, 'PUB_REVISION_NEEDED')}>Revision Needed  </span>
                            <span className="actionButtonTextSpan" onClick={() => toggleOrderEdtPopup(cell, 'REJECTED')}>Reject  </span>
                            <span className="actionButtonTextSpan" onClick={() => { toggleOrderDetailsPopup(cell); }} > View Details  </span>
                            {/* <span className="actionButtonTextSpan" onClick={() => toggleOrderEdtPopup(cell, 'CANCELLED')}>Cancel  </span> */}
                          </div>

                        }
                        {cell?.status === 'IN_PROGRESS' &&
                          <div>
                            {/* <span className="actionButtonTextSpan" onClick={() => orderAction(cell, 'DELIVERED','')}>Deliver</span> */}
                            <span className="actionButtonTextSpan" onClick={() => toggleOrderEdtPopup(cell, 'DELIVERED')}>Deliver </span>
                            <span className="actionButtonTextSpan" onClick={() => { toggleOrderDetailsPopup(cell); }} >View Details  </span>
                          </div>
                        }
                        {(cell?.status === 'PUB_REVISION_NEEDED') &&
                          <div className="actionDiv">
                            <div className="actionSummaryDiv noPadding">Waiting for advertiser to re-submit the order</div>
                          </div>
                        }
                        {(cell?.status === 'ADV_REVISION_NEEDED') &&
                          <div className="actionDiv">
                            <div className="actionSummaryDiv noPadding">Advertiser requested more information</div>
                            <div className="noPadding textAlignCenter">
                              <span className="actionButtonTextSpan" onClick={() => toggleOrderEdtPopup(cell, 'DELIVERED')}>Deliver </span>
                              <span className="actionButtonTextSpan" onClick={() => { toggleOrderDetailsPopup(cell); }} >View Details  </span>
                              {/* <span className="actionButtonTextSpan" onClick={() => fechRemarks(cell, cell?.status)}>Remarks</span> */}
                            </div>
                          </div>
                        }
                        {(cell?.status === 'DELIVERED') &&
                          <div className="actionDiv">
                            <div className="actionSummaryDiv noPadding">Waiting for advertiser to review the order</div>
                            <div className="noPadding textAlignCenter">
                              <span className="actionButtonTextSpan" onClick={() => { toggleOrderDetailsPopup(cell); }} > View Details  </span>
                            </div>
                          </div>
                        }
                        {cell?.status === 'REJECTED' &&
                          <div className="actionDiv">
                            <div className="actionSummaryDiv noPadding">Order rejected by publisher</div>
                            <div className="noPadding textAlignCenter">
                              <span className="actionButtonTextSpan" onClick={() => { toggleOrderDetailsPopup(cell); }} >View Details  </span>
                              {/* <span className="actionButtonTextSpan" onClick={() => fechRemarks(cell, cell?.status)}>Remarks</span> */}
                            </div>
                          </div>
                        }
                        {cell?.status === 'CANCELLED' &&
                          <div className="actionDiv">
                            <div className="actionSummaryDiv noPadding">Order cancelled by Advertiser</div>
                            <div className="noPadding textAlignCenter">
                              <span className="actionButtonTextSpan" onClick={() => { toggleOrderDetailsPopup(cell); }} >View Details  </span>
                              {/* <span className="actionButtonTextSpan" onClick={() => fechRemarks(cell, cell?.status)}>Remarks</span> */}
                            </div>
                          </div>
                        }
                        {(cell?.status === 'COMPLETED') &&
                          <div className="actionDiv">
                            <div className="actionSummaryDiv noPadding">Order Completed</div>
                            <div className="noPadding textAlignCenter">
                              <span className="actionButtonTextSpan" onClick={() => { toggleOrderDetailsPopup(cell); }} >View Details  </span>
                            </div>
                          </div>
                        }
                      </h4>
                    }
                  </div>
                ))}
            </InfiniteScroll>
          }
        </section>
        {/* {isTableLoading && (
          <div className="publisherMid__loader__con">
            <Loader size="lg" />
          </div>
        )} */}
      </div>

      {isOpen && <Popup
        content={<>
          <div><div className="popup-header">Order Details - {getPriceType(selectedOrder?.priceType)}</div>
            <div className="popup-content">


              {selectedOrder.priceType !== "LINK_INSERTION" &&
                <div>
                  <div className="margin1"><span>Content Provided by Advertiser. Please click the link below to download the content. </span></div>
                  <div className="margin1"><span className="downloadText" onClick={() => { downloadAttachment(selectedOrder); }} > {selectedOrder.fileName}</span></div>
                </div>
              }
              {selectedOrder.priceType === "LINK_INSERTION" &&
                <div>
                  <div className="margin1">
                    <div className="margin1"> Existing Post URL  </div>
                    <div className="margin1"> <input type="text" className="existingPostUrl" placeholder="Enter Text" value={selectedOrder.existingPostUrl} disabled ></input></div>
                  </div>
                  <div className="margin1">
                    <div className="margin1"> Anchor Text  </div>
                    <div className="margin1"> <input type="text" className="existingPostUrl" placeholder="Enter Text" value={selectedOrder.anchorText} disabled ></input></div>
                  </div>
                  <div className="margin1">
                    <div className="margin1"> Target Link  </div>
                    <div className="margin1"> <input type="text" className="existingPostUrl" placeholder="Enter Text" value={selectedOrder.targetUrl} disabled ></input></div>
                  </div>
                </div>
              }
              <div>
                <div>
                  <p className="orderContentMargin"><label htmlFor="">
                    Additional Instructions {" "}
                  </label></p>
                  <textarea id="w3review" name="w3review" className="instructionField" rows={7} cols={calculateCols()} value={selectedOrder.instructions} disabled>
                  </textarea>
                </div>
              </div>

            </div>
            <div className="btnDiv">
              <button className="wishList_btn addToCart_btn" onClick={() => { toggleOrderDetailsPopup(""); }} > Close  </button>
            </div>
          </div>
        </>}
        handleClose={toggleOrderDetailsPopup}
      />}

      {isAddRemarkPopupOpen && <Popup
        content={<>
          <div><div className="popup-header">{remarksHeader}</div>
            <div className="popup-content">
              <div>
                <div>
                  <p className="remarkHeaderMargin"><label htmlFor="">
                    {remarksHeaderMsg}{!isRemarkDisabled && <span className="red">*</span>}
                  </label> {!isRemarkDisabled && <span className="maxLengthCount">{remarks.length}/{remarksMaxLength}</span>}</p>

                  <textarea id="w3review" name="w3review" className="instructionField remarkContentMargin" rows={7} cols={calculateCols()} value={remarks} onChange={(e) => {
                    setRemarks(e.target.value);
                  }} disabled={isRemarkDisabled} placeholder={remarksPlaceholder} maxLength={remarksMaxLength}>
                  </textarea>
                  {!isRemarkDisabled && showRemarksError && <span>
                    {" "}
                    <span className="red" >This field is required</span>{" "}
                  </span>}
                </div>
              </div>
            </div>
            <div className="btnDiv">
              {!isRemarkDisabled && <button className=" addToCart_btn" onClick={() => { remarksOrderAction(); }} > {remarksSubmittBtnName}  </button>}
              {isRemarkDisabled && <button className="wishList_btn addToCart_btn" onClick={() => { onlyToggleOrderEdtPopup(); }} > Close  </button>}
            </div>
          </div>
        </>}
        handleClose={onlyToggleOrderEdtPopup}
      />}

      {isResubmitOrderOpen && <Popup
        class={'resubmitOrder'}
        content={<>
          <div><div className="popup-header">Re-Submit Order</div>
            <div className="popup-content">
              <div className="url_cart">
                <div className="urlDiv">
                  {selectedResubmitOrder.websiteUrl}
                  <Tooltip id="show-guidelines" place="top" />
                  <Tooltip data-class="tool" id="show-sample" place="top" />
                  {selectedResubmitOrder.postGuideline !== undefined && selectedResubmitOrder.postGuideline !== '' && <img className="iconStyle" src={questionIcon} alt=""
                    data-tooltip-id="show-guidelines" data-tooltip-content="Show Guidelines" onClick={() => { togglePopup(selectedResubmitOrder.postGuideline); }} />}
                  {selectedResubmitOrder.sampleUrl !== undefined && selectedResubmitOrder.sampleUrl !== '' && <a href={selectedResubmitOrder.sampleUrl} target="_blank" rel="noreferrer" >
                    <img className="iconStyle" src={checkIcon} style={{ marginBottom: '-4px' }} alt="" data-class="tool1" data-tooltip-id="show-sample" data-tooltip-content="Show Sample" /></a>}
                </div>


                <div className="tatCart" style={{ textAlign: 'end' }} >
                  <b>TAT - {selectedResubmitOrder.tat} days </b>
                </div>

              </div>
              <div className="domain_traffic_referring_cart " >
                <div className="dtrDivCart">
                  <div className="width20">  </div>
                  <div className="width80" > <div>  <b> Domain Authority </b>{selectedResubmitOrder.domainAuthority} </div></div>
                </div>
                <div className="dtrDivCart justifyCenter">

                  < div><div>  <b> Traffic </b>{formatTraffic(selectedResubmitOrder.traffic)} </div> </div>
                </div>
                <div className="dtrDivCart">
                  <div className="width20">  </div>
                  <div className="width80" > <div> <b> Domain Rating </b> {selectedResubmitOrder.referingDomain} </div>  </div>
                </div>
                <div className="dtrDivCart">
                  <div className="width20">  </div>
                  <div className="width80" > <div> <b> Spam Score </b> {selectedResubmitOrder.spamScore} </div>  </div>
                </div>
              </div>
              <div className="domain_traffic_referring_cart top_border_cart" >
                <div className="dtrDivCart">
                  <div className="width20">  </div>
                  <div className="width80" > <div> <b>Post Validity - </b> {getPostValidityType(selectedResubmitOrder.guaranteePostValidity)}</div></div>
                </div>
                <div className="dtrDivCart justifyCenter">

                  < div><div><b>  Min. Word Count -</b> {formatTraffic(selectedResubmitOrder.minPostWordCount)}</div> </div>
                </div>
                <div className="dtrDivCart">
                  <div className="width20">  </div>
                  <div className="width80" > <div>  <b> Max Backlinks - </b> {selectedResubmitOrder.maxBackLinks}
                    &nbsp;&nbsp;&nbsp;{getBacklinkType(selectedResubmitOrder.backLinkType)} </div>  </div>
                </div>
              </div>
              <div className="categories_cart" >
                <div className="guest_link_casino">
                  <div className="" > <b>Categories</b> </div>
                  {selectedResubmitOrder.websiteCategoryIdList?.map((id: any, index: any) => (
                    <div className="category_div_cart" key={index} > {getCategoryName(id, index)}  </div>

                  ))}
                </div>
              </div>

              {selectedResubmitOrder.priceType !== "LINK_INSERTION" &&
                <div className="contentAttachmentDiv displayFlex">
                  <div className="contentTypeDiv">
                    <p className="contentMargin"><label htmlFor="">
                      Content Type <span className="red">*</span>{" "}
                    </label></p>
                    <select name="contentType" id="contentType" className="contentTypeSelect">
                      <option value="provideContent">Provide Content</option>
                    </select>
                  </div>
                  <div className="attachmentDiv">
                    <p className="contentMargin"><label htmlFor="">
                      Attachment  (Supported - .doc, .docx, .txt and html file){" "}
                    </label></p>
                    <input type="file" id="myfile" name="myfile" placeholder="Drop your file here" value={""}
                      onChange={(e) => { handleFileChange(selectedResubmitOrder, e) }} />
                    {selectedResubmitOrder?.attachmentFile &&
                      <span>{selectedResubmitOrder?.attachmentFile?.name} </span>}
                    {!selectedResubmitOrder?.attachmentFile &&
                      <span className="downloadText" onClick={() => { downloadAttachment(selectedResubmitOrder); }} >{selectedResubmitOrder.fileName}</span>}
                    {!selectedResubmitOrder?.attachmentFile && !selectedResubmitOrder.fileName && resubmitOrderError.fileError && (
                      <span>
                        {" "}
                        <img src={info} alt="" /> <span>{resubmitOrderError.fileError}</span>{" "}
                      </span>
                    )}

                  </div>
                </div>}
              {selectedResubmitOrder.priceType === "LINK_INSERTION" &&
                <div className=" ">
                  <div className="">
                    <p className="contentMargin"><label htmlFor="">
                      Existing Post Url <span className="red">*</span>{" "}
                      {resubmitOrderError.existingPostUrlError && (
                        <span>
                          {" "}
                          <img src={info} alt="" /> <span>{resubmitOrderError.existingPostUrlError}</span>{" "}
                        </span>
                      )}
                    </label></p>

                    <input type="text" className="existingPostUrl" placeholder="https://sample.com" value={selectedResubmitOrder.existingPostUrl}
                      onChange={(e: any) => handleUpdate(selectedResubmitOrder, e, 'existingPostUrl')}></input>
                  </div>
                  <div className="displayFlex">
                    <div className="contentTypeDiv">
                      <p className="contentMargin"><label htmlFor="">
                        Anchor Text <span className="red">*</span>{" "}
                        {resubmitOrderError.anchorTextError && (
                          <span>
                            {" "}
                            <img src={info} alt="" /> <span>{resubmitOrderError.anchorTextError}</span>{" "}
                          </span>
                        )}
                      </label></p>

                      <input type="text" className="existingPostUrl" placeholder="Enter Text" value={selectedResubmitOrder.anchorText}
                        onChange={(e: any) => handleUpdate(selectedResubmitOrder, e, 'anchorText')}></input>

                    </div>
                    <div className="contentTypeDiv">
                      <p className="contentMargin"><label htmlFor="">
                        Target Link <span className="red">*</span>{" "}
                        {resubmitOrderError.targetUrlError && (
                          <span>
                            {" "}
                            <img src={info} alt="" /> <span>{resubmitOrderError.targetUrlError}</span>{" "}
                          </span>
                        )}
                      </label></p>

                      <input type="text" className="existingPostUrl" placeholder="https://sample.com" value={selectedResubmitOrder.targetUrl}
                        onChange={(e: any) => handleUpdate(selectedResubmitOrder, e, 'targetUrl')}></input>
                      {/* <span>{selectedResubmitOrder?.fileName} </span> */}
                    </div>
                  </div>
                </div>}

              <div>

              </div>

              <div>
                <p className="contentMargin"><label htmlFor="">
                  Additional Instructions {" "}
                </label></p>
                <textarea id="w3review" name="w3review" className="instructionField" rows={5} cols={calculateResubmitCols()} value={selectedResubmitOrder.instructions}
                  onChange={(e: any) => handleUpdate(selectedResubmitOrder, e, 'instructions')} >
                </textarea>
              </div>
              <div className="priceButtonDivResubmit">
                <div className="priceTypeReSubmit">
                  <b>  {getPriceType(selectedResubmitOrder.priceType)} ${selectedResubmitOrder.price}</b>
                </div>
                <div className="ReSubmitBtnDiv">
                  <button className=" addToCart_btn floatRight" onClick={() => { reSubmitOrderAction() }} > Re-Submit  </button>
                  <button className="wishList_btn addToCart_btn floatRight" onClick={() => { onlyToggleResubmitOrderPopup() }} >  Back  </button>
                </div>
              </div>
            </div>
          </div>
        </>}
        handleClose={onlyToggleResubmitOrderPopup}
      />}
      {isToolTipOpen && <Popup
        content={<>
          <div><div className="popup-header">Guidelines</div>
            <div className="popup-content">{parse(guidelineContent)}</div>
            <div className="btnDiv">
              <button className="wishList_btn addToCart_btn" onClick={() => { togglePopup(""); }} > Close  </button>
            </div>
          </div>
        </>}
        handleClose={togglePopup}
      />}
      <p className="publisherWebsiteMid__Footer">
        © 2024 Powered By Outreach Kart | All Rights Reserved
      </p>
    </div>
  );
};

export default OrderDashboardMid;
