import React, { useEffect, useState } from "react";
import "./orders.scss";
import withAuth from "../../hoc/withAuth";
import "react-toastify/dist/ReactToastify.css";
import SideHeaderAdmin from "../../components/SideHeader/SideHeaderAdmin";
import DashboardHeaderAdmin from "../../components/DashboardHeader/DashboardHeaderAdmin";
import { fetchOrdersListPost } from "../../utils/api/orders";
import { ToastContainer, toast } from "react-toastify";
import search from "../../assets/add_Websites/search.svg";
import { selectOrderStatusArray, selectOrderTypeArray, selectDateRangeArray } from "../../utils/constants/selected";
import { getDateFormatted, getPriceType, getOrderStatus, getAdvertiserActionMsg, getPublisherActionMsg } from "../../utils/filters/nameFilters";
import InfiniteScroll from 'react-infinite-scroll-component';
import AppLoader from "../../components/defaultLoader/DefaultLoader";
import { Tooltip } from "react-tooltip";
import { FormControl, InputLabel, MenuItem, Select, Button } from "@mui/material";
import { useParams } from 'react-router-dom';
//import CopyToClipboard from 'react-copy-to-clipboard';
//import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const OrdersComp = () => {
    const { status } = useParams();
    const tableCells = [1, 2, 3, 4, 5, 6];
    const [searchInput, setSearchInput] = useState<string>("");
    const [ordersList, setOrdersList] = useState<any>([]);
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [hasMoreData, setHasMoreData] = useState<boolean>(true);
    const [orderTypeFilter, setOrderTypeFilter] = useState<any>('');
    const [statusFilter, setStatusFilter] = useState<any>('');
    const [dateRangeFilter, setDateRangeFilter] = useState<any>('');
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [updatedDateGTE, setUpdatedDateGTE] = useState<string>("");
    const [updatedDateLT, setUpdatedDateLT] = useState<string>("");

    const getOrdersListData = async (orderTypeFilterStr: string | undefined) => {
        const response = await fetchOrdersListPost(20, 0, undefined, orderTypeFilterStr, '', '', '');
        console.log("--->  ", response)
        if (response?.status?.code === 200) {
            setPageNumber(1);
            setOrdersList(response?.data?.customers?.orders);
            setHasMoreData(response?.data?.customers?.pageInfo?.hasNext);
            setIsLoading(false);
        } else {
            setIsLoading(false);
            toast.error("Something went wrong", {
                position: "top-right",
                theme: "colored",
                autoClose: 5000,
            });
        }
    };

    const getFilteredOrdersListData = async (searchStr: string | undefined) => {
        setIsLoading(true);
        const response = await fetchOrdersListPost(10, 0, orderTypeFilter,
            statusFilter, '', updatedDateGTE, updatedDateLT);
        setSearchInput('');
        if (response?.status?.code === 200) {
            setPageNumber(1);
            setOrdersList(response?.data?.customers?.orders);
            setHasMoreData(response?.data?.customers?.pageInfo?.hasNext);
            setIsLoading(false);
        } else {
            setIsLoading(false);
            toast.error("Something went wrong", {
                position: "top-right",
                theme: "colored",
                autoClose: 5000,
            });
        }
    };

    const fetchMoreData = async () => {
        const response = await fetchOrdersListPost(10, pageNumber, orderTypeFilter,
            statusFilter, searchInput, updatedDateGTE, updatedDateLT);
        if (response?.status?.code === 200) {
            setHasMoreData(response?.data?.customers?.pageInfo?.hasNext);
            const list = ordersList;
            const mergedResult = [...list, ...response?.data?.customers?.orders];
            setPageNumber(pageNumber + 1);
            setOrdersList(mergedResult);
        }
    };

    const searchOrderData = () => {
        setPageNumber(1);
        getFilteredOrdersListData(undefined);
    };

    const clearSearchOrderData = () => {
        setSearchInput('');
        getFilteredOrdersListData("");
    };

    const setSearchOrderData = (e: any) => {
        setSearchInput(e.target.value);
    };

    const handleStatusChange = (event: { target: { value: string } }) => {
        setStatusFilter(event.target.value);
    };

    const handleTypeChange = (event: { target: { value: string } }) => {
        setOrderTypeFilter(event.target.value);
    };

    const handleDateRangeChange = (event: { target: { value: string } }) => {
        const selecedVal = event.target.value;
        var userTimezoneOffset = new Date().getTimezoneOffset() * 60000;
        const dateOffset = (24 * 60 * 60 * 1000);
        if (selecedVal === "LAST_30_DAYS") {
            setUpdatedDateGTE(new Date((new Date().getTime() - dateOffset * 30) - userTimezoneOffset).toISOString());
            setUpdatedDateLT(new Date(new Date().getTime() - userTimezoneOffset).toISOString());
        } else if (selecedVal === "LAST_60_DAYS") {
            setUpdatedDateGTE(new Date((new Date().getTime() - dateOffset * 60) - userTimezoneOffset).toISOString());
            setUpdatedDateLT(new Date(new Date().getTime() - userTimezoneOffset).toISOString());
        } else if (selecedVal === "LAST_120_DAYS") {
            setUpdatedDateGTE(new Date((new Date().getTime() - dateOffset * 120) - userTimezoneOffset).toISOString());
            setUpdatedDateLT(new Date(new Date().getTime() - userTimezoneOffset).toISOString());
        } else if (selecedVal === "CUSTOM_DATE_RANGE") {

        }
        setDateRangeFilter(selecedVal);
    };

    useEffect(() => {
        if (status && status !== 'all') {
            setStatusFilter(status?.toUpperCase());
            getOrdersListData(status?.toUpperCase());
        } else {
            getOrdersListData(undefined);
        }
    }, [status]);

    return (
        <div className={`dashboard_Con`}>
            <SideHeaderAdmin />

            <div className={`dashboard_Con__Right`}>
                <DashboardHeaderAdmin />
                <div className="publisherWebsiteMid__Con">
                    {isLoading && <AppLoader size="lg" />}
                    <div className="pubLisherAddWebsite__Con">
                        <h4>Order Management</h4>
                        <div>
                            <div className="sortDiv floatLeft marginRightOnePerc">
                                <FormControl sx={{ m: 1, minWidth: 180 }} size="small">
                                    <InputLabel id="demo-select-small-label">Select Status</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-helper-label"
                                        id="demo-simple-select-helper"
                                        value={statusFilter}
                                        label="Select Status"
                                        onChange={handleStatusChange}>
                                        <MenuItem value="">
                                            <em>Select Status</em>
                                        </MenuItem>
                                        {selectOrderStatusArray?.map((selectRoleInterestObj: any, index) => (
                                            <MenuItem value={selectRoleInterestObj.id} key={index}>{selectRoleInterestObj.friendlyName}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>

                            <div className="sortDiv floatLeft marginRightOnePerc">
                                <FormControl sx={{ m: 1, minWidth: 180 }} size="small">
                                    <InputLabel id="demo-select-small-label">Select Order Type</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-helper-label"
                                        id="demo-simple-select-helper"
                                        value={orderTypeFilter}
                                        label="Select Status"
                                        onChange={handleTypeChange}>
                                        <MenuItem value="">
                                            <em>Select Order Type</em>
                                        </MenuItem>
                                        {selectOrderTypeArray?.map((selectOrderTypeObj: any, index) => (
                                            <MenuItem value={selectOrderTypeObj.id} key={index}>{selectOrderTypeObj.friendlyName}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                            <div className="sortDiv floatLeft marginRightOnePerc">
                                <FormControl sx={{ m: 1, minWidth: 180 }} size="small">
                                    <InputLabel id="demo-select-small-label">Select Date Range</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-helper-label"
                                        id="demo-simple-select-helper"
                                        value={dateRangeFilter}
                                        label="Select Status"
                                        onChange={handleDateRangeChange}>
                                        <MenuItem value="">
                                            <em>Select Date Range</em>
                                        </MenuItem>
                                        {selectDateRangeArray?.map((selectDateRangeObj: any, index) => (
                                            <MenuItem value={selectDateRangeObj.id} key={index}>{selectDateRangeObj.friendlyName}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                            <div className=" floatLeft marginRightOnePerc">
                                <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                                    <Button variant="contained" color="success" onClick={() => { getFilteredOrdersListData(searchInput) }}>
                                        Apply
                                    </Button>
                                </FormControl>
                            </div>
                            <div className="searchAdvPubDiv floatRight">
                                <FormControl sx={{ m: 1, minWidth: 250, background: 'white' }} size="small">
                                    <input
                                        className="searchMarketPlace"
                                        type="text"
                                        value={searchInput}
                                        placeholder='Search Advertiser/Publisher'
                                        onChange={(e: any) => setSearchOrderData(e)}
                                    />
                                    {searchInput !== '' && <img className="search-img right25px" src={search} alt="" onClick={() => { searchOrderData(); }} />}
                                    {searchInput !== '' && <span className="material-icons lightGrey close-img right0" onClick={() => { clearSearchOrderData(); }}>close</span>}
                                </FormControl>
                            </div>
                        </div>
                        <div className="PublisherSummaryTable__Con noPadding">
                            <section className="PublisherSummaryTable__Con__Table__Header noMargin">
                                <article>
                                    <p>Order ID</p>
                                    <p>Order Date</p>
                                    <p>Publisher</p>
                                    <p>Advertiser</p>
                                    <p>Website</p>
                                    <p>Order Type</p>
                                    <p>Price</p>
                                    <p>Status</p>
                                    <p>Publisher's Action</p>
                                    <p>Advertiser's Action</p>
                                </article>
                                <InfiniteScroll
                                    dataLength={ordersList.length}
                                    next={fetchMoreData}
                                    hasMore={hasMoreData}
                                    loader={''}
                                    height={'calc(58vh - 10px)'}>
                                    {ordersList.length !== 0 &&
                                        ordersList?.map((cell: any, index: number) => (
                                            <div
                                                className={`PublisherSummaryTable__Con__Table__Cell ${index % 2 === 0
                                                    ? "PublisherSummaryTable__Con__Table__Cell__grey__bg"
                                                    : ""
                                                    } ${index === tableCells.length - 1
                                                        ? "PublisherSummaryTable__Con__Table__Cell__last__border"
                                                        : ""
                                                    } `}
                                                key={index}>
                                                <p className="WebsiteUrlPublisherSummaryTable">{cell?.orderId}</p>
                                                <p>{getDateFormatted(cell?.createdDate)}</p>
                                                <Tooltip id="show-publisher-mail" place="top" openOnClick />

                                                <p className={"text-ellipses"} data-tooltip-content={cell?.publisherEmail}
                                                    data-tooltip-id="show-publisher-mail">
                                                    {/* <CopyToClipboard text={cell?.publisherEmail}>
                                                        <ContentCopyIcon className="copy-icon" />
                                                    </CopyToClipboard> */}
                                                    {cell?.publisherEmail}
                                                </p>

                                                <Tooltip id="show-advertiser-mail" place="top" />
                                                {/* <CopyToClipboard text={cell?.advertiserEmail}>
                                                    <ContentCopyIcon className="copy-icon" />
                                                </CopyToClipboard> */}
                                                <p className={"text-ellipses"} data-tooltip-content={cell?.advertiserEmail}
                                                    data-tooltip-id="show-advertiser-mail">
                                                    {cell?.advertiserEmail}
                                                </p>


                                                <Tooltip id="show-website-url" place="top" />
                                                <p className={"text-ellipses"} data-tooltip-content={cell?.websiteUrl}
                                                    data-tooltip-id="show-website-url">
                                                    {/* <CopyToClipboard text={cell?.websiteUrl}>
                                                        <ContentCopyIcon className="copy-icon" />
                                                    </CopyToClipboard> */}
                                                    {cell?.websiteUrl}
                                                </p>


                                                <p>{getPriceType(cell?.priceType)}</p>
                                                <p>${(cell?.priceWithCommission)}</p>
                                                <p>{getOrderStatus(cell?.status)}</p>
                                                <p>{getPublisherActionMsg(cell?.status)}</p>
                                                <p>{getAdvertiserActionMsg(cell?.status)}</p>
                                            </div>
                                        ))}
                                </InfiniteScroll>
                            </section>
                        </div>
                    </div>
                </div>
                <ToastContainer />
            </div>
        </div>
    );
};
export default withAuth(OrdersComp);