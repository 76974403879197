import React, { useEffect, useState } from "react";
import search from "../../assets/add_Websites/search.svg";
import {
  setActiveSuccess,
  setAddFormDetailsEdit,
  setFormDisplay,
  setIsDeleteSuccess,
} from "../../reducers/addWebsite/addWebsitedataReducer";
import { useAppDispatch } from "../../store/reduxHooks";
import { setAddWebsiteData } from "../../reducers/addWebsite/addWebsitedataReducer";
import "./publisherSummaryTable.scss";
import { numberDisplay, formatTraffic } from "../../utils/filters/nameFilters";
import { AddWebsiteDetailsPayload } from "../../utils/types/main";
import PublisherDeletePopup from "./PublisherDeletePopup";
import PublisherActivePopup from "./PublisherActivePopup";
import InfiniteScroll from 'react-infinite-scroll-component';
import { customerWebsiteList } from "../../utils/api/addWebsite";
import Popup from '../popup/popup';

type Props = {
  setIsAddWebsite: React.Dispatch<React.SetStateAction<boolean>>;
  customerWebsiteTable: any;
};

const PublisherSummaryTable: React.FC<Props> = ({
  setIsAddWebsite,
  customerWebsiteTable,
}) => {
  const tableCells = [1, 2, 3, 4, 5, 6];
  const [filteredWebsiteTable, setFilteredWebsiteTable] = useState<any>([]);
  const [searchInput, setSearchInput] = useState<string>("");
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [hasMoreData, setHasMoreData] = useState<boolean>(true);
  const [rejectedReason, setRejectedReason] = useState<string>("");
  const [showRemarks, setShowRemarks] = useState<boolean>(false);
  const fetchMoreData = async () => {
    const response = await customerWebsiteList(10, pageNumber, searchInput);
    if (
      response?.status?.code === 200
    ) {
      setHasMoreData(response?.data?.customer?.pageInfo?.hasNext);
      const list = filteredWebsiteTable;
      const mergedResult = [...list, ...response?.data?.customer?.customerWebsites];
      setPageNumber(pageNumber + 1);
      setFilteredWebsiteTable(mergedResult);

    }
  };

  const searchWebsiteData = async (searchText: string) => {
    const response = await customerWebsiteList(10, 0, searchText);
    if (response?.status?.code === 200) {
      setSearchInput(searchText);
      setHasMoreData(response?.data?.customer?.pageInfo?.hasNext);
      setPageNumber(1);
      setFilteredWebsiteTable(response?.data?.customer?.customerWebsites);

    }
  };

  const [deleteData, setDeleteData] = useState<{
    display: boolean;
    id: string;
  }>({
    display: false,
    id: "",
  });
  const [inactiveData, setInactiveData] = useState<{
    display: boolean;
    id: string;
    isActivateAction: boolean;
  }>({
    display: false,
    id: "",
    isActivateAction: false,
  });
  const dispatch = useAppDispatch();

  const handleDelete = (id: string) => {
    setDeleteData({
      display: true,
      id,
    });
    dispatch(setIsDeleteSuccess(false));
  };
  const handleInactive = (id: string) => {
    setInactiveData({
      display: true,
      id,
      isActivateAction: false,
    });
    dispatch(setActiveSuccess(false));
  };

  const handleActive = (id: string) => {
    setInactiveData({
      display: true,
      id,
      isActivateAction: true,
    });
    dispatch(setActiveSuccess(false));
  };

  const showRejectedRemarks = (reason: string) => {
    setRejectedReason(reason);
    setShowRemarks(!showRemarks);
  };

  const formResume = (
    websiteData: AddWebsiteDetailsPayload,
    isEdit?: boolean
  ) => {

    setIsAddWebsite(false);
    dispatch(
      setAddWebsiteData({
        checkEligibility: {
          websiteEligibility: {
            websiteUrl: websiteData?.websiteUrl,
          },
        },
      })
    );

    if (isEdit) {
      dispatch(
        setAddFormDetailsEdit({
          domainAuthority: websiteData?.domainAuthority || 0,
          traffic: websiteData?.traffic || 0,
          websiteName: websiteData?.websiteName || "",
          websiteUrl: websiteData?.websiteUrl || "",
          guestPostPrice: websiteData?.guestPostPrice || 0,
          referingDomain: websiteData?.referingDomain || 0,
          linkInsertionPrice: websiteData?.linkInsertionPrice || 0,
          casinoPostPrice: websiteData?.casinoPostPrice || 0,
          sampleUrl: websiteData?.sampleUrl || "",
          tat: websiteData?.tat || 0,
          postGuideline: websiteData?.postGuideline || "",
          contactNumber: websiteData?.contactNumber || "",
          skypeId: websiteData?.skypeId || "",
          websiteCategoryIdList: websiteData?.websiteCategoryIdList || [],
          minPostWordCount: websiteData?.minPostWordCount || 0,
          guaranteePostValidity: websiteData?.guaranteePostValidity || '',
          backLinkType: websiteData?.backLinkType || '',
          maxBackLinks: websiteData?.maxBackLinks || 0,
          cryptoPostPrice: websiteData?.cryptoPostPrice || 0,
          cbdPostPrice: websiteData?.cbdPostPrice || 0,
          adultPostPrice: websiteData?.adultPostPrice || 0,
          isSenstiveAccepted: websiteData?.isSenstiveAccepted || false,
          spamScore: websiteData?.spamScore || 0,
          status: websiteData?.status || ''
        })
      );
    }

    dispatch(
      setFormDisplay({
        begin: false,
        meta: false,
        html: false,
        dns: false,
        details: true,
        isAdd: false,
      })
    );
  };
  useEffect(() => {
    setFilteredWebsiteTable(customerWebsiteTable);
  }, [customerWebsiteTable]);

  return (
    <>
      <div className="PublisherSummaryTable__Con">
        <section className="PublisherSummaryTable__Con__Inner">
          <header className="PublisherSummaryTable__Con__Header">
            <p>My Websites</p>
            <article>
              <div>
                <input
                  type="text"
                  value={searchInput}
                  placeholder="Search"
                  onChange={(e: any) => setSearchInput(e.target.value)}
                />
                {searchInput !== '' && <img src={search} alt="" onClick={() => { searchWebsiteData(searchInput); }} />}
                {searchInput !== '' && <span className="material-icons lightGrey close-img-my-website"
                  onClick={() => { searchWebsiteData(''); }}>close</span>}
              </div>
              <button onClick={() => setIsAddWebsite(false)}>
                Add Website
              </button>
            </article>
          </header>
        </section>



        <section className="PublisherSummaryTable__Con__Table__Header_mywebsite">
          <article>
            <h5>Website URL</h5>
            <p>DA</p>
            <p>Traffic</p>
            <p>DR</p>
            <p>Spam Score</p>
            <div className="pricediv">
              <p className="">General </p>
              <p className="">Link Insertion</p>
              <h4>
                {"  "}
                <span>Post Price ($)</span>
                <span>Crypto/Finance</span>
              </h4>
              <p className="">Casino</p>
              <p className="">CBD/Vape </p>
              <p className="">Adult </p>
            </div>
            <p>Status</p>
            <h6>Action</h6>
          </article>
          <InfiniteScroll
            dataLength={filteredWebsiteTable.length}
            next={fetchMoreData}
            hasMore={hasMoreData}
            loader={''}
            height={'calc(62vh - 10px)'}
          >
            {filteredWebsiteTable.length !== 0 &&
              filteredWebsiteTable?.map((cell: any, index: number) => (
                <div
                  className={`PublisherSummaryTable__Con__Table__Cell_mywebsite ${index % 2 === 0
                    ? "PublisherSummaryTable__Con__Table__Cell__grey__bg"
                    : ""
                    } ${index === tableCells.length - 1
                      ? "PublisherSummaryTable__Con__Table__Cell__last__border"
                      : ""
                    } `}
                  key={index}
                >
                  <a
                    href={cell?.websiteUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <p style={{ textAlign: 'left' }} className="WebsiteUrlPublisherSummaryTable">{cell?.websiteUrl}</p>
                  </a>
                  <p>{numberDisplay(cell?.domainAuthority)}</p>
                  <p>{formatTraffic(cell?.traffic)}</p>
                  <p>{numberDisplay(cell?.referingDomain)}</p>
                  <p>{numberDisplay(cell?.spamScore)}</p>
                  <div className="pricediv">
                    <p className="" >{numberDisplay(cell?.guestPostPrice)}</p>
                    <p className=""> {numberDisplay(cell?.linkInsertionPrice)}</p>
                    <p className="">{numberDisplay(cell?.cryptoPostPrice)}</p>
                    <p className="">{numberDisplay(cell?.casinoPostPrice)}</p>
                    <p className="">{numberDisplay(cell?.cbdPostPrice)}</p>
                    <p className="">{numberDisplay(cell?.adultPostPrice)}</p>
                  </div>
                  <p>{cell?.status}</p>
                  {cell?.status === "Incomplete" && (
                    <h6>
                      <span style={{ textDecoration: 'underline' }} onClick={() => formResume(cell, true)}>
                        Resume
                      </span>
                      <span style={{ textDecoration: 'underline' }} onClick={() => handleDelete(cell?.id)}>Delete</span>
                    </h6>
                  )}{" "}
                  {cell?.status === "Awaiting" && (
                    <h6>
                      <span style={{ textDecoration: 'underline' }} onClick={() => formResume(cell, true)}>Edit</span>

                      <span style={{ textDecoration: 'underline' }} onClick={() => handleDelete(cell?.id)}> Delete</span>
                    </h6>
                  )}
                  {cell?.status === "Approved" && (
                    <h6>
                      <span style={{ textDecoration: 'underline' }} onClick={() => formResume(cell, true)}>
                        Edit
                      </span>
                      <span style={{ textDecoration: 'underline' }} onClick={() => handleInactive(cell?.id)}>Mark as Inactive</span>
                    </h6>
                  )}
                  {cell?.status === "Rejected" && (
                    <h6>
                      <span style={{ textDecoration: 'underline' }} onClick={() => formResume(cell, true)}>Edit</span>
                      <span style={{ textDecoration: 'underline' }} onClick={() => showRejectedRemarks(cell.rejectedReason)}>Remarks</span>
                    </h6>
                  )}{" "}
                  {cell?.status === "Inactive" && (
                    <h6>
                      <span style={{ textDecoration: 'underline' }} onClick={() => handleActive(cell?.id)}>Mark as Active</span>
                    </h6>
                  )}{" "}
                </div>
              ))}
          </InfiniteScroll>
        </section>

      </div>
      {deleteData?.display && (
        <PublisherDeletePopup
          setDeleteData={setDeleteData}
          deleteData={deleteData}
        />
      )}
      {inactiveData?.display && (
        <PublisherActivePopup
          setInactiveData={setInactiveData}
          inactiveData={inactiveData}
        />
      )}
      {showRemarks && <Popup
        content={<>
          <div><div className="popup-header">Rejection Reason</div>
            <div className="popup-content-rejected-reason">{rejectedReason}</div>
            <div className="btnDiv">
              <button className="wishList_btn addToCart_btn" onClick={() => { showRejectedRemarks(""); }} > Close  </button>
            </div>
          </div>
        </>}
        handleClose={showRejectedRemarks}
      />}
    </>
  );
};

export default PublisherSummaryTable;
