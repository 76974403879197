import axios from "axios";
import {  REGISTER,ADD_CUSTOMER_BY_ADMIN } from "../constants/url";
import { RegisterPayload } from "../types/main";
export const registerPost = async (registerData: RegisterPayload,recaptchaToken :string | undefined) => {
  try {
    const response = await axios.post(process.env.REACT_APP_BASE + REGISTER, registerData,
      { headers: { 'X-Recaptcha-Token': recaptchaToken}});

    return response.data;
  } catch (err:any) {
    return err?.response?.data;
  }
};

export const registerAdminPost = async (registerData: RegisterPayload) => {
  try {
    const accessToken = sessionStorage.getItem('access-token');
    const response = await axios.post(process.env.REACT_APP_BASE + ADD_CUSTOMER_BY_ADMIN, registerData,
      { headers: { 'Authorization': 'Bearer '+accessToken}});

    return response.data;
  } catch (err:any) {
    return err?.response?.data;
  }
};