import React, { useEffect, useState } from "react";
import "./publisherWebsitesMid.scss";
import manThinking from "../../assets/add_Websites/manThinking.svg";
import { useAppSelector } from "../../store/reduxHooks";
import AddWebsiteForm from "../addWebsiteForm/AddWebsiteForm";
import PublisherSummaryTable from "../publisherSummaryTable/PublisherSummaryTable";
import { customerWebsiteList } from "../../utils/api/addWebsite";
import Loader from "../loader/Loader";

const PublisherWebsitesMid = () => {
  const [isAddWesite, setIsAddWebsite] = useState<boolean>(true);
  const [showTable, setShowTable] = useState<boolean>(true);
  const [isTableLoading, setIstableLoading] = useState<boolean>(true);
  const [customerWebsiteTable, setCustomerWebsiteTable] = useState<any>([]);
  const { isDeleteSuccess, isActiveSuccess } = useAppSelector((state) => ({
    isDeleteSuccess: state?.addWebsiteState?.isDeleteSuccess,
    isActiveSuccess: state?.addWebsiteState?.isActiveSuccess,
  }));
  const getCustomerWebsiteList = async () => {
    setIstableLoading(true);
    const response = await customerWebsiteList(10, 0,'');

    if (
      response?.status?.code === 200 &&
      response?.data?.customer?.customerWebsites.length !== 0
    ) {
      setCustomerWebsiteTable(response?.data?.customer?.customerWebsites);
      setShowTable(true);
      setIstableLoading(false);
    } else {
      setShowTable(false);
      setIstableLoading(false);
    }
  };
  useEffect(() => {
    getCustomerWebsiteList();
  }, [isAddWesite]);

  useEffect(() => {
    if (!isDeleteSuccess) return;
    getCustomerWebsiteList();
  }, [isDeleteSuccess]);
  useEffect(() => {
    if (!isActiveSuccess) return;
    getCustomerWebsiteList();
  }, [isActiveSuccess]);
  return (
    <div className="publisherWebsiteMid__Con">
      {!isTableLoading &&
        isAddWesite &&
        (showTable ? (
          <PublisherSummaryTable
            customerWebsiteTable={customerWebsiteTable}
            setIsAddWebsite={setIsAddWebsite}
          />
        ) : (
          <div className="publisherWebsiteMid__initial">
            <h4>My Website</h4>
            <div className="publisherWebsiteMid__initial__Add__Website__Con">
              <div className="">
                <p>Now you are ready to add website.</p>
                <button
                  className="publisherWebsiteMid__btn"
                  onClick={() => setIsAddWebsite(false)}
                >
                  Add Website
                </button>
              </div>
              <img src={manThinking} className="man_thinking" alt="" />
            </div>
          </div>
        ))}
      {!isTableLoading && !isAddWesite && (
        <AddWebsiteForm
          setIsAddWebsite={setIsAddWebsite}
          setShowTable={setShowTable}
        />
      )}
      {isTableLoading && (
        <div className="publisherMid__loader__con">
          <Loader size="lg" />
        </div>
      )}

      <p className="publisherWebsiteMid__Footer">
        © 2024 Powered By Outreach Kart | All Rights Reserved
      </p>
    </div>
  );
};

export default PublisherWebsitesMid;
