import axios from "axios";
import {  LoginPayload } from "../types/main";
import { LOGIN,LOGIN_ADMIN,RESEND_VERIFICATION_LINK } from "../constants/url";

export const loginPost = async (
  loginData:LoginPayload, recaptchaToken :string | undefined ,otp :string | undefined
) => {
  try {
    console.log("  otp  ------------->>>>>>   ",otp)
    if(otp){
      loginData = {...loginData,otp}
    }
    const response = await axios.post(process.env.REACT_APP_BASE + LOGIN ,loginData,{
      headers: { 'Api-Key': 'outreachkart','X-Recaptcha-Token': recaptchaToken }
    });
    console.log(response);
    return response.data;
  } catch (err: any) {
    console.log("err   ----?   ", err);
    return err?.response?.data;
  }
};

export const resendVerificationLink = async (
  emailId :string | undefined, recaptchaToken :string | undefined
) => {
  try {
    const response = await axios.post(process.env.REACT_APP_BASE + RESEND_VERIFICATION_LINK ,{emailId},{
      headers: { 'X-Recaptcha-Token': recaptchaToken }
    });
    console.log(response);
    return response.data;
  } catch (err: any) {
    console.log("err   ----?   ", err);
    return err?.response?.data;
  }
};

export const loginAdminPost = async (
  emailId:string
) => {
  try {
    console.log('loginData   -->  ',emailId);
    const accessToken = sessionStorage.getItem('admin-customer-access-token');
    const response = await axios.post(process.env.REACT_APP_BASE + LOGIN_ADMIN,{emailId},{
      headers: { 'Authorization': 'Bearer '+accessToken}
    });

    return response.data;
  } catch (err) {
    return err;
  }
};