import React from "react";
import Footer from "../../components/footer/Footer";
import Header from "../../components/Header";
import "./pageNotFound.scss";
const PageNotFound = () => {
  return (
    <div className="signup-bg">
      <Header />
      <div className="createPassword__pagenot__found">
        <h4>404</h4>
        <p>Page not found</p>
        <a href="https://dev.outreachkart.de/">Go to Home </a>
      </div>
      <div className="footer-bg">
        <Footer />
      </div>
    </div>
  );
};

export default PageNotFound;
