import React from "react";
import "./DefaultLoader.scss";
type Props = {
  size: "sm" | "md" | "lg";
  text?: string;
};

const AppLoader: React.FC<Props> = ({ size, text }) => {
  return (
    <div id="cover-spin" className={size} >{text}</div>
    //   {size === "lg" && <div id="cover-spin"></div>}
    //   {size === "md" && <div id="cover-spin"></div>}
    //   {size === "sm" && <div id="cover-spin"></div>}
    // </div>
  );
};

export default AppLoader;
