import React, { useEffect } from 'react';

interface ReCaptchaProps {
  onVerify: (token: string | null) => void;
}

interface WindowWithGrecaptcha extends Window {
  grecaptcha: {
    execute: (siteKey: string, options: { action: string }) => Promise<string>;
    ready: (callback: () => void) => void;
  };
}

const executeReCaptcha = (onVerify: (token: string | null) => void) => {
  // Execute reCAPTCHA
  (window as unknown as WindowWithGrecaptcha).grecaptcha.execute(process.env.REACT_APP_RECAPTCHA_SITE_KEY || '', { action: 'submit' })
    .then((token: string) => {
      onVerify(token);
    })
    .catch((error: any) => {
      console.error('Error executing reCAPTCHA:', error);
    });
};

const returnReCaptcha = async () => {
    try {
      // Execute reCAPTCHA
      const token = await (window as unknown as WindowWithGrecaptcha).grecaptcha.execute(process.env.REACT_APP_RECAPTCHA_SITE_KEY || '', { action: 'submit' });
      return token;
    } catch (error) {
      console.error('Error executing reCAPTCHA:', error);
      return ""; // Return empty string in case of error
    }
  };

const ReCaptchaComponent: React.FC<ReCaptchaProps> = ({ onVerify }) => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://www.google.com/recaptcha/api.js?render='+process.env.REACT_APP_RECAPTCHA_SITE_KEY;
    script.async = true;

    script.onload = () => {
      (window as unknown as WindowWithGrecaptcha).grecaptcha.ready(() => {
        // reCAPTCHA is ready, execute it
        executeReCaptcha(onVerify);
      });
    };

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Run only once when the component mounts

  return null; // Invisible reCAPTCHA does not render any UI element
};

export const generateToken = (onVerify: (token: string | null) => void) => {
  executeReCaptcha(onVerify);
};

export const reGenerateToken = async () => {
   return returnReCaptcha();
  };

export default ReCaptchaComponent;
