import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ICustomerProfile {
  title: string;
  role: string;
}

type InitialState = {
  isLoggedIn: boolean;
  customerProfile: ICustomerProfile;
};
const initialState: InitialState = {
  isLoggedIn: JSON.parse(sessionStorage.getItem("isLoggedIn")!)
    ? JSON.parse(sessionStorage.getItem("isLoggedIn")!)
    : false,
  customerProfile: JSON.parse(sessionStorage.getItem("customerProfile")!)
    ? JSON.parse(sessionStorage.getItem("customerProfile")!)
    : {
        title: "",
        role: "",
      },
};

const loginSlice = createSlice({
  name: "Login",
  initialState,
  reducers: {
    logged: (state, action: PayloadAction<boolean>) => {
      state.isLoggedIn = action.payload;
      sessionStorage.setItem("isLoggedIn", JSON.stringify(action.payload));
    },
    setCustomerProfile: (state, action: PayloadAction<ICustomerProfile>) => {
      state.customerProfile = action.payload;
      sessionStorage.setItem("customerProfile", JSON.stringify(action.payload));
    },
  },
});

export default loginSlice.reducer;
export const { logged, setCustomerProfile } = loginSlice.actions;
