import dashboard from "../../assets/sidebar/dashboard.svg";
import website from "../../assets/sidebar/websites.svg";
import order from "../../assets/sidebar/order.svg";
import payments from "../../assets/sidebar/payments.svg";
import report from "../../assets/sidebar/report.svg";
//import coupon from "../../assets/sidebar/coupon.svg";
import arrow from "../../assets/sidebar/Shape.svg";
import { MenuItem } from "../../utils/types/main";

export const menuItem: MenuItem[] = [
  {
    title: "Dashboard",
    image: dashboard,
    next: arrow,
    link: "/dashboard",
  },
  {
    title: "My Websites",
    image: website,
    next: arrow,
    link: "/dashboard/publisherWebsites",
  },
  {
    title: "My Orders",
    image: order,
    next: arrow,
    link: "/dashboard/orderDashboard",
  },
  {
    title: "Payments",
    image: payments,
    next: arrow,
    link: "/dashboard/paymentDashboard",
  },
  // {
  //   title: "Report",
  //   image: report,
  //   next: arrow,
  //   link: "/dashboard/reportDashboard",
  // },
  // {
  //   title: "Coupon",
  //   image: coupon,
  //   next: arrow,
  //   link: "/dashboard/couponDashboard",
  // },
];

export const menuItemAdvertiser: MenuItem[] = [
  {
    title: "Dashboard",
    image: dashboard,
    next: arrow,
    link: "/dashboard",
  },
  {
    title: "Marketplace",
    image: website,
    next: arrow,
    link: "/dashboard/marketPlace",
  },
  {
    title: "My Orders",
    image: order,
    next: arrow,
    link: "/dashboard/orderDashboard",
  },
  // {
  //   title: "Content",
  //   image: payments,
  //   next: arrow,
  //   link: "/dashboard/marketPlace",
  // },
  {
    title: "Billing",
    image: report,
    next: arrow,
    link: "/dashboard/billing",
  },
  // {
  //   title: "Reports",
  //   image: coupon,
  //   next: arrow,
  //   link: "/dashboard/marketPlace",
  // }
];