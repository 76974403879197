import axios from "axios";
import { ADDWEBSITE_DETAILS,ADD_ADMIN_WEBSITE} from "../constants/url";
import { AddWebsiteDetailsPayload,AddAdminWebsiteDetailsPayload } from "../types/main";
export const addWebsiteDetailsPost = async (
  addWebsiteDetailsData: AddWebsiteDetailsPayload
) => {
  try {
    const accessToken = sessionStorage.getItem('access-token');
    const response = await axios.post(
      process.env.REACT_APP_BASE + ADDWEBSITE_DETAILS,
      addWebsiteDetailsData,{ headers: { 'Authorization': 'Bearer '+accessToken}}
    );

    return response.data;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const addAdminWebsiteDetailsPost = async (
  addWebsiteDetailsData: AddAdminWebsiteDetailsPayload
) => {
  try {
    const accessToken = sessionStorage.getItem('access-token');
    const response = await axios.post(
      process.env.REACT_APP_BASE + ADD_ADMIN_WEBSITE,
      addWebsiteDetailsData,{ headers: { 'Authorization': 'Bearer '+accessToken}}
    );

    return response.data;
  } catch (err) {
    console.log(err);
    return err;
  }
};
