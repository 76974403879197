import Footer from "../../components/footer/Footer";
import Header from "../../components/Header";
import PrivacyMid from "../../components/privacyMid/PrivacyMid";
import "./privacyPage.scss";

const PrivacyPage = () => {
  return (
    <div className="privacy-bg">
      <Header />
      <PrivacyMid />
      <div className="footer-bg">
        <Footer />
      </div>
    </div>
  );
};

export default PrivacyPage;
