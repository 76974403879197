import selected from "../../assets/role/selected.svg";
import unselected from "../../assets/role/unselect.svg";
import {
  SelectedRole,
  RegisterPayload,
  LoginPayload,
  AdminLoginPayload,
  ForgotPasswordPayload,
  ResetPasswordPayload,
  MarketPlaceSortObj
} from "../types/main";

export const allUnselected: SelectedRole[] = [
  {
    selected: false,
    image: unselected,
    role: "PUBLISHER",
    background: "#8A8A8A",
    color: "#979797",
  },
  {
    selected: false,
    image: unselected,
    role: "ADVERTISER",
    background: "#8A8A8A",
    color: "#979797",
  },
  {
    selected: false,
    image: unselected,
    role: "PUBADV",
    background: "#8A8A8A",
    color: "#979797",
  },
];

export const publisherSelected: SelectedRole[] = [
  {
    selected: true,
    image: selected,
    role: "PUBLISHER",
    background: "#525B88",
    color: "#525B88",
  },
  {
    selected: false,
    image: unselected,
    role: "ADVERTISER",
    background: "#8A8A8A",
    color: "#979797",
  },
  {
    selected: false,
    image: unselected,
    role: "BOTH",
    background: "#8A8A8A",
    color: "#979797",
  },
];

export const advertisementSelected: SelectedRole[] = [
  {
    selected: false,
    image: unselected,
    role: "PUBLISHER",
    background: "#8A8A8A",
    color: "#979797",
  },
  {
    selected: true,
    image: selected,
    role: "ADVERTISER",
    background: "#525B88",
    color: "#525B88",
  },
  {
    selected: false,
    image: unselected,
    role: "BOTH",
    background: "#8A8A8A",
    color: "#979797",
  },
];

export const bothSelected: SelectedRole[] = [
  {
    selected: false,
    image: unselected,
    role: "PUBLISHER",
    background: "#8A8A8A",
    color: "#979797",
  },
  {
    selected: false,
    image: unselected,
    role: "ADVERTISER",
    background: "#8A8A8A",
    color: "#979797",
  },
  {
    selected: true,
    image: selected,
    role: "PUBADV",
    background: "#525B88",
    color: "#525B88",
  },
];
export const registerAdminInitial: RegisterPayload = {
  emailId: "",
  firstName: "",
  password: "",
  confirmPassword: "",
  lastName: "",
  role:""
};

export const registerInitial: RegisterPayload = {
  emailId: "",
  firstName: "",
  password: "",
  confirmPassword: "",
  lastName: "",
};
export const loginInitial: LoginPayload = {
  emailId: "",
  password: "",
};
export const adminLoginInitial: AdminLoginPayload = {
  emailId: "",
};
export const forgotPasswordInitial: ForgotPasswordPayload = {
  emailId: "",
};
export const resetPasswordInitial: ResetPasswordPayload = {
  newPassword: "",
  confirmNewPassword: "",
};
export const sortMarketPlaceArray: MarketPlaceSortObj[] = [
  {
    friendlyName: "General Post Price - Low to High",
    sortBy: "guestPostPrice",
    orderBy: "ASC",
  },
  {
    friendlyName: "General Post Price - High to Low",
    sortBy: "guestPostPrice",
    orderBy: "DESC",
  },  
  {
    friendlyName: "Link Insertion Price - Low to High",
    sortBy: "linkInsertionPrice",
    orderBy: "ASC",
  },
  {
    friendlyName: "Link Insertion Price - High to Low",
    sortBy: "linkInsertionPrice",
    orderBy: "DESC",
  },
  {
    friendlyName: "Casino Post Price - Low to High",
    sortBy: "casinoPostPrice",
    orderBy: "ASC",
  },
  {
    friendlyName: "Casino Post Price - High to Low",
    sortBy: "casinoPostPrice",
    orderBy: "DESC",
  },
  {
    friendlyName: "Crypto/Finance Post Price - Low to High",
    sortBy: "cryptoPostPrice",
    orderBy: "ASC",
  },
  {
    friendlyName: "Crypto/Finance Post Price - High to Low",
    sortBy: "cryptoPostPrice",
    orderBy: "DESC",
  },
  {
    friendlyName: "CBD/Vape Post Price - Low to High",
    sortBy: "cbdPostPrice",
    orderBy: "ASC",
  },
  {
    friendlyName: "CBD/Vape Post Price - High to Low",
    sortBy: "cbdPostPrice",
    orderBy: "DESC",
  }, 

  {
    friendlyName: "Adult Post Price - Low to High",
    sortBy: "adultPostPrice",
    orderBy: "ASC",
  },
  {
    friendlyName: "Adult Post Price - High to Low",
    sortBy: "adultPostPrice",
    orderBy: "DESC",
  },
  {
    friendlyName: "Domain Authority - Low to High",
    sortBy: "domainAuthority",
    orderBy: "ASC",
  },

  {
    friendlyName: "Domain Authority - High to Low",
    sortBy: "domainAuthority",
    orderBy: "DESC",
  },
  {
    friendlyName: "Spam Score - Low to High",
    sortBy: "spamScore",
    orderBy: "ASC",
  },

  {
    friendlyName: "Spam Score - High to Low",
    sortBy: "spamScore",
    orderBy: "DESC",
  },
  {
    friendlyName: "Traffic - Low to High",
    sortBy: "traffic",
    orderBy: "ASC",
  },
  {
    friendlyName: "Traffic - High to Low",
    sortBy: "traffic",
    orderBy: "DESC",
  },
  {
    friendlyName: "Domain Rating - Low to High",
    sortBy: "referingDomain",
    orderBy: "ASC",
  },
  {
    friendlyName: "Domain Rating - High to Low",
    sortBy: "referingDomain",
    orderBy: "DESC",
  }
];

export const postTypeArray: any[] = [
  {  
    friendlyName: "General Post",
    id: "guestPostPrice",
  },
  {
    friendlyName: "Link Insertion",
    id: "linkInsertionPrice",
  },
  {
    friendlyName: "Casino Post ",
    id: "casinoPostPrice",
  },
  {
    friendlyName: "Crypto/Finance Post",
    id: "cryptoPostPrice",
  },
  {
    friendlyName: "CBD/Vape Post",
    id: "cbdPostPrice",
  },
  {
    friendlyName: "Adult Post",
    id: "adultPostPrice",
  }
];

export const selectRoleInterestArray: any[] = [
  {
    friendlyName: "Advertiser",
    id: "ADVERTISER",
  },
  {
    friendlyName: "Publisher",
    id: "PUBLISHER",
  },  
  {
    friendlyName: "Both",
    id: "PUBADV",
  }
]

export const selectVerifiedArray: any[] = [
  {
    friendlyName: "Yes",
    id: "YES",
  },
  {
    friendlyName: "No",
    id: "NO",
  }
]

export const selectOrderStatusArray: any[] = [
  {
    friendlyName: "Pending",
    id: "PENDING",
  },
  {
    friendlyName: "In Progress",
    id: "IN_PROGRESS",
  },  
  {
    friendlyName: "Revision Needed",
    id: "REVISION_NEEDED",
  },
  {
    friendlyName: "Delivered",
    id: "DELIVERED",
  },
  {
    friendlyName: "Completed",
    id: "COMPLETED",
  },  
  {
    friendlyName: "Rejected",
    id: "REJECTED",
  },
  {
    friendlyName: "Cancelled",
    id: "CANCELLED",
  }
]

export const selectOrderTypeArray: any[] = [
  {
    friendlyName: "Casino Post",
    id: "CASINO_POST",
  },
  {
    friendlyName: "Link Insertion",
    id: "LINK_INSERTION",
  },  
  {
    friendlyName: "General Post",
    id: "GUEST_POST",
  },
  {
    friendlyName: "Crypto/Finance",
    id: "CRYPTO_POST",
  },
  {
    friendlyName: "Adult",
    id: "ADULT_POST",
  },  
  {
    friendlyName: "CBD/Vape",
    id: "CBD_POST",
  }
]

export const selectDateRangeArray: any[] = [
  {
    friendlyName: "Last 30 Days",
    id: "LAST_30_DAYS",
  },
  {
    friendlyName: "Last 60 Days",
    id: "LAST_60_DAYS",
  },  
  {
    friendlyName: "Last 120 Days",
    id: "LAST_120_DAYS",
  },  
  {
    friendlyName: "Custom Date Range",
    id: "CUSTOM_DATE_RANGE",
  }
]


export const selectInitiatedByArray: any[] = [
  {
    friendlyName: "Admin",
    id: "ADMIN",
  },
  {
    friendlyName: "Advertiser",
    id: "ADVERTISER",
  },
]

export const maxBacklinkArray: any[] = [
  {
    friendlyName: "1",
    id: 1,
  },
  {
    friendlyName: "2",
    id: 2,
  },
  {
    friendlyName: "3",
    id: 3,
  },
  {
    friendlyName: "4",
    id: 4,
  },
  {
    friendlyName: "5",
    id: 5,
  },
  {
    friendlyName: "6",
    id: 6,
  },
]

export const backlinkTypeArray: any[] = [
  {
    friendlyName: "Dofollow",
    id: "DOFOLLOW",
  },
  {
    friendlyName: "Nofollow",
    id: "NOFOLLOW",
  },
]

export const guaranteePostValidityArray: any[] = [
  {
    friendlyName: "Yearly",
    id: "YEARLY",
  },
  {
    friendlyName: "Half Yearly",
    id: "HALF_YEARLY",
  },
  {
    friendlyName: "Lifetime",
    id: "LIFETIME",
  },
]

export const adminWebsiteApprovalColumnsArray: any[] = [
  {
    friendlyName: "Post Validity",
    id: "guaranteePostValidity",
  },
  {
    friendlyName: "Min. Word Count",
    id: "minPostWordCount",
  },
  {
    friendlyName: "Max. Backlinks",
    id: "maxBackLinks",
  },
  {
    friendlyName: "TAT (Days)",
    id: "tat",
  },
  {
    friendlyName: "Traffic",
    id: "traffic",
  },
  {
    friendlyName: "DA",
    id: "domainAuthority",
  },
  {
    friendlyName: "DR",
    id: "referingDomain",
  },
  {
    friendlyName: "Spam Score",
    id: "spamScore",
  },
  {
    friendlyName: "General Post Price",
    id: "guestPostPrice",
  },
  {
    friendlyName: "Link Insertion Price",
    id: "linkInsertionPrice",
  },
  {
    friendlyName: "Crypto/Finance Post",
    id: "cryptoPostPrice",
  },
  {
    friendlyName: "Casino Post Price",
    id: "casinoPostPrice",
  },
  {
    friendlyName: "CBD/Vape Post",
    id: "cbdPostPrice",
  },
  {
    friendlyName: "Adult Post Price",
    id: "adultPostPrice",
  },
]

export const websiteStatusArray: any[] = [
  {
    friendlyName: "Awaiting",
    id: "COMPLETED_VERIFIED",
  },
  {
    friendlyName: "Approved",
    id: "APPROVED",
  },
  {
    friendlyName: "Rejected",
    id: "REJECTED",
  },
  {
    friendlyName: "Incomplete",
    id: "INCOMPLETED_VERIFIED",
  },
  {
    friendlyName: "Inactive",
    id: "INACTIVE",
  },
  {
    friendlyName: "Initiated",
    id: "INITIATED",
  },
  {
    friendlyName: "Inactive price",
    id: "INACTIVE_PRICE",
  },
]
