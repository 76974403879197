import Footer from "../../components/footer/Footer";
import Header from "../../components/Header";
import ServiceMid from "../../components/serviceMid/ServiceMid";
import "./service.scss";

const Services = () => {
  return (
    <div className="about-bg">
      <Header />
      <ServiceMid />
      <div className="footer-bg">
        <Footer />
      </div>
    </div>
  );
};

export default Services;
