import axios from "axios";
import {  PAYMENT_DETAILS_HISTORY,PAYMENT_STATUS,PAYMENT_WIHDRAW } from "../constants/url";

export const getPaymentURL = async (params: string) => {
  try {
    const accessToken = sessionStorage.getItem('access-token');
    const response = await axios.get(process.env.REACT_APP_BASE + PAYMENT_DETAILS_HISTORY,	
      {
        params: { type: params },
        headers: { 'Authorization': 'Bearer '+accessToken}
      }
    );
    return response.data;
  } catch (err) {
    console.log(err);
    return err;
  }
};
export const getPaymentStatus = async () => {
  try {
    const accessToken = sessionStorage.getItem('access-token');
    const response = await axios.get(process.env.REACT_APP_BASE + PAYMENT_STATUS,	
      {
        headers: { 'Authorization': 'Bearer '+accessToken}
      }
    );
    return response.data;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const withdraw = async () => {
  try {
    const accessToken = sessionStorage.getItem('access-token');
    const response = await axios.get(process.env.REACT_APP_BASE + PAYMENT_WIHDRAW,	
      {
        headers: { 'Authorization': 'Bearer '+accessToken}
      }
    );
    return response.data;
  } catch (err) {
    console.log(err);
    return err;
  }
};