export const BASE_URL = "https://api.dev.outreachkart.de/api/v1";
export const BLOG_POST = "/api/v1/blog/post";
export const CLIENT_FEEDBACK = "/api/v1/client/feedback";
export const FETCHING_WEBSITE = "/api/v1/top/websites";
export const FETCH_STATS = "/api/v1/total/stats";
export const STILL_DOUBTS = "/api/v1/still/doubts";
export const REGISTER = "/api/v1/customer/register";
export const LOGIN = "/api/v1/customer/login";
export const LOGIN_ADMIN = "/api/v1/admin/customer/login"; 
export const DASHBOARD_DATA = "/api/v1/customer/dashboard";
export const CUSTOMER_DATA = "/api/v1/customer/profile";
export const CONTACT_US = "/api/v1/submit/query";
export const CHECK_WEBSITE_ELIGIBILITY = "/api/v1/check/website/eligibility";
export const WEBSITE_CATEGORIES = "/api/v1/website/categories";
export const ADDWEBSITE_DETAILS = "/api/v1/customer/website";
export const ACTIVATE_WEBSITES = "/api/v1/customer/website/activate";
export const IN_ACTIVATE_WEBSITES = "/api/v1/customer/website";
export const CHECK_WEBSITE_VERIFIED = "/api/v1/customer/website/verify";
export const FORGOT_PASSWORD = "/api/v1/customer/forgot/password";
export const RESET_PASSWORD = "/api/v1/customer/reset/password";
export const FORGOT_RESET_PASSWORD = "/api/v1/customer/forgot/password/verify";
export const ADMIN_WEBSITE_DETAILS = "/api/v1/admin/website/list";
export const ADMIN_WEBSITE_COUNT = "/api/v1/admin/website/approval/count";
export const ADMIN_WEBSITE_REJECT = "/api/v1/admin/website/reject";
export const ADMIN_WEBSITE_APPROVE = "/api/v1/admin/website/approve";
export const MARKET_PLACE_LIST = "/api/v1/marketplace";
export const ADD_TO_CART_WISH = "/api/v1/customer/bag";
export const CART_COUNT = "/api/v1/customer/bag/icon/";
export const ADD_ADMIN_WEBSITE = "/api/v1/admin/website";
export const CART_WISH_LIST = "/api/v1/customer/bag/";
export const PLACE_ORDER_URL = "/api/v1/customer/order";
export const ORDER_SUCCESSS_URL = "/api/v1/customer/payment/success";
export const MY_ORDER_URL = "/api/v1/customer/order/";
export const PUB_ORDER_ACTION = "/api/v1/customer/order/publisher/action";
export const ADV_ORDER_ACTION = "/api/v1/customer/order/advertiser/action";
export const FETCH_REMARK_URL = "/api/v1/customer/order/remarks";
export const DOWNLOAD_FILE_URL = "/api/v1/customer/file/download/";
export const PAYMENT_DETAILS_HISTORY = "/api/v1/customer/iframe";
export const PAYMENT_STATUS = "/api/v1/customer/payee/status";
export const PAYMENT_WIHDRAW = "/api/v1/customer/payee/bill/submit";
export const CUSTOMER_DETAILS = "/api/v1/admin/customers";
export const CUSTOMER_WEBSITES = "/api/v1/admin/customer/websites";
export const ADMIN_SAVE_BLOG = "/api/v1/admin/blog";
export const GET_BLOG = "/api/v1/blog";
export const GET_BLOG_CATEGORIES = "/api/v1/admin/blog/categories";
export const ADD_BLOG_CATEGORY = "/api/v1/admin/blog/category";
export const CATEGORIES_LIST = "/api/v1/blog/categories";
export const BLOG_BY_PERMLINK = "/api/v1/blog/permalink/";
export const AUTHORS_LIST = "/api/v1/admin/blog/authors";
export const ADD_AUTHOR = "/api/v1/admin/blog/author";
export const GET_AUTHOR = "/api/v1/blog/author/";
export const EXPORT_CUSTOMERS = "/api/v1/excel/download/customers";
export const ADMIN_STATS = "/api/v1/admin/stats";
export const ORDERS_LIST = "/api/v1/admin/orders";
export const ADVERTISER_BILLING_LIST = "/api/v1/customer/invoices";
export const ADD_CUSTOMER_BY_ADMIN = "/api/v1/admin/customer/register";
export const PLACE_ORDER_BY_ADMIN_URL = "/api/v1/admin/customer/order";
export const ADMIN_BILLING_URL ="/api/v1/admin/customer/invoices";
export const RESEND_VERIFICATION_LINK ="/api/v1/customer/generate/verify/email";
export const DELETE_WEBSITE = "/api/v1/admin/customer/website";
export const ORDER_COUNT = "/api/v1/order/stats";
export const WEBSITE_DETAILS = "/api/v1/admin/website/";
export const EXPORT_WEBSITE = "/api/v1/excel/download/websites"