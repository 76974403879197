import React, { useEffect, useState } from "react";
import search from "../../assets/add_Websites/search.svg";
import { useAppSelector, useAppDispatch } from "../../store/reduxHooks";
import "./adminSummaryTable.scss";
import {
  numberDisplay,
  websiteNAMEFilter,
  formatTraffic
} from "../../utils/filters/nameFilters";
import Loader from "../loader/Loader";
import AdminApprovePopup from "./AdminApprovePopup";
import AdminViewAndApprovePopup from "./AdminViewAndApprove";
import AdminRejectPopup from "./AdminRejectPopup";
import InfiniteScroll from 'react-infinite-scroll-component';
import {
  getAdminWebsiteDetails,
  getWebsiteDetails
} from "../../utils/api/adminWebsite";
import {
  setAdminWebsiteDetails,
} from "../../reducers/admin/adminReducer";
import { Checkbox, ListItemText, OutlinedInput, SelectChangeEvent } from "@mui/material";
import { getWebsiteCategories } from "../../utils/api/getWebsiteCategories";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { adminWebsiteApprovalColumnsArray } from "../../utils/constants/selected";

interface Hash {
  [key: string]: boolean;
}
type Props = {};

const AdminSummaryTable: React.FC<Props> = () => {
  const tableCells = [1, 2, 3, 4, 5, 6];
  const dispatch = useAppDispatch();
  const [searchInput, setSearchInput] = useState<string>("");
  const [websiteCategories, setWebsiteCategories] = useState<any>({});
  //  const [reload, setReload] = useState<boolean>(false);
  const { adminWebsiteDetails } = useAppSelector((state) => (
    {
      taskboardDisplay: state.adminState.taskboardDisplay,
      adminWebsiteDetails: state?.adminState?.adminWebsiteDetails,
    }
  ));

  const [approveData, setApproveData] = useState<{
    display: boolean;
    websiteObj: any;
    categories: any;
  }>({
    display: false,
    websiteObj: undefined,
    categories: [],
  });
  const [approveAndViewData, setApproveAndViewData] = useState<{
    display: boolean;
    websiteObj: any;
    categories: any;
    isNewWebsiteApproval: boolean;
    updatedKeyhash: any;
    isAllWebsite: boolean;
  }>({
    display: false,
    websiteObj: undefined,
    categories: [],
    isNewWebsiteApproval: false,
    updatedKeyhash: useState<Hash | null>(null),
    isAllWebsite: false,
  });
  const [rejectData, setRejectData] = useState<{
    display: boolean;
    id: string;
    rejectedReason: string;
  }>({
    display: false,
    id: "",
    rejectedReason: "",
  });
  // console.log(localStorage.getItem('selected-approval-array'));
  // State for selected columns
  // const [selectedColumns, setSelectedColumns] = useState<string[]>([


  const selectionArray = localStorage.getItem('selected-approval-array') !== null ?
    JSON.parse(localStorage.getItem('selected-approval-array') || '["websiteUrl","emailId"]') :
    [
      'websiteUrl',
      'emailId',
      'guaranteePostValidity',
      'minPostWordCount',
      'maxBackLinks',
      'tat'
    ];
  const [selectedColumns, setSelectedColumns] = useState<string[]>(selectionArray);
  const handleApproveAndView = async (websiteObjSelected: any) => {
    console.log(websiteObjSelected);
    const updatedKeyhash: { [key: string]: boolean } = {};
    if (!adminWebsiteDetails.isNewWebsiteApproval) {
      const response = await getWebsiteDetails(websiteObjSelected.id);
      if (response?.status?.code === 200) {
        console.log(response);
        const newObj: Hash = {};
        response?.data?.websiteApprovalData?.websiteDetail?.updatedFields.forEach((item: string) => {
          newObj[item] = true;
        });

        console.log(updatedKeyhash);
        setApproveAndViewData({
          display: true,
          websiteObj: websiteObjSelected,
          categories: websiteCategories,
          isNewWebsiteApproval: true,
          updatedKeyhash: newObj,
          isAllWebsite: false,
        });
      }
    } else {
      setApproveAndViewData({
        display: true,
        websiteObj: websiteObjSelected,
        categories: websiteCategories,
        isNewWebsiteApproval: true,
        updatedKeyhash: updatedKeyhash,
        isAllWebsite: false,
      });
    }
    // dispatch(setIsDeleteSuccess(false));
  };

  const handleApprove = (websiteObjSelected: any) => {
    setApproveData({
      display: true,
      websiteObj: websiteObjSelected,
      categories: websiteCategories,
    });
    // dispatch(setIsDeleteSuccess(false));
  };

  const handleReject = (id: string) => {
    setRejectData({
      ...rejectData,
      display: true,
      id,
    });
    // dispatch(setActiveSuccess(false));
  };

  const fetchMoreData = async () => {
    const pageCount = adminWebsiteDetails.pageCount + 1;
    const response = await getAdminWebsiteDetails(
      pageCount,
      10,
      adminWebsiteDetails.isNewWebsiteApproval ? "NEW_APPROVAL" : "EDIT_APPROVAL"
    );

    if (response?.status?.code === 200) {
      const list = adminWebsiteDetails.data;
      const mergedResult = [...list, ...response?.data?.websiteApprovalData?.websites];
      dispatch(
        setAdminWebsiteDetails({ ...adminWebsiteDetails, data: mergedResult, pageCount: pageCount }));
    }
  };

  const handleColumnChange = (event: SelectChangeEvent<string[]>) => {
    const value = event.target.value as string[];
    setSelectedColumns(value);
    localStorage.setItem('selected-approval-array', JSON.stringify(value));
  };

  const getCategories = async (setWebsiteCategories: React.Dispatch<any>) => {
    const response = await getWebsiteCategories();
    if (response?.status?.code === 200) {
      const map = new Map();
      response?.data?.websiteCategories?.categories.forEach((obj: { id: any; friendlyName: any; }) => {
        map.set(obj.id, obj.friendlyName);
      });
      setWebsiteCategories(map);
    }
  };

  const getPostValidityType = (postValidityType: string) => {
    if (postValidityType === 'YEARLY') {
      return 'Yearly';
    } else if (postValidityType === 'HALF_YEARLY') {
      return 'Half Yearly';
    } else if (postValidityType === 'LIFETIME') {
      return 'Lifetime';
    }
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };


  useEffect(() => {
    setApproveAndViewData({
      ...approveAndViewData,
      display: false
    });
    getCategories(setWebsiteCategories);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adminWebsiteDetails]);
  return (
    <>
      <div className="PublisherSummaryTable__Con">
        {approveAndViewData?.display &&
          <>
            <section className="PublisherSummaryTable__Con__Inner">
              <header className="PublisherSummaryTable__Con__Header">
                <AdminViewAndApprovePopup
                  setApproveAndViewData={setApproveAndViewData}
                  approveAndViewData={approveAndViewData}
                />
              </header>
            </section>
          </>
        }

        {!approveAndViewData?.display && <>
          <section className="PublisherSummaryTable__Con__Inner">
            <header className="PublisherSummaryTable__Con__Header_Approval">
              {adminWebsiteDetails.isNewWebsiteApproval && <p>Task Board - New Website Approval </p>}
              {!adminWebsiteDetails.isNewWebsiteApproval && <p>Task Board - Edit Website Approval </p>}
              <article>
                <div className="display-inline-flex">
                  <div className="search_div_admin_approval">
                    <input
                      type="text"
                      value={searchInput}
                      onChange={(e: any) => setSearchInput(e.target.value)}
                    />
                    <img src={search} alt="" />
                  </div>
                  {/* <div>
                  <FormControl sx={{ m: 1, width: 300 }}>
                    <InputLabel id="demo-select-small-label">Select Columns</InputLabel>
                    <Select
                      labelId="demo-simple-select-helper-label"
                      id="demo-simple-select-helper"
                      multiple
                      value={selectedColumns}
                      label="Select Columns"
                      onChange={handleColumnChange}
                    >
                      <MenuItem value="websiteUrl">Website URL</MenuItem>
                      <MenuItem value="emailId">Email Id</MenuItem>
                      <MenuItem value="domainAuthority">DA</MenuItem>
                      <MenuItem value="referingDomain">DR</MenuItem>
                      <MenuItem value="traffic">Traffic</MenuItem>
                      <MenuItem value="guaranteePostValidity">Post Validity</MenuItem>
                      <MenuItem value="minPostWordCount">Min. Word Count</MenuItem>
                      <MenuItem value="maxBackLinks">Max. Backlinks</MenuItem>
                      <MenuItem value="tat">TAT (Days)</MenuItem>
                      <MenuItem value="guestPostPrice">Guest Post Price</MenuItem>
                      <MenuItem value="linkInsertionPrice">Link Insertion Price</MenuItem>
                      <MenuItem value="casinoPostPrice">Casino Post Price</MenuItem>
                    </Select>
                  </FormControl>
                </div> */}
                  <div>
                    <FormControl sx={{ m: 1, width: 300 }}>
                      <InputLabel id="demo-multiple-checkbox-label">Select Columns</InputLabel>
                      <Select
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        multiple
                        value={selectedColumns}
                        onChange={handleColumnChange}
                        input={<OutlinedInput label="Tag" />}
                        renderValue={(selected) => selected.join(', ')}
                        MenuProps={MenuProps}
                      >
                        {adminWebsiteApprovalColumnsArray.map((loopObj) => (
                          <MenuItem key={loopObj.friendlyName} value={loopObj.id}>
                            <Checkbox checked={selectedColumns.indexOf(loopObj.id) > -1} />
                            <ListItemText primary={loopObj.friendlyName} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </div>
              </article>
            </header>
          </section>
          <section className="PublisherSummaryTable__Con__Table__Header">
            <article>
              {selectedColumns.includes('websiteUrl') && <h5>Website URL</h5>}
              {selectedColumns.includes('emailId') && <h5>Email Id</h5>}
              {selectedColumns.includes('guaranteePostValidity') && <p>Post Validity</p>}
              {selectedColumns.includes('minPostWordCount') && <p>Min. Word Count</p>}
              {selectedColumns.includes('maxBackLinks') && <p>Max. Backlinks</p>}
              {selectedColumns.includes('tat') && <p>TAT (Days) </p>}
              {selectedColumns.includes('traffic') && <p>Traffic</p>}
              {selectedColumns.includes('domainAuthority') && <p>DA</p>}
              {selectedColumns.includes('referingDomain') && <p>DR</p>}
              {selectedColumns.includes('spamScore') && <p>Spam Score</p>}
              {selectedColumns.includes('guestPostPrice') && <p>General Post ($)</p>}
              {selectedColumns.includes('linkInsertionPrice') && <p>Link Insertion Post ($)</p>}
              {selectedColumns.includes('cryptoPostPrice') && <p>Crypto/Finance Post ($)</p>}
              {selectedColumns.includes('casinoPostPrice') && <p>Casino Post ($)</p>}
              {selectedColumns.includes('cbdPostPrice') && <p>CBD/Vape Post ($)</p>}
              {selectedColumns.includes('adultPostPrice') && <p>Adult Post ($)</p>}
              <h6>Action</h6>
            </article>

            {adminWebsiteDetails?.isLoading && <Loader size="sm" />}

            {adminWebsiteDetails.data?.length !== 0 &&
              <InfiniteScroll dataLength={adminWebsiteDetails.pageInfo.totalCount}
                next={fetchMoreData} hasMore={adminWebsiteDetails.pageInfo.hasNext}
                loader={''}
                height={'calc(56vh - 10px)'}>
                {adminWebsiteDetails.data?.length !== 0 &&
                  !adminWebsiteDetails?.isLoading &&
                  adminWebsiteDetails.data?.map((cell: any, index: number) => (
                    <div
                      className={`PublisherSummaryTable__Con__Table__Cell ${index % 2 === 0
                        ? "PublisherSummaryTable__Con__Table__Cell__grey__bg"
                        : ""
                        } ${index === tableCells.length - 1
                          ? "PublisherSummaryTable__Con__Table__Cell__last__border"
                          : ""
                        } `}
                      key={index}
                    >
                      {selectedColumns.includes('websiteUrl') && (
                        <a
                          href={cell?.websiteUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {websiteNAMEFilter(cell?.websiteUrl)}
                        </a>
                      )}
                      {selectedColumns.includes('emailId') && <h5>{websiteNAMEFilter(cell?.emailId)}</h5>}
                      {selectedColumns.includes('guaranteePostValidity') && <p>{getPostValidityType(cell?.guaranteePostValidity)}</p>}
                      {selectedColumns.includes('minPostWordCount') && <p>{numberDisplay(cell?.minPostWordCount)}</p>}
                      {selectedColumns.includes('maxBackLinks') && <p>{numberDisplay(cell?.maxBackLinks)}</p>}
                      {selectedColumns.includes('tat') && <p>{numberDisplay(cell?.tat)}</p>}
                      {selectedColumns.includes('traffic') && <p>{formatTraffic(cell?.traffic)}</p>}
                      {selectedColumns.includes('domainAuthority') && <p>{numberDisplay(cell?.domainAuthority)}</p>}
                      {selectedColumns.includes('referingDomain') && <p>{numberDisplay(cell?.referingDomain)}</p>}
                      {selectedColumns.includes('spamScore') && <p>{numberDisplay(cell?.spamScore)}</p>}
                      {selectedColumns.includes('guestPostPrice') && <p>{numberDisplay(cell?.guestPostPrice)}</p>}
                      {selectedColumns.includes('linkInsertionPrice') && <p> {numberDisplay(cell?.linkInsertionPrice)}</p>}
                      {selectedColumns.includes('cryptoPostPrice') && <p>{numberDisplay(cell?.cryptoPostPrice)}</p>}
                      {selectedColumns.includes('casinoPostPrice') && <p>{numberDisplay(cell?.casinoPostPrice)}</p>}
                      {selectedColumns.includes('cbdPostPrice') && <p>{numberDisplay(cell?.cbdPostPrice)}</p>}
                      {selectedColumns.includes('adultPostPrice') && <p>{numberDisplay(cell?.adultPostPrice)}</p>}
                      <h6>
                        <span onClick={() => handleApproveAndView(cell)}>View</span>
                        {adminWebsiteDetails.isNewWebsiteApproval && <span onClick={() => handleApprove(cell)}>Approve</span>}
                        <span onClick={() => handleReject(cell?.id)}>Reject</span>
                      </h6>
                    </div>
                  ))}
              </InfiniteScroll>
            }
          </section>
        </>}
      </div >
      {approveData?.display && (
        <AdminApprovePopup
          setApproveData={setApproveData}
          approveData={approveData}
        />
      )
      }
      {rejectData?.display && (
        <AdminRejectPopup
          setRejectData={setRejectData}
          rejectData={rejectData}
        />
      )
      }

    </>
  );
};

export default AdminSummaryTable;