import { useEffect } from "react";
import "./termAndCond.scss";

const TermAndCond = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="termAndCond_Con">
      <h4>Terms & Conditions</h4>
      <p>Welcome to Outreach Kart!</p>
      <p>
        These terms and conditions outline the rules and regulations for the use
        of Outreach Kart's Website, located at{" "}
        <a href="https://outreachkart.com"> https://outreachkart.com</a>
      </p>{" "}
      <p>
        Outreach Kart is a link building and content creation services venture.
        We provide our services and campaigns after we receive the full fee.
      </p>{" "}
      <p>
        {" "}
        As a client or service partner, you shall consent to the following terms
        and conditions of the services offered by Outreach Kart.
      </p>
      <h4> Outreach Kart Services</h4>
      <p>
        {" "}
        Outreach Kart will perform the following services in SEO, depending upon
        the subscription:
      </p>
      <p>
        <ol className="term_list">
          <li>Create visual and written content like blogs and articles</li>
          <li>Generate placement and submission reports.</li>
          <li>
            Propagate link popularity by obtaining backlinks from other
            websites.
          </li>
          <li>
            Fetch potential bloggers that shall publish content promoting the
            customer’s brand.
          </li>
        </ol>
      </p>
      <p>
        By accessing this website we assume you accept these terms and
        conditions. Do not continue to use Outreach Kart if you do not agree to
        take all of the terms and conditions stated on this page.
      </p>
      <p>
        The following terminology applies to these Terms and Conditions, Privacy
        Statement and Disclaimer Notice and all Agreements: "Client", "You" and
        "Your" refers to you, the person log on this website and compliant to
        the Company’s terms and conditions. "The Company", "Ourselves", "We",
        "Our" and "Us", refers to our Company. "Party", "Parties", or "Us",
        refers to both the Client and ourselves. All terms refer to the offer,
        acceptance and consideration of payment necessary to undertake the
        process of our assistance to the Client in the most appropriate manner
        for the express purpose of meeting the Client’s needs in respect of
        provision of the Company’s stated services, in accordance with and
        subject to, prevailing law of United States. Any use of the above terminology or
        other words in the singular, plural, capitalization and/or he/she or
        they, are taken as interchangeable and therefore as referring to the
        same.
      </p>
      <h4>
        IF YOU DO NOT AGREE TO THESE TERMS, PLEASE DO NOT ENTER TO, CONNECT TO,
        ACCESS OR USE THE SITE AND/OR THE SERVICE.
      </h4>
      <h4>The Service</h4>
      <p>
        Our site available at{" "}
        <a href="https://outreachkart.com">https://outreachkart.com</a> provides
        you with information regarding Outreach Kart and our services. The site
        includes a link to the platform's log-in page. Our Platform is available
        to users who have opened an Account (as further detailed below) and will
        be made available to Users through a cloud-based dashboard (the
        "Dashboard").
      </p>
      <p>The process is as follows:</p>
      <p>
        <ol className="term_list">
          <li>
            Buyer places an order, which includes certain criteria pertaining to
            potential Posts, based on Dashboard's filters (such as the requested
            Properties, the Seller, the topic of the potential Post and etc.)
            (the "Order)
          </li>
          <li>
            The Order will be sent to the applicable Seller, for his approval.
            If the Seller is unable to provide the required services in
            accordance with the terms stipulated in the Order, the Order will be
            declined and the fees will be refunded to the Buyer's Outreach Kart
            Account.
          </li>
          <li>
            Upon the successful publication of the Post, a notice of publication
            will be issued to the Buyer, ratifying the publication of the Post,
            in accordance with the terms agreed in the Order ("Notice of
            Publication").
          </li>
        </ol>
      </p>
      <h4>Cookies</h4>
      <p>
        We employ the use of cookies. By accessing Outreach Kart, you agreed to
        use cookies in agreement with Outreach Kart's Privacy Policy.
      </p>
      <br />
      <p>
        Most interactive websites use cookies to let us retrieve the user’s
        details for each visit. Our website uses cookies to enable the
        functionality of certain areas to make it easier for people visiting our
        website. Some of our affiliate/advertising partners may also use
        cookies.
      </p>
      <h4>License</h4>
      <p>
        Unless otherwise stated, Outreach Kart and/or its licensors own the
        intellectual property rights for all material on Outreach Kart. All
        intellectual property rights are reserved. You may access this from
        Outreach Kart for your own personal use subjected to restrictions set in
        these terms and conditions.
      </p>
      <br />
      <p>You must not:</p>
      <p>
        <ul className="term_list">
          <li>Republish material from Outreach Kart</li>
          <li>Sell, rent, or sub-license material from Outreach Kart</li>
          <li>Reproduce, duplicate or copy material from Outreach Kart</li>
          <li>Redistribute content from Outreach Kart</li>
          This Agreement shall begin on the date hereof. Our Terms and
          Conditions were created with the help of the Terms And Conditions
          Template.
        </ul>
      </p>
      <p>
        Parts of this website offer an opportunity for users to post and
        exchange opinions and information in certain areas of the website.
        Outreach Kart does not filter, edit, publish or review Comments prior to
        their presence on the website. Comments do not reflect the views and
        opinions of Outreach Kart, its agents, and/or affiliates. Comments
        reflect the views and opinions of the person who posts their views and
        opinions. To the extent permitted by applicable laws, Outreach Kart shall
        not be liable for the Comments or for any liability, damages, or
        expenses caused and/or suffered as a result of any use of and/or posting
        of and/or appearance of the Comments on this website.
      </p>
      <p>
        {" "}
        Outreach Kart reserves the right to monitor all Comments and remove any
        Comments that can be considered inappropriate, offensive, or cause a
        breach of these Terms and Conditions.
      </p>
      <p>You warrant and represent that:</p>
      <p>
        <ul className="term_list">
          <li>
            You are entitled to post the Comments on our website and have all
            necessary licenses and consents to do so;
          </li>
          <li>
            The Comments do not invade any intellectual property right,
            including without limitation copyright, patent, or trademark of any
            third party;
          </li>
          <li>
            The Comments do not contain any defamatory, libelous, offensive,
            indecent or otherwise unlawful material which is an invasion of
            privacy
          </li>
          <li>
            The Comments will not be used to solicit or promote business or
            custom or present commercial activities or unlawful activity.
          </li>
        </ul>
      </p>
      <p>
        You hereby grant Outreach Kart a non-exclusive license to use, reproduce,
        edit and authorize others to use, reproduce and edit any of your
        Comments in any and all forms, formats, or media.
      </p>
      <h4>Hyperlinking to our Content</h4>
      <p>
        The following organizations may link to our Website without prior
        written approval:
      </p>
      <p>
        <ul className="term_list">
          <li>Government agencies;</li>
          <li>Search engines;</li>
          <li>News organizations;</li>
          <li>
            Online directory distributors may link to our Website in the same
            manner as they hyperlink to the Websites of other listed businesses;
            and
          </li>
          <li>
            System-wide Accredited Businesses except soliciting non-profit
            organizations, charity shopping malls, and charity fundraising
            groups which may not hyperlink to our Web site.
          </li>
          These organizations may link to our home page, to publications or to
          other Website information so long as the link: (a) is not in any way
          deceptive; (b) does not falsely imply sponsorship, endorsement or
          approval of the linking party and its products and/or services; and
          (c) fits within the context of the linking party’s site
        </ul>
      </p>
      <p>
        We will approve link requests from these organizations if we decide
        that: (a) the link would not make us look unfavorably to ourselves or to
        our accredited businesses; (b) the organization does not have any
        negative records with us; (c) the benefit to us from the visibility of
        the hyperlink compensates the absence of Outreach Kart, and (d) the link
        is in the context of general resource information.
      </p>
      <p>
        These organizations may link to our home page so long as the link: (a)
        is not in any way deceptive; (b) does not falsely imply sponsorship,
        endorsement or approval of the linking party and its products or
        services; and (c) fits within the context of the linking party’s site.
      </p>
      <p>
        If you are one of the organizations listed in paragraph 2 above and are
        interested in linking to our website, you must inform us by sending an
        e-mail to Outreach Kart. Please include your name, your organization
        name, contact information as well as the URL of your site, a list of any
        URLs from which you intend to link to our Website, and a list of the
        URLs on our site to which you would like to link. Wait 2-3 weeks for a
        response.
      </p>
      <h4>iFrames</h4>
      <p>
        Without prior approval and written permission, you may not create frames
        around our Webpages that alter in any way the visual presentation or
        appearance of our Website.
      </p>
      <h4>Content Liability</h4>
      <p>
        We shall not be held responsible for any content that appears on your
        Website. You agree to protect and defend us against all claims that is
        rising on your Website. No link(s) should appear on any Website that may
        be interpreted as libelous, obscene, or criminal, or which infringes,
        otherwise violates, or advocates the infringement or other violation of,
        any third party rights.
      </p>
      <h4>Your Privacy</h4>
      <p>Please read Privacy Policy</p>
      <h4>Reservation of Rights</h4>
      <p>
        We reserve the right to request that you remove all links or any
        particular link to our Website. You approve to immediately remove all
        links to our Website upon request. We also reserve the right to amend
        these terms and conditions and its linking policy at any time. By
        continuously linking to our Website, you agree to be bound to and follow
        these linking terms and conditions.
      </p>
      <h4>Removal of links from our website</h4>
      <p>
        If you find any link on our Website that is offensive for any reason,
        you are free to contact and inform us at any moment. We will consider
        requests to remove links but we are not obligated to or so or to respond
        to you directly.
      </p>
      <p>
        We do not ensure that the information on this website is correct, we do
        not warrant its completeness or accuracy; nor do we promise to ensure
        that the website remains available or that the material on the website
        is kept up to date.
      </p>
      <h4>Disclaimer</h4>
      <p>
        To the maximum extent permitted by applicable law, we exclude all
        representations, warranties, and conditions relating to our website and
        the use of this website. Nothing in this disclaimer will:
      </p>
      <p>
        <ul className="term_list">
          <li>
            limit or exclude our or your liability for death or personal injury;
          </li>
          <li>
            limit or exclude our or your liability for fraud or fraudulent
            misrepresentation;
          </li>
          <li>
            limit any of our or your liabilities in any way that is not
            permitted under applicable law; or
          </li>
          <li>
            exclude any of our or your liabilities that may not be excluded
            under applicable law.
          </li>
        </ul>
      </p>
      <p>
        The limitations and prohibitions of liability set in this Section and
        elsewhere in this disclaimer: (a) are subject to the preceding
        paragraph; and (b) govern all liabilities arising under the disclaimer,
        including liabilities arising in contract, in tort and for breach of
        statutory duty.
      </p>
      <p>
        As long as the website and the information and services on the website
        are provided free of charge, we will not be liable for any loss or
        damage of any nature.
      </p>
      <h4>In What cases is Outreach Kart not responsible for a refund?</h4>
      <p>
        {" "}
        Outreach Kart is not responsible for a refund if the publisher sells the
        site after publishing the guest posts. However, we try to reinstate the
        link, but we do not take a guarantee of issuing refunds in such cases.
      </p>
      <p>
        If you used PayPal to add funds to Outreach Kart’s wallet, please refer
        to this page for more details regarding refund.
      </p>
      <p>
        Users who added funds using RazorPay gateway should refer to this page
        for more information related to refunds.
      </p>
      <p>Terms and conditions for Publishers</p>
      <p>
        <ul className="term_list">
          <li>
            The publisher is responsible for providing the correct payment
            address. If the address provided is incorrect, and the payment has
            been sent to it, the withdrawal is considered to be completed.
            Outreach Kart will not send the same payment again to the corrected
            address.
          </li>
          <li>We may ask for the changes after completing the order.</li>
          <li>
            We may update the details added by you at the time of adding the
            website to our platform.
          </li>
        </ul>
      </p>
      <br />
      <p>Terms and conditions for Advertisers</p>
      <p>
        <ul className="term_list">
          <li>
            The order will be auto-approved if we don’t receive any response
            after hours.
          </li>
          <li>Withdrawal of the amount from the wallet is a manual process.</li>
        </ul>
      </p>
      <p>
        For more information, questions or notification of errors, please
        contact us at <strong> admin@outreachkart.com </strong>
      </p>
    </div>
  );
};

export default TermAndCond;
