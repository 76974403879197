import axios from "axios";
import { ResetPasswordPayload } from "../types/main";
import { RESET_PASSWORD } from "../constants/url";

export const resetPasswordPost = async (
  resetPasswordData: ResetPasswordPayload,
  token: string
) => {
  try {
    const response = await axios.post(
      process.env.REACT_APP_BASE + RESET_PASSWORD,
      resetPasswordData ,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  } catch (err) {
    return err;
  }
};
