import {
  AddWebsiteDetailsError,
  AddWebsiteDetailsPayload,
  AddAdminWebsiteDetailsPayload,
  AddAdminWebsiteDetailsError,
  ApproveWebsiteDetailsError,
  ApproveWebsiteDetailsPayload
} from "../types/main";

export const addWebsiteValidator = (
  addWebsiteDetails: AddWebsiteDetailsPayload,
  displayError: AddWebsiteDetailsError,
  setDisplayError: React.Dispatch<React.SetStateAction<AddWebsiteDetailsError>>
) => {
  let innerDisplay: any = {
    isError: false,
    domainAuthority: false,
    traffic: false,
    websiteName: false,
    websiteUrl: false,
    guestPostPrice: false,
    linkInsertionPrice: false,
    casinoPostPrice: false,
    sampleUrl: false,
    tat: false,
    postGuideline: false,
    contactNumber: false,
    skypeId: false,
    websiteCategoryIdList: false,
    priceError:false,
    sensetivePriceError : false,
    referingDomain : false,
    minPostWordCount: false,
    guaranteePostValidity: false,
    backLinkType : false,
    maxBackLinks : false,
    cryptoPostPrice: false,
    cbdPostPrice: false,
    adultPostPrice: false,
    isSenstiveAccepted: false,

  };
  // if (addWebsiteDetails.domainAuthority) {
  //   if (addWebsiteDetails.domainAuthority > 100) {
  //     innerDisplay = {
  //       ...innerDisplay,
  //       isError: true,
  //       domainAuthority: "Domain authority value cannot be greater than 100",
  //     };     
  //   }else{
  //     innerDisplay = { ...innerDisplay, domainAuthority: false };
  //   }
  // } else {
  //   innerDisplay = {
  //     ...innerDisplay,
  //     isError: true,
  //     domainAuthority: "Please enter valid domain authority",
  //   };
  // }
  // if (addWebsiteDetails.traffic) {
  //   innerDisplay = { ...innerDisplay, traffic: false };
  // } else {
  //   innerDisplay = {
  //     ...innerDisplay,
  //     isError: true,
  //     traffic: "Please enter valid traffic",
  //   };
  // }
  // if (addWebsiteDetails.referingDomain) {
  //   if (addWebsiteDetails.referingDomain > 100) {
  //     innerDisplay = {
  //       ...innerDisplay,
  //       isError: true,
  //       referingDomain: "Domain rating value cannot be greater than 100",
  //     }
  //     }else{
  //       innerDisplay = { ...innerDisplay, referingDomain: false };
  //     }
  // } else {
  //   innerDisplay = {
  //     ...innerDisplay,
  //     isError: true,
  //     referingDomain: "Please enter valid domain rating",
  //   };
  // }
  
    if (addWebsiteDetails.minPostWordCount) {
    if (addWebsiteDetails.minPostWordCount > 4000 || addWebsiteDetails.minPostWordCount < 300) {
      innerDisplay = {
        ...innerDisplay,
        isError: true,
        minPostWordCount: "Minimum 300 and Maximum 4000 word count is allowed",
      };     
    }else{
      innerDisplay = { ...innerDisplay, minPostWordCount: false };
    }
  } else {
    innerDisplay = {
      ...innerDisplay,
      isError: true,
      minPostWordCount: "Please enter valid minimum post word count",
    };
  }

  if (addWebsiteDetails.guaranteePostValidity) {
    innerDisplay = { ...innerDisplay, guaranteePostValidity: false };
  } else {
    innerDisplay = {
      ...innerDisplay,
      isError: true,
      guaranteePostValidity: "Please enter valid guarantee post validity",
    };
  }

  if (addWebsiteDetails.backLinkType) {
    innerDisplay = { ...innerDisplay, backLinkType: false };
  } else {
    innerDisplay = {
      ...innerDisplay,
      isError: true,
      backLinkType: "Please enter valid backlink type",
    };
  }

  if (addWebsiteDetails.maxBackLinks) {
    innerDisplay = { ...innerDisplay, maxBackLinks: false };
  } else {
    innerDisplay = {
      ...innerDisplay,
      isError: true,
      maxBackLinks: "Please enter valid maximum backlinks",
    };
  }

  if (addWebsiteDetails.websiteName) {
    if (addWebsiteDetails.websiteName.length > 100) {
      innerDisplay = {
        ...innerDisplay,
        isError: true,
        websiteName: "Website name length cannot be greater than 100",
      };
    }else{
      innerDisplay = { ...innerDisplay, websiteName: false };
    }
  } else {
    innerDisplay = {
      ...innerDisplay,
      isError: true,
      websiteName: "Please enter valid website name",
    };
  }
  if (addWebsiteDetails.websiteUrl) {
    innerDisplay = { ...innerDisplay, websiteUrl: false };
  } else {
    innerDisplay = {
      ...innerDisplay,
      isError: true,
      websiteUrl: "Please enter valid website URL",
    };
  }
  // if (addWebsiteDetails.guestPostPrice) {
  //   innerDisplay = { ...innerDisplay, guestPostPrice: false };
  // } else {
  //   innerDisplay = {
  //     ...innerDisplay,
  //     isError: true,
  //     guestPostPrice: "Please enter valid general post price",
  //   };
  // }
  if (addWebsiteDetails.sampleUrl) {
    if (addWebsiteDetails.sampleUrl.length > 200) {
      innerDisplay = {
        ...innerDisplay,
        isError: true,
        sampleUrl: "Sample URL length cannot be greater than 200",
      };
    }else{
      innerDisplay = { ...innerDisplay, sampleUrl: false };
    }
  } 
  else {
    innerDisplay = {
      ...innerDisplay,
      isError: true,
      sampleUrl: "Please Enter Valid sample Url",
    };
  }
  if(!addWebsiteDetails.guestPostPrice && !addWebsiteDetails.linkInsertionPrice && !addWebsiteDetails.cryptoPostPrice){
    innerDisplay = {
      ...innerDisplay,
      isError: true,
      priceError: " *At least one price is required",
    };
  } else {
    innerDisplay = { ...innerDisplay, priceError: false };
  }
  if(addWebsiteDetails.isSenstiveAccepted){
   if(!addWebsiteDetails.casinoPostPrice && !addWebsiteDetails.cbdPostPrice && !addWebsiteDetails.adultPostPrice){
      innerDisplay = {
        ...innerDisplay,
        isError: true,
        sensetivePriceError: " *At least one price is required",
      };
    } else {
      innerDisplay = { ...innerDisplay, sensetivePriceError: false };
    }
  }
  if (addWebsiteDetails.tat) {
    if (addWebsiteDetails.tat > 30) {
      innerDisplay = {
        ...innerDisplay,
        isError: true,
        tat: "Turn around time cannot be greater than 30 days",
      };     
    }else{
      innerDisplay = { ...innerDisplay, tat: false };
    }
   
  } else {
    innerDisplay = {
      ...innerDisplay,
      isError: true,
      tat: "Please enter valid TAT",
    };
  }
  if (addWebsiteDetails.postGuideline && addWebsiteDetails.postGuideline.length > 12) {
    innerDisplay = { ...innerDisplay, postGuideline: false };
    if (addWebsiteDetails.postGuideline ) {
      const length = addWebsiteDetails.postGuideline.replaceAll(/<.*?>/gi, "").length;
      if (length> 2050) {
        innerDisplay = {
          ...innerDisplay,
          isError: true,
          postGuideline: "Post guideline length cannot be greater than 2000",
        };
      }else{
        innerDisplay = { ...innerDisplay, postGuideline: false };
      }
    } 
  } else {
    innerDisplay = {
      ...innerDisplay,
      isError: true,
      postGuideline: "Please Enter Valid post Guideline",
    };
  }

  if (addWebsiteDetails.websiteCategoryIdList && addWebsiteDetails.websiteCategoryIdList.length > 0) {
    innerDisplay = { ...innerDisplay, websiteCategoryIdList: false };
  } else {
    innerDisplay = {
      ...innerDisplay,
      isError: true,
      websiteCategoryIdList: "Post categories required",
    };
  }
  setDisplayError(innerDisplay);
  return innerDisplay

};
export const addAdminWebsiteValidator = (
  addWebsiteDetails: AddAdminWebsiteDetailsPayload,
  displayError: AddAdminWebsiteDetailsError,
  setDisplayError: React.Dispatch<React.SetStateAction<AddAdminWebsiteDetailsError>>
) => {
  let innerDisplay: any = {
    isError: false,
    domainAuthority: false,
    traffic: false,
    websiteName: false,
    websiteUrl: false,
    guestPostPrice: false,
    linkInsertionPrice: false,
    casinoPostPrice: false,
    sampleUrl: false,
    tat: false,
    postGuideline: false,
    contactNumber: false,
    skypeId: false,
    websiteCategoryIdList: false,
    priceError:false,
    sensetivePriceError : false,
    referingDomain : false,
    siteAdmin : false,
    communicationEmail : false,
    emailId : false,
    minPostWordCount: false,
    guaranteePostValidity: false,
    backLinkType : false,
    maxBackLinks : false,
    cryptoPostPrice: false,
    cbdPostPrice: false,
    adultPostPrice: false,
    isSenstiveAccepted: false,
  };
  if (addWebsiteDetails.domainAuthority) {
    if (addWebsiteDetails.domainAuthority > 100) {
      innerDisplay = {
        ...innerDisplay,
        isError: true,
        domainAuthority: "Domain authority value cannot be greater than 100",
      };     
    }else{
      innerDisplay = { ...innerDisplay, domainAuthority: false };
    }
  } else {
    innerDisplay = {
      ...innerDisplay,
      isError: true,
      domainAuthority: "Please enter valid domain authority",
    };
  }
  if (addWebsiteDetails.traffic) {
    innerDisplay = { ...innerDisplay, traffic: false };
  } else {
    innerDisplay = {
      ...innerDisplay,
      isError: true,
      traffic: "Please enter valid traffic",
    };
  }
  if (addWebsiteDetails.referingDomain) {
    if (addWebsiteDetails.referingDomain > 100) {
      innerDisplay = {
        ...innerDisplay,
        isError: true,
        referingDomain: "Refering Domain value cannot be greater than 100",
      }
      }else{
        innerDisplay = { ...innerDisplay, referingDomain: false };
      }
  } else {
    innerDisplay = {
      ...innerDisplay,
      isError: true,
      referingDomain: "Please enter valid refering domain",
    };
  }
  if (addWebsiteDetails.spamScore || addWebsiteDetails.spamScore === 0 ) {
    if (addWebsiteDetails.spamScore > 30) {
      innerDisplay = {
        ...innerDisplay,
        isError: true,
        spamScore: "Spam score value cannot be greater than 30",
      };     
    }else{
      innerDisplay = { ...innerDisplay, spamScore: false };
    }
  } else {
    innerDisplay = {
      ...innerDisplay,
      isError: true,
      spamScore: "Please enter valid spam score",
    };
  }

    
  if (addWebsiteDetails.minPostWordCount) {
    if (addWebsiteDetails.minPostWordCount > 4000 || addWebsiteDetails.minPostWordCount < 300) {
      innerDisplay = {
        ...innerDisplay,
        isError: true,
        minPostWordCount: "Minimum 300 and Maximum 4000 word count is allowed",
      };     
    }else{
      innerDisplay = { ...innerDisplay, minPostWordCount: false };
    }
  } else {
    innerDisplay = {
      ...innerDisplay,
      isError: true,
      minPostWordCount: "Please enter valid minimum post word count",
    };
  }

  if (addWebsiteDetails.guaranteePostValidity) {
    innerDisplay = { ...innerDisplay, guaranteePostValidity: false };
  } else {
    innerDisplay = {
      ...innerDisplay,
      isError: true,
      guaranteePostValidity: "Please enter valid guarantee post validity",
    };
  }

  if (addWebsiteDetails.backLinkType) {
    innerDisplay = { ...innerDisplay, backLinkType: false };
  } else {
    innerDisplay = {
      ...innerDisplay,
      isError: true,
      backLinkType: "Please enter valid backlink type",
    };
  }

  if (addWebsiteDetails.maxBackLinks) {
    innerDisplay = { ...innerDisplay, maxBackLinks: false };
  } else {
    innerDisplay = {
      ...innerDisplay,
      isError: true,
      maxBackLinks: "Please enter valid maximum backlinks",
    };
  }

  
  if (addWebsiteDetails.emailId) {
    innerDisplay = { ...innerDisplay, emailId: false };
  } else {
    innerDisplay = {
      ...innerDisplay,
      isError: true,
      emailId: "Please enter valid publisher",
    };
  }
  // if (addWebsiteDetails.traffic) {
  //   innerDisplay = { ...innerDisplay, traffic: false };
  // } else {
  //   innerDisplay = {
  //     ...innerDisplay,
  //     isError: true,
  //     traffic: "Please enter valid traffic",
  //   };
  // }
  if (addWebsiteDetails.communicationEmail) {
    var validRegexemail = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (addWebsiteDetails.communicationEmail.match(validRegexemail)) {
      innerDisplay = { ...innerDisplay, communicationEmail: false };
    } else {
      innerDisplay = {
        ...innerDisplay,
        isError: true,
        communicationEmail: "Please enter valid site admin",
      };
    }
  } else {
    innerDisplay = {
      ...innerDisplay,
      isError: true,
      communicationEmail: "Please enter valid communication email",
    };
  }
  if (addWebsiteDetails.siteAdmin) {
    var validRegexSiteAdmin = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (addWebsiteDetails.siteAdmin.match(validRegexSiteAdmin)) {
      innerDisplay = { ...innerDisplay, siteAdmin: false };
    } else {
      innerDisplay = {
        ...innerDisplay,
        isError: true,
        siteAdmin: "Please enter valid site admin",
      };
    }
  } else {
    innerDisplay = {
      ...innerDisplay,
      isError: true,
      siteAdmin: "Please enter valid site admin",
    };
  }
  // if (addWebsiteDetails.referingDomain) {
  //   if (addWebsiteDetails.referingDomain > 100) {
  //     innerDisplay = {
  //       ...innerDisplay,
  //       isError: true,
  //       referingDomain: "Domain rating value cannot be greater than 100",
  //     }
  //     }else{
  //       innerDisplay = { ...innerDisplay, referingDomain: false };
  //     }
  // } else {
  //   innerDisplay = {
  //     ...innerDisplay,
  //     isError: true,
  //     referingDomain: "Please enter valid domain rating",
  //   };
  // }
  
  if (addWebsiteDetails.websiteName) {
    if (addWebsiteDetails.websiteName.length > 100) {
      innerDisplay = {
        ...innerDisplay,
        isError: true,
        websiteName: "Website name length cannot be greater than 100",
      };
    }else{
      innerDisplay = { ...innerDisplay, websiteName: false };
    }
  } else {
    innerDisplay = {
      ...innerDisplay,
      isError: true,
      websiteName: "Please enter valid website name",
    };
  }
  if (addWebsiteDetails.websiteUrl) {
    innerDisplay = { ...innerDisplay, websiteUrl: false };
  } else {
    innerDisplay = {
      ...innerDisplay,
      isError: true,
      websiteUrl: "Please enter valid website URL",
    };
  }
  // if (addWebsiteDetails.guestPostPrice) {
  //   innerDisplay = { ...innerDisplay, guestPostPrice: false };
  // } else {
  //   innerDisplay = {
  //     ...innerDisplay,
  //     isError: true,
  //     guestPostPrice: "Please enter valid general post price",
  //   };
  // }
  if (addWebsiteDetails.sampleUrl) {
    if (addWebsiteDetails.sampleUrl.length > 200) {
      innerDisplay = {
        ...innerDisplay,
        isError: true,
        sampleUrl: "Sample URL length cannot be greater than 200",
      };
    }else{
      innerDisplay = { ...innerDisplay, sampleUrl: false };
    }
  } 
  else {
    innerDisplay = {
      ...innerDisplay,
      isError: true,
      sampleUrl: "Please Enter Valid sample Url",
    };
  }
  if(!addWebsiteDetails.guestPostPrice && !addWebsiteDetails.linkInsertionPrice && !addWebsiteDetails.cryptoPostPrice){
    innerDisplay = {
      ...innerDisplay,
      isError: true,
      priceError: " *At least one price is required",
    };
  } else {
    innerDisplay = { ...innerDisplay, priceError: false };
  }
  if(addWebsiteDetails.isSenstiveAccepted){
    if(!addWebsiteDetails.casinoPostPrice && !addWebsiteDetails.cbdPostPrice && !addWebsiteDetails.adultPostPrice){
       innerDisplay = {
         ...innerDisplay,
         isError: true,
         sensetivePriceError: " *At least one price is required",
       };
     } else {
       innerDisplay = { ...innerDisplay, sensetivePriceError: false };
     }
   }
  if (addWebsiteDetails.tat) {
    if (addWebsiteDetails.tat > 30) {
      innerDisplay = {
        ...innerDisplay,
        isError: true,
        tat: "Turn around time cannot be greater than 30 days",
      };     
    }else{
      innerDisplay = { ...innerDisplay, tat: false };
    }
   
  } else {
    innerDisplay = {
      ...innerDisplay,
      isError: true,
      tat: "Please enter valid TAT",
    };
  }
  if (addWebsiteDetails.postGuideline && addWebsiteDetails.postGuideline.length > 12 ) {
    innerDisplay = { ...innerDisplay, postGuideline: false };
  } else {
    innerDisplay = {
      ...innerDisplay,
      isError: true,
      postGuideline: "Please Enter Valid post Guideline",
    };
  }
  if (addWebsiteDetails.postGuideline) {
    const length = addWebsiteDetails.postGuideline.replaceAll(/<.*?>/gi, "").length;
    if (length> 2050) {
      innerDisplay = {
        ...innerDisplay,
        isError: true,
        postGuideline: "Post guideline length cannot be greater than 2000",
      };
    }else{
      innerDisplay = { ...innerDisplay, postGuideline: false };
    }
  } 
  if (addWebsiteDetails.websiteCategoryIdList && addWebsiteDetails.websiteCategoryIdList.length > 0) {
    innerDisplay = { ...innerDisplay, websiteCategoryIdList: false };
  } else {
    innerDisplay = {
      ...innerDisplay,
      isError: true,
      websiteCategoryIdList: "Post categories required",
    };
  }
  setDisplayError(innerDisplay);
  return innerDisplay

};

export const approveWebsiteValidator = (
  addWebsiteDetails: ApproveWebsiteDetailsPayload,
  displayError: ApproveWebsiteDetailsError,
  setDisplayError: React.Dispatch<React.SetStateAction<ApproveWebsiteDetailsError>>
) => {
  let innerDisplay: any = {
    isError: false,
    domainAuthority: false,
    traffic: false,
    referingDomain : false,
    spamScore: false,
  };
  if (addWebsiteDetails.domainAuthority) {
    if (addWebsiteDetails.domainAuthority > 100) {
      innerDisplay = {
        ...innerDisplay,
        isError: true,
        domainAuthority: "Domain authority value cannot be greater than 100",
      };     
    }else{
      innerDisplay = { ...innerDisplay, domainAuthority: false };
    }
  } else {
    innerDisplay = {
      ...innerDisplay,
      isError: true,
      domainAuthority: "Please enter valid domain authority",
    };
  }
  if (addWebsiteDetails.traffic) {
    innerDisplay = { ...innerDisplay, traffic: false };
  } else {
    innerDisplay = {
      ...innerDisplay,
      isError: true,
      traffic: "Please enter valid traffic",
    };
  }
  if (addWebsiteDetails.referingDomain) {
    if (addWebsiteDetails.referingDomain > 100) {
      innerDisplay = {
        ...innerDisplay,
        isError: true,
        referingDomain: "Refering Domain value cannot be greater than 100",
      }
      }else{
        innerDisplay = { ...innerDisplay, referingDomain: false };
      }
  } else {
    innerDisplay = {
      ...innerDisplay,
      isError: true,
      referingDomain: "Please enter valid refering domain",
    };
  }
  if (addWebsiteDetails.spamScore  || addWebsiteDetails.spamScore === 0 ) {
    if (addWebsiteDetails.spamScore > 30) {
      innerDisplay = {
        ...innerDisplay,
        isError: true,
        spamScore: "Spam score value cannot be greater than 30",
      };     
    }else if(addWebsiteDetails.spamScore < 0){
      innerDisplay = {
        ...innerDisplay,
        isError: true,
        spamScore: "Please enter valid spam score",
      };     
    } else{
      innerDisplay = { ...innerDisplay, spamScore: false };
    }
  } else {
    innerDisplay = {
      ...innerDisplay,
      isError: true,
      spamScore: "Please enter valid spam score",
    };
  }
  
  setDisplayError(innerDisplay);
  return innerDisplay

};