import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../store/reduxHooks";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { checkVErifiedWebsite } from "../../utils/api/addWebsite";
import {

  setFormDisplay,
} from "../../reducers/addWebsite/addWebsitedataReducer";
type Props = {
  setIsAddWebsite: React.Dispatch<React.SetStateAction<boolean>>;
};

const AddWebsiteFormMeta: React.FC<Props> = ({ setIsAddWebsite }) => {
  const [isCopied, setIscopied] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const { addWebsiteState } = useAppSelector((state) => ({
    addWebsiteState: state?.addWebsiteState?.addedWebsiteData,
    formDisplay: state?.addWebsiteState?.formDisplay,
  }));
  console.log(addWebsiteState)
  const copyText = (e: any) => {
    e.preventDefault();
    navigator.clipboard.writeText(
      `<meta name="outreach-site-verification" content="${addWebsiteState?.checkEligibility?.verificationTokens?.metaTagToken}" >`
    );
    setIscopied(true);
  };
  const onClickAddWebsiteForm = async (e: any) => {
    e.preventDefault();
    const response = await checkVErifiedWebsite(
      addWebsiteState?.checkEligibility?.websiteEligibility?.websiteUrl,
      "metaTag"
    );
    if (
      response?.status?.code === 200 &&
      response?.data?.websiteVerification?.verification?.isVerified
    ) {
      toast.success("Website added successfully", {
        position: "top-right",
        theme: "colored",
        autoClose: 3000,
      });

      dispatch(
        setFormDisplay({
          begin: false,
          meta: false,
          html: false,
          dns: false,
          details: true,
          isAdd: true,
        })
      );
    } else {
      toast.error("Website not verified", {
        position: "top-right",
        theme: "colored",
        autoClose: 3000,
      });
    }
  };

  return (
    <div className="AddWebsiteFormMeta__con">
      <div className="AddWebsiteFormMeta__Switch__Btn">
        <button
          onClick={(e) => {
            e.preventDefault()
            dispatch(
              setFormDisplay({
                begin: false,
                meta: true,
                html: false,
                dns: false,
                details: false,
                isAdd: true,
              })
            );
          }}
        >
          Meta Tag
        </button>
        <button
          onClick={(e) => {
            e.preventDefault()
            dispatch(
              setFormDisplay({
                begin: false,
                meta: false,
                html: true,
                dns: false,
                details: false,
                isAdd: true,
              })
            );
          }}
        >
          HTML File
        </button>
        <button
          onClick={(e) => {
            e.preventDefault()
            dispatch(
              setFormDisplay({
                begin: false,
                meta: false,
                html: false,
                dns: true,
                details: false,
                isAdd: true,
              })
            );
          }}
          style={{ display: 'none' }}
        >
          DNS Record
        </button>
      </div>
      <div className="AddWebsiteFormMeta__input__Con">
        <label htmlFor=""> 1.Copy the given below Meta Tag</label>
        <div>
          <input
            type="text"
            placeholder={`<meta name=”outreachkart-site-verification” content="${addWebsiteState?.checkEligibility?.verificationTokens?.metaTagToken}" >`}
          />
          <button
            onClick={(e: any) => {
              copyText(e);
            }}
          >
            {" "}
            Copy Meta{" "}
          </button>
          {isCopied && <p> Text Copied!</p>}
        </div>
      </div>

      <p>2. Paste meta tag into head section of your website’s HTML.</p>
      <p>3. Hit Verify button for website verification.</p>
      <button
        className="AddWebsiteFormMeta__verify__btn"
        onClick={(e: any) => onClickAddWebsiteForm(e)}
      >
        Verify
      </button>
      <ToastContainer />
    </div>
  );
};

export default AddWebsiteFormMeta;
