import { useState, useEffect } from "react";
import "./contactMid.scss";
import { ContactUs } from "../../utils/types/main";
import { contactPost } from "../../utils/api/contactUs";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const ContactMid = () => {
  const [contactData, setContactData] = useState<ContactUs>({
    firstName: "",
    emailId: "",
    lastName: "",
    phoneNumber: "",
    description: "",
  });
  const [displayMessage, setDisplayMessage] = useState<any>({
    isShown: false,
    text: "Something went wrong Please contact Admin",
  });
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const handleSubmit = async (e: any) => {
    e.preventDefault();
   
    const response = await contactPost(contactData);
    if (response?.status?.code === 200) {
      setDisplayMessage({
        text: toast.success("Email Sent Successfully", {
          position: "top-right",
          theme: "colored",
          autoClose: 5000,
        }),
        isShown: true,
      });
      setContactData({
        firstName: "",
        emailId: "",
        lastName: "",
        phoneNumber: "",
        description: "",
      })
  } else {
      setDisplayMessage({
        text: toast.error("Something went wrong Please contact Admin", {
          position: "top-right",
          theme: "colored",
          autoClose: 5000,
        }),
        isShown: true,
      });
    }
  };
  return (
    <div className="contactMid_Con">
      <h4>Contact Us</h4>
      <p>
        You have any concern and/or query? Our experts are just a click away to
        resolve your queries and concern.
      </p>
      {displayMessage.isShown && <ToastContainer />}

      <form onSubmit={(e) => handleSubmit(e)} className="contactMid_Form">
        <div className="contactMid-input">
          <label htmlFor="">First Name</label>
          <input
            type="text"
            placeholder="Your First Name"
            value={contactData.firstName}
            onChange={(e) =>
              setContactData({ ...contactData, firstName: e.target.value })
            }
            required
          />
        </div>
        <div className="contactMid-inputs">
          <label htmlFor="">Last Name</label>
          <input
            type="text"
            placeholder="Your Last Name"
            value={contactData.lastName}
            onChange={(e) =>
              setContactData({ ...contactData, lastName: e.target.value })
            }
          />
        </div>
        <div className="contactMid-input">
          <label htmlFor="">Email</label>
          <input
            type="text"
            placeholder="Your Email"
            value={contactData.emailId}
            onChange={(e) =>
              setContactData({ ...contactData, emailId: e.target.value })
            }
            required
          />
        </div>
        <div className="contactMid-inputs">
          <label htmlFor="">Phone Number</label>
          <input
            type="number"
            placeholder="Your Number"
            value={contactData.phoneNumber}
            onChange={(e) =>
              setContactData({
                ...contactData,
                phoneNumber: parseInt(e.target.value),
              })
            }
          />
        </div>
        <div className="contactMid-input">
          <label htmlFor="">Description</label>

          <textarea
            name=""
            id=""
            placeholder="Enter Description "
            cols={30}
            rows={10}
            value={contactData.description}
            onChange={(e) =>
              setContactData({ ...contactData, description: e.target.value })
            }
            required
          ></textarea>
        </div>
        <div className="contactMid-input">
          {" "}
          <button type="submit" className="contact-button">
            Submit
          </button>{" "}
        </div>
      </form>
    </div>
  );
};

export default ContactMid;
