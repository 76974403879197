import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AddWebsiteDetailsPayload } from "../../utils/types/main";

type InitialState = {
  addedWebsiteData: any;
  formDisplay: {
    begin: boolean;
    meta: boolean;
    html: boolean;
    dns: boolean;
    details: boolean;
    isAdd:boolean;
  };
  addFormDetailsEdit: AddWebsiteDetailsPayload;
  isDeleteSuccess:boolean,
  isActiveSuccess:boolean,
};

const initialState: InitialState = {
  addedWebsiteData: {},
  formDisplay: {
    begin: true,
    meta: false,
    html: false,
    dns: false,
    details: false,
    isAdd: true,
  },
  addFormDetailsEdit: {
    domainAuthority: 0,
    traffic: 0,
    websiteName: "",
    websiteUrl: "",
    guestPostPrice: 0,
    referingDomain: 0,
    linkInsertionPrice: 0,
    casinoPostPrice: 0,
    sampleUrl: "",
    tat: 0,
    postGuideline: "",
    contactNumber: "",
    skypeId: "",
    websiteCategoryIdList: [],
    minPostWordCount: 700,
    guaranteePostValidity: 'YEARLY',
    backLinkType : 'DOFOLLOW',
    maxBackLinks : 3,
    cryptoPostPrice: 0,
    cbdPostPrice: 0,
    adultPostPrice: 0,
    isSenstiveAccepted: false,
    spamScore: 0,
    status:""
  },
  isDeleteSuccess:false,
  isActiveSuccess:false,
};

const addWebsiteDataSlice = createSlice({
  name: "addWebsiteState",
  initialState,
  reducers: {
    setAddWebsiteData: (state, action: PayloadAction<any>) => {
      state.addedWebsiteData = action.payload;
    },
    setFormDisplay: (
      state,
      action: PayloadAction<{
        begin: boolean;
        meta: boolean;
        html: boolean;
        dns: boolean;
        details: boolean;
        isAdd:boolean;
      }>
    ) => {
      state.formDisplay = action.payload;
    },
    setAddFormDetailsEdit: (
      state,
      action: PayloadAction<AddWebsiteDetailsPayload>
    ) => {
      state.addFormDetailsEdit = action.payload;
    },
    setIsDeleteSuccess:(
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isDeleteSuccess = action.payload;
    },
    setActiveSuccess:(
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isDeleteSuccess = action.payload;
    },
  },
});

export default addWebsiteDataSlice.reducer;

export const { setAddWebsiteData, setFormDisplay ,setAddFormDetailsEdit,setIsDeleteSuccess,setActiveSuccess} =
  addWebsiteDataSlice.actions;
