import { useState,useEffect } from "react";
import SideHeader from "../../components/SideHeader/SideHeader";

import DashboardHeader from "../../components/DashboardHeader/DashboardHeader";
//import MarketPlaceMid from "../../components/marketPlace/MarketPlaceMid";
import {getPaymentURL} from "../../utils/api/payment";
import withAuth from "../../hoc/withAuth";
import AppLoader from "../../components/defaultLoader/DefaultLoader";

const PaymentHistory = () => {
  const roleType = sessionStorage.getItem("roleType");
  const [paymentUrl, setPaymentUrl] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const getPaymentURLAction = async () => {
    const response = await getPaymentURL("PAYMENT_HISTORY");
    if (response?.status?.code === 200){
        setPaymentUrl(response?.data?.iframe?.url);
       // setIsLoading(false);
        console.log(response);
    }
    
  };

  const loaded = () => {
    setIsLoading(false);
  }
  
  useEffect(() => {
    getPaymentURLAction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="dashboard_Con">
      <SideHeader roleType={roleType}/>
      <div className="dashboard_Con__Right">
        <DashboardHeader  />
        {isLoading && <AppLoader size="lg" ></AppLoader>}
        <iframe onLoad={() => {loaded();}}  width={'100%'} height={'100%'} 
        src={paymentUrl} frameBorder="0" title="payment"></iframe>
      </div>
    </div>
  );
};

export default  withAuth(PaymentHistory);
