import "./header.scss";
import logo from "../assets/header/logo.svg";
import { Link } from "react-router-dom";

const Header = () => {
  return (
    <>
      <div className="header_Con"></div>
      <header className="header">
        <div className="ul">
          <a href="https://outreachkart.com/">
            <img
              src={logo}
              alt="logo"
              className="logoImg"
              onClick={() => window.scrollTo(0, 0)}
            />
          </a>
          <div className="nav" style={{ display: "none" }}>
            {/* <Link to="/" className="contact-btn">
              {" "}
              Services{" "}
            </Link> */}

            <a href="https://outreachkart.com/about-us/" className="contact-btn">
              {" "}
              About Us{" "}
            </a>
            <a href="https://outreachkart.com/our-services/" className="contact-btn">
              Services
            </a>
            <a href="/#exclusive" className="contact-btn">
              Exclusive Websites
            </a>
            <a href="/#client" className="contact-btn">
              Testimonials
            </a>
            {/* <Link to="/#blog" className="contact-btn">
            Blog
          </Link> */}

            <a href="https://outreachkart.com/blog/" className="contact-btn">
              {" "}
              Blog{" "}
            </a>
            <a
              href="#faq"
              className="contact-btn"
              onClick={() => window.location.replace("/#faq")}
            >
              FAQ
            </a>
            {/* <Link to="/#faq" className="contact-nav">
            {" "}
            FAQ
          </Link> */}

            <a href="https://outreachkart.com/contact-us/" className="contact-nav">
              Contact Us
            </a>
          </div>
          <span style={{ display: "none" }}>
            <Link to="/login" className="login-btn__Header">
              Login
            </Link>

            <Link to="/signup" className="signUp-btn">
              Sign Up
            </Link>
          </span>
        </div>
      </header>
    </>
  );
};

export default Header;
