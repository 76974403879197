import { useState, useEffect } from "react";
import SideHeader from "../../components/SideHeader/SideHeader";
import "./paymentDashboard.scss";
import DashboardHeader from "../../components/DashboardHeader/DashboardHeader";
//import MarketPlaceMid from "../../components/marketPlace/MarketPlaceMid";
import { getPaymentURL } from "../../utils/api/payment";
import withAuth from "../../hoc/withAuth";
import AppLoader from "../../components/defaultLoader/DefaultLoader";

const PaymentDashboard = () => {
  const roleType = sessionStorage.getItem("roleType");
  const [paymentUrl, setPaymentUrl] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(true);


  const getPaymentURLAction = async () => {
    const response = await getPaymentURL("PAYEE_DASHBOARD_HOME");
    if (response?.status?.code === 200) {
      setPaymentUrl(response?.data?.iframe?.url);
      console.log(response);
      // setIsLoading(false);
    }

  };

  const loaded = () => {
    setIsLoading(false);
  }


  useEffect(() => {
    getPaymentURLAction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="dashboard_Con">
      <SideHeader roleType={roleType} />
      <div className="dashboard_Con__Right">
        <DashboardHeader  />
        {isLoading && <AppLoader size="lg" ></AppLoader>}
        <div className="paymentDisclaimer">
          <h4> While registering, please ensure the following information is entered correctly (if using ACH or Wire or eCheck/SEPA):</h4>
          <ul className="paymentDisclaimerList">
            <li>	Bank account number </li>
            <li>	The name on your bank account should match the name of your account while registration. </li>
            <li>	IBAN/SWIFT/Routing, based on your bank address. </li>
          </ul>
          If using PayPal, please ensure your account is valid at the time of registration and remains active in order to receive payments in a timely manner.
          <br />
          <br />
          <h4 className="red">Incorrect bank information will result in rejection fees charged to your account. Also, it will delay your payments</h4>

        </div>
        <iframe onLoad={() => { loaded(); }} width={'100%'} height={'65%'}
          src={paymentUrl} frameBorder="0" title="payment"></iframe>
      </div>
    </div>
  );
};

export default withAuth(PaymentDashboard);
