import React from "react";
import { deleteCustomerWebsite } from "../../utils/api/addWebsite";
import {  toast } from "react-toastify";
import { useAppDispatch } from "../../store/reduxHooks";
import { setIsDeleteSuccess } from "../../reducers/addWebsite/addWebsitedataReducer";
type Props = {
  setDeleteData: React.Dispatch<
    React.SetStateAction<{
      display: boolean;
      id: string;
    }>
  >;
  deleteData: {
    display: boolean;
    id: string;
  };
};

const PublisherDeletePopup: React.FC<Props> = ({
  setDeleteData,
  deleteData,
}) => {
  const dispatch = useAppDispatch();
  const handleYes = async () => {
    setDeleteData({ ...deleteData, display: false });
    const response = await deleteCustomerWebsite(deleteData?.id);
    if (response?.status?.code === 200) {
      toast.success("Website deleted successfully", {
        position: "top-right",
        theme: "colored",
        autoClose: 5000,
      });
      dispatch(setIsDeleteSuccess(true))
    } else {
      toast.error(
        response?.status?.errors?.[0]?.websiteNotFound ||
          "Something went wrong",
        {
          position: "top-right",
          theme: "colored",
          autoClose: 5000,
        }
      );
      dispatch(setIsDeleteSuccess(true))
    }
  };
  const handleNo = () => {
    setDeleteData({ ...deleteData, display: false });
  };

  return (
    <div className="publisherDeletePopup__Con__out">
      <div className="publisherDeletePopup__Con__in">
        <p>Are you sure you want to remove this website from the portal?</p>
        <section className="publisherDeletePopup__button_Con">
          <button
            className="publisherDeletePopup__button--Yes"
            onClick={() => handleYes()}
          >
            Yes
          </button>
          <button
            className="publisherDeletePopup__button--No"
            onClick={() => handleNo()}
          >
            No
          </button>
        </section>
      </div>

    </div>
  );
};

export default PublisherDeletePopup;
