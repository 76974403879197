import './AccountVerifiedPage.scss';
import Footer from '../../components/footer/Footer'
import Header from '../../components/Header'
import AccountVerified  from '../../components/accountVerified/AccountVerified';

const AccountVerifiedPage = () => {
  return (
    <div className='accountverified-bg' >
    <Header/>
    <AccountVerified/>
    <div className='footer-bg' >
    <Footer/>
    </div>
    </div>
  )
}

export default AccountVerifiedPage