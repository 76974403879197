import axios from "axios";
import { ContactUs } from "../types/main";
import {  CONTACT_US } from "../constants/url";

export const contactPost = async (contactData: ContactUs) => {
  try {
    const response = await axios.post(process.env.REACT_APP_BASE + CONTACT_US, contactData);

    return response.data;
  } catch (err) {
    console.log(err);
    return err;
  }
};
