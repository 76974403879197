import React, { useEffect, useState } from "react";
import "./dashboardMidAdmin.scss";
// import { useAppSelector } from "../../store/reduxHooks";
import { getAdminStats } from "../../utils/api/dashboardStats";
import AppLoader from "../../components/defaultLoader/DefaultLoader";
import { useNavigate } from "react-router-dom";

const DashboardMidAdmin = () => {
  const [adminStatsMap, setAdminStatsMap] = useState(new Map());
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const navigate = useNavigate();

  const getAdminStatsData = async () => {
    const response = await getAdminStats();
    if (response?.status?.code === 200) {
      const map = new Map();
      console.log(response);
      response?.data?.statsDetail?.stats.forEach((obj: any) => {
        map.set(obj.order, obj);
      });
      console.log(map)
      setAdminStatsMap(map);
      setIsLoading(false);
    };
  };

  const navigateTo = (link: string) => {
    navigate(link);
  };

  const getStatsValue = (key: any) => {
    const obj = adminStatsMap?.get(key);
    return obj === undefined ? "" : obj.value;
  };

  const getStatsName = (key: any) => {
    const obj = adminStatsMap?.get(key);
    return obj === undefined ? "" : obj.name;
  };

  useEffect(() => {
    getAdminStatsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="dashboardMid__Con_stats_tile">
      {isLoading && <AppLoader size="lg" ></AppLoader>}
      {!isLoading &&
        <>
          <div className="height20 width100 paddingStatsDiv displayFlex">
            <div className="width30perc padding1Perc  " onClick={() => navigateTo("/admin/customerDetails/all")}>
              <div className="statsTile"> <p>{getStatsName(1)}
                <span className="bigCircle">{getStatsValue(1)}</span></p>
              </div>
            </div>
            <div className="width30perc padding1Perc">
              <div className="shorterStatsTile" onClick={() => navigateTo("/admin/customerDetails/AdvertiserPublisher")}> <p>{getStatsName(2)}  <span className="circle"> {getStatsValue(2)} </span></p>

              </div>
              <div className="shorterStatsTile" onClick={() => navigateTo("/admin/customerDetails/Advertiser")}> <p>{getStatsName(3)}  <span className="circle"> {getStatsValue(3)} </span></p>

              </div>
            </div>
            <div className="width30perc padding1Perc">
              <div className="shorterStatsTile" onClick={() => navigateTo("/admin/customerDetails/Publisher")}> <p>{getStatsName(4)}  <span className="circle"> {getStatsValue(4)} </span></p>
              </div>
            </div>
          </div>
          <div className="height20 width100 paddingStatsDiv displayFlex">
            <div className="width30perc padding1Perc" >
              <div className="statsTile" onClick={() => navigateTo("/admin/allWebsites/All")}> <p>{getStatsName(5)}
                <span className="bigCircle">{getStatsValue(5)}</span></p>
              </div>
            </div>
            <div className="width30perc padding1Perc" >
              <div className="shorterStatsTile" onClick={() => navigateTo("/admin/allWebsites/APPROVED")} > <p>{getStatsName(6)}  <span className="circle"> {getStatsValue(6)} </span></p>

              </div>
              <div className="shorterStatsTile" onClick={() => navigateTo("/admin/allWebsites/REJECTED")} > <p>{getStatsName(7)}  <span className="circle"> {getStatsValue(7)} </span></p>

              </div>
            </div>
            <div className="width30perc padding1Perc" >
              <div className="shorterStatsTile" onClick={() => navigateTo("/admin/allWebsites/INCOMPLETED_VERIFIED")}> <p>{getStatsName(8)}  <span className="circle"> {getStatsValue(8)} </span></p>
              </div>
            </div>
          </div>
          <div className="height20 width100 paddingStatsDiv displayFlex">
            <div className="width30perc padding1Perc" onClick={() => navigateTo("/admin/orders/all")}>
              <div className="statsTile"> <p>{getStatsName(9)}
                <span className="bigCircle">{getStatsValue(9)}</span></p>
              </div>
            </div>
            <div className="width30perc padding1Perc">
              <div className="shorterStatsTile" onClick={() => navigateTo("/admin/orders/Pending")}> <p>{getStatsName(10)}   <span className="circle"> {getStatsValue(10)} </span></p>

              </div>
              <div className="shorterStatsTile" onClick={() => navigateTo("/admin/orders/Rejected")}> <p>{getStatsName(11)}   <span className="circle"> {getStatsValue(11)} </span></p>

              </div>
            </div>
            <div className="width30perc padding1Perc" onClick={() => navigateTo("/admin/orders/Completed")}>
              <div className="shorterStatsTile"> <p>{getStatsName(12)}  <span className="circle"> {getStatsValue(12)} </span></p>
              </div>
            </div>
          </div>
          {/* <div className="height20 width100 paddingStatsDiv displayFlex">
            <div className="width30perc padding1Perc">
              <div className="statsTile"> <p>{getStatsName(13)}
                <span className="bigCircle">{getStatsValue(13)}</span></p>
              </div>
            </div>
            <div className="width30perc padding1Perc">
              <div className="shorterStatsTile"> <p>{getStatsName(14)}  <span className="circle"> {getStatsValue(14)} </span></p>

              </div>
              <div className="shorterStatsTile"> <p>{getStatsName(15)}  <span className="circle"> {getStatsValue(15)} </span></p>

              </div>
            </div>

          </div> */}

          <p className="dashBoardMid__Footer">
            © 2024 Powered By Outreach Kart | All Rights Reserved
          </p>
        </>
      }
    </div>
  );
};

export default DashboardMidAdmin;
