import axios from "axios";
import { CUSTOMER_DETAILS,CUSTOMER_WEBSITES,EXPORT_CUSTOMERS,DELETE_WEBSITE,EXPORT_WEBSITE} from "../constants/url";

export const fetchCustomerDetailsPost = async (
  pageRowLimit: number,
  pageOffset: number,
  verifiedFiler: any,
  roleFiler: any,
  searchInp: String,
  sortColumn:any
) => {
  try {
    const page = {pageRowLimit:pageRowLimit,pageOffset:pageOffset};
    let filters ;
    let searchParams ;
    let sortParams;
    const isVerified = verifiedFiler !== undefined ? verifiedFiler.id === "YES" ? true :  verifiedFiler.id === "NO" ? false : undefined : undefined;
    const selecedRole = roleFiler !== undefined ? roleFiler.id : undefined;
    if(isVerified !== undefined && selecedRole !== undefined){
      filters = [{name:"accountVerified",value:isVerified},{name:"role",value:selecedRole}];
    }else if(isVerified !== undefined){
      filters = [{name:"accountVerified",value:isVerified}];
    }else if( selecedRole !== undefined){
      filters = [{name:"role",value:selecedRole}];
    }
    if(searchInp){
      searchParams = [{name:"emailId",value:searchInp}];
    }
    if(sortColumn){
      sortParams = [sortColumn];
    }
    const customerDetailsRequestObj = {page:page,filters:filters,searchParams:searchParams,sortParams:sortParams};
    const accessToken = sessionStorage.getItem('access-token');
    const response = await axios.post(
      process.env.REACT_APP_BASE + CUSTOMER_DETAILS,
      customerDetailsRequestObj , { headers: { 'Authorization': 'Bearer '+accessToken}}
    );

    return response.data;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const customerWebsiteList = async (
  pageRowLimit: number,
  pageOffset: number,
  customerId: any ,
  searchParam : string,
  status : any,
) => {
  try {
    // let filters ;
    // if( filter !== undefined && filter !== ''  ){
    //   filters = [{name:"status",value:filter}];
    // }
    // console.log(filter);
    // const filterParam = filter === undefined || filter === ''  ? undefined : JSON.stringify([{name: "status", value: filter , operator: "eq"}]);
    // console.log("  filterParam ----->>  ",filterParam)
    let params;
    if(status === undefined || status === ''){
      params = { pageRowLimit, pageOffset, customerId, searchParam}
    } else{
      params = { pageRowLimit, pageOffset, customerId, searchParam, status}
    }
    const accessToken = sessionStorage.getItem('access-token');
    const response = await axios.get(
      process.env.REACT_APP_BASE + CUSTOMER_WEBSITES,
      {
        params ,
        headers: { 'Authorization': 'Bearer '+accessToken}
      }
    );
    return response.data;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const exportCustomers = async () => {
  try {
    const accessToken = sessionStorage.getItem('access-token');
    const response = await axios.get(
      process.env.REACT_APP_BASE + EXPORT_CUSTOMERS,
      {
        headers: { 'Authorization': 'Bearer ' + accessToken },
        responseType: 'arraybuffer', // Set the responseType to 'arraybuffer'
      }
    );
    return response.data;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const exportAllWebsite = async () => {
  try {
    const accessToken = sessionStorage.getItem('access-token');
    const response = await axios.get(
      process.env.REACT_APP_BASE + EXPORT_WEBSITE,
      {
        headers: { 'Authorization': 'Bearer ' + accessToken },
        responseType: 'arraybuffer', // Set the responseType to 'arraybuffer'
      }
    );
    return response.data;
  } catch (err) {
    console.log(err);
    return err;
  }
};

 

export const deleteWebsiteApi = async (
  websiteId: string,
) => {
  try {
    const accessToken = sessionStorage.getItem('access-token');
    const response = await axios.delete(
      process.env.REACT_APP_BASE + DELETE_WEBSITE + "/" + websiteId,
      {
        headers: { 'Authorization': 'Bearer '+accessToken}
      }
    );
    return response.data;
  } catch (err : any) {
    console.log(err);
    return  err?.response?.data;;
  }
};
