import React, { useEffect, useState } from "react";
import "./CustomerDetails.scss";
import withAuth from "../../hoc/withAuth";
import "react-toastify/dist/ReactToastify.css";
import SideHeaderAdmin from "../../components/SideHeader/SideHeaderAdmin";
import DashboardHeaderAdmin from "../../components/DashboardHeader/DashboardHeaderAdmin";
import { fetchCustomerDetailsPost, customerWebsiteList, exportCustomers, deleteWebsiteApi } from "../../utils/api/customerDetails";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import search from "../../assets/add_Websites/search.svg";
import { selectRoleInterestArray, selectVerifiedArray } from "../../utils/constants/selected";
import { getYesNoFromBool, getRole, numberDisplay, formatTraffic, getDateFormatted } from "../../utils/filters/nameFilters";
import InfiniteScroll from 'react-infinite-scroll-component';
import AppLoader from "../../components/defaultLoader/DefaultLoader";
import SignUpAdmin from "../../components/SignUpAdmin/signUpAdmin";
//import LoginCustAdmin from "../../components/login/LoginCustAdmin";
import Popup from '../../components/popup/popup';
import { useParams } from 'react-router-dom'

const CustomerDetails = () => {
    //const dispatch = useAppDispatch();
    // const sessionId = 'uniqueSessionId';
    const { interest } = useParams();
    const tableCells = [1, 2, 3, 4, 5, 6];
    const [searchInput, setSearchInput] = useState<string>("");
    const [customersList, setCustomersList] = useState<any>([]);
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [hasMoreData, setHasMoreData] = useState<boolean>(true);
    const [verifiedFilter, setVerifiedFilter] = useState<any>();
    const [roleFilter, setRoleFilter] = useState<any>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [customerWebsitesList, setCustomerWebsitesList] = useState<any>([]);
    const [selectedCustomerObj, setSelectedCustomerObj] = useState<any>({});
    const [isCustomerWebsiteView, setIsCustomerWebsiteView] = useState<boolean>(false);
    const [customerWebsitePageNumber, setCustomerWebsitePageNumber] = useState<number>(1);
    const [hasCustomerWebsiteMoreData, setHasCustomerWebsiteMoreData] = useState<boolean>(true);
    const [isAddCustomerView, setIsAddCustomerView] = useState<boolean>(false);
    const [isAdminCustomerLogin, setIsAdminCustomerLogin] = useState<boolean>(false);
    const [isDeleteWebsitePopupOpen, setIsDeleteWebsitePopupOpen] = useState(false);
    const [deleteWebsiteId, setDeleteWebsiteId] = useState("");
    const [adminUrl, setAdminUrl] = useState<string>("");
    const [selectRole, setSelectRole] = useState<number>(-1);
    const [hoveredColumn, setHoveredColumn] = useState<string | null>('');
    const [sortColumn, setSortColumn] = useState<any>({ sortBy: 'createdDate', direction: 'DESC' });

    const handleMouseEnter = (column: string) => {
        setHoveredColumn(column);
    };

    const handleMouseLeave = () => {
        setHoveredColumn(null);
    };

    const renderSortIcon = (column: string): string | null => {
        if (sortColumn === undefined || sortColumn.sortBy !== column) {
            return '';
        }
        return sortColumn.direction === 'ASC' ? 'north' : 'south';
    };

    const renderHoverIcon = (column: string): string | null => {
        if (hoveredColumn === column && sortColumn) {
            return sortColumn.sortBy === column && sortColumn.direction === 'ASC' ? 'south' : 'north';
        }
        return renderSortIcon(column);
    };

    const toggleDeleteWebsitePopup = (websiteId: string) => {
        setDeleteWebsiteId(websiteId);
        setIsDeleteWebsitePopupOpen(!isDeleteWebsitePopupOpen);
    }

    const getCustomerData = async () => {
        const response = await fetchCustomerDetailsPost(10, 0, undefined, undefined, '', sortColumn);
        if (response?.status?.code === 200) {
            setPageNumber(1);
            setCustomersList(response?.data?.customersData?.customers);
            setHasMoreData(response?.data?.customersData?.pageInfo?.hasNext);
            setIsLoading(false);
        } else {
            setIsLoading(false);
            toast.error("Something went wrong", {
                position: "top-right",
                theme: "colored",
                autoClose: 5000,
            });
        }
    };

    const getCustomerDataCustom = async (verifiedFilterCustom: any, roleFilterCustom: any, searchInputCustom: string, sortColumnCustom: any) => {
        setIsLoading(true);
        const response = await fetchCustomerDetailsPost(10, 0, verifiedFilterCustom,
            roleFilterCustom, searchInputCustom, sortColumnCustom);
        if (response?.status?.code === 200) {
            setPageNumber(1);
            setCustomersList(response?.data?.customersData?.customers);
            setHasMoreData(response?.data?.customersData?.pageInfo?.hasNext);
            setIsLoading(false);
        } else {
            setIsLoading(false);
            toast.error("Something went wrong", {
                position: "top-right",
                theme: "colored",
                autoClose: 5000,
            });
        }
    };

    const fetchMoreData = async () => {
        const response = await fetchCustomerDetailsPost(10, pageNumber, verifiedFilter, roleFilter, searchInput, sortColumn);
        if (response?.status?.code === 200) {
            setHasMoreData(response?.data?.customersData?.pageInfo?.hasNext);
            const list = customersList;
            const mergedResult = [...list, ...response?.data?.customersData?.customers];
            setPageNumber(pageNumber + 1);
            setCustomersList(mergedResult);
        }
    };

    const deleteWebsite = async () => {
        const response = await deleteWebsiteApi(deleteWebsiteId);
        if (response?.status?.code === 200) {
            fetchCustomerWebsite(selectedCustomerObj);
            toast.success(
                "Website deleted successfully.", {
                position: "top-right",
                theme: "colored",
                autoClose: 5000,
            }
            );
            toggleDeleteWebsitePopup("");
        } else if (response?.status?.code === 400) {
            let error = response?.status?.errors[0]?.['websiteCanNotDeleted'];
            error = error !== undefined ? "Delete action can’t be performed because open order and/or order history found for this website."
                : "Something went wrong please try again";
            toast.error(error, {
                position: "top-right",
                theme: "colored",
                autoClose: 5000,
            });
            toggleDeleteWebsitePopup("");
        } else {
            toast.error("Something went wrong please try again", {
                position: "top-right",
                theme: "colored",
                autoClose: 5000,
            });
            toggleDeleteWebsitePopup("");
        }
    };

    const fetchCustomerWebsite = async (customerObj: any) => {
        setIsLoading(true);
        setSelectedCustomerObj(customerObj);
        const response = await customerWebsiteList(10, 0, customerObj.id, '', undefined);
        if (response?.status?.code === 200) {
            setCustomerWebsitesList(response?.data?.customer?.customerWebsites);
            setIsLoading(false);
            if (!response?.data?.customer?.customerWebsites || response?.data?.customer?.customerWebsites.length === 0) {
                toast.error("There are no websites associated with this account.", {
                    position: "top-right",
                    theme: "colored",
                    autoClose: 5000,
                });
                return;
            }
            setIsCustomerWebsiteView(true);
            setHasCustomerWebsiteMoreData(response?.data?.customer?.pageInfo?.hasNext);
            setCustomerWebsitePageNumber(1);

        } else {
            setIsLoading(false);
            toast.error("Something went wrong", {
                position: "top-right",
                theme: "colored",
                autoClose: 5000,
            });
        }
    };


    const openAdminLoginPage = async (customerObj: any) => {
        setIsAdminCustomerLogin(true);
        setIsCustomerWebsiteView(false);
        setIsAddCustomerView(false);
        setSelectedCustomerObj(customerObj);
        setIsLoading(true);
        // setIsCustomerWebsiteView(false);
        // setSelectedCustomerObj({});
        // setCustomerWebsitesList([]);
    };

    const navigateBack = async () => {
        setIsCustomerWebsiteView(false);
        setSelectedCustomerObj({});
        setCustomerWebsitesList([]);
    };

    const navigateToAddCustomer = async () => {
        setIsAddCustomerView(true);
        setIsCustomerWebsiteView(false);
        setSelectedCustomerObj({});
        setCustomerWebsitesList([]);
    };

    const fetchCustomerWebsiteMoreData = async () => {
        const response = await customerWebsiteList(10, customerWebsitePageNumber, selectedCustomerObj.id, '', undefined);
        if (response?.status?.code === 200) {
            setHasCustomerWebsiteMoreData(response?.data?.customer?.pageInfo?.hasNext);
            const list = customerWebsitesList;
            const mergedResult = [...list, ...response?.data?.customer?.customerWebsites];
            setCustomerWebsitePageNumber(customerWebsitePageNumber + 1);
            setCustomerWebsitesList(mergedResult);
        }
    };

    const downloadCustomers = async () => {
        setIsLoading(true);
        const response = await exportCustomers();
        if (response) {
            // Create a Blob from the response data
            const blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            // Create a download link
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'customers.xlsx'; // Set the desired file name
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);
            setIsLoading(false);
        } else {
            setIsLoading(false);
        }
    }


    const searchCustomerData = () => {
        setPageNumber(1);
        getCustomerDataCustom(verifiedFilter, roleFilter, searchInput, sortColumn);
    };

    const sortCustomerData = (column: string) => {
        const sortObj = {
            sortBy: column,
            direction: sortColumn && sortColumn.sortBy === column && sortColumn.direction === 'ASC' ? 'DESC' : 'ASC',
        }
        setSortColumn(sortObj);
        setPageNumber(1);
        getCustomerDataCustom(verifiedFilter, roleFilter, searchInput, sortObj);
    };

    const clearSearchCustomerData = () => {
        setSearchInput('');
        getCustomerDataCustom(verifiedFilter, roleFilter, '', sortColumn);
    };

    const setSearchCustomerData = (e: any) => {
        //   var inputType = e.nativeEvent.inputType;
        //   if (inputType !== 'deleteContentForward' && inputType !== 'deleteContentBackward') {
        setSearchInput(e.target.value);
        //   }
    };

    const filterRole = (event: any) => {
        let selectedVal;
        // setPageNumber(0);
        if (event.target.value !== '-1') {
            selectedVal = selectRoleInterestArray[event.target.value];
        }
        setSelectRole(event.target.value);
        setRoleFilter(selectedVal);
        getCustomerDataCustom(verifiedFilter, selectedVal, searchInput, sortColumn);
        setPageNumber(1);
    };

    const filterVerified = (event: any) => {
        let selectedVal;
        if (event.target.value !== '-1') {
            selectedVal = selectVerifiedArray[event.target.value];
        }
        setVerifiedFilter(selectedVal);
        setPageNumber(1);
        getCustomerDataCustom(selectedVal, roleFilter, searchInput, sortColumn);
    };

    const statusClass = (websiteObj: any) => {
        if (websiteObj.status === "Approved") {
            return 'statusGreen'
        } else if (websiteObj.status === "Awaiting") {
            return 'statusOrange';
        } else {
            return 'statusRed';
        }
    };

    const passDataToIframe = () => {
        setIsLoading(false);
        const iframe = document.getElementById('CustomerFrame');
        // Send the data to the iframe
        const url = process.env.REACT_APP_OUTREACH_ADMIN_URL !== undefined ? process.env.REACT_APP_OUTREACH_ADMIN_URL
            : 'https://dev.admin.outreachkart.de';
        console.log("urll iframe -->  ", url)
        if (iframe) {
            const accessToken = sessionStorage.getItem('access-token');
            // The data you want to send
            const data = {
                message: accessToken,
                emailId: selectedCustomerObj.emailId,
            };
            // Send the data to the iframe
            //(iframe as HTMLIFrameElement).contentWindow?.postMessage(data, 'https://dev.admin.outreachkart.de');
            (iframe as HTMLIFrameElement).contentWindow?.postMessage(data, url);
        } else {
            console.error("Iframe not found!");
        }
    };

    useEffect(() => {

        if (interest && interest !== 'all') {
            const index = interest === 'Advertiser' ? 0 : interest === 'Publisher' ? 1 : 2;
            const selectedVal = selectRoleInterestArray[index];
            setRoleFilter(selectedVal);
            setPageNumber(1);
            setSelectRole(index);
            getCustomerDataCustom(verifiedFilter, selectedVal, searchInput, sortColumn);
        } else {
            getCustomerData();
        }

        const tempAdminUrl = process.env.REACT_APP_OUTREACH_ADMIN_URL !== undefined ? process.env.REACT_APP_OUTREACH_ADMIN_URL + '/loginCustAdmin'
            : 'https://dev.admin.outreachkart.de/loginCustAdmin';
        setAdminUrl(tempAdminUrl)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (

        <div className={`dashboard_Con   `}>
            <SideHeaderAdmin />

            <div className={`dashboard_Con__Right`}>
                <DashboardHeaderAdmin />
                <div className="publisherWebsiteMid__Con">
                    {isLoading && <AppLoader size="lg" ></AppLoader>}
                    {!isCustomerWebsiteView && !isAddCustomerView && !isAdminCustomerLogin &&
                        <div className="pubLisherAddWebsite__Con">
                            <h4>Customer Details </h4>
                            <div>
                                <div className="sortDiv floatLeft">
                                    <select className="sortMarketPlace" onChange={(e) => filterRole(e)} value={selectRole} placeholder="Select Interest">
                                        <option key={-1} value={-1}>{'Select Interest'}</option>
                                        {selectRoleInterestArray?.map((selectRoleInterestObj: any, index) => (
                                            <option key={selectRoleInterestObj.friendlyName} value={index}>{selectRoleInterestObj.friendlyName}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="sortDiv floatLeft">

                                    <select className="sortMarketPlace" onChange={(e) => filterVerified(e)} placeholder="Select Verified">
                                        <option key={-1} value={-1}>{'Select Verified'}</option>
                                        {selectVerifiedArray?.map((selectVerifiedObj: any, index) => (
                                            <option key={selectVerifiedObj.friendlyName} value={index}>{selectVerifiedObj.friendlyName}</option>
                                        ))}
                                    </select>
                                </div>

                                <div className=" floatLeft">
                                    <input
                                        className="customer-export"
                                        type="button"
                                        value='Add Customer'
                                        onClick={() => { navigateToAddCustomer(); }}
                                    />
                                </div>
                                <div className=" floatRight">
                                    <input
                                        className="customer-export"
                                        type="button"
                                        value='Export'
                                        onClick={() => { downloadCustomers(); }}
                                    />
                                </div>
                                <div className="searchDiv floatRight">
                                    <input
                                        className="searchMarketPlace"
                                        type="text"
                                        value={searchInput}
                                        placeholder='Search Email Id'
                                        onChange={(e: any) => setSearchCustomerData(e)}
                                    />
                                    {searchInput !== '' && <img className="search-img" src={search} alt="" onClick={() => { searchCustomerData(); }} />}
                                    {searchInput !== '' && <span className="material-icons lightGrey close-img" onClick={() => { clearSearchCustomerData(); }}>close</span>}
                                </div>

                            </div>
                            <div className="PublisherSummaryTable__Con noPadding">
                                <section className="PublisherSummaryTable__Con__Table__Header noMargin">
                                    <article className="no-select">
                                        <h5
                                            onMouseEnter={() => handleMouseEnter('emailId')}
                                            onMouseLeave={handleMouseLeave}
                                            onClick={() => sortCustomerData('emailId')}
                                        >
                                            Email ID
                                            <span className="material-icons sortArrow">{renderHoverIcon('emailId')}</span>
                                        </h5>
                                        <p
                                            onMouseEnter={() => handleMouseEnter('firstName')}
                                            onMouseLeave={handleMouseLeave}
                                            onClick={() => sortCustomerData('firstName')}
                                        >
                                            First Name
                                            <span className="material-icons sortArrow">{renderHoverIcon('firstName')}</span>
                                        </p>
                                        <p
                                            onMouseEnter={() => handleMouseEnter('lastName')}
                                            onMouseLeave={handleMouseLeave}
                                            onClick={() => sortCustomerData('lastName')}
                                        >
                                            Last Name
                                            <span className="material-icons sortArrow">{renderHoverIcon('lastName')}</span>
                                        </p>
                                        <p
                                            onMouseEnter={() => handleMouseEnter('role')}
                                            onMouseLeave={handleMouseLeave}
                                            onClick={() => sortCustomerData('role')}
                                        >
                                            Interest
                                            <span className="material-icons sortArrow">{renderHoverIcon('role')}</span>
                                        </p>
                                        <p
                                            onMouseEnter={() => handleMouseEnter('accountVerified')}
                                            onMouseLeave={handleMouseLeave}
                                            onClick={() => sortCustomerData('accountVerified')}
                                        >
                                            Is Verified
                                            <span className="material-icons sortArrow">{renderHoverIcon('accountVerified')}</span>
                                        </p>
                                        <p
                                            onMouseEnter={() => handleMouseEnter('createdDate')}
                                            onMouseLeave={handleMouseLeave}
                                            onClick={() => sortCustomerData('createdDate')}
                                        >
                                            Registered On
                                            <span className="material-icons sortArrow">{renderHoverIcon('createdDate')}</span>
                                        </p>
                                        <h6>Action</h6>
                                    </article>
                                    <InfiniteScroll
                                        dataLength={customersList.length}
                                        next={fetchMoreData}
                                        hasMore={hasMoreData}
                                        loader={''}
                                        height={'calc(57vh - 10px)'}
                                    >
                                        {customersList.length !== 0 &&
                                            customersList?.map((cell: any, index: number) => (
                                                <div
                                                    className={`no-select PublisherSummaryTable__Con__Table__Cell ${index % 2 === 0
                                                        ? "PublisherSummaryTable__Con__Table__Cell__grey__bg"
                                                        : ""
                                                        } ${index === tableCells.length - 1
                                                            ? "PublisherSummaryTable__Con__Table__Cell__last__border"
                                                            : ""
                                                        } `}
                                                    key={index}
                                                >
                                                    {/* <a
                                                        href="#"
                                                        // target="_blank"
                                                        rel="noopener noreferrer"
                                                        style={{ textDecoration: 'none', cursor: 'auto' }}
                                                    > */}
                                                    <span style={{ textAlign: 'left' }} onClick={() => openAdminLoginPage(cell)}
                                                        className="WebsiteUrlPublisherSummaryTable mailIdCustomerDetails">{cell?.emailId}</span>
                                                    {/* </a> */}
                                                    <p>{(cell?.firstName)}</p>
                                                    <p>{(cell?.lastName)}</p>
                                                    <p>{getRole(cell?.role)}</p>
                                                    <p>{getYesNoFromBool(cell?.accountVerified)}</p>
                                                    <p>{getDateFormatted(cell?.createdDate)}</p>
                                                    <h6>
                                                        {/* {!cell.isAddedInCart && <span onClick={() => fetchCustomerWebsite(cell)}> */}
                                                        <span onClick={() => fetchCustomerWebsite(cell)} >View</span>
                                                    </h6>

                                                </div>

                                            ))}
                                    </InfiniteScroll>
                                </section>

                            </div>
                        </div>}
                    {isCustomerWebsiteView && !isAddCustomerView && !isAdminCustomerLogin &&
                        <div className="pubLisherAddWebsite__Con">
                            <h4>Customer Websites </h4>
                            <div>
                                <span>{selectedCustomerObj.firstName} {selectedCustomerObj.lastName} | {selectedCustomerObj.emailId}</span>
                                <button className="paymentDetailsBtn floatRight backButtonblue" onClick={() => navigateBack()} >
                                    Back
                                </button>
                            </div>
                            <div className="PublisherSummaryTable__Con noPadding">
                                <section className="PublisherSummaryTable__Con__Table__Header noMargin">
                                    <article>
                                        <h5>Website URL</h5>
                                        <p>General Post</p>
                                        <p>Link Insertion</p>
                                        <p>Crypto/Finance Post</p>
                                        <p>Casino Post</p>
                                        <p>CBD/Vape Post</p>
                                        <p>Adult Post</p>
                                        <p>DA</p>
                                        <p>DR</p>
                                        <p>Traffic</p>
                                        <p>Status</p>
                                        <h6>Action</h6>
                                    </article>
                                    <InfiniteScroll
                                        dataLength={customerWebsitesList.length}
                                        next={fetchCustomerWebsiteMoreData}
                                        hasMore={hasCustomerWebsiteMoreData}
                                        loader={''}
                                        height={'calc(57vh - 10px)'}
                                    >
                                        {customerWebsitesList.length !== 0 &&
                                            customerWebsitesList?.map((cell: any, index: number) => (
                                                <div
                                                    className={`PublisherSummaryTable__Con__Table__Cell ${index % 2 === 0
                                                        ? "PublisherSummaryTable__Con__Table__Cell__grey__bg"
                                                        : ""
                                                        } ${index === tableCells.length - 1
                                                            ? "PublisherSummaryTable__Con__Table__Cell__last__border"
                                                            : ""
                                                        } `}
                                                    key={index}
                                                >
                                                    <a
                                                        href={cell?.websiteUrl}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        style={{ textDecoration: 'none', cursor: 'auto' }}
                                                    >
                                                        <p style={{ textAlign: 'left' }} className="WebsiteUrlPublisherSummaryTable">{cell?.websiteUrl}</p>
                                                    </a>

                                                    <p>{numberDisplay(cell?.guestPostPrice)}</p>
                                                    <p>{numberDisplay(cell?.linkInsertionPrice)}</p>
                                                    <p>{numberDisplay(cell?.cryptoPostPrice)}</p>
                                                    <p>{numberDisplay(cell?.casinoPostPrice)}</p>
                                                    <p>{numberDisplay(cell?.cbdPostPrice)}</p>
                                                    <p>{numberDisplay(cell?.adultPostPrice)}</p>
                                                    <p>{numberDisplay(cell?.domainAuthority)}</p>
                                                    <p>{numberDisplay(cell?.referingDomain)}</p>
                                                    <p>{formatTraffic(cell?.traffic)}</p>
                                                    <p className={`text-white ${statusClass(cell)}`} >{(cell?.status)}</p>
                                                    <h6>
                                                        {/* {!cell.isAddedInCart && <span onClick={() => fetchCustomerWebsite(cell)}> */}

                                                        <span className="material-icons red" onClick={() => toggleDeleteWebsitePopup(cell.id)}>delete_outline</span>

                                                    </h6>

                                                </div>

                                            ))}
                                    </InfiniteScroll>
                                </section>

                            </div>
                        </div>
                    }
                    {isAddCustomerView &&
                        <SignUpAdmin></SignUpAdmin>

                    }
                    {isAdminCustomerLogin &&
                        //   <iframe onLoad={() => {loaded();}}  width={'100%'} height={'100%'} 
                        <iframe id="CustomerFrame" onLoad={() => passDataToIframe()} width={'90%'} height={'100%'}
                            src={adminUrl}
                            // src="http://localhost:3000/loginCustAdmin"
                            frameBorder="1" title="payment"></iframe>
                        // <LoginCustAdmin />

                    }
                </div>
                {isDeleteWebsitePopupOpen && <Popup
                    content={<>
                        <div><div className="popup-header">Delete Website</div>
                            <div className="popup-content-verify-mail">Are you sure you want to permanently delete this website ?</div>
                            <div className="btnDiv">

                                <button className=" addToCart_btn" onClick={() => { deleteWebsite() }} > Ok </button>
                                <button className="wishList_btn addToCart_btn" onClick={() => { toggleDeleteWebsitePopup(""); }} > Close  </button>
                            </div>
                        </div>
                    </>}
                    handleClose={toggleDeleteWebsitePopup}
                />}
                <ToastContainer />
            </div>
        </div>
    );
};
export default withAuth(CustomerDetails);
