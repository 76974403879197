import React, { useEffect, useState, useRef } from "react";
import "./BlogManagement.scss";
import withAuth from "../../hoc/withAuth";
import "react-toastify/dist/ReactToastify.css";
import SideHeaderAdmin from "../../components/SideHeader/SideHeaderAdmin";
import DashboardHeaderAdmin from "../../components/DashboardHeader/DashboardHeaderAdmin";
import { adminBlogPostSummary, saveBlogApi, fetchBlogApi, fetchBlogCategories, saveCategoryApi } from "../../utils/api/blogpost";
import { authorsListApi} from "../../utils/api/author";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import search from "../../assets/add_Websites/search.svg";
import { getYesNoFromBool, getDateFormatted } from "../../utils/filters/nameFilters";
import InfiniteScroll from 'react-infinite-scroll-component';
import AppLoader from "../../components/defaultLoader/DefaultLoader";
import JoditEditor from 'jodit-react';
import { BlogTypeAdmin, BlogError } from "../../utils/types/main";
import { validateBlog } from "../../utils/validators/blogValidator";
import info from "../../assets/add_Websites/info.svg";
import close from "../../assets/dropdown/close.svg";
import { blogCategoryFilter } from "../../utils/filters/websitecategoryFilter";
import Popup from '../../components/popup/popup';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import dayjs from 'dayjs';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

const BlogManagement = () => {
    //const dispatch = useAppDispatch();
    const tableCells = [1, 2, 3, 4, 5, 6];
    const [searchInput, setSearchInput] = useState<string>("");
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [hasMoreData, setHasMoreData] = useState<boolean>(true);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isCustomerWebsiteView, setIsCustomerWebsiteView] = useState<boolean>(false);
    const [blogsList, setBlogsList] = useState<any>([]);
    const [blogObj, setBlogObj] = useState<BlogTypeAdmin>({ description: '' });
    const editor = useRef(null);
    const [isBlogEnable, setIsBlogEnable] = useState<boolean>(true);
    const [selectedFile, setSelectedFile] = useState<any>();
    const [blogTitle, setBlogTitle] = useState<string>("");
    const [blogTagLine, setBlogTagLine] = useState<string>("");
    const [blogId, setBlogId] = useState<string>("");
    const [isEditBlog, setIsEditBlog] = useState<boolean>(false);
    const [blogImageUrl, setBlogImageUrl] = useState<string>("");
  //  const [sampleURL, setSampleURL] = useState<string>("");
    const [blogCategories, setBlogCategories] = useState<any>([]);
    const [filteredCategories, setFilteredCategories] = useState<any>([]);
    const [searchCategory, setSearchCategory] = useState<any>("");
    const [isDropdownDisplay, setIsdropDownDisplay] = useState<boolean>(false);
    const [isMouseOnDropDown, setIsMouseOnDropDown] = useState<boolean>(false);
    const [isAddCategoryOpen, setIsAddCategoryOpen] = useState<boolean>(false);
    const [blogCategoriesList, setBlogCategoriesList] = useState<any>([]);
    const [addCategoryName, setAddCategoryName] = useState<any>([]);
    const [scheduledDate, setScheduledDate] = useState<any>(new Date().getTime());
    const [authorsList, setAuthorsList] = useState<any>([]);
    const [selectedAuthor, setSelectedAuthor] = useState<any>("OUTREACHKART_ADMIN");
    const [selectedCategories, setSelectedCategories] = useState<any>([{
        id: String,
        name: String,
    }]);
    const [blogCategoryError, setBlogCategoryError] = useState<{
        isError: boolean;
        message: string;
    }>({
        isError: false,
        message: "",
    });
    const [blogError, setBlogError] = useState<BlogError>({
        isError: false,
        imageUrlError: "",
        titleError: "",
        tagLineError: "",
        descriptionError: ""
    });

    const getBlogCategories = async () => {
        const response = await fetchBlogCategories();
        console.log('blog categories', response)
        if (response?.status?.code === 200) {
            setBlogCategories(response?.data?.blog?.blogCategories);
            setFilteredCategories(response?.data?.blog?.blogCategories);
        }
    };

    const getAuthorsData = async () => {
        const response = await authorsListApi(0, 1000, undefined);
        console.log(response)
        if (response?.status?.code === 200) {
            setAuthorsList(response?.data?.blog?.authors);
        } else {
            toast.error("Something went wrong", {
                position: "top-right",
                theme: "colored",
                autoClose: 5000,
            });
        }
    };

    const getBlogsData = async () => {
        setSearchInput('');
        const response = await adminBlogPostSummary(0, 10, undefined, undefined, undefined, undefined);
        if (response?.status?.code === 200) {
            setPageNumber(1);
            setBlogsList(response.data.blogPost.blog);
            setHasMoreData(response?.data?.blogPost?.pageInfo?.hasNext);
            setIsLoading(false);
        } else {
            setIsLoading(false);
            toast.error("Something went wrong", {
                position: "top-right",
                theme: "colored",
                autoClose: 5000,
            });
        }
    };

    const fetchMoreData = async () => {
        const response = await adminBlogPostSummary(pageNumber, 10, undefined, undefined, searchInput, undefined);
        if (response?.status?.code === 200) {
            setHasMoreData(response?.data?.blogPost?.pageInfo?.hasNext);
            const list = blogsList;
            const mergedResult = [...list, ...response.data.blogPost.blog];
            setPageNumber(pageNumber + 1);
            setBlogsList(mergedResult);
        }
    };

    const fetchBlog = async (blogObj: any) => {
        console.log(blogObj);
        setBlogError({});
        setIsLoading(true);
        setIsEditBlog(true);
        setBlogCategoryError({
            isError: false,
            message: "",
        });
        const response = await fetchBlogApi(blogObj.id);
        if (response?.status?.code === 200) {
            console.log(response)
            setIsLoading(false);
            setIsCustomerWebsiteView(true);
            const fetchedBlog = response?.data?.blog?.blogDetail;
            setBlogTagLine(fetchedBlog?.permaLink);
            setBlogTitle(fetchedBlog?.title)
            setIsBlogEnable(fetchedBlog?.isEnabled);
            setDescription(fetchedBlog?.description);
            setBlogId(fetchedBlog?.id);
            setBlogImageUrl(fetchedBlog?.imageUrl);
        //    const sampleURL = "https://outreachkart.com/blog/" + fetchedBlog?.permaLink?.replace(/ /g, "-");
         //   setSampleURL(sampleURL);
            setSelectedFile({});
            setSelectedCategories([]);
            setScheduledDate(new Date(fetchedBlog?.effectiveDateTime).getTime());
            setSelectedAuthor(fetchedBlog?.author['id']);
            var matches = [];
            const fetchedCategories = fetchedBlog?.categoryIds;
            for (var i = 0; i < fetchedCategories.length; i++) {
                for (var e = 0; e < blogCategories.length; e++) {
                    if (fetchedCategories[i] === blogCategories[e].id) {
                        matches.push(blogCategories[e])
                    }
                }
            }
            setSelectedCategories(matches);
            console.log("selected categories", fetchedBlog?.categoryIds);
            console.log("matches matches", matches);
        } else {
            setIsLoading(false);
            toast.error("Something went wrong", {
                position: "top-right",
                theme: "colored",
                autoClose: 5000,
            });
        }
    };

    const navigateBack = async () => {
        setIsCustomerWebsiteView(false);
    };

    const addBlog = async () => {
        setScheduledDate(new Date().getTime());
        setIsCustomerWebsiteView(true);
        setIsBlogEnable(false);
        setDescription("");
        setSelectedFile({});
        setBlogTitle("");
        setBlogTagLine("");
        setBlogId("");
        setBlogImageUrl("");
        setIsEditBlog(false);
        setBlogObj({ description: '' });
        setBlogError({});
        setSelectedCategories([]);
        setBlogCategoryError({
            isError: false,
            message: "",
        });
        setSelectedAuthor("OUTREACHKART_ADMIN");
    };

    const saveBlog = async (enable: boolean) => {
        setIsLoading(true);
        blogObj.isEnabled = enable;
        blogObj.permaLink = blogTagLine.replace(/ /g, "-");
        blogObj.title = blogTitle;
        blogObj.id = blogId;
        blogObj.author = {"id":selectedAuthor};
        console.log(scheduledDate);
        var userTimezoneOffset = new Date().getTimezoneOffset() * 60000;
        console.log(userTimezoneOffset);
        if (!scheduledDate || scheduledDate === '') {
            console.log("inside if ", blogObj.effectiveDateTime);
            blogObj.effectiveDateTime = new Date(new Date().getTime() - userTimezoneOffset).toISOString();
        } else {
            // const date = new Date(parseFloat(scheduledDate)).toLocaleString(undefined, {timeZone: 'Asia/Kolkata'});
            blogObj.effectiveDateTime = new Date(parseFloat(scheduledDate) - userTimezoneOffset).toISOString();
            console.log("inside else ", blogObj.effectiveDateTime);
        }
        let categoryIds: string[] = [];

        for (var i = 0; i < selectedCategories.length; i++) {
            categoryIds = [...categoryIds, selectedCategories[i].id]
        }
        blogObj.categoryIds = categoryIds;
        if (isEditBlog) {
            blogObj.imageUrl = blogImageUrl;
        }
        const errorObj = validateBlog(blogObj, isEditBlog, selectedFile);
        if (errorObj.isError) {
            setBlogError(errorObj);
            setIsLoading(false);
            return;
        }
        const response = await saveBlogApi(blogObj, selectedFile);
        if (response?.status?.code === 200) {
            const msg = isEditBlog ? "Blog updated Succesfully" : "Blog added Succesfully";
            toast.success(msg, {
                position: "top-right",
                theme: "colored",
                autoClose: 5000,
            });
            
            if(enable){
                getBlogsData();
                setIsCustomerWebsiteView(false);
            }else{
                setIsLoading(false);
                setIsBlogEnable(enable);
                setBlogObj(blogObj);
            }
        } else {
            setIsLoading(false);
            toast.error("Something went wrong", {
                position: "top-right",
                theme: "colored",
                autoClose: 5000,
            });
        }

    };

    const setSelectedAuthorDropDown = (event:any) => {
        if(event.target.value !== '-1'){
             setSelectedAuthor(event.target.value);
         }else{
            setSelectedAuthor('');
        }
      };

    const searchCustomerData = async() => {
        setPageNumber(0);
        const response = await adminBlogPostSummary(0, 10, undefined, undefined, searchInput, undefined);
        if (response?.status?.code === 200) {
            setPageNumber(1);
            setBlogsList(response.data.blogPost.blog);
            setHasMoreData(response?.data?.blogPost?.pageInfo?.hasNext);
            setIsLoading(false);
        } else {
            setIsLoading(false);
            toast.error("Something went wrong", {
                position: "top-right",
                theme: "colored",
                autoClose: 5000,
            });
        }
        //   getCustomerDataCustom(verifiedFilter, roleFilter, searchInput);
    };



    const clearSearchCustomerData = () => {
        setSearchInput('');
        setIsLoading(true);
        getBlogsData();
    };

    const setSearchCustomerData = (e: any) => {
       // var inputType = e.nativeEvent.inputType;
        //if (inputType !== 'deleteContentForward' && inputType !== 'deleteContentBackward') {
            setSearchInput(e.target.value);
       // }
    };

    const setDescription = (val: any) => {
        blogObj.description = val;
        setBlogObj(blogObj);
    }

    // const SetBlogTagLineAndSampleUrl = (e: any) => {
    //     const val = e.target.value;
    //     console.log(val)
    //     setBlogTagLine(val);
    //     const sampleURL = "https://outreachkart.com/blog/" + val.replace(/ /g, "-");
    //     console.log(sampleURL)
       
    //     setSampleURL(sampleURL);
    // }

    const getSampleURL = () => {
        const sampleURLText = "https://outreachkart.com/blog/" + blogTagLine.replace(/ /g, "-");
        return sampleURLText;
    }


    const setFilteredSelectedCategories = (categoryObj: any) => {
        if(isEditBlog && isBlogEnable){
            return;
        }
        setSelectedCategories(
            selectedCategories?.filter((currentCategory: any) =>
                categoryObj.id !== currentCategory.id),
        );


    }

    const toggleAddCategory = () => {
        setIsAddCategoryOpen(!isAddCategoryOpen);
        setAddCategoryName("")
    }

    const setAddCategoryNameCustom = (val:string) => {
        if(val.length <= 20){
            setAddCategoryName(val);
        }
    }
    

    const saveNewCategory = async () => {
        setIsLoading(true);
        if (!addCategoryName || addCategoryName === "") {
            toast.error("Please enter category name.", {
                position: "top-right",
                theme: "colored",
                autoClose: 5000,
            });
        }
        const response = await saveCategoryApi(addCategoryName);
        if (response?.status?.code === 200) {
            const msg = "Category added succesfully";
            toast.success(msg, {
                position: "top-right",
                theme: "colored",
                autoClose: 5000,
            });
            const blogCat = response?.data?.blog?.blogCategories;
            setBlogCategories([...blogCategories, ...blogCat]);
            setBlogCategoriesList([...blogCategoriesList, ...blogCat]);
            setFilteredCategories([...filteredCategories, ...blogCat]);
            setIsAddCategoryOpen(!isAddCategoryOpen);
            setIsLoading(false);
        }else if (response?.status?.code === 400) {
            const errors = response?.status?.errors;
            let errorStr = "";
            errors.forEach (function(value:any, key:any) {
                if(value['blogCategoryFound']){
                    errorStr = value['blogCategoryFound']
                }
              });
              if(errorStr === ""){
                errorStr = "Something went wrong";
              }
              setIsLoading(false);
              toast.error(errorStr, {
                  position: "top-right",
                  theme: "colored",
                  autoClose: 5000,
              });
              //setIsAddCategoryOpen(!isAddCategoryOpen);           
        }else {
            setIsLoading(false);
            toast.error("Something went wrong", {
                position: "top-right",
                theme: "colored",
                autoClose: 5000,
            });
            setIsAddCategoryOpen(!isAddCategoryOpen);
        }
    }


    const handleFileChange = (e: any) => {
        if (e.target.files) {
            const fileSize = e.target.files[0].size;
            const fileMb = fileSize / 1024 ** 2;
            if (fileMb >= 2) {
                toast.error("Please select a file less than 2MB.", {
                    position: "top-right",
                    theme: "colored",
                    autoClose: 5000,
                });
                return;
            }
            setSelectedFile(e.target.files[0]);
        }
    };

    const joditConfig = {
        readonly: (isEditBlog && isBlogEnable),
        autofocus: false,
        tabIndex: 1,
        askBeforePasteHTML: false,
        askBeforePasteFromWord: false,
        placeholder: 'Please enter blog detail description',
        beautyHTML: true,
        height: 350,
        width: 1350,
    }

    useEffect(() => {
        getBlogCategories();
        getBlogsData();
        getAuthorsData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (

        <div className={`dashboard_Con   `}>
            <SideHeaderAdmin />

            <div className={`dashboard_Con__Right`}>
                <DashboardHeaderAdmin  />
                <div className="publisherWebsiteMid__Con">
                    {isLoading && <AppLoader size="lg" ></AppLoader>}
                    {!isCustomerWebsiteView &&
                        <div className="pubLisherAddWebsite__Con">
                            <h4>Blog Management
                                <button className="paymentDetailsBtn marginLeft3" onClick={() => addBlog()} >
                                    Add Blog
                                </button>
                                <div className="searchDiv floatRight">
                                    <input
                                        className="searchMarketPlace"
                                        type="text"
                                        value={searchInput}
                                        placeholder='Search Blog title'
                                        onChange={(e: any) => setSearchCustomerData(e)}
                                    />
                                    {searchInput !== '' && <img className="search-img" src={search} alt="" onClick={() => { searchCustomerData(); }} />}
                                    {searchInput !== '' && <span className="material-icons lightGrey close-img" onClick={() => { clearSearchCustomerData(); }}>close</span>}
                                </div>
                            </h4>
                            <div className="PublisherSummaryTable__Con noPadding">
                                <section className="PublisherSummaryTable__Con__Table__Header noMargin">
                                    <article>
                                        <p>Date</p>
                                        <p>Title</p>
                                        <p>Permalink</p>
                                        <p>Published</p>
                                        <h6>Action</h6>
                                    </article>
                                    <InfiniteScroll
                                        dataLength={blogsList.length}
                                        next={fetchMoreData}
                                        hasMore={hasMoreData}
                                        loader={''}
                                        height={'calc(62vh - 10px)'}
                                    >
                                        {blogsList.length !== 0 &&
                                            blogsList?.map((cell: any, index: number) => (
                                                <div
                                                    className={`PublisherSummaryTable__Con__Table__Cell ${index % 2 === 0
                                                        ? "PublisherSummaryTable__Con__Table__Cell__grey__bg"
                                                        : ""
                                                        } ${index === tableCells.length - 1
                                                            ? "PublisherSummaryTable__Con__Table__Cell__last__border"
                                                            : ""
                                                        } `}
                                                    key={index}
                                                >

                                                    <p className="WebsiteUrlPublisherSummaryTable">{getDateFormatted(cell?.updatedDate)}</p>

                                                    <p>{(cell?.title)}</p>
                                                    <p>{(cell?.permaLink)}</p>
                                                    <p>{getYesNoFromBool(cell?.isEnabled)}</p>
                                                    <h6>
                                                        {/* {!cell.isAddedInCart && <span onClick={() => fetchCustomerWebsite(cell)}> */}
                                                        <span onClick={() => fetchBlog(cell)} >Edit</span>
                                                    </h6>

                                                </div>

                                            ))}
                                    </InfiniteScroll>
                                </section>

                            </div>
                        </div>}
                    {isCustomerWebsiteView &&
                        
                            <div className="pubLisherAddWebsite__Con height100">
                                <h4> {isEditBlog && <>Edit Blog</>} {!isEditBlog && <>Add Blog</>}
                                    <button className="paymentDetailsBtn floatRight backButtonblue" onClick={() => navigateBack()} >
                                        Back
                                    </button>
                                </h4>
                                <div className="PublisherSummaryTable__Con noPadding fontSize20 height100">
                                    <div className="PublisherSummaryTable__Con__Table__Header noMargin addBlogPadding height100">
                                        <div className="width100 displayFlex rowDiv">
                                            <div className="width60 floatLeft"> <div className="width150px floatLeft" > Permalink<span className="red">*</span></div>
                                                <div className="floatLeft width70"> <input
                                                    type="text"
                                                    placeholder="Please enter permalink"
                                                    value={blogTagLine}
                                                    className="addBlogInput"
                                                    onChange={(e: any) => setBlogTagLine(e.target.value)}
                                                    disabled={isEditBlog && isBlogEnable}
                                                />
                                                    {blogError.tagLineError && <p className="blog_error">
                                                        {" "}
                                                        <img src={info} alt="" />{" "}
                                                        <span>{blogError.tagLineError}</span>{" "}
                                                    </p>
                                                    }</div>
                                            </div>
                                            <div className="width25 floatLeft"> <div className="width25 floatLeft">Scheduling</div>
                                                <div className="width60 floatRight">
                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                        <MobileDateTimePicker defaultValue={dayjs(scheduledDate)}
                                                            onChange={(newValue) => {
                                                                setScheduledDate(newValue + "");
                                                            }} 
                                                            disabled={isEditBlog && isBlogEnable} />

                                                    </LocalizationProvider>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="width100 displayFlex rowDiv">
                                            <div className="width150px"> Sample URL</div>
                                            <div className="width80">
                                                {getSampleURL()}
                                            </div>
                                        </div>
                                        <div className="width100 displayFlex rowDiv">
                                            <div className="width150px"> Title<span className="red">*</span></div> <div className="width80"> <input
                                                type="text"
                                                placeholder="Please enter blog title"
                                                value={blogTitle}
                                                className="addBlogInput"
                                                onChange={(e: any) => setBlogTitle(e.target.value)}
                                                disabled={isEditBlog && isBlogEnable}
                                            />
                                                {blogError.titleError && <p className="blog_error">
                                                    {" "}
                                                    <img src={info} alt="" />{" "}
                                                    <span>{blogError.titleError}</span>{" "}
                                                </p>}
                                            </div>
                                        </div>

                                        <div className="width100 displayFlex rowDiv">
                                            <div className="width60">
                                            <div className="width150px floatLeft">  Upload Image
                                                {!isEditBlog &&
                                                    <span className="red">*</span>
                                                } </div> <div className="width80 floatLeft">
                                                {/* <input type="text"
                                    placeholder="Select Image"
                                    value={blogObj.imageUrl}
                                    className="addBlogInput"
                                    onChange={(e: any) =>
                                        setBlogObj({
                                        ...blogObj,
                                        imageUrl: e.target.value,
                                        })
                                    }
                                /> */}
                                                <input type="file" id="myfile" name="myfile" placeholder="Drop your file here" value={""}
                                                    onChange={(e) => { handleFileChange(e) }} disabled={isEditBlog && isBlogEnable} />
                                                {selectedFile &&
                                                    <span>{selectedFile?.name} </span>}
                                                {blogError.imageUrlError && <p className="blog_error">
                                                    {" "}
                                                    <img src={info} alt="" />{" "}
                                                    <span>{blogError.imageUrlError}</span>{" "}
                                                </p>}
                                            </div>
</div>
                                            <div>
                                            <div className="width100px floatLeft">  Author
                                                 </div>
                                            <div className="width150px floatLeft">

                                            <Select
        labelId="demo-select-small-label"
        id="demo-select-small"
        value={selectedAuthor}
        label="Age"
        onChange={(e) => setSelectedAuthorDropDown(e)}
        disabled={isEditBlog && isBlogEnable}
      >
       
       {authorsList?.map((author:any ) => (
        <MenuItem key={author.id} value={author.id}>{author.name}</MenuItem>
        ))}
      </Select>

                
                
                
              </div>
                                            </div>
                                        </div>
                                        <div className="width100 displayFlex rowDiv">
                                            <div className="width150px"> Category<span className="red">*</span></div>
                                            <div className="width40 AddWebsiteFormDetails__Form__Name__Categories blog_categories">
                                                <article
                                                    style={{
                                                        paddingTop: `${selectedCategories?.length > 3 ? "10px" : "0px"
                                                            }`,
                                                    }}
                                                >
                                                    {selectedCategories?.map(
                                                        (selectedWebsiteCategory: any, index: number) => (
                                                            <h5 key={index}>
                                                                {selectedWebsiteCategory.title}{" "}
                                                                <img
                                                                    src={close}
                                                                    className="AddWebsiteFormDetails__Form__Name__Categories__Close"
                                                                    alt=""
                                                                    onClick={() => { setFilteredSelectedCategories(selectedWebsiteCategory); }}
                                                                // onClick={() => {

                                                                //   setSelectedCategories([
                                                                //      selectedCategories?.filter(
                                                                //       (currentCategory: any) =>

                                                                //         selectedWebsiteCategory.id !== currentCategory.id

                                                                //     ),
                                                                //     ]);

                                                                //   // setBlogCategoriesList([blogCategoriesList,websiteCategory.id]);
                                                                //   // setBlogCategoriesList({
                                                                //   //     blogCategories?.filter(
                                                                //   //       (websiteCategoryId: any, index2: number) =>
                                                                //   //         index2 !== index
                                                                //   //     ),
                                                                //   // });
                                                                // }}
                                                                />
                                                            </h5>
                                                        )
                                                    )}

                                                    <input
                                                        type="text"
                                                        name=""
                                                        id=""
                                                        placeholder="Enter categories"
                                                        value={searchCategory}
                                                        disabled={isEditBlog && isBlogEnable}
                                                        onChange={(e: any) => {
                                                            setSearchCategory(e.target.value);
                                                            blogCategoryFilter(
                                                                setFilteredCategories,
                                                                blogCategories,
                                                                e.target.value
                                                            );
                                                        }}
                                                        onFocus={() => setIsdropDownDisplay(true)}
                                                        onBlur={() => {
                                                            if (!isMouseOnDropDown) {
                                                                setIsdropDownDisplay(false);
                                                            }
                                                        }}
                                                        style={{
                                                            // border: `${
                                                            //   selectedWebsiteCategories?.name?.length > 3
                                                            //     ? "1px solid #aaaaaa"
                                                            //     : "none"
                                                            // }`,

                                                            margin: `${selectedCategories?.length > 3
                                                                    ? "9px 0px"
                                                                    : "0px 0px"
                                                                }`,
                                                            padding: `${selectedCategories?.length > 3
                                                                    ? "7px 13px"
                                                                    : "15px 13px"
                                                                }`,
                                                        }}
                                                    />
                                                </article>
                                                <div
                                                    className="AddWebsiteFormDetails__Form__Name__Categories__DropDownContent"
                                                    style={{
                                                        display: `${isDropdownDisplay && filteredCategories.length > 0 ? "block" : "none"}`,
                                                        top: `${selectedCategories?.length > 3 ? "117px" : "46px"
                                                            }`,
                                                        left: `${selectedCategories?.length > 4 ? "200px" : "10px"
                                                            }`,
                                                        height: `${filteredCategories.length < 10
                                                                ? `${filteredCategories.length * 41}px`
                                                                : "410px"
                                                            }`,
                                                    }}
                                                >
                                                    {filteredCategories?.length > 0 &&
                                                        filteredCategories?.map((websiteCategory: any) => (
                                                            <div style={{ lineHeight: '25px' }}
                                                                key={websiteCategory.id}
                                                                onMouseEnter={() => setIsMouseOnDropDown(true)}
                                                                onMouseLeave={() => setIsMouseOnDropDown(false)}
                                                                onClick={() => {
                                                                    if (selectedCategories?.length < 3) {
                                                                        setBlogCategoryError({
                                                                            isError: false,
                                                                            message: "",
                                                                        });
                                                                        if (
                                                                            selectedCategories?.includes(
                                                                                websiteCategory
                                                                            )
                                                                        ) {
                                                                            setBlogCategoryError({
                                                                                isError: true,
                                                                                message: "Category already selected",
                                                                            });
                                                                        } else {
                                                                            setBlogCategoryError({
                                                                                isError: false,
                                                                                message: "",
                                                                            });
                                                                            setSelectedCategories([...selectedCategories, websiteCategory]);
                                                                            setSearchCategory('');
                                                                            blogCategoryFilter(
                                                                                setFilteredCategories,
                                                                                blogCategories,
                                                                                ''
                                                                            );
                                                                            setBlogCategoriesList([blogCategoriesList, websiteCategory.id]);

                                                                        }
                                                                    } else {
                                                                        setBlogCategoryError({
                                                                            isError: true,
                                                                            message: "Only 3 categories can be selected",
                                                                        });
                                                                    }
                                                                    setIsdropDownDisplay(false);

                                                                }}
                                                            >
                                                                {websiteCategory?.title}{" "}
                                                            </div>
                                                        ))}
                                                </div>

                                                {blogCategoryError.isError && (
                                                    <p>
                                                        {" "}
                                                        <img src={info} alt="" />{" "}
                                                        <span>{blogCategoryError.message}</span>{" "}
                                                    </p>
                                                )}

                                                {blogError.titleError && <p className="blog_error">
                                                    {" "}
                                                    <img src={info} alt="" />{" "}
                                                    <span>{blogError.titleError}</span>{" "}
                                                </p>}
                                            </div>
                                            <div className="width40 add-new-Category"> <a href="#/" onClick={() => toggleAddCategory()}> Add New Category </a></div>
                                        </div>
                                        <div className="width100 displayFlex rowDiv">
                                            Description  <span className="red">*</span>:</div>
                                        <div className="height100">
                                            < JoditEditor
                                                ref={editor}
                                                value={blogObj.description}
                                                config={joditConfig}
                                                // tabIndex of textarea
                                                onBlur={newContent => setDescription(newContent)} // preferred to use only this option to update the content for performance reasons
                                                onChange={newContent => { }}
                                                
                                            />
                                            {blogError.descriptionError && <p className="blog_error">
                                                {" "}
                                                <img src={info} alt="" />{" "}
                                                <span>{blogError.descriptionError}</span>{" "}
                                            </p>}
                                        </div>
                                        <div className="width100 rowDiv center">
                                            {!isBlogEnable && <><button className="paymentDetailsBtn marginLeft3 backButtonblue" onClick={() => saveBlog(false)} >
                                                Save
                                            </button>
                                                <button className="paymentDetailsBtn marginLeft3 backButtonblue" onClick={() => saveBlog(true)} >
                                                    Save and Publish
                                                </button> </>}
                                            {isBlogEnable && <button className="paymentDetailsBtn marginLeft3 backButtonblue" onClick={() => saveBlog(false)} >
                                                Un-publish
                                            </button>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        
                    }
                </div>
                {isAddCategoryOpen && <Popup
                    content={<>
                        <div><div className="popup-header">Add Category</div>
                            <div className="popup-content popup-content-add-category">
                                <input
                                    type="text"
                                    placeholder="Please enter category name."
                                    value={addCategoryName}
                                    className="addBlogInput"
                                    onChange={(e: any) => setAddCategoryNameCustom(e.target.value)}
                                />
                                <p className="floatRight">{addCategoryName.length}/20</p>
                            </div>
                            <div className="btnDiv text-align-center">
                                <button className="wishList_btn addToCart_btn backButtonblue" onClick={() => { saveNewCategory(); }} > Add  </button>
                                <button className="wishList_btn addToCart_btn orange" onClick={() => { toggleAddCategory(); }} > Cancel  </button>
                            </div>
                        </div>
                    </>}
                    handleClose={toggleAddCategory}
                />}
                <ToastContainer />
            </div>
        </div>
    );
};
export default withAuth(BlogManagement);
