import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../store/reduxHooks";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { checkVErifiedWebsite } from "../../utils/api/addWebsite";
import {
 
  setFormDisplay,
} from "../../reducers/addWebsite/addWebsitedataReducer";
type Props = {
  setIsAddWebsite: React.Dispatch<React.SetStateAction<boolean>>;
};

const AddwebsiteFormDns: React.FC<Props> = ({ setIsAddWebsite }) => {
  const dispatch = useAppDispatch();
  const [isCopied, setIscopied] = useState<boolean>(false);
  const { addWebsiteState } = useAppSelector((state) => ({
    addWebsiteState: state?.addWebsiteState?.addedWebsiteData,
    formDisplay: state?.addWebsiteState?.formDisplay,
  }));

  const copyText = (e: any) => {
    e.preventDefault();
    navigator.clipboard.writeText(
      `outreachkart-site-verification="${addWebsiteState?.checkEligibility?.verificationTokens?.dnsRecordToken}"`
    );
    setIscopied(true);
  };

  const onClickAddWebsite = async (e: any) => {
    e.preventDefault();
    const response = await checkVErifiedWebsite(
      addWebsiteState?.checkEligibility?.websiteEligibility?.websiteUrl,
      "dnsMethod"
    );
    if (
      response?.status?.code === 200 &&
      response?.data?.websiteVerification?.verification?.isVerified
    ) {
      toast.success("Website added successfully", {
        position: "top-right",
        theme: "colored",
        autoClose: 5000,
      });

      dispatch(
        setFormDisplay({
          begin: false,
          meta: false,
          html: false,
          dns: false,
          details: true,
          isAdd:true,
        })
      );
    } else {
      toast.error("Website not verified", {
        position: "top-right",
        theme: "colored",
        autoClose: 5000,
      });
    }
  };

  return (
    <div className="AddwebsiteFormDns__con">
      <div className="AddwebsiteFormDns__Switch__Btn">
        <button
          onClick={(e) => {
            e.preventDefault()
            dispatch(
              setFormDisplay({
                begin: false,
                meta: true,
                html: false,
                dns: false,
                details: false,
                isAdd:true,
              })
            );
          }}
        >
          Meta Tag
        </button>
        <button
          onClick={(e) => {
            e.preventDefault()
            dispatch(
              setFormDisplay({
                begin: false,
                meta: false,
                html: true,
                dns: false,
                details: false,
                isAdd:true,
              })
            );
          }}
        >
          HTML File
        </button>
        <button onClick={(e: any) => e.preventDefault()}>DNS Record</button>
      </div>
      <p>1. Login into your hosting provider like godaddy.com</p>
      <div className="AddwebsiteFormDns__input__Con">
        <label htmlFor=""> 2. Copy the given below TXT record </label>
        <div>
          <input
            type="text"
            placeholder={`outreachkart-site-verification=${addWebsiteState?.checkEligibility?.verificationTokens?.dnsRecordToken}`}
          />
          <button onClick={(e) => copyText(e)}> Copy Meta </button>
          {isCopied && <p> Text Copied!</p>}
        </div>
      </div>

      <p>3. Hit Verify button for website verification.</p>
      <button
        className="AddwebsiteFormDns__verify__btn"
        onClick={(e: any) => onClickAddWebsite(e)}
      >
        Verify
      </button>
      <ToastContainer />
    </div>
  );
};

export default AddwebsiteFormDns;
