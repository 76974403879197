import SideHeader from "../../components/SideHeader/SideHeader";

import DashboardHeader from "../../components/DashboardHeader/DashboardHeader";
import OrderDashboardMid from "../../components/orderDashboardMid/OrderDashboardMid";
import withAuth from "../../hoc/withAuth";


const OrderDashboard = () => {
  const roleType = sessionStorage.getItem("roleType");

  return (
    <div className="dashboard_Con">
      <SideHeader roleType={roleType}/>
      <div className="dashboard_Con__Right">
        <DashboardHeader   />
        <OrderDashboardMid role={roleType}/>
      </div>
    </div>
  );
};

export default  withAuth(OrderDashboard);
