import axios from "axios";
import { ORDERS_LIST} from "../constants/url";

export const fetchOrdersListPost = async (
  pageRowLimit: number,
  pageOffset: number,
  orderTypeFilter: any,
  statusFilter: any,
  searchInp: String,
  updatedDateGTE: String,
  updatedDateLT: String,
) => {
  try {
    const page = {pageRowLimit:pageRowLimit,pageOffset:pageOffset};
    const filters = [];
    let searchParams ;
    if(orderTypeFilter !== undefined && orderTypeFilter !== ""){
      filters.push({name:"priceType",value:orderTypeFilter});
    }
    if(statusFilter !== undefined && statusFilter !== ""){
        filters.push({name:"status",value:statusFilter});
      }
    if(searchInp){
      searchParams = [{name:"emailId",value:searchInp}]
    }
    if(updatedDateGTE !== undefined && updatedDateGTE !== ""){
      filters.push({name:"createdDate",value: updatedDateGTE.replace('Z', ''),operator:"gte"});
      filters.push({name:"createdDate",value: updatedDateLT.replace('Z', '') ,operator:"lt"});
    }

    const customerDetailsRequestObj = {page:page,filters:filters,searchParams:searchParams};
    const accessToken = sessionStorage.getItem('access-token');
    const response = await axios.post(
      process.env.REACT_APP_BASE + ORDERS_LIST,
      customerDetailsRequestObj , { headers: { 'Authorization': 'Bearer '+accessToken}}
    );

    return response.data;
  } catch (err) {
    console.log(err);
    return err;
  }
};