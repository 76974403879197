import {
  RegisterPayload,
  LoginPayload,
  ForgotPasswordPayload,
  ResetPasswordPayload,
} from "../types/main";

export const isEmail = (email: string) =>
  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);

export const isPassword = (password: string) =>
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+-=[\]||{}\\;':"<>,./?])[A-Za-z\d!@#$%^&*()_+-=|[\]|{}\\;':"<>,./?]{8,}$/i.test(
    password
  );

export const isValidUrl = (url: string) =>
  /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/g.test(
    url
  );
export const isValidUrlJs = (url: string) => {
  let input;
  try {
    input = new URL(url);
  } catch (_) {
    return false;
  }

  return input.protocol === "http:" || input.protocol === "https:";
};

export const registerValidator = (registerData: RegisterPayload) => {
  const error: any = {};
  let isError: boolean = false;
  if (registerData.firstName === "") {
    error.firstName = "First name is required";
    isError = true;
  }
  if (registerData.firstName.length > 50) {
    error.firstName = "Name Cannot be length of 50 characters";
    isError = true;
  }

  if (!isEmail(registerData.emailId)) {
    error.emailId =
      " Email must be in a valid email format (e.g., user@domai.com). Please try again.";
    isError = true;
  }
  if (!isPassword(registerData.password)) {
    error.password = "";
    isError = true;
  }
  if (registerData.password.length > 15) {
    error.password = "";
    isError = true;
  }
  if (registerData.password !== registerData.confirmPassword) {
    error.confirmPassword = "Password and confirm password must be same";
    isError = true;
  }
  if (registerData.emailId === "") {
    error.emailId = "Email address is required";
    isError = true;
  }
  if (registerData.firstName === "") {
    error.firstName = "First name is required";
    isError = true;
  }
  if (registerData.password === "") {
    error.password = "Password is required";
    isError = true;
  }
  if (registerData.confirmPassword === "") {
    error.confirmPassword = "Confirm password is required";
    isError = true;
  }
  return { error, isError };
};

export const loginValidator = (loginData: LoginPayload) => {
  const error: any = {};
  let isError: boolean = false;

  if (!isEmail(loginData.emailId)) {
    error.emailId =
      " Email must be in a valid email format (e.g,user@domain.com). Please try again";
    isError = true;
  }
  if (loginData.emailId === "") {
    error.emailId = "Email address is required";
    isError = true;
  }
  if (loginData.password === "") {
    error.password = "Password is required";
    isError = true;
  }
  return { error, isError };
};

export const urlValidator = (url: string) => {
  const error: any = {};
  let isError: boolean = false;

  if (!isValidUrlJs(url)) {
    error.url = "Enter a valid URL";
    isError = true;
  }
  return { error, isError };
};

export const forgotPasswordValidator = (
  forgotPasswordData: ForgotPasswordPayload
) => {
  const error: any = {};
  let isError: boolean = false;

  if (!isEmail(forgotPasswordData.emailId)) {
    error.emailId =
      " Email must be in a valid email format (e.g,user@domain.com). Please try again";
    isError = true;
  }
  if (forgotPasswordData.emailId === "") {
    error.emailId = "Email address is required";
    isError = true;
  }

  return { error, isError };
};
export const resetPasswordValidator = (
  resetPasswordData: ResetPasswordPayload
) => {
  const error: any = {};
  let isError: boolean = false;

  if (!isPassword(resetPasswordData.newPassword)) {
    error.password = "";
    isError = true;
  }
  if (resetPasswordData.newPassword.length > 15) {
    error.password = "";
    isError = true;
  }
  if (resetPasswordData.newPassword !== resetPasswordData.confirmNewPassword) {
    error.confirmPassword = "Password and confirm password must be same";
    isError = true;
  }
  if (resetPasswordData.newPassword === "") {
    error.password = "Password is required";
    isError = true;
  }
  if (resetPasswordData.confirmNewPassword === "") {
    error.confirmPassword = "Confirm password is required";
    isError = true;
  }

  return { error, isError };
};
