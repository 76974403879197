import axios from "axios";
import {MARKET_PLACE_LIST,CART_COUNT,ADD_TO_CART_WISH,CART_WISH_LIST,PLACE_ORDER_URL,
  ORDER_SUCCESSS_URL,MY_ORDER_URL,PUB_ORDER_ACTION,ADV_ORDER_ACTION,FETCH_REMARK_URL,
  DOWNLOAD_FILE_URL,PLACE_ORDER_BY_ADMIN_URL,ORDER_COUNT} from "../constants/url";

  export const getMarketPlaceList = async (
    pageOffset: number ,
    pageRowLimit: number ,
    searchParam : string,
    sortParm : {},
    filter : any,
  ) => {
    try {
      const categoryFilter = filter !== undefined ? filter.categoryFilter : undefined;
      const trafficFilter = filter !== undefined ? filter.trafficFilter : undefined;
      const spamScoreFilter = filter !== undefined ? filter.spamScoreFilter : undefined;
      const guestPostPriceFilter = filter !== undefined ? filter.guestPostPriceFilter : undefined;
      const linkInsertionPriceFilter = filter !== undefined ? filter.linkInsertionPriceFilter : undefined;
      const casinoPostPriceFilter = filter !== undefined ? filter.casinoPostPriceFilter : undefined;
      const adultPostPriceFilter = filter !== undefined ? filter.adultPostPriceFilter : undefined;
      const cryptoPostPriceFilter = filter !== undefined ? filter.cryptoPostPriceFilter : undefined;
      const cbdPostPriceFilter = filter !== undefined ? filter.cbdPostPriceFilter : undefined;
      const domainAuthFilter = filter !== undefined ? filter.domainAuthFilter : undefined;
      const referingDomainFilter = filter !== undefined ? filter.referingDomainFilter : undefined;
      const accessToken = sessionStorage.getItem('access-token');
      const response = await axios.post(
        process.env.REACT_APP_BASE + MARKET_PLACE_LIST,
        {
          pageRowLimit,
          pageOffset,
          searchParam,
          sortParm,
          categoryFilter,
          trafficFilter,
          guestPostPriceFilter,
          linkInsertionPriceFilter,
          casinoPostPriceFilter,
          cbdPostPriceFilter,
          cryptoPostPriceFilter,
          adultPostPriceFilter,
          domainAuthFilter,
          spamScoreFilter,
          referingDomainFilter
        },
        { headers: { 'Authorization': 'Bearer '+accessToken}}
      );
      return response?.data;
    } catch (err: any) {
      console.log(err);
      return err?.response?.data;
    }
  };

  export const addToCartWishList = async (
    websiteId: string ,
    bagType: string ,
    priceType: string ,
  ) => {
    try {
      const accessToken = sessionStorage.getItem('access-token');
      const response = await axios.post(
        process.env.REACT_APP_BASE + ADD_TO_CART_WISH,
        {
          websiteId,
          bagType,
          priceType,
        },
        { headers: { 'Authorization': 'Bearer '+accessToken}}
      );
      return response?.data;
    } catch (err: any) {
      console.log(err);
      return err?.response?.data;
    }
  };


  export const bagCount = async (type: string) => {
    try {
      const accessToken = sessionStorage.getItem('access-token');
      const response = await axios.get(
        process.env.REACT_APP_BASE + CART_COUNT + type,
        {
          headers: { 'Authorization': 'Bearer '+accessToken}
        }
      );
      return response.data;
    } catch (err:any) {
      console.log(err);
      return err?.response?.data;
    }
  };

  export const getWishCartList = async (type: string) => {
    try {
      const accessToken = sessionStorage.getItem('access-token');
      const response = await axios.get(
        process.env.REACT_APP_BASE + CART_WISH_LIST + type,
        {
          headers: { 'Authorization': 'Bearer '+accessToken}
        }
      );
      return response.data;
    } catch (err:any) {
      console.log(err);
      return err?.response?.data;
    }
  };

  // export const placeOrder = async (
  //   websiteId: string ,
  //   priceType: string ,
  //   existingPostUrl: string ,
  //   anchorText: string ,
  //   targetUrl: string ,
  //   instructions: string ,
  //   price: number ,
  //   file: any,
  // ) => {
  //   try {
  //     const accessToken = sessionStorage.getItem('access-token');
  //     const response = await axios.post(
  //       process.env.REACT_APP_BASE + PLACE_ORDER_URL,
  //       {
  //         websiteId,
  //         priceType,
  //         existingPostUrl,
  //         anchorText,
  //         targetUrl,
  //         instructions,
  //         price
  //       },
  //       { headers: { 'Authorization': 'Bearer '+accessToken,}}
  //     );
  //     return response?.data;
  //   } catch (err: any) {
  //     console.log(err);
  //     return err?.response?.data;
  //   }
  // };

  export const placeOrder = async (
    websiteId: string ,
    priceType: string ,
    existingPostUrl: string ,
    anchorText: string ,
    targetUrl: string ,
    instructions: string ,
    price: number ,
    file : any,
  ) => {
    try {
      var formData = new FormData();
      // if(file !== undefined){

        const isCustAdminLogin = JSON.parse(sessionStorage.getItem('isCustAdminLogin')+"");
        const url = isCustAdminLogin === true ? PLACE_ORDER_BY_ADMIN_URL : PLACE_ORDER_URL
        const customerProfile = JSON.parse(sessionStorage.getItem('customerProfile')+"");
        const emailId = isCustAdminLogin === true ? customerProfile?.emailId : undefined
        const accessToken = sessionStorage.getItem('access-token');
        const adminAccessToken = isCustAdminLogin === true ? sessionStorage.getItem('admin-customer-access-token') : accessToken;

        
        formData.append("file", file);
        formData.append("payload", JSON.stringify({
          websiteId,
          priceType,
          existingPostUrl,
          anchorText,
          targetUrl,
          instructions,
          price,
          emailId
        }));
    //  }

      const response = await axios.post(
        process.env.REACT_APP_BASE + url,
        formData,
        { headers: { 'Authorization': 'Bearer '+adminAccessToken,
        'Accept': 'application/json',
        'Content-Type': 'multipart/form-data',}}
      );
      return response?.data;
    } catch (err: any) {
      console.log(err);
      return err?.response?.data;
    }
  };

  export const removeFromWishList = async (id: string, bagType: string ,) => {
    try {
      const accessToken = sessionStorage.getItem('access-token');
      const response = await axios.delete(
        `${process.env.REACT_APP_BASE + ADD_TO_CART_WISH +'/'+ bagType}/${id}`,{ headers: { 'Authorization': 'Bearer '+accessToken}}
      );
      return response.data;
    } catch (err:any) {
      console.log(err?.response?.data);
      return err?.response?.data;
    }
  };

  export const paymentSuccessApi = async (param: string) => {
    try {
      const accessToken = sessionStorage.getItem('access-token');
      const response = await axios.get(
        process.env.REACT_APP_BASE + ORDER_SUCCESSS_URL +param,
        {
         
          headers: { 'Authorization': 'Bearer '+accessToken}
        }
      );
      return response.data;
    } catch (err:any) {
      console.log(err);
      return err?.response?.data;
    }
  };

  export const getOrderListApi = async (
    role:string,
    pageRowLimit: any,
    pageOffset: any,
    status: string) => {
    try {
      let param;
      if(status === 'ALL'){
        param = { pageRowLimit: pageRowLimit, pageOffset: pageOffset}
      }else{
        param = { pageRowLimit: pageRowLimit, pageOffset: pageOffset, status : status}
      }
      const accessToken = sessionStorage.getItem('access-token');
      const response = await axios.get(
        process.env.REACT_APP_BASE + MY_ORDER_URL + role,
        {
          params: param,
          headers: { 'Authorization': 'Bearer '+accessToken}
        }
      );
      return response.data;
    } catch (err:any) {
      console.log(err);
      return err?.response?.data;
    }
  };

  export const getOrderCountApi = async (
    role:string) => {
    try {
      const accessToken = sessionStorage.getItem('access-token');
      const response = await axios.get(
        process.env.REACT_APP_BASE + ORDER_COUNT +"?userRole="+ role.toUpperCase(),
        {
          headers: { 'Authorization': 'Bearer '+accessToken}
        }
      );
      return response.data;
    } catch (err:any) {
      console.log(err);
      return err?.response?.data;
    }
  };

  export const orderActionApi = async (
    id: string ,
    actionType: string ,
    role : string,
    remarks : string,
    resubmit : any,
    file : any,
  ) => {
    try {
      const reqObj = resubmit === undefined ? { id , remarks} : {...resubmit , id , remarks};
      var formData = new FormData();
      formData.append("file", file);
      formData.append("payload", JSON.stringify(reqObj));
      const url = role === 'Advertiser' ? ADV_ORDER_ACTION : PUB_ORDER_ACTION ;
      const accessToken = sessionStorage.getItem('access-token');
      const response = await axios.post(
        process.env.REACT_APP_BASE + url ,
        formData,
        { params: { actionType : actionType},
          headers: { 'Authorization': 'Bearer '+accessToken}}
      );
      return response?.data;
    } catch (err: any) {
      console.log(err);
      return err?.response?.data;
    }
  };

  export const fetchRemarksApi = async (
    actionType:string,
    id: string) => {
    try {
      const accessToken = sessionStorage.getItem('access-token');
      const response = await axios.get(
        process.env.REACT_APP_BASE + FETCH_REMARK_URL,
        {
          params: {actionType,id},
          headers: { 'Authorization': 'Bearer '+accessToken}
        }
      );
      return response.data;
    } catch (err:any) {
      console.log(err);
      return err?.response?.data;
    }
  };

  export const downloadAttachmentApi = async (selectedOrderObj: any) => {
    try {
        var req = new XMLHttpRequest();
        req.open("GET", process.env.REACT_APP_BASE + DOWNLOAD_FILE_URL + selectedOrderObj.contentId, true);
        req.responseType = "blob";
        const accessToken = sessionStorage.getItem('access-token');
        req.setRequestHeader('Authorization', 'Bearer '+accessToken);
        req.onload = function (event) {
            var blob = req.response;
            var link=document.createElement('a');
            link.href=window.URL.createObjectURL(blob);
            link.download=selectedOrderObj.fileName;
            link.click();
        };
        req.send();
    } catch (err:any) {
      console.log(err);
      return err?.response?.data;
    }
  };