import React, { useEffect, useState } from "react";
import "./AllWebsites.scss";
import SideHeaderAdmin from "../../components/SideHeader/SideHeaderAdmin";
import DashboardHeaderAdmin from "../../components/DashboardHeader/DashboardHeaderAdmin";
import AppLoader from "../../components/defaultLoader/DefaultLoader";
//import { ToastContainer, toast } from "react-toastify";
import InfiniteScroll from 'react-infinite-scroll-component';
import withAuth from "../../hoc/withAuth";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import search from "../../assets/add_Websites/search.svg";
import { numberDisplay, formatTraffic } from "../../utils/filters/nameFilters";
import { customerWebsiteList, exportAllWebsite } from "../../utils/api/customerDetails";
import { Checkbox, ListItemText, OutlinedInput, SelectChangeEvent } from "@mui/material";
import { adminWebsiteApprovalColumnsArray, websiteStatusArray } from "../../utils/constants/selected";
import { useParams } from 'react-router-dom'
import AdminViewAndApprovePopup from "../../components/publisherSummaryTable/AdminViewAndApprove";
import { getWebsiteCategories } from "../../utils/api/getWebsiteCategories";


interface Hash {
    [key: string]: boolean;
}

const AllWebsites = () => {
    const { status } = useParams();
    const tableCells = [1, 2, 3, 4, 5, 6];
    const [websiteList, setWebsiteList] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [hasMoreData, setHasMoreData] = useState<boolean>(true);
    const [initiatedByFilter, setInitiatedByFilter] = useState<any>('');
    const [searchInput, setSearchInput] = useState<string>("");
    const [websiteCategories, setWebsiteCategories] = useState<any>({});
    //  let [filter, setFilterMarketPlace] = useState<any>();
    const selectionArray = localStorage.getItem('selected-all-website-array') !== null ?
        JSON.parse(localStorage.getItem('selected-all-website-array') || '["websiteUrl","emailId",]') :
        [
            'websiteUrl',
            'emailId',
            'domainAuthority',
            'referingDomain',
            'traffic',
            'spamScore',
            'status',
            'action'
        ];
    const [selectedColumns, setSelectedColumns] = useState<string[]>(selectionArray);
    const [approveAndViewData, setApproveAndViewData] = useState<{
        display: boolean;
        websiteObj: any;
        categories: any;
        isNewWebsiteApproval: boolean;
        updatedKeyhash: any;
        isAllWebsite: boolean
    }>(
        {
        display: false,
        websiteObj: undefined,
        categories: [],
        isNewWebsiteApproval: false,
        updatedKeyhash: useState<Hash | null>(null),
        isAllWebsite: true
    });

    const handleColumnChange = (event: SelectChangeEvent<string[]>) => {
        const value = event.target.value as string[];
        setSelectedColumns(value);
        localStorage.setItem('selected-all-website-array', JSON.stringify(value));
    };

    const fetchAllWebsitelist = async () => {
        // const response = await fetchAdminBillingList(0, 10, intiatedByFiler);
        const response = await customerWebsiteList(10, 0, undefined, searchInput, initiatedByFilter);
        if (response?.status?.code === 200) {
            setWebsiteList(response?.data?.customer?.customerWebsites);
            setPageNumber(1);
            setHasMoreData(response?.data?.customer?.pageInfo?.hasNext);
            setIsLoading(false);
        }
    };

    const fetchAllWebsitelistCustom = async (search: string, filter: string) => {
        // const response = await fetchAdminBillingList(0, 10, intiatedByFiler);
        const response = await customerWebsiteList(10, 0, undefined, search, filter);
        if (response?.status?.code === 200) {
            setWebsiteList(response?.data?.customer?.customerWebsites);
            setPageNumber(1);
            setHasMoreData(response?.data?.customer?.pageInfo?.hasNext);
            setIsLoading(false);
        }
    };

    const fetchMoreData = async () => {
        const response = await customerWebsiteList(10, pageNumber, undefined, searchInput, initiatedByFilter);
        if (response?.status?.code === 200) {
            setHasMoreData(response?.data?.customer?.pageInfo?.hasNext);
            const list = websiteList;
            const mergedResult = [...list, ...response?.data?.customer?.customerWebsites];
            setPageNumber(pageNumber + 1);
            setWebsiteList(mergedResult);
        }
    };

    const handleInitiatedByFilterChange = (event: { target: { value: string } }) => {
        setInitiatedByFilter(event.target.value);
        fetchAllWebsitelistCustom(searchInput, event.target.value);
    };

    const searchWebsiteData = () => {
        setPageNumber(1);
        fetchAllWebsitelist();
    };


    const clearSearchWebsiteData = () => {
        setSearchInput('');
        fetchAllWebsitelistCustom("", initiatedByFilter);
    };

    const setSearchWebsiteData = (e: any) => {
        setSearchInput(e.target.value);
    };

    const getPostValidityType = (postValidityType: string) => {
        if (postValidityType === 'YEARLY') {
            return 'Yearly';
        } else if (postValidityType === 'HALF_YEARLY') {
            return 'Half Yearly';
        } else if (postValidityType === 'LIFETIME') {
            return 'Lifetime';
        }
    };

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    const getCategories = async (setWebsiteCategories: React.Dispatch<any>) => {
        const response = await getWebsiteCategories();
        if (response?.status?.code === 200) {
            const map = new Map();
            response?.data?.websiteCategories?.categories.forEach((obj: { id: any; friendlyName: any; }) => {
                map.set(obj.id, obj.friendlyName);
            });
            setWebsiteCategories(map);
        }
    };




    const downloadAllWebsite = async () => {
        setIsLoading(true);
        const response = await exportAllWebsite();
        if (response) {
            // Create a Blob from the response data
            const blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            // Create a download link
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'websites.xlsx'; // Set the desired file name
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);
            setIsLoading(false);
        } else {
            setIsLoading(false);
        }
    }

    const handleApproveAndView = (websiteObjSelected: any) => {
        setApproveAndViewData({
            display: true,
            websiteObj: websiteObjSelected,
            categories: websiteCategories,
            isNewWebsiteApproval: true,
            updatedKeyhash: {},
            isAllWebsite: true,
        });
        // dispatch(setIsDeleteSuccess(false));
    };

    const handleUpdatedList = (updatedWebsite: any) => {
        setWebsiteList(prevList => {
          const updatedList = prevList.map(website => {
            if (website.id === updatedWebsite.id) {
              return { ...website, ...updatedWebsite }; // Update the object
            } else {
              return website; // Keep other objects unchanged
            }
          });
    
          console.log('Updated list:', updatedList);
          return updatedList; // Return the updated list
        });
      };

    useEffect(() => {
        setApproveAndViewData({
            ...approveAndViewData,
            display: false
        });
        getCategories(setWebsiteCategories);
        if (status && status !== '' && status !== 'All') {
            setPageNumber(1);
            setInitiatedByFilter(status);
            fetchAllWebsitelistCustom(searchInput, status);
        } else {
            fetchAllWebsitelist();
        }


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (

        <div className={`dashboard_Con   `}>
            <SideHeaderAdmin />

            <div className={`dashboard_Con__Right`}>
                <DashboardHeaderAdmin />
                <div className="publisherWebsiteMid__Con">
                    {isLoading && <AppLoader size="lg" ></AppLoader>}
                    {approveAndViewData?.display &&
                        <>
                            <section className="PublisherSummaryTable__Con__Inner">
                                <header className="PublisherSummaryTable__Con__Header">
                                    <AdminViewAndApprovePopup
                                        setApproveAndViewData={setApproveAndViewData}
                                        approveAndViewData={approveAndViewData}
                                        sendUpdatedList = {handleUpdatedList}
                                    />
                                </header>
                            </section>
                        </>
                    }
                    {!approveAndViewData?.display &&
                        <div className="pubLisherAddWebsite__Con">
                            <h4>All Websites</h4>
                            <div>
                                <div className="sortDiv floatLeft">
                                    <FormControl sx={{ m: 1, minWidth: 180 }} size="small">
                                        <InputLabel id="demo-select-small-label">Select Status</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-helper-label"
                                            id="demo-simple-select-helper"
                                            value={initiatedByFilter}
                                            label="Select Initiated By"
                                            onChange={handleInitiatedByFilterChange}>
                                            <MenuItem value="">
                                                <em>Select Status</em>
                                            </MenuItem>
                                            {websiteStatusArray?.map((selectInitiatedByObj: any, index) => (
                                                <MenuItem value={selectInitiatedByObj.id} key={index}>{selectInitiatedByObj.friendlyName}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="width50 floatRight">
                                    <FormControl sx={{ m: 1, minWidth: 250, background: 'white' }} size="small">
                                        <input
                                            className="searchMarketPlace"
                                            type="text"
                                            value={searchInput}
                                            placeholder='Search Website'
                                            onChange={(e: any) => setSearchWebsiteData(e)}
                                        />
                                        {/* <TextField id="outlined-basic" label="Search Advertiser/Publisher" variant="outlined"
                                 value={searchInput} onChange={(e: any) => setSearchCustomerData(e)} className="searchMarketPlace"/> */}

                                        {searchInput !== '' && <img className="search-img right25px" src={search} alt="" onClick={() => { searchWebsiteData(); }} />}
                                        {searchInput !== '' && <span className="material-icons lightGrey close-img right0" onClick={() => { clearSearchWebsiteData(); }}>close</span>}
                                    </FormControl>

                                    <FormControl sx={{ m: 1, width: 300 }}>
                                        <InputLabel id="demo-multiple-checkbox-label">Select Columns</InputLabel>
                                        <Select
                                            labelId="demo-multiple-checkbox-label"
                                            id="demo-multiple-checkbox"
                                            multiple
                                            value={selectedColumns}
                                            onChange={handleColumnChange}
                                            input={<OutlinedInput label="Tag" />}
                                            renderValue={(selected) => selected.join(', ')}
                                            MenuProps={MenuProps}
                                        >
                                            {adminWebsiteApprovalColumnsArray.map((loopObj) => (
                                                <MenuItem key={loopObj.friendlyName} value={loopObj.id}>
                                                    <Checkbox checked={selectedColumns.indexOf(loopObj.id) > -1} />
                                                    <ListItemText primary={loopObj.friendlyName} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    <input
                                        className="customer-export"
                                        type="button"
                                        value='Export'
                                        onClick={() => { downloadAllWebsite(); }}
                                    />
                                </div>
                            </div>


                            <section className="PublisherSummaryTable__Con__Table__Header">
                                <article>
                                    <h5>Email ID </h5>
                                    <h5>Website URL </h5>
                                    {selectedColumns.includes('domainAuthority') && <p>DA</p>}
                                    {selectedColumns.includes('referingDomain') && <p>DR</p>}
                                    {selectedColumns.includes('traffic') && <p>Traffic</p>}
                                    {selectedColumns.includes('spamScore') && <p>Spam Score</p>}
                                    {selectedColumns.includes('guaranteePostValidity') && <p>Post Validity</p>}
                                    {selectedColumns.includes('minPostWordCount') && <p>Min. Word Count</p>}
                                    {selectedColumns.includes('maxBackLinks') && <p>Max. Backlinks</p>}
                                    {selectedColumns.includes('tat') && <p>TAT (Days) </p>}
                                    {selectedColumns.includes('guestPostPrice') && <p>General Price ($)</p>}
                                    {selectedColumns.includes('linkInsertionPrice') && <p>Link Insertion Price ($)</p>}
                                    {selectedColumns.includes('cryptoPostPrice') && <p>Crypto/Finance Price ($)</p>}
                                    {selectedColumns.includes('casinoPostPrice') && <p>Casino Price ($)</p>}
                                    {selectedColumns.includes('cbdPostPrice') && <p>CBD/Vape Price ($)</p>}
                                    {selectedColumns.includes('adultPostPrice') && <p>Adult Price ($)</p>}
                                    <p>Status</p>
                                    <h6>Action</h6>
                                </article>
                                <InfiniteScroll
                                    dataLength={websiteList.length}
                                    next={fetchMoreData}
                                    hasMore={hasMoreData}
                                    loader={''}
                                    height={'calc(58vh - 10px)'}
                                >
                                    {websiteList.length !== 0 &&
                                        websiteList?.map((cell: any, index: number) => (
                                            <div
                                                className={`PublisherSummaryTable__Con__Table__Cell ${index % 2 === 0
                                                    ? "PublisherSummaryTable__Con__Table__Cell__grey__bg"
                                                    : ""
                                                    } ${index === tableCells.length - 1
                                                        ? "PublisherSummaryTable__Con__Table__Cell__last__border"
                                                        : ""
                                                    } `}
                                                key={index}
                                            >
                                                <a
                                                    href={cell?.emailId}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    <p style={{ textAlign: 'left' }} className="WebsiteUrlPublisherSummaryTable">{cell?.emailId}</p>
                                                </a>
                                                <a
                                                    href={cell?.websiteUrl}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    <p style={{ textAlign: 'left' }} className="WebsiteUrlPublisherSummaryTable">{cell?.websiteUrl}</p>
                                                </a>
                                                {selectedColumns.includes('domainAuthority') && <p>{numberDisplay(cell?.domainAuthority)}</p>}
                                                {selectedColumns.includes('referingDomain') && <p>{numberDisplay(cell?.referingDomain)}</p>}
                                                {selectedColumns.includes('traffic') && <p>{formatTraffic(cell?.traffic)}</p>}
                                                {selectedColumns.includes('spamScore') && <p>{(cell?.spamScore)}</p>}
                                                {selectedColumns.includes('guaranteePostValidity') && <p>{getPostValidityType(cell?.guaranteePostValidity)}</p>}
                                                {selectedColumns.includes('minPostWordCount') && <p>{numberDisplay(cell?.minPostWordCount)}</p>}
                                                {selectedColumns.includes('maxBackLinks') && <p>{numberDisplay(cell?.maxBackLinks)}</p>}
                                                {selectedColumns.includes('tat') && <p>{numberDisplay(cell?.tat)}</p>}
                                                {selectedColumns.includes('guestPostPrice') && <p>{numberDisplay(cell?.guestPostPrice)}</p>}
                                                {selectedColumns.includes('linkInsertionPrice') && <p> {numberDisplay(cell?.linkInsertionPrice)}</p>}
                                                {selectedColumns.includes('cryptoPostPrice') && <p>{numberDisplay(cell?.cryptoPostPrice)}</p>}
                                                {selectedColumns.includes('casinoPostPrice') && <p>{numberDisplay(cell?.casinoPostPrice)}</p>}
                                                {selectedColumns.includes('cbdPostPrice') && <p>{numberDisplay(cell?.cbdPostPrice)}</p>}
                                                {selectedColumns.includes('adultPostPrice') && <p>{numberDisplay(cell?.adultPostPrice)}</p>}
                                                <p>{(cell?.status)}</p>
                                                <h6 className="">
                                                    {/* {!cell.isAddedInCart && <span onClick={() => fetchCustomerWebsite(cell)}> */}
                                                    <span onClick={() => handleApproveAndView(cell)} >View</span>
                                                </h6>
                                            </div>
                                        ))}
                                </InfiniteScroll>
                            </section>
                        </div>}
                </div>
            </div>
        </div>

    );
};

export default withAuth(AllWebsites);
