import { ADMIN_WEBSITE_APPROVE } from "./../constants/url";
import axios from "axios";
import {
  ADMIN_WEBSITE_COUNT,
  ADMIN_WEBSITE_DETAILS,
  ADMIN_WEBSITE_REJECT,
  WEBSITE_DETAILS,
} from "../constants/url";

export const getAdminWebsiteDetails = async (
  pageOffset: number = 0,
  pageRowLimit: number = 10,
  filterBy: "NEW_APPROVAL" | "EDIT_APPROVAL" = "NEW_APPROVAL"
) => {
  try {
    const accessToken = sessionStorage.getItem('access-token');
    const response = await axios.post(
      process.env.REACT_APP_BASE + ADMIN_WEBSITE_DETAILS,
      {
        pageRowLimit,
        pageOffset,
        filterBy,
      },
      { headers: { 'Authorization': 'Bearer '+accessToken}}
    );
    return response?.data;
  } catch (err: any) {
    console.log(err);
    return err?.response?.data;
  }
};

export const getAdminWebsiteCount = async () => {
  try {
    const accessToken = sessionStorage.getItem('access-token');
    const response = await axios.get(
      process.env.REACT_APP_BASE + ADMIN_WEBSITE_COUNT,{ headers: { 'Authorization': 'Bearer '+accessToken}}
    );
    return response?.data;
  } catch (err: any) {
    console.log(err);
    return err?.response?.data;
  }
};

export const getWebsiteDetails = async (websiteId:string) => {
  try {
    const accessToken = sessionStorage.getItem('access-token');
    const response = await axios.get(
      process.env.REACT_APP_BASE + WEBSITE_DETAILS +websiteId,{ headers: { 'Authorization': 'Bearer '+accessToken}}
    );
    return response?.data;
  } catch (err: any) {
    console.log(err);
    return err?.response?.data;
  }
};

export const postAdminApproveWebsite = async (websiteDetails: any) => {
  try {
    const accessToken = sessionStorage.getItem('access-token');
    const response = await axios.post(
      `${process.env.REACT_APP_BASE + ADMIN_WEBSITE_APPROVE}`,
       websiteDetails ,
      { headers: { 'Authorization': 'Bearer '+accessToken}}
    );
    return response.data;
  } catch (err: any) {
    console.log(err?.response?.data);
    return err?.response?.data;
  }
};

export const postAdminRejectWebsite = async (
  websiteId: string,
  rejectedReason: string
) => {
  try {
    const accessToken = sessionStorage.getItem('access-token');
    const response = await axios.post(
      `${process.env.REACT_APP_BASE + ADMIN_WEBSITE_REJECT}`,
      {
        websiteId,
        rejectedReason,
      },
      { headers: { 'Authorization': 'Bearer '+accessToken}}
    );
    return response.data;
  } catch (err: any) {
    console.log(err?.response?.data);
    return err?.response?.data;
  }
};
