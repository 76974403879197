import { useEffect } from "react";
import "./ServiceMid.scss";
//import { Link } from "react-router-dom";

const ServiceMid = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="aboutMid_Con">

<h4>Services</h4>
<h3>Content Writing: </h3>
<p>Facing challenges in improving the SEO ranking of your content? If your answer is YES, 
    then consider working with Outreachkart, your go-to destination for the best content writing services.
     As a leading platform in the world of content creation, we consider ourselves to deliver exceptional website content-writing services.</p>

   <p>At Outreachkart, we have a dedicated team of content writers who possess unique expertise in writing and providing engaging and SEO-optimized content,
     particularly in US English. Our writers are committed to ensuring that your content meets the demands as prescribed by you.</p>

     <p>Trust in our expertise and take that important first step toward enhancing your online presence.
         Increase your website's visibility, engage your audience, and boost your organic traffic with Outreachkart.</p>
         <h3>Guest posting: </h3>
<p>Welcome to Outreachkart, your gateway to premium guest posting services.
     We specialize in connecting businesses with high-quality content in different genres, enhancing brand visibility and SEO rankings. 
     Our well-designed network and experienced outreach team ensure strategic content placements customized as per your conditions. 
     Partner with Outreachkart for seamless, efficient guest posting, and enhance your online impact today. Your success in the digital landscape begins with us!</p>
     <h3>Link Building: </h3> 
     <p>Introducing Outreachkart, your ultimate destination for effective link-building solutions. 
        Here, we specialize in making strategic link-building strategies that boost your website's authority and search engine rankings. 
        Our team employs white-hat techniques to secure high-quality backlinks from high-authority sources, enhancing your online presence. 
        Improve your website's standing in the digital landscape – join Outreachkart and pave the path to achieving online success.</p>
        <h3>Content Marketing:</h3>
        <p>Are you in search of unmatchable content marketing services to enhance your website’s digital presence? 
            Look no further than Outreachkart. Also, if you are struggling to connect with your 
            audience and have no idea how to align your content strategy then Outreachkart has the solution.</p>

            <p>Our expert team customizes content marketing strategies to enhance your brand visibility,
                 drive engagement, and achieve measurable results. So, are you ready to transform your content game? </p>
                 <h3>SEO Reseller:</h3>
                 <p>Are you a digital agency struggling to scale your service offerings or keep up with the ever-evolving SEO landscape? Look no further than Outreachkart, your trusted white-label SEO powerhouse. We partner with agencies like yours to supercharge your client visibility and organic growth, all under your brand.	It will help you expand your agency without in-house expertise.
Join us and unlock the door to reselling success today!</p>

<h3>One-Step Solution | Email us for Solution</h3>
<p>Outreachkart provides all the above services in easy steps. We have dedicated staff to provide you with the best of all services. For any queries, please reach out to us through email or the contact form.</p>




    
    </div>
  );
};

export default ServiceMid;
