import React from 'react'
import Footer from '../../components/footer/Footer'
import Header from '../../components/Header'
import VerifyEmail from '../../components/verifyEmail/VerifyEmail'
import './verifyEmail.scss'

const Signup = () => {
  return (
    <div className='signup-bg' >
<Header/>
<VerifyEmail/>
<div className='footer-bg' >
<Footer/>
</div>
</div>
  )
}

export default Signup