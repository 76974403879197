import axios from "axios";
import { ADVERTISER_BILLING_LIST, ADMIN_BILLING_URL } from "./../constants/url";

export const fetchAdvertiserBillingList = async () => {
  try {
    const accessToken = sessionStorage.getItem('access-token');
    const page = {page:{pageRowLimit:10,pageOffset:0}};
    const response = await axios.post(
      `${process.env.REACT_APP_BASE + ADVERTISER_BILLING_LIST}`,page,
      { headers: { 'Authorization': 'Bearer '+accessToken}}
    );
    return response.data;
  } catch (err: any) {
    console.log(err?.response?.data);
    return err?.response?.data;
  }
};

export const fetchAdminBillingList = async (
  pageOffset: number,
  pageRowLimit: number,
  initiatedByFilter : string|undefined,
) => {
  try {
    let filters = undefined;
    if(initiatedByFilter !== undefined && initiatedByFilter !== ""){
      filters = [];
      filters.push({name:"initiatedBy",value:initiatedByFilter});
    }
    const accessToken = sessionStorage.getItem('access-token');
    const page = {pageRowLimit:pageRowLimit,pageOffset:pageOffset};
    const billingRequestObj = {page,filters};
    const response = await axios.post(
      `${process.env.REACT_APP_BASE + ADMIN_BILLING_URL}`,billingRequestObj,
      { headers: { 'Authorization': 'Bearer '+accessToken}}
    );
    return response.data;
  } catch (err: any) {
    console.log(err?.response?.data);
    return err?.response?.data;
  }
};
