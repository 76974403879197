import Footer from "../../components/footer/Footer";
import Header from "../../components/Header";
import ContactMid from "../../components/contactMid/ContactMid";
import "./contactUs.scss";

const ContactUs = () => {
  return (
    <div className="contactUs-bg">
      <Header />
      <ContactMid />
      <div className="footer-bg">
        <Footer />
      </div>
    </div>
  );
};

export default ContactUs;
