import { createSlice, PayloadAction } from '@reduxjs/toolkit'

type InitialState = {
  email: string
}
const initialState: InitialState = {

    email: "",
}

const emailSlice = createSlice({
  name: 'register',
  initialState,
  reducers: {
 
    emailRegister: (state, action: PayloadAction<string>) => {
      state.email = action.payload
      
    }
  }
})

export default emailSlice.reducer
export const { emailRegister } = emailSlice.actions
