import React, { useEffect } from "react";
import { useAppSelector } from "../store/reduxHooks";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch } from "../store/reduxHooks";
import { logged } from "../reducers/login/loginReducer";

export const withAuth = (Component: React.FC) => {
  return (props: any) => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useAppDispatch();
    const { isLoggedIn, customerProfile } = useAppSelector((state) => ({
      isLoggedIn: state.login.isLoggedIn,
      customerProfile: state?.login?.customerProfile,
    }));

    useEffect(() => {
      const tokenExpirationTime = sessionStorage.getItem('tokenExpiryTime') || '-1';
      const isTokenExpired = parseInt(tokenExpirationTime) <= Date.now();
      if (!isLoggedIn || isTokenExpired) {
        dispatch(logged(false));
        navigate("/login");
      } else {
        if (customerProfile?.role === "ADMIN") {
    
          if (location?.pathname?.split("/")[1] !== "admin") {
            navigate("/admin/dashboard");
          }
        } else if (customerProfile?.role === "PUBLISHER") {
          if (location?.pathname?.split("/")[1] === "admin") {
            navigate("/dashboard");
          }
        } else {
          if (location?.pathname?.split("/")[1] === "admin") {
            navigate("/dashboard");
          }
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoggedIn]);

    if (isLoggedIn) {
      return <Component {...props} />;
    } else {
      return null;
    }
  };
};

export default withAuth;
