import axios from "axios";
import { AUTHORS_LIST,ADD_AUTHOR,GET_AUTHOR} from "../constants/url";

export const authorsListApi = async (
  pageOffset: number,
  pageRowLimit: number,
  searchInput : string|undefined
) => {
  let searchParams ;
  if(searchInput){
    searchParams = [{name:"name",value:searchInput}]
  }
  try {
    const page = {pageRowLimit:pageRowLimit,pageOffset:pageOffset};
    const accessToken = sessionStorage.getItem('access-token');
    const response = await axios.post(process.env.REACT_APP_BASE + AUTHORS_LIST,{page,searchParams},{ headers: { 'Authorization': 'Bearer '+accessToken}});
    return response.data;
  } catch (err) {
    return err;
  }
};

export const addAuthorApi = async (
    blogObj: {} ,
    file : any,
  ) => {
    try {
      var formData = new FormData();
      formData.append("file", file);
      formData.append("payload", JSON.stringify(blogObj));
      const url = ADD_AUTHOR ;
      const accessToken = sessionStorage.getItem('access-token');
      const response = await axios.post(
        process.env.REACT_APP_BASE + url ,
        formData,
        { headers: { 'Authorization': 'Bearer '+accessToken}}
      );
      return response?.data;
    } catch (err: any) {
      console.log(err);
      return err?.response?.data;
    }
  };
  
  export const fetchAuthorApi = async (
    authorId: string,
  ) => {
    try {
      const accessToken = sessionStorage.getItem('access-token');
      const response = await axios.get(
        process.env.REACT_APP_BASE + GET_AUTHOR+authorId,
        {
          headers: { 'Authorization': 'Bearer '+accessToken}
        }
      );
      return response.data;
    } catch (err) {
      console.log(err);
      return err;
    }
  };

  export const fetchMiscAuthorApi = async (
    authorId: string,
  ) => {
    try {
      const accessToken = sessionStorage.getItem('access-token');
      const response = await axios.get(
        process.env.REACT_APP_BASE + GET_AUTHOR+authorId,
        {
          headers: { 'Authorization': 'Bearer '+accessToken}
        }
      );
      return response.data;
    } catch (err) {
      console.log(err);
      return err;
    }
  };