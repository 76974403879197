import React from "react";
import "./adminDashboard.scss";

import withAuth from "../../hoc/withAuth";

import "react-toastify/dist/ReactToastify.css";
import SideHeaderAdmin from "../../components/SideHeader/SideHeaderAdmin";
import DashboardMidAdmin from "../../components/DashboardMid/DashboardMidAdmin";
import DashboardHeaderAdmin from "../../components/DashboardHeader/DashboardHeaderAdmin";

const AdminDashboard = () => {
  // const [errorRole, setErrorRole] = useState<{
  //   display: boolean;
  //   message: string;
  // }>({
  //   display: false,
  //   message: "",
  // });
  // const [selectRole, setSelectRole] = useState<{
  //   role: string;
  //   display: boolean;
  // }>({
  //   display: false,
  //   role: "",
  // });




  return (
    <>
      <div className={`dashboard_Con   `}>
        <SideHeaderAdmin />
        
        <div className={`dashboard_Con__Right`}>
          <DashboardHeaderAdmin  />

          <DashboardMidAdmin />
        </div>
      </div>
    </>
  );
};

export default withAuth(AdminDashboard);
