import React, { useEffect } from "react";
import SideHeader from "../../components/SideHeader/SideHeader";

import DashboardHeader from "../../components/DashboardHeader/DashboardHeader";

import withAuth from "../../hoc/withAuth";

import ContactMid from "../../components/contactMid/ContactMid";
import './couponDashboard.scss'
const CouponDashboard = () => {
  const roleType = sessionStorage.getItem("roleType");

  useEffect(() => {

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="dashboard_Con">
      <SideHeader roleType={roleType}/>
      <div className="dashboard_Con__Right">
        <DashboardHeader    />
           <div className="coupon__dashboard__Contact__con" >
           <ContactMid/>
           </div>
           
      </div>
    </div>
  );
};

export default  withAuth(CouponDashboard);
