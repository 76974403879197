export const websitePathSet = (pathname: any) => {
  if (pathname === "/dashboard") {
    return "Dashboard";
  } else if (pathname === "/dashboard/publisherWebsites") {
    return "My Websites";
  } else if (pathname === "/dashboard/couponDashboard") {
    return "Coupon";
  } else if (pathname === "/dashboard/orderDashboard") {
    return "My Orders";
  } else if (pathname === "/dashboard/paymentDashboard") {
    return "Payments";
  } else if (pathname === "/dashboard/paymentWithdraw") {
    return "Payments";
  } else if (pathname === "/dashboard/paymentHistory") {
    return "Payments";
  } else if (pathname === "/dashboard/invoiceHistory") {
    return "Payments";
  } else if (pathname === "/dashboard/reportDashboard") {
    return "Report";
  }else if (pathname === "/dashboard/marketPlace") {
    return "Marketplace";
  }else if (pathname === "/dashboard/billing") {
    return "Billing";
  }
};

export const subWebsitePathSet = (pathname: any) => {
  if (pathname === "/dashboard") {
    return "Dashboard";
  } else if (pathname === "/dashboard/publisherWebsites") {
    return "My Websites";
  } else if (pathname === "/dashboard/couponDashboard") {
    return "Coupon";
  } else if (pathname === "/dashboard/orderDashboard") {
    return "My Orders";
  } else if (pathname === "/dashboard/paymentDashboard") {
    return "PAYMENT_DETAILS";
  } else if (pathname === "/dashboard/paymentWithdraw") {
    return "PAYMENT_WITHDRAW";
  } else if (pathname === "/dashboard/paymentHistory") {
    return "PAYMENT_HISTORY";
  } else if (pathname === "/dashboard/invoiceHistory") {
    return "INVOICE_HISTORY";
  } else if (pathname === "/dashboard/reportDashboard") {
    return "Report";
  }else if (pathname === "/dashboard/marketPlace") {
    return "Marketplace";
  }
};


export const adminPathSet = (pathname: any) => {
  if (pathname === "/admin/dashboard") {
    return "Dashboard";
  } else if (pathname === "/admin/taskboard") {
    return "Taskboard";
  }else if (pathname === "/admin/addWebsite") {
    return "Add Website";
  }else if(pathname.includes("/admin/customerDetails")){
    return "Customer Details";
  }else if(pathname === "/admin/blogManagement"){
    return "Blog Management";
  }else if(pathname === "/admin/authors"){
    return "Author’s Biography";
  }else if(pathname.includes("/admin/billing")){
    return "Billing Management";
  }else if(pathname === "/admin/marketPlace"){
    return "Marketplace";
  }else if(pathname.includes("/admin/allWebsites")){
    return "All Websites";
  }else if(pathname.includes("/admin/orders")){
    return "Order Management";
  }

};
