import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../store/reduxHooks";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { checkVErifiedWebsite } from "../../utils/api/addWebsite";
import FileSaver from "file-saver";
import { setFormDisplay } from "../../reducers/addWebsite/addWebsitedataReducer";
type Props = {
  setIsAddWebsite: React.Dispatch<React.SetStateAction<boolean>>;
};
const AddWebsiteFormHtml: React.FC<Props> = ({ setIsAddWebsite }) => {
  const dispatch = useAppDispatch();
  const { addWebsiteState } = useAppSelector((state) => ({
    addWebsiteState: state?.addWebsiteState?.addedWebsiteData,
    formDisplay: state?.addWebsiteState?.formDisplay,
  }));

  const onClickAddWebsiteForm = async (e: any) => {
    e.preventDefault();
    const response = await checkVErifiedWebsite(
      addWebsiteState?.checkEligibility?.websiteEligibility?.websiteUrl,
      "fileBased"
    );
    if (
      response?.status?.code === 200 &&
      response?.data?.websiteVerification?.verification?.isVerified
    ) {
      toast.success("Website added successfully", {
        position: "top-right",
        theme: "colored",
        autoClose: 5000,
      });

      dispatch(
        setFormDisplay({
          begin: false,
          meta: false,
          html: false,
          dns: false,
          details: true,
          isAdd:true,
        })
      );
    } else {
      toast.error("Website not verified", {
        position: "top-right",
        theme: "colored",
        autoClose: 5000,
      });
    }
  };
  const createHtmlFile = (e: any) => {
    e.preventDefault();
    const blob = new Blob([""], {
      type: "text/plain;charset=utf-8",
    });
    FileSaver.saveAs(
      blob,
      `${addWebsiteState?.checkEligibility?.verificationTokens?.htmlFileToken}.html`
    );
  };
  useEffect(() => {}, []);
  return (
    <div className="AddWebsiteFormHtml__con">
      <div className="AddWebsiteFormHtml__Switch__Btn">
        <button
          onClick={(e) => {
            e.preventDefault();
            dispatch(
              setFormDisplay({
                begin: false,
                meta: true,
                html: false,
                dns: false,
                details: false,
                isAdd:true,
              })
            );
          }}
        >
          Meta Tag
        </button>
        <button
          onClick={(e) => {
            e.preventDefault();
            dispatch(
              setFormDisplay({
                begin: false,
                meta: false,
                html: true,
                dns: false,
                details: false,
                isAdd:true,
              })
            );
          }}
        >
          HTML File
        </button>
        <button
          onClick={(e) => {
            e.preventDefault();
            dispatch(
              setFormDisplay({
                begin: false,
                meta: false,
                html: false,
                dns: true,
                details: false,
                isAdd:true,
              })
            );
          }}
          style={{display: 'none' }}
        >
          DNS Record
        </button>
      </div>
      <div className="AddWebsiteFormHtml__con__mid">
        <section className="AddWebsiteFormHtml__con__mid__left">
          <p>1. Login into your hosting provider like godaddy.com</p>
          <p>2. Download the file by hitting the button provided</p>
          <button onClick={(e: any) => createHtmlFile(e)}>
            Verification File
          </button>
          <p>3. Upload the file into your domain’s root folder</p>
          <p>4. Hit Verify button for website verification.</p>
        </section>
        <section className="AddWebsiteFormHtml__con__mid__right">
          <h4>Note -</h4>
          <p>
            Once uploaded successfully, file should be accessible at &nbsp;
            {addWebsiteState?.websiteEligibility?.websiteUrl
              ? addWebsiteState?.checkEligibility?.websiteEligibility
                  ?.websiteUrl
              : `${
                  addWebsiteState?.checkEligibility?.websiteEligibility
                    ?.websiteUrl
                }${
                  addWebsiteState?.checkEligibility?.websiteEligibility
                    ?.websiteUrl?.[
                    addWebsiteState?.checkEligibility?.websiteEligibility
                      ?.websiteUrl?.length - 1
                  ] === "/"
                    ? ""
                    : "/"
                }${
                  addWebsiteState?.checkEligibility?.verificationTokens
                    ?.htmlFileToken
                }.html`}
          </p>
        </section>
      </div>

      <button
        className="AddWebsiteFormMeta__verify__btn"
        onClick={(e: any) => onClickAddWebsiteForm(e)}
      >
        Verify
      </button>
      <ToastContainer />
    </div>
  );
};

export default AddWebsiteFormHtml;
