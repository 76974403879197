import axios from "axios";
import {  DASHBOARD_DATA, CUSTOMER_DATA,ADMIN_STATS } from "../constants/url";

export const getDashboardStats = async () => {
  try {
    const accessToken = sessionStorage.getItem('access-token');
    const response = await axios.get(process.env.REACT_APP_BASE + DASHBOARD_DATA,{ headers: { 'Authorization': 'Bearer '+accessToken}});

    return response.data;
  } catch (err) {
    console.log(err);
    return err;
  }
};
export const getUserStats = async () => {
  try {
    const accessToken = sessionStorage.getItem('access-token');
    const response = await axios.get(process.env.REACT_APP_BASE + CUSTOMER_DATA, { headers: { 'Authorization': 'Bearer '+accessToken}});
    return response.data;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const postUserRole = async (role: string) => {
  try {
    const accessToken = sessionStorage.getItem('access-token');
    const response = await axios.post(process.env.REACT_APP_BASE + CUSTOMER_DATA, {
      role,
    },{ headers: { 'Authorization': 'Bearer '+accessToken}});
    return response?.data;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const postProfileUpdate = async (role: any) => {
  try {
    const accessToken = sessionStorage.getItem('access-token');
    const response = await axios.post(process.env.REACT_APP_BASE + CUSTOMER_DATA, 
      role,{ headers: { 'Authorization': 'Bearer '+accessToken}});
    return response?.data;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const getAdminStats = async () => {
  try {
    const accessToken = sessionStorage.getItem('access-token');
    const response = await axios.get(process.env.REACT_APP_BASE + ADMIN_STATS, { headers: { 'Authorization': 'Bearer '+accessToken}});
    return response.data;
  } catch (err) {
    console.log(err);
    return err;
  }
};