import React from "react";
import "./dashboardMid.scss";
import { useAppSelector } from "../../store/reduxHooks";
import amountPending from '../../assets/dashboardMid/amount-pending.svg'
import amountReceived from '../../assets/dashboardMid/amount-received.svg'
import pendingOrder from '../../assets/dashboardMid/pending-order.svg'
import totalAmount from '../../assets/dashboardMid/total-amount.svg'
import totalOrder from '../../assets/dashboardMid/total-orders.svg'
import totalWebsite from '../../assets/dashboardMid/total-website.svg'
import completedOrder from '../../assets/dashboardMid/completed-order.svg'

const DashboardMid = ({roleType}: {
  roleType: any
}) => {
 const customerData= useAppSelector(state=>state?.dashboardState?.customer)
  return (
    <div className="dashboardMid__Con">
      {roleType === "Publisher" && 
      <div className="dashboardMid__grid__Con">
          <div className="dashboardMid__grid__Item" >
            <img src={totalWebsite} alt="" />
            <div className="dashboardMid__grid__data_Con">
              <p>Total Websites</p>
              <h4>{customerData?.publisherDashboard?.totalPublisherWebsites}</h4>
            </div>
          </div>
          <div className="dashboardMid__grid__Item" >
            <img src={totalOrder} alt="" />
            <div className="dashboardMid__grid__data_Con">
              <p>Total Orders</p>
              <h4>{customerData?.publisherDashboard?.totalPublisherOrder}</h4>
            </div>
          </div>
          <div className="dashboardMid__grid__Item" >
            <img src={pendingOrder} alt="" />
            <div className="dashboardMid__grid__data_Con">
              <p>Pending Orders</p>
              <h4>{customerData?.publisherDashboard?.publisherPendingAmount}</h4>
            </div>
          </div>
          <div className="dashboardMid__grid__Item" style={{display: 'none' }} >
            <img src={totalAmount} alt="" />
            <div className="dashboardMid__grid__data_Con">
              <p>Total Amount</p>
              <h4>{customerData?.publisherDashboard?.publisherTotalAmount}</h4>
            </div>
          </div>
          <div className="dashboardMid__grid__Item" style={{display: 'none' }} >
            <img src={amountReceived} alt="" />
            <div className="dashboardMid__grid__data_Con">
              <p>Amount Received</p>
              <h4>{customerData?.publisherDashboard?.publisherReceivedAmount}</h4>
            </div>
          </div>
          <div className="dashboardMid__grid__Item" style={{display: 'none' }} >
            <img src={amountPending} alt="" />
            <div className="dashboardMid__grid__data_Con">
              <p>Amount Pending</p>
              <h4>{customerData?.publisherDashboard?.publisherPendingAmount}</h4>
            </div>
          </div>
      </div>
    }
    {roleType === "Advertiser" && 
      <div className="dashboardMid__grid__Con">
          <div className="dashboardMid__grid__Item" >
            <img src={totalOrder} alt="" />
            <div className="dashboardMid__grid__data_Con">
              <p>Total Orders</p>
              <h4>{customerData?.advertiserDashboard?.totalAdvertiserOrder}</h4>
            </div>
          </div>
          <div className="dashboardMid__grid__Item" >
            <img src={pendingOrder} alt="" />
            <div className="dashboardMid__grid__data_Con">
              <p>Pending Orders</p>
              <h4>{customerData?.advertiserDashboard?.advertiserPendingOrder}</h4>
            </div>
          </div>
          <div className="dashboardMid__grid__Item" >
            <img src={completedOrder} alt="" />
            <div className="dashboardMid__grid__data_Con">
              <p>Completed Orders</p>
              <h4>{customerData?.advertiserDashboard?.advertiserCompletedOrder}</h4>
            </div>
          </div>
          <div className="dashboardMid__grid__Item" style={{display: 'none' }} >
            <img src={totalAmount} alt="" />
            <div className="dashboardMid__grid__data_Con">
              <p>Amount Spent</p>
              <h4>{customerData?.advertiserDashboard?.advertiserSpentAmount}</h4>
            </div>
          </div>
      </div>
    }
      <p className="dashBoardMid__Footer">
        © 2024 Powered By Outreach Kart | All Rights Reserved
      </p>
    </div>
  );
};

export default DashboardMid;
