import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AddWebsiteDetailsPayload } from "../../utils/types/main";
interface ItaskboardSisplay {
  isActive: boolean;
  total: number;
  new: number;
  edit: number;
  selected: "New" | "Edit";
  isReload:boolean;
}

interface IadminWebsiteDetails {
  isLoading: boolean;
  pageCount:number;
  data: AddWebsiteDetailsPayload[];
  pageInfo: { hasNext: boolean; totalCount: number };
  isNewWebsiteApproval:boolean;
}

type InitialState = {
  taskboardDisplay: ItaskboardSisplay;
  adminWebsiteDetails: IadminWebsiteDetails;
};

const initialState: InitialState = {
  taskboardDisplay: {
    isActive: false,
    total: 0,
    new: 0,
    edit: 0,
    selected: "New",
    isReload: false,
  },
  adminWebsiteDetails: {
    isLoading: false,
    pageCount:0,
    data: [],
    pageInfo: { hasNext: false, totalCount: 0 },
    isNewWebsiteApproval: true,
  },
};

const dashboardStatsSlice = createSlice({
  name: "adminState",
  initialState,
  reducers: {
    setTaskboardDisplay: (state, action: PayloadAction<ItaskboardSisplay>) => {
      state.taskboardDisplay = action.payload;
    },
    setAdminWebsiteDetails: (
      state,
      action: PayloadAction<IadminWebsiteDetails>
    ) => {
      state.adminWebsiteDetails = action.payload;
    },
  },
});
export default dashboardStatsSlice.reducer;

export const { setTaskboardDisplay, setAdminWebsiteDetails } =
  dashboardStatsSlice.actions;
