import './selectRolePage.scss'
import Footer from '../../components/footer/Footer'
import Header from '../../components/Header'
// import SelectRole from '../../components/selectRole/SelectRole'
const SelectRolePage = () => {
  return (
    <div className='SelectRole-bg' >
    <Header/>
    {/* <SelectRole/> */}
    <div className='footer-bg' >
    <Footer/>
    </div>
    </div>
  )
}

export default SelectRolePage