export const nameFilter = (name: string) => {
    if(!name) return '-'
    const nameArray=name.split(' ').map((item:string)=>{
    if(item.length>23){
        return `${item.substring(0,11)}...${item.substring(item.length-8,item.length)}`
    }
    else{
        return `${item}`
    }
}).join(' ')
return nameArray
};


export const designationFilter=(designation:string)=>{
    if(!designation) return '-'
    if(designation.length>48){
                return `${designation.substring(0,48)}...`
    }
    else{
        return designation
    }
}
export const websiteNAMEFilter=(designation:string)=>{
    if(!designation) return '-'
    if(designation.length>24){
                return `${designation.substring(0,18)}...${designation.substring(designation.length-3,designation.length)}`
    }
    else{
        return designation
    }
}
export const orderIdFilter=(designation:string)=>{
    if(!designation) return '-'
    if(designation.length>16){
                return `${designation.substring(designation.length-5,designation.length)}`
    }
    else{
        return designation
    }
}
export const numberDisplay=(number:number,length?:number,unit?:string)=>{
let displayNumber:any=number
    if(!number || number===0 ) return "-"
    if(length)displayNumber.toFixed(length)
    if(unit){
        displayNumber=`${displayNumber} ${unit}`
    }
    
    return displayNumber
}

export const getPriceType=(type?:string)=>{
    if(type === 'GUEST_POST'){
        return "General Post";
    }else if(type === 'LINK_INSERTION'){
        return "Link Insertion";
    }else if(type === 'CRYPTO_POST'){
        return "Crypto/Finance";
    }else if(type === 'ADULT_POST'){
        return "Adult";
    }else if(type === 'CBD_POST'){
        return "CBD/Vape";
    }else{
        return "Casino Post";
    }    
}

export const getStatus=(type?:string)=>{
    if(type === 'PAYMENT_PENDING'){
        return "Payment Pending";
    }else if(type === 'PENDING'){
        return "Pending";
    }else  if(type === 'CANCELLED'){
        return "Cancelled";
    }else if(type === 'IN_PROGRESS'){
        return "In Progress";
    }else  if(type === 'REVISION_NEEDED' || type === 'PUB_REVISION_NEEDED' || type === 'ADV_REVISION_NEEDED' ){
        return "Revision Needed";
    }else if(type === 'REJECTED'){
        return "Rejected";
    }else if(type === 'DELIVERED'){
        return "Delivered";
    }else if(type === 'COMPLETED'){
        return "Completed";
    }else{
        return type;
    }    
}

export const getRemarksHeader=(obj?:any)=>{
    if(obj?.status === 'PUB_REVISION_NEEDED'){
        return "Revision Needed Detail";
    }else{
        return 'Remarks';
    }    
}

export const getRemarksHeaderMsg=(obj?:any)=>{
    if(obj?.status === 'PUB_REVISION_NEEDED'){
        return "Details Entered by Publisher";
    }else{
        return ' ';
    }    
}

export const getRemarksHeaderEdit=(type?:string)=>{
    if(type === 'PUB_REVISION_NEEDED' || type === 'ADV_REVISION_NEEDED'){
        return "Revision Needed";
    }else if(type === 'CANCELLED'){
        return "Cancelled Reason";
    }else if(type === 'REJECTED'){
        return "Order Rejection ";
    }else if(type === 'DELIVERED'){
        return "Deliver Details ";
    }else{
        return '';
    }    
}

export const getRemarksHeaderMsgEdit=(type?:string)=>{
    if(type === 'PUB_REVISION_NEEDED' || type === 'ADV_REVISION_NEEDED'){
        return "Enter Details ";
    }else if(type === 'CANCELLED'){
        return "Enter Details ";
    }else if(type === 'REJECTED'){
        return "Enter Details ";
    }else if(type === 'DELIVERED'){
        return "Enter Details ";
    }else{
        return '';
    }    
}
export const getRemarksPlaceholder=(type?:string)=>{
    if(type === 'PUB_REVISION_NEEDED'){
        return "Enter Details for advertiser"
    }else if(type === 'CANCELLED'){
        return "Enter Cancel Reason";
    }else if(type === 'REJECTED'){
        return "Enter Rejection Details for advertiser";
    }else if(type === 'ADV_REVISION_NEEDED'){
        return "Enter Details for publisher";
    }else if(type === 'DELIVERED'){
        return "Enter Deliver Details for advertiser";
    }else {
        return '';
    }    
}

export const getOrderActionMessage=(type?:string)=>{
    if(type === 'IN_PROGRESS'){
        return "Order accepted";
    }else if(type === 'CANCELLED'){
        return "Order Cancelled";
    }else if(type === 'COMPLETED'){
        return "Order completed";
    }else if(type === 'REJECTED'){
        return "Order rejected";
    }else if(type === 'ADV_REVISION_NEEDED'){
        return "Order sent to publisher for revision";
    }else if(type === 'PUB_REVISION_NEEDED'){
        return "Order sent to advertiser for revision";
    }else if(type === 'DELIVERED'){
        return "Order successfully delivered";
    }else {
        return 'Success';
    }    
}

export const getRemarksbtnName=(type?:string)=>{
    if(type === 'IN_PROGRESS'){
        return "Order Accept";
    }else if(type === 'CANCELLED'){
        return "Order Cancel";
    }else if(type === 'REJECTED'){
        return "Reject";
    }else if(type === 'ADV_REVISION_NEEDED'){
        return "Submit";
    }else if(type === 'PUB_REVISION_NEEDED'){
        return "Submit";
    }else if(type === 'DELIVERED'){
        return "Deliver";
    }else {
        return 'Submit';
    }    
}

export const formatTraffic = (n:any) => {
    if (n < 1e3) return n;
    if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + "K+";
    if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + "M+";
    if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + "B+";
    if (n >= 1e12) return +(n / 1e12).toFixed(1) + "T";
  
  };

  export const getYesNoFromBool=(obj?:boolean)=>{
    if(obj){
        return "Yes";
    }else{
        return "No";
    }    
}

export const getRole=(obj?:string)=>{
    if(obj === "ADVERTISER"){
        return "Advertiser";
    }else if(obj === "PUBLISHER"){
        return "Publisher";
    }else if (obj){
        return "Both";
    }    
}


export const getDateFormatted=(obj?:string)=>{
    if(obj){
        const date = new Date(obj);
        return date.getDate() + "-"+ monthNames[date.getMonth()]+"-"+date.getFullYear();
    }
    return "";   
}

const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
  "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"
];

export const getOrderStatus=(obj?:string)=>{
    if(obj === "PAYMENT_PENDING"){
        return "Payment Pending";
    }else if(obj === "PENDING"){
        return "Pending";
    }else if(obj === "CANCELLED"){
        return "Cancelled";
    }else if(obj === "IN_PROGRESS"){
        return "In Progress";
    }else if(obj === "PUB_REVISION_NEEDED"){
        return "Publisher Revision Needed";
    }else if(obj === "ADV_REVISION_NEEDED"){
        return "Advertiser Revision Needed";
    }else if(obj === "REVISION_NEEDED"){
        return "Revision Needed";
    }else if(obj === "REJECTED"){
        return "Rejected";
    }else if(obj === "DELIVERED"){
        return "Delivered";
    }else if(obj === "COMPLETED"){
        return "Completed";
    }
    return "";
};

export const getAdvertiserActionMsg=(obj?:string)=>{
    if(obj === "PENDING"){
        return "Waiting for publisher to accept the order";
    }else if(obj === "CANCELLED"){
        return "Order cancelled by Advertiser";
    }else if(obj === "IN_PROGRESS"){
        return "Order accepted by advertiser";
    }else if(obj === "PUB_REVISION_NEEDED"){
        return "Publisher requested more information";
    }else if(obj === "ADV_REVISION_NEEDED"){
        return "Advertiser requested more information";
    }else if(obj === "REVISION_NEEDED"){
        return "Revision Needed";
    }else if(obj === "REJECTED"){
        return "Order rejected by publisher";
    }else if(obj === "DELIVERED"){
        return "Order delivered by publisher";
    }else if(obj === "COMPLETED"){
        return "Order Completed";
    }
    return "-";
};

export const getPublisherActionMsg=(obj?:string)=>{
    if(obj === "CANCELLED"){
        return "Order cancelled by Advertiser";
    }else if(obj === "PUB_REVISION_NEEDED"){
        return "Waiting for advertiser to re-submit the order";
    }else if(obj === "ADV_REVISION_NEEDED"){
        return "Advertiser requested more information";
    }else if(obj === "REJECTED"){
        return "Order rejected by publisher";
    }else if(obj === "DELIVERED"){
        return "Waiting for advertiser to review the order";
    }else if(obj === "COMPLETED"){
        return "Order Completed";
    }
    return "-";
};

export const getBillingStatus=(obj?:string)=>{
    if(obj === "DRAFTED"){
        return "Drafted";
    }else if(obj === "SENT"){
        return "Invoice Generated";
    }else if(obj === "PAID"){
        return "Paid";
    }else if(obj === "MARK_AS_PAID"){
        return "Paid or Paid - Offline";
    }else {
        return transformString(obj);
    }
};

export const getInitalCap=(obj?:string)=>{
    return transformString(obj);
};


function transformString(inputString: string|undefined): string {
    if(!inputString){
        return ""
    }
    // Split the input string by underscores
    const words = inputString.split('_');

    // Capitalize the first character of each word and convert to lowercase for the rest
    const transformedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());

    // Join the words with space
    return transformedWords.join(' ');
}