import React from "react";
//import Footer from "../../components/footer/Footer";
import Header from "../../components/Header";
import SignUpMid from "../../components/signupMid/SignUpMid";
import { loggedTrue } from "../../hoc/loggedTrue";

import "./signup.scss";

const Signup = () => {
  return (
    <div className="signup-bg">
      <Header />
      <SignUpMid />
      {/* <div className="footer-bg">
        <Footer />
      </div> */}
    </div>
  );
};

export default loggedTrue(Signup);
