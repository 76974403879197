import React, { useEffect } from "react";
import "./adminTaskboard.scss";

import {
  getDashboardStats,
} from "../../utils/api/dashboardStats";
import withAuth from "../../hoc/withAuth";
import { useAppDispatch } from "../../store/reduxHooks";
import { setCustomerData } from "../../reducers/dashboard/dashboardStatsReduccer";

import "react-toastify/dist/ReactToastify.css";
import SideHeaderAdmin from "../../components/SideHeader/SideHeaderAdmin";

import DashboardHeaderAdmin from "../../components/DashboardHeader/DashboardHeaderAdmin";
import AdminSummaryTable from "../../components/publisherSummaryTable/AdminSummaryTable";

const AdminTaskboard = () => {
  const dispatch = useAppDispatch();
  // const [errorRole, setErrorRole] = useState<{
  //   display: boolean;
  //   message: string;
  // }>({
  //   display: false,
  //   message: "",
  // });
  // const [selectRole, setSelectRole] = useState<{
  //   role: string;
  //   display: boolean;
  // }>({
  //   display: false,
  //   role: "",
  // });

  const getDashboardData = async () => {
    const response = await getDashboardStats();

    if (response?.status?.code === 200) {
      dispatch(setCustomerData(response.data.customer));
    }
  };

  useEffect(() => {
    getDashboardData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <div className={`dashboard_Con   `}>
        <SideHeaderAdmin />

        <div className={`dashboard_Con__Right`}>
          <DashboardHeaderAdmin  />

          <div className="admin_Summary_Table_Parent">
            <AdminSummaryTable />
          </div>
        </div>
      </div>
    </>
  );
};

export default withAuth(AdminTaskboard);
