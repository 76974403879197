import MarketPlaceMid from "../../components/marketPlace/MarketPlaceMid";
import withAuth from "../../hoc/withAuth";
import SideHeaderAdmin from "../../components/SideHeader/SideHeaderAdmin";
import DashboardHeaderAdmin from "../../components/DashboardHeader/DashboardHeaderAdmin";

const AdminMarketPlace = () => {
  return (
    <div className="dashboard_Con">
      <SideHeaderAdmin />
      <div className="dashboard_Con__Right">
        <DashboardHeaderAdmin   />
        <MarketPlaceMid/>
      </div>
    </div>
  );
};

export default  withAuth(AdminMarketPlace);
