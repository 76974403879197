import "./verifyEmail.scss";

import verifyEmail from "../../assets/verifyEmail/verifyEmail.svg";
import signupImage from "../../assets/verifyEmail/signup.svg";
import { Link } from "react-router-dom";
const VerifyEmail = () => {
  return (
    <div className="verifyEmail_Con">
      <div className="verifyEmail_Left">
        <h2>Verify Email</h2>
        <h5>
          An email sent to your registered email address, kindly check and
          verify the same to activate your account.
        </h5>
        <div className="verifyEmail_Left_Bottom">
          <div className="verify-checkmark_icon">
            {" "}
            <img src={verifyEmail} alt="checkmark" className="checkmark" />
            <p>Verification email sent</p>
          </div>
          <div className="verify_button">
            <Link to="/" className="home-btn">
              Go to Home
            </Link>
            <button className="verifyEmail-register-btn" style={{display: 'none' }} >Resend Link</button>
          </div>
        </div>
      </div>
      <div className="verifyEmail_Right">
        <img src={signupImage} alt="Signup" />
      </div>
    </div>
  );
};

export default VerifyEmail;
