import axios from "axios";
import { FORGOT_RESET_PASSWORD } from "../constants/url";

export const getForgotResetPassword = async (token:string) => {
  try {
    const response = await axios.get(
      process.env.REACT_APP_BASE + FORGOT_RESET_PASSWORD,{headers:{
        'Authorization': `Bearer ${token}` 
      }}
    );
    return response.data;
  } catch (err) {
    console.error(err)
    return err;
  }
};
