import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type InitialState = {
  customer: any;
};

const initialState: InitialState = {
  customer: {},
};

const dashboardStatsSlice = createSlice({
  name: "dashboardState",
  initialState,
  reducers: {
    setCustomerData: (state, action: PayloadAction<any>) => {
      state.customer = action.payload;
    },
  },
});
export default dashboardStatsSlice.reducer;

export const { setCustomerData } = dashboardStatsSlice.actions;
