import SideHeader from "../../components/SideHeader/SideHeader";
import DashboardHeader from "../../components/DashboardHeader/DashboardHeader";
import MarketPlaceMid from "../../components/marketPlace/MarketPlaceMid";
import withAuth from "../../hoc/withAuth";

const MarketPlace = () => {
  const roleType = sessionStorage.getItem("roleType");

  return (
    <div className="dashboard_Con">
      <SideHeader roleType={roleType}/>
      <div className="dashboard_Con__Right">
        <DashboardHeader   />
        <MarketPlaceMid/>
      </div>
    </div>
  );
};

export default  withAuth(MarketPlace);
