import './createpasswordpage.scss';
import Footer from '../../components/footer/Footer'
import Header from '../../components/Header'
import CreatePassword from '../../components/createpassword/CreatePassword';

const CreatePasswordPage = () => {
  return (
<div className='createpassword-bg' >
    <Header/>
    <CreatePassword/>
    <div className='footer-bg' >
    <Footer/>
    </div>
    </div>
  )
}

export default CreatePasswordPage