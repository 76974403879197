import signupImage from "../../assets/signup/login.png";
import google from "../../assets/signup/google.svg";
import fb from "../../assets/signup/fb.svg";
//import eye from "../../assets/signup/eye.svg";
//import Visibilityoff from "../../assets/signup/visibilityoff.svg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./login.scss";
import { useState, useEffect } from "react";
import { AdminLoginPayload, } from "../../utils/types/main";
import { adminLoginInitial } from "../../utils/constants/selected";
//import { loginValidator } from "../../utils/validators/registerValidator";
import { Link } from "react-router-dom";
import { loginAdminPost } from "../../utils/api/login";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../store/reduxHooks";
import { logged, setCustomerProfile } from "../../reducers/login/loginReducer";
import Loader from "../loader/Loader";
import { getUserStats } from "../../utils/api/dashboardStats";
const LoginCustAdmin = () => {
  //const [errorData, SetErrorData] = useState<any>("");
  //const [passwordShown, setPasswordShown] = useState<boolean>(false);
  const [loginData, setLoginData] = useState<AdminLoginPayload>(adminLoginInitial);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const loginSubmit = async (emailId: string) => {
    // const { error, isError } = loginValidator(loginData);

    // if (isError) {
    //   SetErrorData(error);
    // } else {
    //   SetErrorData(error);
    const response = await loginAdminPost(emailId);

    if (response?.status?.code === 200) {
      const token = response?.data?.auth?.accessToken;
      const expiryTime = response?.data?.auth?.expiresAt || (Date.now() + (10 * 60 * 60 * 1000));

      sessionStorage.setItem('adminTokenExpiryTime', '' + expiryTime);
      sessionStorage.setItem('access-token', token);
      sessionStorage.setItem('tokenExpiryTime', '' + expiryTime);
      sessionStorage.setItem('isCustAdminLogin', '' + true);
      setLogoutExpiration(expiryTime);
      const userProfileData = await getUserStats();
      if (userProfileData?.status?.code === 200) {
        dispatch(
          setCustomerProfile(userProfileData?.data?.customer?.customerProfile)
        );
      }

      navigate("/admin/dashboard");
      // dispatch(setIsCustAdminLoggedIn(true));
      dispatch(logged(true));
      setLoading(true);
    } else {
      dispatch(logged(false));
      toast.error(
        "You entered an incorrect email or password. Please try again or try resetting your password.",
        {
          position: "top-right",
          theme: "colored",
          autoClose: 5000,
        }
      );
    }
    // }
  };
  // const togglePasswordVisiblity = () => {
  //   setPasswordShown(passwordShown ? false : true);
  // };

  const setLogoutExpiration = (expireAt: any) => {
    const currentTime = new Date();
    const delay = expireAt - currentTime.getTime();
    if (delay > 0) {
      const timerId = setTimeout(() => {
        dispatch(logged(false));
      }, delay);
      return () => {
        clearTimeout(timerId);
      };
    }
  }

  // const handleKeyPress = (e:any) => {
  //   if (e.key === 'Enter') {
  //     loginSubmit();
  //   }
  // }

  // Listen for messages from the parent window
  window.addEventListener('message', function (event) {
    // Check the origin to ensure it's trusted
    console.log("event   ,,  -->  ", event);
    const url = 'https://dashboard.outreachkart.com';
    if (event.origin === 'https://dev.outreachkart.de' || event.origin === 'http://localhost:3000' ||
      event.origin === url) {
      // Access the data sent from the main site
      var receivedData = event.data;
      console.log(' receivedData  --->>>  ', receivedData)
      if (receivedData?.message) {
        console.log('Original rrrrr  Received message from main site:', receivedData?.emailId);
        // localStorage.setItem('admin-access', receivedData?.message);
        setLoginData({ ...loginData, emailId: receivedData?.emailId })
        sessionStorage.setItem('admin-customer-access-token', receivedData?.message);
        loginSubmit(receivedData?.emailId);
      }
      console.log('Received message from main site:', receivedData);
    }
  });
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="loginnow_Con" style={{ display: "none" }}>
      {loading && <Loader size="lg" />}
      <div className="loginMid_Left" style={{ display: "none" }}>
        <h2>Log in Now</h2>
        <h5>Welcome back! Please enter your details.</h5>
        <div className="loginMid_Left-btn_Con" style={{ display: "none" }}>
          <button>
            <span>
              <img src={google} alt="google" />
            </span>
            Log in with Google
          </button>
          <button>
            <span>
              <img src={fb} alt="facebook" />
            </span>
            Log in with Facebook
          </button>
        </div>
        <div className="loginMid_Left-line_Con" style={{ display: "none" }}>
          <span></span>
          <p>or Log in with email</p>
          <span></span>
        </div>

        <div className="login-input">
          <label htmlFor="">Email Address</label>
          <input
            type="text"
            placeholder="Enter your email address"
            value={loginData.emailId.trim()}
            onChange={(e) =>
              setLoginData({ ...loginData, emailId: e.target.value })
            }

          />

        </div>


        <div className="remember_password " >
          <div style={{ display: "none" }}>
            {" "}
            <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike" />
            <label htmlFor="vehicle1"> Remember me</label>
          </div>
          <Link to="/resetPassword" style={{ textDecoration: "none" }}>
            <h5>Forgot password?</h5>
          </Link>
        </div>
        <div className="login-btn_area">
          {/* <button className="login-btn" onClick={() => loginSubmit()}>
            Login
          </button> */}
        </div>
        <ToastContainer />
        <h4>
          Don’t have an account?{" "}
          <Link to="/signup" style={{ textDecoration: "none" }}>
            {" "}
            <span>Sign Up for Free</span>
          </Link>
        </h4>
      </div>
      <div className="loginMid_Right">
        <img src={signupImage} alt="Signup" />
      </div>
    </div>
  );
};

export default LoginCustAdmin;
