import { useState, useEffect } from "react";
import "./paymentWithdraw.scss";
import SideHeader from "../../components/SideHeader/SideHeader";

import DashboardHeader from "../../components/DashboardHeader/DashboardHeader";
//import MarketPlaceMid from "../../components/marketPlace/MarketPlaceMid";
import { getPaymentStatus, withdraw } from "../../utils/api/payment";
import withAuth from "../../hoc/withAuth";
import { Link } from "react-router-dom";
import Popup from '../../components/popup/popup';
import { ToastContainer, toast } from "react-toastify";
import { getDashboardStats } from "../../utils/api/dashboardStats";
import AppLoader from "../../components/defaultLoader/DefaultLoader";

const PaymentWithdraw = () => {
  const roleType = sessionStorage.getItem("roleType");
  const [taxFormStatus, setTaxFormStatus] = useState<string>("");
  const [isPayable, setIsPayable] = useState<boolean>(false);
  const [availableAmount, setAvailableAmount] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isTipaltiError, setIsTipaltiError] = useState<boolean>(false);

  const getDashboardData = async () => {
    const response = await getDashboardStats();
    console.log(response);
    if (response?.status?.code === 200) {
      setAvailableAmount(response?.data?.customer?.publisherDashboard?.availableAmount + '');
      //dispatch(setCustomerData(response.data.customer));
    }
  };

  const [isWithdrawPopupOpen, setIsWithdrawPopupOpen] = useState(false);
  const togglePopup = () => {
    setIsWithdrawPopupOpen(!isWithdrawPopupOpen);
  }

  const getPaymentStatusAction = async () => {
    const response = await getPaymentStatus();
    if (response?.status?.code === 200) {
      setTaxFormStatus(response?.data?.iframe?.taxFormStatus);
      setIsPayable(response?.data?.iframe?.payable);
      setIsLoading(false);
    } else {
      setIsTipaltiError(true);
      setIsLoading(false);
    }
  };

  const withdrawAction = async () => {
    setIsLoading(true);
    const response = await withdraw();
    if (response?.status?.code === 200) {
      toast.success("Withdrawal request has been submitted successfully.", {
        position: "top-right",
        theme: "colored",
        autoClose: 5000,
      });
      setAvailableAmount(0 + '');
      setIsWithdrawPopupOpen(!isWithdrawPopupOpen);
      setIsLoading(false);
    } else if (response?.response?.status === 400) {
      const errorArray = response?.response?.data?.status?.errors;
      const errorMsg = errorArray[0]['withDrawPriceNotValid'];
      console.log(errorMsg);
      toast.error(errorMsg, {
        position: "top-right",
        theme: "colored",
        autoClose: 5000,
      });
      setIsWithdrawPopupOpen(!isWithdrawPopupOpen);
      setIsLoading(false);
    } else {
      toast.error(" Due to some technical error your request has not been placed. Please try after some time.", {
        position: "top-right",
        theme: "colored",
        autoClose: 5000,
      });
      setIsWithdrawPopupOpen(!isWithdrawPopupOpen);
      setIsLoading(false);
    }
  };


  useEffect(() => {
    getDashboardData();
    getPaymentStatusAction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="dashboard_Con">

      <SideHeader roleType={roleType} />
      <div className="dashboard_Con__Right">
        <DashboardHeader />

        <div className="publisherWebsiteMid__Con">
          {isLoading && <AppLoader size="lg" ></AppLoader>}
          {/* <ToastContainer />
       {isLoading && <AppLoader size="lg" ></AppLoader>} */}
          <div className="OrderSummaryTable__Con">
            <section className="PublisherSummaryTable__Con__Inner">
              <header className="PublisherSummaryTable__Con__Header">
                <p>Payments</p>
              </header>
            </section>
            {isTipaltiError &&
              <div className="textAlignCenter emptyCartMargin">
                <div className="size-40 ">
                  There is some technical issue. Please try after some time. <br />
                </div>
              </div>

            }

            {!isPayable &&
              <div>
                {taxFormStatus === "NOT_SUBMITTED" &&
                  <div className="textAlignCenter emptyCartMargin">
                    <div className="size-40 ">
                      You haven't provided payment details yet. <br />
                      Please provide payment details. <br />
                      <Link to="/dashboard/paymentDashboard" ><button className="paymentDetailsBtn" >
                        Payment Details
                      </button></Link>
                    </div>
                  </div>}
                {taxFormStatus === "PENDING_REVIEW" &&
                  <div className="textAlignCenter emptyCartMargin">
                    <div className="size-40 ">
                      Thanks for providing payment details.  <br />
                      Your documents are being verified. Once approved then you would be eligible for withdrawing the payment.
                    </div>
                  </div>}
                {taxFormStatus === "PENDING_REVIEW (PENDING_DOCUMENTS)" &&
                  <div className="textAlignCenter emptyCartMargin">
                    <div className="size-40 ">
                      You haven't provided required documents yet.<br />
                      Please provide required documents. <br />
                      <Link to="/dashboard/paymentDashboard" ><button className="paymentDetailsBtn" >
                        Payment Details
                      </button></Link>
                    </div>
                  </div>}
                {taxFormStatus === "PENDING_REVIEW (PENDING_DOCUMENTS_APPROVAL)" &&
                  <div className="textAlignCenter emptyCartMargin">
                    <div className="size-40 ">
                      Thanks for providing payment details. Your documents are being verified. <br />
                      Once approved then you would be eligible for withdrawing the payment.
                    </div>
                  </div>}
                {taxFormStatus === "SUBMITTED (PENDING_DOCUMENTS)" &&
                  <div className="textAlignCenter emptyCartMargin">
                    <div className="size-40 ">
                      You haven't provided required documents yet.  <br /> Please provide required documents.
                      <Link to="/dashboard/paymentDashboard" ><button className="paymentDetailsBtn" >
                        Payment Details
                      </button></Link>
                    </div>
                  </div>}
                {taxFormStatus === "SUBMITTED (PENDING_DOCUMENTS_APPROVAL)" &&
                  <div className="textAlignCenter emptyCartMargin">
                    <div className="size-40 ">
                      Thanks for providing payment details. Your details are being verified. <br />
                      Once approved then you would be eligible for withdrawing the payment.
                    </div>
                  </div>}
                {taxFormStatus === "SUBMITTED (FAILED_TIN_VALIDATION)" &&
                  <div className="textAlignCenter emptyCartMargin">
                    <div className="size-40 ">
                      Verification failed for the provided details.  <br />
                      Please check again and update the details.
                      <Link to="/dashboard/paymentDashboard" ><button className="paymentDetailsBtn" >
                        Payment Details
                      </button></Link>
                    </div>
                  </div>}
              </div>
            }
            {isPayable &&
              <div>
                {(taxFormStatus === "SUBMITTED (TIN_VALIDATED)" || taxFormStatus === "SUBMITTED") && isPayable &&
                  <div className="withdrawDiv">
                    Total amount for withdrawal : $ {availableAmount}
                    <button className={`paymentDetailsBtn withdrawBtn ${availableAmount === "0"
                      ? "disabled_btn" : ""} `} disabled={availableAmount === '0'} onClick={() => { togglePopup(); }} >
                      Withdraw
                    </button>
                  </div>
                }
                {(taxFormStatus === "SUBMITTED (PENDING_TIN_VALIDATION)") &&
                  <div className="textAlignCenter emptyCartMargin">
                    <div className="size-40 ">
                      Thanks for providing payment details. Your documents are being verified. <br />
                      Once approved then you would be eligible for withdrawing the payment.
                    </div>
                  </div>}

              </div>

            }

            <ToastContainer />
            {isWithdrawPopupOpen && <Popup
              content={<>
                <div><div className="popup-header">Withdraw</div>
                  <div className="popup-content-withdraw">You have opted to withdraw ${availableAmount}. Request will be processed either on 15th or 27th of month, as applicable.
                  </div>
                  <div className="withdraw-btn-div">
                    <button className="paymentDetailsBtn withdrawBtn" onClick={() => { withdrawAction(); }} > Confirm  </button>
                    <button className="paymentDetailsBtn withdrawBtn disabled_btn" onClick={() => { togglePopup(); }} > Cancel  </button>
                  </div>
                </div>
              </>}
              handleClose={togglePopup}
            />}
          </div>

          <p className="publisherWebsiteMid__Footer">
            © 2024 Powered By Outreach Kart | All Rights Reserved
          </p>
        </div>

      </div>

    </div>
  );
};

export default withAuth(PaymentWithdraw);
