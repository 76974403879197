export const websiteCategoryFilter = (
  setFilteredCategories: React.Dispatch<any>,
  websiteCategories: any,
  searchCategory: any
) => {
//     console.log(searchCategory.toLowerCase(), websiteCategories.filter((websiteCategory: any, index: number) =>
//     websiteCategory?.websiteCategory?.friendlyName
//       .toLowerCase()
//       .includes(searchCategory.toLowerCase())
//   )
// )
  if (searchCategory === "") {
    setFilteredCategories(websiteCategories);
  } else {
    setFilteredCategories(
      websiteCategories.filter((websiteCategory: any, index: number) =>
        websiteCategory?.friendlyName
          .toLowerCase()
          .startsWith(searchCategory.toLowerCase())
      )
    );
  }
};

export const blogCategoryFilter = (
  setFilteredCategories: React.Dispatch<any>,
  blogCategories: any,
  searchCategory: any
) => {
//     console.log(searchCategory.toLowerCase(), websiteCategories.filter((websiteCategory: any, index: number) =>
//     websiteCategory?.websiteCategory?.friendlyName
//       .toLowerCase()
//       .includes(searchCategory.toLowerCase())
//   )
// )
  if (searchCategory === "") {
    setFilteredCategories(blogCategories);
  } else {
    setFilteredCategories(
      blogCategories.filter((blogCategory: any, index: number) =>
      blogCategory?.title
          .toLowerCase()
          .startsWith(searchCategory.toLowerCase())
      )
    );
  }
};