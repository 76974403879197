import React from "react";
type Props = {
  background: string;
  color: string;
};

const PublisherSvg: React.FC<Props> = ({ background, color }) => {
  return (
   <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="50" cy="50" r="50" fill={background} fillOpacity="0.08"/>
<path d="M71.884 26.0026H62.9374C62.8746 25.9991 62.8097 25.9991 62.7464 26.0026C60.3401 26.18 60.5258 29.7675 62.9374 29.6958H67.6959L56.0794 41.3098C54.3337 43.0555 56.953 45.6739 58.6982 43.9281L70.3146 32.3038V37.0719C70.3707 39.4802 73.9553 39.4802 74.0089 37.0719V28.0836C74.0089 26.6254 73.4366 26.0024 71.884 26.0024V26.0026ZM27.6667 26.0026C26.7205 26.0957 25.9995 26.8917 26 27.8423V72.1607C26.0039 73.1762 26.8278 73.9981 27.8436 74H72.1695C73.184 73.9961 74.0049 73.1752 74.0093 72.1607V49.9541C73.9532 47.5459 70.3687 47.5459 70.315 49.9541V70.3075H29.6981V29.6956H50.0952C52.5039 29.642 52.5039 26.056 50.0952 26.0023H27.8436C27.7876 25.9994 27.7257 25.9994 27.6672 26.0023L27.6667 26.0026ZM35.2316 40.765C32.7663 40.765 32.7663 44.4616 35.2316 44.4616H44.4633C46.9286 44.4616 46.9286 40.765 44.4633 40.765H35.2316ZM35.0405 48.1549C32.5202 48.23 32.7171 52.0339 35.2316 51.8482H57.3883C59.9062 51.9042 59.9062 48.0989 57.3883 48.1549L35.2316 48.1554C35.1687 48.152 35.1039 48.152 35.0405 48.1554V48.1549ZM35.0405 55.5415C32.5767 55.6701 32.7678 59.3668 35.2316 59.2381H64.7776C67.2414 59.2366 67.2414 55.54 64.7776 55.5415L35.2316 55.5419C35.1687 55.5385 35.1039 55.5385 35.0405 55.5419V55.5415Z" fill={color}/>
</svg>

  );
};

export default PublisherSvg;
// fill="#525B88"