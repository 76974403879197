import PublisherSvg from "../../assets/role/PublisherSvg";
import AdvertiserSvg from "../../assets/role/AdvertiserSvg";
import BothSvg from "../../assets/role/BothSvg";
import Rectangle from "../../assets/role/Rectangle.svg";

import "./selectRole.scss";
import React, { useState } from "react";
import { SelectedRole } from "../../utils/types/main";
import {
  allUnselected,
  publisherSelected,
  advertisementSelected,
  bothSelected,
} from "../../utils/constants/selected";

type Props = {
  setUserRole: any;
  className: string;
};

const SelectRole: React.FC<Props> = ({ setUserRole, className }) => {
  const [curRole, setCurRole] = useState<SelectedRole[]>(allUnselected);
  return (
    <>
      <div className={`selectRole__dashboard__Con ${className}`}>
        <div className="selectRole_Con">
          <div className="selectRoleMid">
            <h2>Select Your Primary Interest</h2>
            <div className="role_card_box">
              <div
                className="role_card"
                onClick={() => {
                  setCurRole(publisherSelected);
                }}
              >
                <img
                  src={curRole[0].image}
                  alt="checkmark"
                  className="checkmark_icon"
                />
                <div className="role_card_con">
                  <div className=".role_icon">
                    <PublisherSvg
                      background={curRole[0].background}
                      color={curRole[0].color}
                    />
                  </div>

                  <h5>I’m a Publisher</h5>
                  <div>
                    <p>
                      {" "}
                      <span>
                        <img src={Rectangle} alt="Rectangle" />{" "}
                      </span>{" "}
                      Publish original content and make money
                    </p>
                    <p>
                      <span>
                        <img src={Rectangle} alt="Rectangle" />{" "}
                      </span>
                      Unique, Relevant, and Quality Content
                    </p>
                    <p>
                      <span>
                        <img src={Rectangle} alt="Rectangle" />{" "}
                      </span>
                      Quality Support and Easy Payouts
                    </p>
                  </div>
                </div>
              </div>
              <div
                className="role_card"
                onClick={() => {
                  setCurRole(advertisementSelected);
                }}
              >
                <img
                  src={curRole[1].image}
                  alt="checkmark"
                  className="checkmark_icon"
                />
                <div className="role_card_con">
                  <div className=".role_icon">
                    <AdvertiserSvg
                      background={curRole[1].background}
                      color={curRole[1].color}
                    />
                  </div>

                  <h5>I’m a Advertiser</h5>
                  <div>
                    <p>
                      {" "}
                      <span>
                        <img src={Rectangle} alt="Rectangle" />{" "}
                      </span>{" "}
                      Quality general post on relevant platform
                    </p>
                    <p>
                      <span>
                        <img src={Rectangle} alt="Rectangle" />{" "}
                      </span>
                      Boost organic and referral traffic
                    </p>
                    <p>
                      <span>
                        <img src={Rectangle} alt="Rectangle" />{" "}
                      </span>
                      Wide range of filters to select Websites
                    </p>
                  </div>
                </div>
              </div>
              <div
                className="role_card"
                onClick={() => {
                  setCurRole(bothSelected);
                }}
              >
                <img
                  src={curRole[2].image}
                  alt="checkmark"
                  className="checkmark_icon"
                />
                <div className="role_card_con">
                  <div className=".role_icon">
                    <BothSvg
                      background={curRole[2].background}
                      color={curRole[2].color}
                    />
                  </div>
                  <h5>Both</h5>
                  <div>
                    <p>
                      {" "}
                      <span>
                        <img src={Rectangle} alt="Rectangle" />{" "}
                      </span>{" "}
                      Quality general post on relevant platform
                    </p>
                    <p>
                      <span>
                        <img src={Rectangle} alt="Rectangle" />{" "}
                      </span>
                      Boost organic and referral traffic
                    </p>
                    <p>
                      <span>
                        <img src={Rectangle} alt="Rectangle" />{" "}
                      </span>
                      Quality Support and Easy Payouts
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="role_button">
              <button
                className={`selectRole-btn ${curRole === allUnselected ? "select-btn-disabled" : ""}`}
                onClick={() =>
                  setUserRole(
                    curRole?.filter((role: any) => role.selected)[0]?.role
                  )
                }
                disabled={curRole === allUnselected}
              >
                Get Started
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="overlay"></div>
    </>
  );
};
export default SelectRole;
