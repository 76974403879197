import axios from "axios";
import { ForgotPasswordPayload } from "../types/main";
import { FORGOT_PASSWORD } from "../constants/url";

export const forgotPasswordPost = async (
  forgotPasswordData:ForgotPasswordPayload,
  recaptchaToken :string | undefined
) => {
  try {
    const response = await axios.post(process.env.REACT_APP_BASE + FORGOT_PASSWORD,forgotPasswordData,
      {headers: {'X-Recaptcha-Token': recaptchaToken }});

    return response.data;
  } catch (err) {
    return err;
  }
};
